import {
  Table,
  Grid,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  MenuItem,
} from "@mui/material";

import { ComponentHeader, Paper, TextField } from "../../../components";

export default function MemberConfigTable({
  editState,
  pagePermissions,
  formikProps,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Member Config"
          subtitle={
            "Here you can set the number of live chat that should be assigned to member."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Member Name</TableCell>
              <TableCell align="center">Member Email</TableCell>
              <TableCell align="center">Live Chat Allowed</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {formikProps.values.membersConfig.members.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">
                    <TextField
                      select
                      fullWidth
                      formikProps={formikProps}
                      name={
                        "membersConfig.members[" +
                        index +
                        "].no_of_live_user_allowed_to_allot"
                      }
                      disabled={!(editState && pagePermissions.update)}
                    >
                      <MenuItem value={0}>0 </MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2 </MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Paper>
  );
}
