import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import pages from "../../constants/pages";
import Table from "./lead list components/Table";

export default function LeadList({ pageID }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { pagePermissions, loader } = useComponent({ pageID });

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [users, setUsers] = useState({ paginatedResults: [], totalCount: 0 });
  const [columns, setColumns] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    loader.start(2);
    //label list
    axios({
      url: `/label/${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();
        setLabels(response.data);
      }
    });
    axios({
      url: "/leads/get_leads?agent_id=" + activeBot.ID + "&skip=0&limit=50",
      method: "POST",
      data: {},
    }).then((response) => {
      if (response.status) {
        setUsers({
          users: response.data.paginatedResults,
          totalCount: response.data.totalCount[0]?.count || 0,
        });

        loader.apiComplete();
      }
    });

    axios({
      url: "/leads/members_list",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setMemberList(response.data);
      }
    });

    axios({
      url:
        "/custom_fields/get_fields_for_agent?agent_id=" +
        activeBot.ID +
        "&is_active=1",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setColumns(response.data);
        loader.apiComplete();
      }
    });
  }, [activeBot.ID, dispatch, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Lead list"
        addIcon={[
          "Create Lead",
          pages.leadCreate.route,
          pagePermissions.create,
        ]}
      />

      <Table
        userData={users}
        column={columns}
        setUsers={setUsers}
        activeBot={activeBot}
        memberList={memberList}
        labels={labels}
      />
    </Loader>
  );
}
