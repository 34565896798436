import { SketchPicker } from "react-color";
import { useRef, useState, useEffect } from "react";
import { Info, ColorLens } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  IconButton,
  MenuItem,
  Tooltip,
} from "@mui/material";

import {
  Paper,
  TextField,
  TextFieldWithIcon,
  ComponentHeader,
} from "../../../../components";

export default function Header({ formikProps, editState }) {
  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        left: "40%",
      },
    },
  };

  const [foregroundColor, setForegroundColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);

  function handleForeground() {
    setForegroundColor(!foregroundColor);
  }

  function handleBackground() {
    setBackgroundColor(!backgroundColor);
  }

  let ClickForeGroundLens = (handler) => {
    let foregroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!foregroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return foregroundNode;
  };

  let foregroundNode = ClickForeGroundLens(() => {
    setForegroundColor(false);
  });

  let ClickBackGroundLens = (handler) => {
    let backgroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!backgroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return backgroundNode;
  };

  let backgroundNode = ClickBackGroundLens(() => {
    setBackgroundColor(false);
  });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Header Settings"
          subtitle=" You can change all your header settings here! Expand it to make
                changes"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="windowHeaderTitle"
          label="Title"
          required
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values.windowHeaderTitle.length}/20
                <Tooltip title="he main title of your bot. This could be the name of your business or any virtual avatar.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="windowHeaderCaption"
          label="Caption(optional)"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{
            maxLength: 30,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values.windowHeaderCaption.length}/30
                <Tooltip title="With caption you can provide extra information about your chatbot or use-case">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          name="fontFamily"
          label="Font Family"
          value={formikProps.values.fontFamily}
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={"sans-serif"}>Sans-serif</MenuItem>
          <MenuItem value={"'Roboto', sans-serif"}>Roboto, sans-serif</MenuItem>
          <MenuItem value={"'Open Sans', sans-serif"}>
            Open, sans-serif
          </MenuItem>
          <MenuItem value={"'Lato', sans-serif"}>Lato, sans-serif</MenuItem>
          <MenuItem value={"'Noto Sans', sans-serif"}>
            Noto, sans-serif
          </MenuItem>
          <MenuItem value={"'Nunito Sans', sans-serif"}>
            Nunito, sans-serif
          </MenuItem>
          <MenuItem value={"'PT Sans', sans-serif"}>PT, sans-serif</MenuItem>
          <MenuItem value={"'Prompt', sans-serif"}>Prompt, sans-serif</MenuItem>
          <MenuItem value={"'Raleway', sans-serif"}>
            Raleway, sans-serif
          </MenuItem>
          <MenuItem value={"'Quicksand', sans-serif"}>
            Quicksand, sans-serif
          </MenuItem>
          <MenuItem value={"'Ubuntu', sans-serif"}>Ubuntu, sans-serif</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={foregroundNode}
        >
          {foregroundColor && (
            <SketchPicker
              styles={pickerStyle}
              display={foregroundColor}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  windowHeaderForeground: hex,
                }));
              }}
              color={formikProps.values.windowHeaderForeground}
            />
          )}

          <TextField
            label="Foreground Colour"
            formikProps={formikProps}
            case="none"
            name="windowHeaderForeground"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values.windowHeaderForeground,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={handleForeground}
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={backgroundNode}
        >
          {backgroundColor && (
            <SketchPicker
              styles={pickerStyle}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  windowHeaderBackground: hex,
                }));
              }}
              color={formikProps.values.windowHeaderBackground}
            />
          )}

          <TextFieldWithIcon
            disabled
            label="Background Colour"
            formikProps={formikProps}
            case="none"
            name="windowHeaderBackground"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values.windowHeaderBackground,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={handleBackground}
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}
