import convertDate from "../../../utils/convertDate";
import { titleCase } from "change-case-all";

export const groupDetailsObject = {
  ID: "",
  groupName: "",
  groupStatus: "",
  totalUsers: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData.group_id,
    groupName: titleCase(responseData.group_name),
    groupStatus: responseData.is_active,
    totalUsers: responseData.total_users,
    createdBy: responseData.created_by,
    updatedBy: responseData.updated_by,
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
  };
}

export function objectFromFormData(formData) {
  return {
    group_name: titleCase(formData.groupName),
    is_active: formData.groupStatus,
  };
}
