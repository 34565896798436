import {
  Grid,
  Typography,
  Paper as MuiPaper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { AddCircleOutlined, RemoveCircleOutlined } from "@mui/icons-material";

import styled from "styled-components";
import { useAxios } from "../../../../hooks";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  border-radius: 0;
  flex-direction: column;
  padding: 15px 0 15px 10px;
`;

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;

export default function LeadScore({
  pagePermissions,
  leadScore,
  setLeadScore,
  formData,
}) {
  const axios = useAxios();

  function updateLeadScore(status) {
    axios({
      url: "/leads/lead_score",
      method: "PATCH",
      disableRedirect: true,
      data: {
        lead_id: formData.ID,
        positive: status,
      },
    }).then((response) => {
      if (response.status) {
        setLeadScore(response.data.lead_score);
      }
    });
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={5}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ColoredTypography variant="h2">
                <IconButton
                  onClick={() => updateLeadScore(false)}
                  disableFocusRipple
                  style={{ padding: "0 5px" }}
                  disabled={!pagePermissions.update}
                >
                  <Tooltip title="Decrease lead score.">
                    <RemoveCircleOutlined
                      style={{ fontSize: "25px", color: "red" }}
                    />
                  </Tooltip>
                </IconButton>
                {leadScore}
                <IconButton
                  onClick={() => updateLeadScore(true)}
                  disableFocusRipple
                  disabled={!pagePermissions.update}
                  style={{ padding: "0 5px" }}
                >
                  <Tooltip title="Increase lead score.">
                    <AddCircleOutlined
                      style={{ fontSize: "25px", color: "green" }}
                    />
                  </Tooltip>
                </IconButton>
              </ColoredTypography>
              <Typography variant="h5"> Lead Score</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
