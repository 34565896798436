import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Stats from "./Stats";
import Table from "./Table";
import { useComponent } from "./../../hooks";
import useAxios from "./../../hooks/useAxios";
import Loader from "./../../components/Loader";
import PageHeader from "./../../components/PageHeader";

export default function LiveChatInsightList({ pageID }) {
  const axios = useAxios();

  const { activeBot } = useSelector((state) => state.memberDetails);

  const { loader } = useComponent({ pageID });

  const [insightData, setInsightData] = useState();

  useEffect(() => {
    loader.start();

    axios({
      url: "/livechat/livechat_details/" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setInsightData(response.data);
      }
      loader.apiComplete();
    });
  }, [activeBot]);

  return (
    <Loader>
      <PageHeader pageName="Live Chat Insight" />

      <Stats insightData={insightData} />

      <Table insightData={insightData} setInsightData={setInsightData} />
    </Loader>
  );
}
