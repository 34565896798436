const pages = {
  //Dashboard Module
  home: {
    ID: "home",
    route: "/",
  },
  profile: {
    ID: "profile",
    route: "/profile",
  },

  businessProfile: {
    ID: "businessProfile",
    route: "/business-profile",
  },

  resetPassword: {
    ID: "resetPassword",
    route: "/reset-password",
  },

  settings: {
    ID: "settings",
    route: "/settings",
  },
  signIn: {
    ID: "signIn",
    route: "/sign-in",
  },
  signUp: {
    ID: "signUp",
    route: "/sign-up",
  },

  //Member Module
  memberList: {
    ID: "18a82d6aa4cb43399fe2eeb8bcc5056f",
    route: "/members/all",
  },
  memberCreate: {
    ID: "3da0752589fd45ccb29160114e446be2",
    route: "/members",
  },
  memberDetails: {
    ID: "3da0752589fd45ccb29160114e446be2",
    route: "/members/",
  },
  memberRegistration: {
    ID: "memberRegistrationPage",
    route: "/member/register/",
  },

  //Insights Module
  insights: {
    ID: "782cf785c1b64f8a85a4645a59de83b1",
    route: "/insights",
  },

  //Bot Module
  botList: {
    ID: "ac2723f3fc1d44d5971f7eb8b3061f5d",
    route: "/bots/all",
  },
  botDetails: {
    ID: "80cff930101d401c9ad384716d59b32d",
    route: "/bots/",
  },

  //Template Module
  templateList: {
    ID: "9c3ec78137d34617b29bb5f8b4cfe685",
    route: "/templates/all",
  },
  templateCreate: {
    ID: "f91270b6e9084d398d494fe0930ff94d",
    route: "/template",
  },
  templateDetails: {
    ID: "f91270b6e9084d398d494fe0930ff94d",
    route: "/template/",
  },
  templateClone: {
    ID: "f91270b6e9084d398d494fe0930ff94d",
    route: "/template/clone/",
  },

  //Leads Module
  leadList: {
    ID: "f3cb235e07474382bcc927e41f2df412",
    route: "/leads/all",
  },
  leadCreate: {
    ID: "a7dcf2b995d645c1b657fdac59f77c45",
    route: "/leads",
  },
  leadDetails: {
    ID: "a7dcf2b995d645c1b657fdac59f77c45",
    route: "/leads/",
  },

  //Custom Fields Module
  customFieldList: {
    ID: "10fa8000966245f48b627d2e4f3dad70",
    route: "/custom-fields/all",
  },
  customFieldCreate: {
    ID: "e6b8a1ec5e654db1bcdde4187343cd2a",
    route: "/custom-fields",
  },
  customFieldDetails: {
    ID: "e6b8a1ec5e654db1bcdde4187343cd2a",
    route: "/custom-fields/",
  },

  //Broadcast Module
  broadcastList: {
    ID: "7804eb28a6ff49ebb56159f82e5ef7dd",
    route: "/broadcasts/all",
  },
  broadcastCreate: {
    ID: "2d0a592d2af6422db56e3e67859921ee",
    route: "/broadcast",
  },
  broadcastDetails: {
    ID: "2d0a592d2af6422db56e3e67859921ee",
    route: "/broadcast/",
  },

  //Group Module
  groupList: {
    ID: "064930d101f649c991a1aeceaa520ef0",
    route: "/groups/all",
  },
  groupDetails: {
    ID: "064930d101f649c991a1aeceaa520ef0",
    route: "/group/",
  },

  //Whatsapp Business Registration Module
  whatsappBusinessRegistration: {
    ID: "whatsappBusinessRegistration",
    route: "/business/registration/",
  },
  whatsappBusinessRegistrationCreate: {
    ID: "whatsappBusinessRegistrationCreate",
    route: "/business/registration/new",
  },
  whatsappBusinessRegistrationDetails: {
    ID: "whatsappBusinessRegistrationDetails",
    route: "/business/registration/details/",
  },

  //Label Module
  labelList: {
    ID: "499992e305ca49cdb7dff34426f8d9db",
    route: "/labels/all",
  },
  labelCreate: {
    ID: "d294e49d915045ac9a088cb746a44bd7",
    route: "/labels",
  },
  labelDetails: {
    ID: "d294e49d915045ac9a088cb746a44bd7",
    route: "/labels/",
  },

  //Utterance Module
  utteranceList: {
    ID: "5c43cafc912140b78d9276261c140271",
    route: "/utterances/all",
  },
  utteranceCreate: {
    ID: "fe25df4e34a647d382d8e107ae968404",
    route: "/utterances",
  },
  utteranceDetails: {
    ID: "fe25df4e34a647d382d8e107ae968404",
    route: "/utterance/",
  },

  //Webhook Module
  webhookList: {
    ID: "testWebhookList",
    route: "/webhooks/all",
  },
  webhookCreate: {
    ID: "testWebhookDetails",
    route: "/webhooks",
  },
  webhookDetails: {
    ID: "testWebhookDetails",
    route: "/webhooks/",
  },

  //Integration Module
  integrationList: {
    ID: "2169341e34974a6bafad25c3ccad2c19",
    route: "/integrations/all",
  },
  integrationCreate: {
    ID: "5f77b1bdd6f940c6b663cce26a82d37d",
    route: "/integrations",
  },
  integrationDetails: {
    ID: "5f77b1bdd6f940c6b663cce26a82d37d",
    route: "/integrations/",
  },

  //Chats Module
  chats: {
    ID: "6881dccbc50b4303a87b50e186985964",
    route: "/chats/",
  },

  //Permission Groups
  permissionsGroupList: {
    ID: "f83f3b18e60f46c3a1a1679f97ac9fb3",
    route: "/permission-groups/all",
  },
  permissionsGroupDetails: {
    ID: "1a538f2b1aaf4600bacc7b24d5181846",
    route: "/permission-groups/",
  },

  //Bot Builder

  botPhrases: {
    ID: "4c2bc28769864ce68c1cedd7ef19b2f1",
    route: "/training-phrases",
  },

  botEntityList: {
    ID: "fe54a7f361064a1b835d27c502438ede",
    route: "/entity-list",
  },
  botEntityDetails: {
    ID: "3bbc25509b614c72993dc12e046896e0",
    route: "/entity-detail/",
  },
  botEntityCreate: {
    ID: "3bbc25509b614c72993dc12e046896e0",
    route: "/create-entity",
  },

  botIntentList: {
    ID: "ee2790d97ed2498b8b120ad8fdbe00e2",
    route: "/intent-list",
  },
  botIntentDEtails: {
    ID: "aa0159e2e45e49c6ad8b892b893d60a9",
    route: "/intent-detail/",
  },
  botIntentCreate: {
    ID: "aa0159e2e45e49c6ad8b892b893d60a9",
    route: "/create-intent",
  },

  slotList: {
    ID: "bf614dca626d46eea424ebe7cb56e7f7",
    route: "/slots",
  },

  slotCreate: {
    ID: "a4533dfdc89443e0bbac6a496d0fcc64",
    route: "/create-slot",
  },

  slotDetails: {
    ID: "a4533dfdc89443e0bbac6a496d0fcc64",
    route: "/slot-detail/",
  },

  botExample: {
    ID: "4c2bc28769864ce68c1cedd7ef19b2f1",
    route: "/examples",
  },

  // style your bot rest

  styleBot: {
    ID: "11d4651f92e64b58ad18df8be34a137e",
    route: "/style-widget",
  },

  // Bot Flow Builder

  botFlowBuilder: {
    ID: "5eca5d6104454a13a6a66475d70d11c4",
    route: "/bot-flow-builder",
  },

  //pmc routes

  pmcRoute: {
    ID: "11dbf3553d034e488c9ac9c8b9806792",
    route: "/broadcast-pmc-create",
  },

  //broadcast routes

  sailBroadcastEmailList: {
    ID: "ed5b69f59fe24354b4523b74a206068b",
    route: "/email-broadcast-list",
  },

  sailBroadcastEmailDetails: {
    ID: "403fd2d8c0ca403bacf0f29576d623da",
    route: "/email-broadcast-details",
  },

  sailBroadcastSMSList: {
    ID: "1bb96320c85647e3bd1e4957e949fbd8",
    route: "/sms-broadcast-list",
  },

  sailBroadcastSMSDetails: {
    ID: "1bb96320c85647e3bd1e4957e949fbd8",
    route: "/sms-broadcast-details",
  },

  //FAQs routes

  faqsList: {
    ID: "6f864d8045f44986ab83e85496ee5969",
    route: "/faq/all",
  },

  faqsDetail: {
    ID: "e1065a7dbc4046019daec57600bffa2c",
    route: "/faq-details/",
  },

  //Chat GPT routes
  chatGptDetail: {
    ID: "a8fd221f72f34550af62a7d239855779",
    route: "/chat-gpt",
  },

  //Feedback routes
  feedbackDetail: {
    ID: "c416838f9b824ccf9a48fdd8940865cb",
    route: "/feedback",
  },
  //whatsapp details page
  whatsappNumberDetail: {
    ID: "a603aa82c40c4e7e83ad5f56996f5641",
    route: "/whatsapp-details",
  },
  //Canned Messages details page
  cannedMessagesList: {
    ID: "7ffc6be49bcb4edb9cebc958cf795a94",
    route: "/canned-message/all",
  },
  cannedMessagesCreate: {
    ID: "faaf4ed5b4264ed29306d3e48a0c282c",
    route: "/canned-message/",
  },
  // livechat
  liveChatConfig: {
    ID: "9836b2b63fff4723b01199c891046420",
    route: "/livechat-config/",
  },
  liveChatReport: {
    ID: "50c7901707e34201b0d156a8e6d16013",
    route: "/livechat-report/",
  },
  liveChatInsight: {
    ID: "09fb676cada047a2b50c74163b9bc4c6",
    route: "/livechat-Insight/",
  },
  // Chat Transcripts
  chatsTranscripts: {
    ID: "ab7de31d38be449193466d5061c2dcad",
    route: "/chats-transcripts/",
  },
  liveChatRequest: {
    ID: "939638f11e0c4845a92f40ae11b5f388",
    route: "/livechat-request/",
  },
  whatsappOnboarding: {
    ID: "96a06607eb1a4b82913eb7c785a8b5f7",
    route: "/whatsapp-onboarding/",
  },
  createOnboardLink: {
    ID: "createOnboardLink",
    route: "/create-onboard-link/",
  },

  // Email Template Create

  emailTemplateCreate: {
    ID: "e97efe0a4c6c45658076f0ce9165cfd5",
    route: "/email-template",
  },

  emailTemplateDetails: {
    ID: "e97efe0a4c6c45658076f0ce9165cfd5",
    route: "/email-template/",
  },

  emailTemplateList: {
    ID: "cd59dfcca6d1428d86510840505b4087",
    route: "/email-templates/all",
  },

  emailBroadcastList: {
    ID: "ed5b69f59fe24354b4523b74a206068b",
    route: "/email-broadcast/all",
  },

  pushNotificationDetails: {
    ID: "f279daec963745a88515f6c0ee605a6c",
    route: "/push-notification/",
  },

  pushNotificationBroadcastList: {
    ID: "0c414f8b97be4130b368c5aca79fd4bf",
    route: "/push-broadcast/all",
  },

  pushNotificationTemplateCreate: {
    ID: "2202020b76cb4a888d7699ec239580e3",
    route: "/push-notification-template",
  },

  pushNotificationTemplateDetails: {
    ID: "2202020b76cb4a888d7699ec239580e3",
    route: "/push-notification-template/",
  },

  pushNotificationTemplateList: {
    ID: "82b3113d666e49efa1c4e55758f0c113",
    route: "/push-notification-templates/all",
  },

  //greviance module

  grevianceList: {
    ID: "3c08b5e692b6434793233fc71f11b1ee",
    route: "/grievance/all",
  },

  grevianceDetails: {
    ID: "56d06ffa7ca24c0db690d58cbd62f97c",
    route: "/grievance-details/",
  },

  //FCM

  FCM: {
    ID: "82b3113d666e49efa1c4e55758f0c113",
    route: "/fcm/",
  },
};

export default pages;
