import styled from "styled-components";

import UserList from "./main window components/UserList";
import Conversations from "./main window components/Conversations";
import { useState } from "react";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  gap: 5px;
`;

export default function MainWindow({
  userList,
  setUserList,
  chatState,
  setChatState,
  userState,
  setUserState,
  searchQuery,
  setSearchQuery,
  senderID,
  setSenderID,
}) {
  const [chatEnd, setChatEnd] = useState("");
  return (
    <Wrapper>
      <UserList
        userList={userList}
        setUserList={setUserList}
        userState={userState}
        setUserState={setUserState}
        chatState={chatState}
        setChatState={setChatState}
        chatEnd={chatEnd}
        setChatEnd={setChatEnd}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        senderID={senderID}
        setSenderID={setSenderID}
      />

      <Conversations
        setUserList={setUserList}
        userState={userState}
        setUserState={setUserState}
        setChatState={setChatState}
        userList={userList}
        chatEnd={chatEnd}
        setChatEnd={setChatEnd}
      />
    </Wrapper>
  );
}
