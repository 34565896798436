import { Fragment } from "react";
import { Formik } from "formik";

import {
  validationSchema,
  groupDetailsObjectFromFormData,
  permissionsObjectFromFormData,
} from "./permissionsGroupDetailsObjects";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import PagePermissionsDisplay from "./PagePermissionsDisplay";

export default function Form({
  editState,
  setEditState,
  formData,
  setFormData,
  botPermissions,
  pagePermissions,
}) {
  const { axios, alert } = useComponent();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={async (formData, { setSubmitting }) => {
        const updateGroupResponse = await axios({
          url: `/permission/update/group/${formData.permissionsGroupID}`,
          method: "PUT",
          data: groupDetailsObjectFromFormData(formData),
          disableRedirect: true,
        });

        const updatePermissionsResponse = await axios({
          url: `/permission/${formData.permissionsGroupID}`,
          method: "POST",
          data: permissionsObjectFromFormData(formData.botPermissions),
          disableRedirect: true,
        });

        if (updateGroupResponse.status && updatePermissionsResponse.status) {
          setFormData(formData);
          setEditState(false);

          alert.success("Group Details updated successfully");
        }

        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
          />

          <PagePermissionsDisplay
            editState={editState}
            botPermissions={botPermissions}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
          />

          <ButtonFooter
            ID={formikProps.values.permissionsGroupID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
