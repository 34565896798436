import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxios, useLoader, useAlert } from "./index";

export default function useComponent({
  pageID = "",
  baseURL = "adminV2",
  customBaseURL = "",
  customAuthToken = "",
  disableAuthToken = false,
} = {}) {
  const dispatch = useDispatch();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const { startLoader, stopLoader, apiComplete } = useLoader();

  const { alert: genericAlert } = useAlert();

  let pagePermissions = useRef({
    read: false,
    create: false,
    update: false,
    delete: false,
  }).current;

  if (!!activeBot && !!pageID) {
    let findPagePermissions = activeBot.pagePermissions.find(
      (page) => page.ID === pageID
    );

    if (!!findPagePermissions) {
      pagePermissions = findPagePermissions;
    }
  }

  const axios = useRef(
    useAxios({
      baseURL,
      customBaseURL,
      customAuthToken,
      disableAuthToken,
    })
  ).current;

  const loader = useRef({
    start: (apiCount) => dispatch(startLoader(apiCount)),

    stop: () => dispatch(stopLoader()),

    apiComplete: (apiCount) => dispatch(apiComplete(apiCount)),
  }).current;

  const alert = useRef({
    success: (message) =>
      dispatch(genericAlert({ type: "success", message: message })),

    info: (message) =>
      dispatch(genericAlert({ type: "info", message: message })),

    warning: (message) =>
      dispatch(genericAlert({ type: "warning", message: message })),

    error: (message) =>
      dispatch(genericAlert({ type: "error", message: message })),
  }).current;

  return {
    pagePermissions,
    axios,
    loader,
    alert,
  };
}
