import * as Yup from "yup";
import { Formik } from "formik";
import { Fragment, useState } from "react";

import Form from "./Form";
import { useAlert, useAxios } from "../../hooks";
import { Paper } from "../../components";
import PageHeader from "./../../components/PageHeader";
import { useDispatch } from "react-redux";

export default function ResetPassword() {
  const axios = useAxios();

  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  return (
    <Fragment>
      <PageHeader pageName="Reset Password" />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Paper style={{ width: "600px" }}>
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(formData, { setSubmitting }) => {
              axios({
                url: "/change/password",
                method: "PUT",
                data: {
                  old_password: formData.oldPassword,
                  password1: formData.newPassword,
                  password2: formData.confirmPassword,
                },
                disableRedirect: true,
              })
                .then((resp) => {
                  if (resp.status) {
                    dispatch(
                      alert({
                        message: "Password changed successfully!",
                        type: "success",
                      })
                    );
                    setFormData({
                      oldPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    });
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }}
          >
            {(formikProps) => {
              return <Form formikProps={formikProps} />;
            }}
          </Formik>
        </Paper>
      </div>
    </Fragment>
  );
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("This is required field."),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#@$!."()%:;^+*,?&`~-])[A-Za-z0-9#@$!."()%:;^+*,?&`~-]{8,}$/,
      "Password should be 8 characters long with upper case,lower case, numbers and special characters #@$!.()%:;^+*,?&~-."
    )
    .max(255)
    .required("Please enter password"),
  confirmPassword: Yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Both password need to be the same")
      .required("Please enter confirm password"),
  }),
});
