import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Switch,
  MenuItem,
  IconButton,
  Tooltip,
  Button,
  InputAdornment,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { SketchPicker } from "react-color";

import "../UI Components/whatsapp.css";
import { ColorLens } from "@mui/icons-material";
import {
  TextField,
  TextFieldWithIcon,
  Paper,
  ComponentHeader,
} from "../../../../components";

import StyleModal from "../UI Components/StyleModal";

export default function General({ editState, formikProps, agentId }) {
  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        right: "0%",
      },
    },
  };
  const [styleModal, setStyleModal] = useState(false);
  const [bubbleIconColor, setBubbleIconColor] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [bubbleBackgroundColor, setBubbleBackgroundColor] = useState(false);

  function handleModalOpen() {
    setStyleModal(true);
  }

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        whatsappNumber: "+" + country.dialCode + "",
      }));
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        whatsappNumber: value,
      }));
    }
  };

  function handleForeground() {
    setBubbleIconColor(!bubbleIconColor);
  }

  function handleBackground() {
    setBubbleBackgroundColor(!bubbleBackgroundColor);
  }

  let ClickIconLens = (handler) => {
    let foregroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!foregroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return foregroundNode;
  };

  let foregroundNode = ClickIconLens(() => {
    setBubbleIconColor(false);
  });

  let ClickBackGroundLens = (handler) => {
    let backgroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!backgroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return backgroundNode;
  };

  let backgroundNode = ClickBackGroundLens(() => {
    setBubbleBackgroundColor(false);
  });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"General Settings"}
          subtitle={
            "Customize bot header by adding custom colors, business avatar,caption etc."
          }
          buttons={[<CreateModalButton />]}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="parentUrl"
          label="Domain Name"
          case="none"
          required
          disabled={!editState}
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Domain of your website where you want to add a widget.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneInput
          value={formikProps.values.whatsappNumber}
          onChange={handleChangePhoneNumber}
          inputProps={{
            name: "whatsappNumber",
          }}
          error={Boolean(
            formikProps.touched.whatsappNumber &&
              formikProps.errors.whatsappNumber
          )}
          helperText={
            formikProps.touched.whatsappNumber &&
            formikProps.errors.whatsappNumber
          }
          onBlur={formikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: !editState ? "rgba(0, 0, 0, 0.38)" : "currentColor",
            borderColor: !validPhoneNumber
              ? editState
                ? "#4076d2"
                : " rgba(0, 0, 0, 0.38)"
              : "#f44336",
            padding: "17.5px 14px 18.5px 58px",
            cursor: !editState && "default",
          }}
          disabled={!editState}
        />
        {validPhoneNumber && (
          <span className="validationError">Whatsapp Number is Required</span>
        )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Prefilled Message(Optional)"
          name="preFilledMessage"
          formikProps={formikProps}
          disabled={!editState}
          case="none"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="This message can be used as the initial message for the user.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          White Label &nbsp;
          <Tooltip title="When enabled, Powered by Dolphinchat will be displayed to the users. To disable this contact support@dolphinchat.ai">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.hideDolphinchatLogo === 1 ? false : true}
          name="whiteLabel"
          onChange={formikProps.handleChange}
          disabled
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontSize: "0.9rem" }}>
          Bubble Settings
        </Typography>
        <Typography style={{ color: "#808080" }} variant="body1">
          You can choose the bubble icon colour and the background colour of the
          bubble.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          name="align"
          label="Bubble Position"
          value={formikProps.values.align}
          formikProps={formikProps}
          disabled={!editState}
        >
          <MenuItem value={"left"}>Left</MenuItem>
          <MenuItem value={"right"}>Right</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={backgroundNode}
        >
          {bubbleBackgroundColor && (
            <SketchPicker
              styles={pickerStyle}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  bubbleBackgroundColor: hex,
                }));
              }}
              color={formikProps.values.bubbleBackgroundColor}
            />
          )}
          <TextField
            label="Background Colour"
            formikProps={formikProps}
            case="none"
            name="bubbleBackgroundColor"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values.bubbleBackgroundColor,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={handleBackground}
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={foregroundNode}
        >
          {bubbleIconColor && (
            <SketchPicker
              styles={pickerStyle}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  bubbleIconColor: hex,
                }));
              }}
              color={formikProps.values.bubbleIconColor}
            />
          )}
          <TextFieldWithIcon
            label="Icon Colour"
            formikProps={formikProps}
            case="none"
            name="bubbleIconColor"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values.bubbleIconColor,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={handleForeground}
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <StyleModal
        styleModal={styleModal}
        setStyleModal={setStyleModal}
        agentId={agentId}
      />
    </Paper>
  );
  function CreateModalButton() {
    return (
      <Button color="primary" variant="contained" onClick={handleModalOpen}>
        Add to Website
      </Button>
    );
  }
}
