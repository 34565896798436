import React, { Fragment } from "react";
import { Formik } from "formik";

import FaqFormDisplay from "./FormDisplay";
import { ButtonFooter } from "../../../components";
import { validationSchema } from "../faq details components/formDetailObject";

export default function Form({
  formData,
  setEditState,
  editState,
  pagePermissions,
}) {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formData}
      validateOnMount
    >
      {(formikProps) => (
        <Fragment>
          <FaqFormDisplay
            editState={editState}
            pagePermissions={pagePermissions}
            formikProps={formikProps}
          />
          <ButtonFooter
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
            setEditState={setEditState}
          />
        </Fragment>
      )}
    </Formik>
  );
}
