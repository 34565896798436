import { Alert, AlertTitle, Grid } from "@mui/material";
import { Fragment } from "react";

export default function ErrorCannedMessage({
  duplicateTitle = [],
  duplicateShortcut = [],
  invalidRows = [],
}) {
  return (
    <Fragment>
      <Grid container sx={{ p: "20px" }} spacing={3}>
        {duplicateTitle.length !== 0 && (
          <Grid item>
            <Alert severity="error">
              <AlertTitle>Error Found Duplicate Title</AlertTitle>
              {duplicateTitle.map((items, index) => {
                if (duplicateTitle.length - 1 === index) {
                  return <span key={index}>{items} . </span>;
                }
                return <span key={index}>{items} , </span>;
              })}
            </Alert>
          </Grid>
        )}

        {duplicateShortcut.length !== 0 && (
          <Grid item>
            <Alert severity="error">
              <AlertTitle>Error Found Duplicate Shortcut</AlertTitle>
              {duplicateShortcut.map((items, index) => {
                if (duplicateShortcut.length - 1 === index) {
                  return <span key={index}>{items.slice(1)} . </span>;
                }
                return <span key={index}>{items.slice(1)} ,</span>;
              })}
            </Alert>
          </Grid>
        )}

        {invalidRows.length !== 0 && (
          <Grid item>
            <Alert severity="error" sx={{ width: "100%" }}>
              <AlertTitle>Error Found Invalid Rows</AlertTitle>
              <ul>
                {invalidRows.slice(0, 2).map((items, index) => (
                  <Fragment key={index}>
                    <li>
                      <strong> Row No :</strong> {items.row_no}
                    </li>
                    <li>
                      <strong> Title Row Error :</strong> {items.error.title}
                    </li>
                    <li>
                      <strong> ShortCuts Row Error :</strong>{" "}
                      {items.error.shortcut}
                    </li>
                    <li>
                      <strong> Text Row Error :</strong> {items.error.text}
                    </li>
                    <br />
                  </Fragment>
                ))}
              </ul>
              {invalidRows.length > 2 && (
                <strong>More Than {invalidRows.length - 2}.....</strong>
              )}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
