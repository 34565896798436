import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Fragment, useRef, useState } from "react";

import { convertDate } from "../../../utils";
import { ComponentHeader, Paper, TextField } from "../../../components";
import ViewConversation from "../../leads/lead detail components/ViewConversation";
import { alert } from "../../../redux/slices/alertSlice";

export default function FormDisplay({ formikProps }) {
  const color = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [viewConversation, setViewConversation] = useState(false);

  if (
    formikProps.values.status === "open" ||
    formikProps.values.status === "reopen"
  ) {
    color.current = "#ff9800";
  }

  if (formikProps.values.status === "progress") {
    color.current = "#2196f3";
  }

  if (formikProps.values.status === "closed") {
    color.current = "#4caf50";
  }

  function handleStatus(value) {
    setLoading(true);
    setMenuAnchor(null);

    formikProps.setValues((prev) => ({
      ...prev,
      status: value,
    }));
    dispatch(
      alert({
        type: "success",
        message: "Status updated",
      })
    );
    setLoading(false);
  }

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={"Details"}
            buttons={[
              <Fragment>
                <LoadingButton
                  variant="outlined"
                  loading={loading}
                  sx={{
                    marginLeft: "5px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={(event) => {
                    setMenuAnchor(event.currentTarget);
                  }}
                  style={{ color: color.current, borderColor: color.current }}
                >
                  Ticket Status - {formikProps.values.status}
                </LoadingButton>

                <Menu
                  style={{ marginTop: "10px" }}
                  open={!!menuAnchor}
                  anchorEl={menuAnchor}
                  onClose={() => setMenuAnchor(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={() => handleStatus("open")}>
                    <Typography variant="h6" color={"#ff9800"}>
                      Open
                    </Typography>
                  </MenuItem>

                  <Divider style={{ marginBlock: 7 }} />

                  <MenuItem onClick={() => handleStatus("progress")}>
                    <Typography color={"#2196f3"} variant="h6">
                      In progress
                    </Typography>
                  </MenuItem>

                  <Divider style={{ marginBlock: 7 }} />

                  <MenuItem onClick={() => handleStatus("closed")}>
                    <Typography color={"#4caf50"} variant="h6">
                      Closed
                    </Typography>
                  </MenuItem>

                  <Divider style={{ marginBlock: 7 }} />

                  <MenuItem onClick={() => handleStatus("reopen")}>
                    <Typography color={"#ff9800"} variant="h6">
                      Reopen
                    </Typography>
                  </MenuItem>
                </Menu>
              </Fragment>,
              <Button
                onClick={() => setViewConversation(true)}
                color="primary"
                variant="outlined"
              >
                View Conversation
              </Button>,
            ]}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="sender_id"
            label="Sender ID"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            select
            name="status"
            label="Status"
            formikProps={formikProps}
          >
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="progress">In Progress</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
            <MenuItem value="reopen">Reopen</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="subject"
            label="Subject"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="ticket_id"
            label="Ticket ID"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="message"
            label="Message"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="created_at"
            label="Created At"
            value={convertDate(formikProps.values.created_at)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            name="created_by"
            label="Created By"
            formikProps={formikProps}
          />
        </Grid>
      </Paper>

      <ViewConversation
        viewConversation={viewConversation}
        setViewConversation={setViewConversation}
        formikProps={formikProps}
        conversation={true}
      />
    </Fragment>
  );
}
