import { Formik } from "formik";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonFooter } from "../../../components";
import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";
import pages from "../../../constants/pages";
import { objectFromFormData, validationSchema } from "./cannedMessageObject";

export default function Form({
  formData,
  editState,
  setEditState,
  activeBot,
  CannedMessageId,
  activeBotID,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cannedMsgErrorData, setCannedMsgErrorData] = useState({
    duplicateShortcut: [],
    duplicateTitle: [],
    invalidRows: [],
  });

  const [error, setError] = useState(false);

  const handleDeleteGroup = () => {
    axios({
      url: `/canned-message/${activeBotID}/${CannedMessageId}`,
      method: "DELETE",
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        navigate(pages.cannedMessagesList.route);
      }
    });
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        let method = "POST";

        let baseURL =
          formData.cannedMsgType === "single"
            ? `/canned-message/${activeBot.ID}`
            : `/canned-message/bulk/${activeBot.ID}`;

        if (!!formData.ID) {
          method = "PUT";
          baseURL += `/${formData.ID}`;
        }

        axios({
          url: baseURL,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
            navigate(pages.cannedMessagesList.route);
            setEditState(false);
          } else {
            setSubmitting(false);
            setCannedMsgErrorData({
              duplicateShortcut: response.response.duplicate_shortcuts,
              duplicateTitle: response.response.duplicate_titles,
              invalidRows: response.response.invalid_rows,
            });
            setError(true);
          }
        });
      }}
    >
      {(formikProps) => {
        return (
          <Fragment>
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              activeBotID={activeBotID}
              setEditState={setEditState}
              error={error}
              cannedMsgErrorData={cannedMsgErrorData}
            />

            <ButtonFooter
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              deleteButton={[true, handleDeleteGroup, "Delete Group"]}
            />
          </Fragment>
        );
      }}
    </Formik>
  );
}
