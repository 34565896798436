import { Chip } from "../../components";
import DataTable from "./../../components/DataTable";
import CustomFilter from "./CustomFilter";

export default function Table({ insightData, setInsightData }) {
  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },

    customToolbar: () => <CustomFilter setInsightData={setInsightData} />,
  };
  return (
    <DataTable
      columns={columns}
      options={options}
      data={insightData?.all_members_livechat.members}
    />
  );
}

const columns = [
  {
    name: "first_name",
    label: "Name",
    options: {
      customBodyRender: (value) => {
        return Boolean(value) ? value : "Not Available";
      },
    },
  },

  {
    name: "livechat_config",
    label: "Ongoing Chat",
    options: {
      customBodyRender: (value) => {
        return Boolean(value?.assigned_bot_user_count)
          ? value.assigned_bot_user_count
          : "Not Available";
      },
    },
  },

  {
    name: "livechat_handled",
    label: "Total chat Handled",
    options: {
      customBodyRender: (value) => {
        return Boolean(value) !== undefined ? value : "Not Available";
      },
    },
  },

  {
    name: "livechat_config",
    label: "Agent Status",
    options: {
      customBodyRender: (value) => {
        return (() => {
          switch (value?.member_status) {
            case 0:
              return (
                <Chip
                  label={"Offline"}
                  style={{ color: "#ff3c3c", backgroundColor: "#ffc0c0" }}
                />
              );
            case 1:
              return (
                <Chip
                  label={"Online"}
                  style={{ color: "#4F8A10", backgroundColor: "#DFF2BF" }}
                />
              );
            case 2:
              return (
                <Chip
                  label={"Break"}
                  style={{ color: "#f6b400", backgroundColor: "#ffe4c0" }}
                />
              );
            default:
              return (
                <Chip
                  label={"Offline"}
                  style={{ color: "#ff3c3c", backgroundColor: "#ffc0c0" }}
                />
              );
          }
        })();
      },
    },
  },
];
