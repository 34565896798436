import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CircularProgress, Dialog, DialogTitle } from "@mui/material";

import { useComponent } from "../../../hooks";
import Message from "../../chats/chats components/main window components/conversation components/conversations window component/Message";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function ViewConversation({
  viewConversation,
  setViewConversation,
  senderID,
  conversation,
}) {
  const classes = useStyles();
  const scrollRef = useRef(null);

  const { axios } = useComponent();

  const userID = senderID;
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [loaderState, setLoaderState] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setLoaderState(true);

    if (userID) {
      axios({
        url: `/livechat_request_conversation/${userID}`,
        disableRedirect: true,
        params: {
          bot_id: activeBot.ID,
        },
      }).then((response) => {
        if (response.status) {
          const messages = [];

          for (let message of response.data.events) {
            if (
              message.payload?.text !== "/human_handoff" &&
              message.payload?.text !== "/restart" &&
              message.payload?.action !== "/human_handoff"
            ) {
              messages.push(formatMessage(message));
            }
          }

          setMessages(messages);

          scrollToBottom();

          setLoaderState(false);
        }
      });
    }
  }, [activeBot.ID, axios, userID, conversation]);

  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true, minScrollbarLength: 20 }}
      containerRef={(ref) => (scrollRef.current = ref)}
    >
      <Dialog
        classes={{ paper: classes.paper }}
        open={viewConversation}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle style={{ borderBottom: "1px solid #cbcbcb" }}>
          View Conversation
        </DialogTitle>

        <Wrapper>
          {loaderState && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}

          {messages.map((message) => (
            <Message key={message.ID} message={message} />
          ))}
        </Wrapper>
      </Dialog>
    </PerfectScrollbar>
  );

  function handleClose() {
    setViewConversation(false);
  }
  function scrollToBottom() {
    scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER;
  }
}

export function formatMessage(message) {
  return {
    ID: message.message_id,
    actor: message.actor,
    body: sanitizeBody(message.payload, message.type),
    timeStamp: message.timestamp,
  };

  function sanitizeBody(payload, type) {
    switch (type) {
      case "text":
        return {
          text: payload.text,
        };

      case "image":
        return {
          imageURL: payload.url,
          footer: payload.caption,
        };

      case "video":
        return {
          videoURL: payload.url,
          footer: payload.caption,
        };

      case "audio":
        return {
          audioURL: payload.url,
        };

      case "document":
        return {
          documentName: payload.name,
          documentURL: payload.url,
        };

      case "quick_replies":
        return {
          text: payload.text,
          buttons: payload.buttons,
        };

      case "carousel":
        return {
          text: "Bot sent a carousel",
        };

      default:
        return {};
    }
  }
}
