import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";

import Chip from "./../../../../components/Chip";
import { DateRange } from "react-date-range";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "750px" },
}));

export default function FilterDialog({
  page,
  labels,
  memberList,
  searchQuery,
  setSearchQuery,
  filterDialogState,
  setFilterDialogState,
}) {
  const [filterParams, setFilterParams] = useState({
    name: "",
    email: "",
    assignedTo: "",
    label: "",
    assign: "",
    startDate: "",
    endDate: "",
    phoneNumber: "",
    conversation: "",
    unReadConversation: "",
  });

  const classes = useStyles();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilterParams({
      name: searchQuery.name,
      email: searchQuery.email,
      phoneNumber: searchQuery.phoneNumber,
      label: searchQuery.label,
      assignedTo: searchQuery.assignedTo,
      conversation: searchQuery.conversation,
      unReadConversation: searchQuery.unReadConversation,
      assign: searchQuery.assign,
    });
  }, [
    searchQuery.email,
    searchQuery.name,
    searchQuery.phoneNumber,
    searchQuery.label,
    searchQuery.assignedTo,
    searchQuery.conversation,
    searchQuery.unReadConversation,
    searchQuery.assign,
  ]);

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.paper }}
      open={filterDialogState}
      onClose={handleClose}
    >
      {/* {console.log("DialogTitle", filterParams, state)} */}
      <DialogTitle>Filter Users</DialogTitle>

      <DialogContent dividers style={{ padding: "20px" }}>
        <Grid container display={"flex"} spacing={3}>
          <Grid item>
            <DateRange
              ranges={state}
              // minDate={
              //   filterParams.startTime
              //     ? new Date(
              //         state[0].startDate.getFullYear(),
              //         state[0].startDate.getMonth(),
              //         state[0].startDate.getDate()
              //       )
              //     : new Date(1000, 1, 0)
              // }
              // maxDate={
              //   filterParams.endTime
              //     ? new Date(
              //         state[0].startDate.getFullYear(),
              //         state[0].startDate.getMonth(),
              //         state[0].startDate.getDate() + 7
              //       )
              //     : new Date(3000, 1, 0)
              // }
              onChange={(items) => handleDateChange(items)}
            ></DateRange>
          </Grid>

          <Grid item spacing={4} xs={6}>
            <Grid item xs={12} mb={2}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                value={filterParams.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                value={filterParams.email}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} mb={2}>
              <TextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                value={filterParams.phoneNumber}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} mb={2}>
              <TextField
                select
                fullWidth
                name="label"
                label="Label"
                value={filterParams.label}
                onChange={handleChange}
                disabled={labels.length < 0}
              >
                {labels.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    <Chip label={item.title} background={item.color} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} mb={2}>
              <TextField
                select
                fullWidth
                name="assignedTo"
                label="Assign To"
                value={filterParams.assignedTo}
                onChange={handleChange}
                disabled={memberList.length < 0}
              >
                {memberList.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* <Grid item xs={12}>
            <TextField
              fullWidth
              name="conversation"
              label="Conversation"
              value={filterParams.conversation}
              onChange={handleChange}
            />
          </Grid> */}

            {/* <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="unReadConversation"
              label="Conversation Status"
              value={filterParams.unReadConversation}
              onChange={handleChange}
            >
              <MenuItem value="True">Read Conversation</MenuItem>
              <MenuItem value="False">Unread Conversation</MenuItem>
            </TextField>
          </Grid> */}

            {/* <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="assign"
              label="Assign"
              value={filterParams.assign}
              onChange={handleChange}
            >
              <MenuItem value="1">Assign To Anyone</MenuItem>
              <MenuItem value="0">No Assign To Anyone</MenuItem>
            </TextField>
          </Grid> */}
          </Grid>

          <Grid container p={2}>
            <Grid item onClick={handleClose}>
              <Button variant="outlined">Cancel</Button>
            </Grid>

            <Grid item xs />

            <Grid item mr={2} onClick={clearFilters}>
              <Button variant="contained">Clear Filters</Button>
            </Grid>

            <Grid item onClick={filterUsers}>
              <Button variant="contained">Filter Users</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  function handleChange({ target }) {
    const { name, value } = target;

    setFilterParams((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function handleDateChange(items) {
    const timestamp1 = new Date(items.selection.startDate).getTime();

    const timestamp2 = new Date(items.selection.endDate).getTime();

    setFilterParams((prevValue) => ({
      ...prevValue,
      startDate: timestamp1 / 1000,
      endDate: timestamp2 / 1000,
    }));

    setState([items.selection]);
  }

  function clearFilters() {
    setSearchQuery({
      search: "",
      name: "",
      email: "",
      phoneNumber: "",
      assignedTo: "",
      label: "",
      conversation: "",
      startDate: "",
      endDate: "",
    });
    setFilterParams({
      name: searchQuery.name,
      email: searchQuery.email,
      phoneNumber: searchQuery.phoneNumber,
      label: searchQuery.label,
      assignedTo: searchQuery.assignedTo,
      conversation: searchQuery.conversation,
      startDate: searchQuery.startDate,
      endDate: searchQuery.endDate,
    });
    page.number = 0;
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    handleClose();
  }

  function filterUsers() {
    setSearchQuery((prevValue) => ({
      ...prevValue,
      ...filterParams,
    }));

    handleClose();
  }

  function handleClose() {
    setFilterDialogState(false);
  }
}
