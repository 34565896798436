import React from "react";
import { makeStyles } from "@mui/styles";
import { CheckCircle } from "@mui/icons-material";
import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export default function Dialog({ dialogState, setDialogState }) {
  const classes = useStyles();

  const handleClose = () => {
    setDialogState(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        open={dialogState}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <DialogContent>
          <div className={classes.div}>
            <CheckCircle
              fontSize="small"
              style={{ marginRight: "10px", color: "green" }}
            />
            <Typography>Your number has been verified.</Typography>
          </div>
        </DialogContent>
      </MUIDialog>
    </React.Fragment>
  );
}
