import { Fragment, useState, useEffect } from "react";
import {
  Paper as MuiPaper,
  Typography,
  LinearProgress,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import styled from "styled-components";

import startDateCalculator from "./startDateCalculator";
import { useComponent } from "../../../hooks";

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function TopIntents({ startDate, botID }) {
  const { axios } = useComponent();

  const [loading, setLoading] = useState(true);
  const [intents, setIntents] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/intents/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        end_date: new Date().getTime() / 1000,
        ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
      },
    }).then((response) => {
      if (response.status) {
        setIntents(response.data.intents);

        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerItem>
                <List dense>
                  {intents.length === 0 && (
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 10 } }}
                    >
                      No intents yet!
                    </Typography>
                  )}
                  {intents.map((intent, index) => (
                    <ListItem key={index} style={{ justifyContent: "center" }}>
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 10 } }}
                      >
                        {intent}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </ContainerItem>
            </Grid>
          </Grid>

          <Typography
            variant="h3"
            sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 10 } }}
          >
            Top Intents
          </Typography>
        </Fragment>
      )}
    </Paper>
  );
}
