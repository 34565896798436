import { Formik } from "formik";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useComponent } from "../../../../hooks";
import { ButtonFooter } from "../../../../components";
import { useNavigate } from "react-router-dom";
import pages from "../../../../constants/pages";
import { dataFromFormData, validationSchema } from "./intentDetailsObject";

export default function Form({
  formData,
  setEditState,
  editState,
  pagePermissions,
}) {
  const navigate = useNavigate();
  const { alert } = useComponent();
  const axios = useAxios({ baseURL: "botBuilder" });
  const activeBot = useSelector((state) => state.memberDetails.activeBot);

  function handleDelete() {
    axios({
      url: "/delete-intent/" + activeBot.ID + "/" + formData.intentName,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        navigate(pages.botIntentList.route);
      }
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/create-intent/" + activeBot.ID;
        if (!!formData.ID) {
          url = "/update-intent/" + activeBot.ID;
        }

        axios({
          url: url,
          method: "POST",
          data: dataFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);
            if (formData.ID) {
              navigate(
                pages.botIntentDEtails.route + response.data.intent_name
              );
            }
            setEditState(false);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            formikProps={formikProps}
            setEditState={setEditState}
            editState={editState}
          />

          <ButtonFooter
            ID={formData.ID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              handleDelete,
              "Delete Entity",
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );
}
