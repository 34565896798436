import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  Image,
  VideoFile,
  AudioFile,
  InsertDriveFile,
} from "@mui/icons-material";

export default function AttachmentMenu({
  menuAnchor,
  setMenuAnchor,
  handleDialogOpen,
}) {
  return (
    <Menu
      open={!!menuAnchor}
      anchorEl={menuAnchor}
      onClose={() => setMenuAnchor(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={() => openDialog("image")}>
        <ListItemIcon>
          <Image />
        </ListItemIcon>

        <ListItemText>Image</ListItemText>
      </MenuItem>

      <MenuItem onClick={() => openDialog("video")}>
        <ListItemIcon>
          <VideoFile />
        </ListItemIcon>

        <ListItemText>Video</ListItemText>
      </MenuItem>

      <MenuItem onClick={() => openDialog("document")}>
        <ListItemIcon>
          <InsertDriveFile />
        </ListItemIcon>

        <ListItemText>Document</ListItemText>
      </MenuItem>

      <MenuItem onClick={() => openDialog("audio")}>
        <ListItemIcon>
          <AudioFile />
        </ListItemIcon>

        <ListItemText>Audio</ListItemText>
      </MenuItem>
    </Menu>
  );

  function openDialog(contentType) {
    handleDialogOpen(contentType);

    setMenuAnchor(null);
  }
}
