import DownloadIcon from "@mui/icons-material/Download";
import { Fragment, useState } from "react";
import { Button } from "@mui/material";

import { Chip, DataTable } from "./../../../components";
import { convertDate } from "../../../utils";
import CustomFilter from "./CustomFilter";

export default function Table({ chatReport, setChatReport }) {
  const options = {
    // onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },

    customToolbar: () => <CustomFilter setChatReport={setChatReport} />,
  };

  return <DataTable columns={columns} options={options} data={chatReport} />;
}

const columns = [
  {
    name: "_id",
    label: "Id",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "business_id",
    label: "Business Id",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "status",
    label: "Report Status",
    options: {
      customBodyRender: (value) => {
        return value ? (
          <Fragment>
            {value === "finished" ? (
              <Chip background={"#4caf50"} label={value} sx={{ mr: "2%" }} />
            ) : (
              <Chip background={"#f9ff4c"} label={value} sx={{ mr: "2%" }} />
            )}
          </Fragment>
        ) : (
          "Not Available"
        );
      },
    },
  },
  {
    name: "updated_at",
    label: "Updated At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "requested_by",
    label: "Requested By",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "member_chat_report",
    label: "Member Chat Report",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "remark",
    label: "Remark",
    options: {
      customBodyRender: (value) => {
        return value ? (
          <Fragment>
            {value === "Report Upload Success" ? (
              <Chip
                background={"rgb(8 177 132)"}
                label={value}
                sx={{ mr: "2%" }}
              />
            ) : (
              <Chip background={"#fe5d28"} label={value} sx={{ mr: "2%" }} />
            )}
          </Fragment>
        ) : (
          "Not Available"
        );
      },
    },
  },
  {
    name: "chat_report_url",
    label: "Download Report ",
    options: {
      customBodyRender: (value) => {
        return value ? (
          <Button
            component="a"
            variant="outlined"
            href={value}
            sx={{ mr: "2%" }}
          >
            <DownloadIcon />
            Download Report
          </Button>
        ) : (
          "Not Available"
        );
      },
    },
  },
];
