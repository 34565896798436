import { convertDate } from "./../../../utils";

export const liveChatConfigObject = {
  ID: "",
  created_at: "",
  updated_at: "",
  is_active: "",
  auto_end_livechat_time: "",
  transferred_message: "",
  assigend_to_message: "",
  no_agent_available_message: "",
  end_chat_message: "",
  auto_assignment: "",
  end_chat_forcefully: "",
  agent_id: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id,
    created_at: convertDate(responseData.created_at),
    updated_at: convertDate(responseData.updated_at),
    is_active: responseData.is_active,
    auto_end_livechat_time: responseData.auto_end_livechat_time,
    transferred_message: responseData.transferred_message,
    assigend_to_message: responseData.assigend_to_message,
    no_agent_available_message: responseData.no_agent_available_message,
    end_chat_message: responseData.end_chat_message,
    auto_assignment: responseData.auto_assignment,
    end_chat_forcefully: responseData.end_chat_forcefully,
    agent_id: responseData.agent_id,
  };
}

export function objectFromFormData(formData) {
  return {
    ID: formData._id,
    created_at: convertDate(formData.created_at),
    updated_at: convertDate(formData.updated_at),
    is_active: formData.is_active,
    auto_end_livechat_time: formData.auto_end_livechat_time,
    transferred_message: formData.transferred_message,
    assigend_to_message: formData.assigend_to_message,
    no_agent_available_message: formData.no_agent_available_message,
    end_chat_message: formData.end_chat_message,
    auto_assignment: formData.auto_assignment,
    end_chat_forcefully: formData.end_chat_forcefully,
    agent_id: formData.agent_id,
  };
}
