import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAxios } from "../../../hooks";
import { Chip } from "../../../components";
import pages from "../../../constants/pages";
import CustomToolbar from "./CustomToolBar";
import { convertDate } from "../../../utils";
import DataTable from "../../../components/DataTable";
import customFieldDate from "../../../utils/customFieldDate";

export default function Table({
  memberList,
  userData,
  column,
  setUsers,
  activeBot,
  labels,
}) {
  const axios = useAxios();
  const navigation = useNavigate();

  const [filter, setFilter] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const [searchTextFilter, setSearchTextFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState({
    isFilter: false,
    data: "",
    url: "",
  });

  var filterColumns = [
    {
      name: "name",
      label: "Name",
      dataType: "string",
    },
    {
      name: "email",
      label: "Email",
      dataType: "string",
    },
    {
      name: "phone",
      label: "Phone",
      dataType: "string",
    },
    {
      name: "created_by",
      label: "Created By",
      dataType: "string",
    },
  ];

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "labels",
      label: "Label",
      options: {
        customBodyRender: (value, { rowData }) => {
          return value.length > 0
            ? value.map((items, index) => {
                if (index < 4) {
                  return (
                    <Chip
                      key={items._id}
                      background={items.color}
                      label={items.title}
                      sx={{ mr: "2%" }}
                    />
                  );
                }
              })
            : "Not Available";
        },
      },
    },
    {
      name: "created_by",
      label: "Created By",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "updated_by",
      label: "Updated By",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
  ];

  for (let [index, field] of column.entries()) {
    var iterator = true;

    filterColumns.push({
      name: field.name,
      label: field.label,
      dataType: field.value_type,
      ...(field.control_id === "61dd2dfa1fb38be82e077ed8" && {
        options: field.options,
      }),
    });

    if (index > 2) {
      iterator = false;
    }
    if (field.control_id !== "61e13a0bf2c60729b06385f3") {
      if (
        field.control_id === "61dd2dfa1fb38be82e077ed8" &&
        Boolean(field.multi_Select)
      ) {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value
                ? value.length > 1
                  ? value[0].label + ", " + value[1].label
                  : value[0].label
                : "Not Available";
            },
          },
        });
      } else if (field.control_id === "61dd2dfa1fb38be82e077ed9") {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value ? customFieldDate(value) : "Not Available";
            },
          },
        });
      } else if (
        field.control_id === "61dd2dfa1fb38be82e077ed8" &&
        !Boolean(field.multi_Select)
      ) {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value ? value[0].label : "Not Available";
            },
          },
        });
      }
    } else if (field.control_id === "61dd2dfa1fb38be82e077ed7") {
      columns.push({
        name: field.name,
        label: field.label,
        options: {
          display: iterator,
          customBodyRender: (value) => {
            return value ? value : "Not Available";
          },
        },
      });
    } else {
      columns.push({
        name: field.name,
        label: field.label,
        options: {
          display: iterator,
          customBodyRender: (value) => {
            return value ? (value === true ? "Yes" : "No") : "No";
          },
        },
      });
    }
  }

  function handleCellClick(_, cellMetaData) {
    navigation(
      pages.leadDetails.route + userData.users[cellMetaData.dataIndex]._id
    );
  }

  const options = {
    filter: false,
    rowsPerPage: 25,
    download: false,
    onCellClick: handleCellClick,
    rowsPerPageOptions: [5, 10, 15, 20, 25],
    count: userData.totalCount,

    customToolbar: () => (
      <CustomToolbar
        setFilter={setFilter}
        filter={filter}
        setUsers={setUsers}
        filterColumns={filterColumns}
        memberList={memberList}
        activeBot={activeBot}
        userData={userData.users}
        labels={labels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
      />
    ),

    onSearchChange(searchText) {
      let skip = 0;
      let limit = 50;

      setSearchTextFilter(searchText);

      axios({
        url:
          "/leads/get_leads?agent_id=" +
          activeBot.ID +
          "&skip=" +
          skip +
          "&limit=" +
          limit +
          "&text=" +
          searchText,
        method: "POST",
        data: {},
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setUsers({
            users: response.data.paginatedResults,
            totalCount: response.data.totalCount[0]?.count || 0,
          });
        }
      });

      setSearchFilter(true);
    },

    onChangePage(currentPage) {
      const newStart = currentPage * 50;

      let limit = newStart + 50;

      if (userData.totalCount - limit < 100) {
        limit = userData.totalCount;
      }
      axios({
        url: `/leads/get_leads?agent_id=${
          activeBot.ID
        }&skip=${newStart}&limit=${limit}${
          searchFilter ? "&text=" + searchTextFilter : ""
        } ${applyFilter.isFilter ? applyFilter.url : ""}`,
        method: "POST",
        data: applyFilter.isFilter ? applyFilter.data : {},
      }).then((response) => {
        if (response.status) {
          if (response.data.paginatedResults.length > userData.users.length) {
            setUsers((prevUsers) => ({
              users: [...prevUsers.users, ...response.data.paginatedResults],
              totalCount: response.data.totalCount[0]?.count || 0,
            }));
          }
        }
      });
    },

    sortOrder: {
      name: "created_at",
      direction: "asc",
    },
  };

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={userData.users} />
    </React.Fragment>
  );
}
