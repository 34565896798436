import { Fragment, useState, useEffect } from "react";
import {
  Paper as MuiPaper,
  Typography,
  LinearProgress,
  Grid,
} from "@mui/material";
import styled from "styled-components";

import startDateCalculator from "./startDateCalculator";
import { useComponent } from "../../../hooks";

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function MessagesDisplay({ startDate, botID }) {
  const { axios } = useComponent();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState({
    total: 0,
    user: 0,
    bot: 0,
    member: 0,
  });

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/messages/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        end_date: new Date().getTime() / 1000,
        ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
      },
    }).then((response) => {
      if (response.status) {
        setMessages(response.data);

        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Typography
            sx={{ fontSize: { lg: 25, md: 23, sm: 23, xs: 20 } }}
            variant="h2"
            color="primary"
          >
            {messages.total}
          </Typography>

          <Typography
            variant="h5"
            sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
          >
            Total Messages
          </Typography>

          <Container>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={6} md={6} lg={4}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {messages.user}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    User Messages
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={4} sm={6} md={6} lg={4}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {messages.bot}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Bot Messages
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={4} sm={12} md={12} lg={4}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {messages.member}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Member Messages
                  </Typography>
                </ContainerItem>
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Paper>
  );
}
