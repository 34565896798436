import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid, Button, ButtonGroup } from "@mui/material";

import "emoji-mart/css/emoji-mart.css";

import { ComponentHeader, Paper } from "../../../../../../components";

export default function TemplateAttachment({ formikProps, editState }) {
  const [count, setCount] = React.useState(1);

  return (
    <Paper>
      <Grid style={{ alignSelf: "center" }} item xs={12}>
        <ComponentHeader
          title={"Template Attachment Count"}
          subtitle={
            " The attachment size limit is 25 MB per file. You can add multiple files as long as their combined size does not  exceed 25 MB."
          }
        />
      </Grid>

      <Grid item container xs={12} justifyContent={"end"}>
        <ButtonGroup>
          <Button
            disabled={Boolean(formikProps.values.ID) && !editState}
            aria-label="reduce"
            onClick={() => {
              setCount(Math.max(count - 1, 0));

              formikProps.setFieldValue(
                "emailAttachment",
                Math.max(count - 1, 0)
              );
            }}
          >
            <RemoveIcon fontSize="small" />
          </Button>

          <Button disabled={Boolean(formikProps.values.ID) && !editState}>
            {formikProps.values.emailAttachment}
          </Button>
          <Button
            disabled={Boolean(formikProps.values.ID) && !editState}
            aria-label="increase"
            onClick={() => {
              setCount(Math.max(count + 1, 0));

              formikProps.setFieldValue(
                "emailAttachment",
                Math.max(count + 1, 0)
              );
            }}
          >
            <AddIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </Grid>
    </Paper>
  );
}
