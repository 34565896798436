import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import { Person } from "@mui/icons-material";

import { alert } from "../../../redux/slices/alertSlice";
import { ComponentHeader, Paper } from "../../../components";
import AddUserPopUp from "./add user components/AddUserPopup";
import UserListTable from "./add user components/UserListTable";

export default function UserListComponent({
  userList,
  setListUsers,
  groupID,
  setUser,
  formikProps,
  setUserList,
  allUsers,
  activeBot,
  columns,
  setAddUsers,
  setAllUsers,
}) {
  const dispatch = useDispatch();

  const [addUserPopUp, setAddUserPopUp] = useState({
    state: false,
    groupUserID: "",
  });

  const handleAddNewUser = () => {
    if (formikProps.values.groupStatus) {
      setAddUserPopUp({
        state: true,
        groupUserID: "",
      });
    } else {
      dispatch(
        alert({
          type: "warning",
          message: "The group status is inactive, make it active to add users",
        })
      );
    }
  };

  return (
    <Paper>
      <Grid item xs>
        <ComponentHeader
          title="User List"
          subtitle="These are users which are in this group."
        />
      </Grid>

      <Grid
        style={{
          textAlign: "end",
          alignSelf: "center",
        }}
        item
      >
        <Button
          onClick={handleAddNewUser}
          variant="outlined"
          color="primary"
          component="span"
          startIcon={<Person />}
        >
          Add New User
        </Button>
      </Grid>

      <Grid item xs={12}>
        <UserListTable
          setUser={setUser}
          userList={userList}
          groupID={groupID}
          setListUsers={setListUsers}
          addUserPopUp={addUserPopUp}
          setUserList={setUserList}
          setAddUserPopUp={setAddUserPopUp}
          allUsers={allUsers}
        />
      </Grid>

      <AddUserPopUp
        addUserPopUp={addUserPopUp}
        setAddUserPopUp={setAddUserPopUp}
        activeBot={activeBot}
        formikProps={formikProps}
        allUsers={allUsers}
        columns={columns}
        setUserList={setUserList}
        setAddUsers={setAddUsers}
        setAllUsers={setAllUsers}
      />
    </Paper>
  );
}
