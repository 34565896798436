import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { Loader } from "../../components";
import { PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import FeedbackTable from "./feedback table component/FeedbackTable";

export default function Feedback() {
  const axios = useAxios();
  const { loader } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: "feedback/bot/" + activeBot.ID,
    }).then((response) => {
      if (response.status) {
        loader.stop();
        setFeedback(response.data);
      }
    });
  }, [axios, loader, activeBot.ID]);

  return (
    <Loader>
      <PageHeader pageName="Feedback" />

      <FeedbackTable feedback={feedback} agentID={activeBot.ID} />
    </Loader>
  );
}
