import React, { Fragment } from "react";

import FormDisplay from "./FormDisplay";

export default function Form() {
  return (
    <Fragment>
      <FormDisplay />
    </Fragment>
  );
}
