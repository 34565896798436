export const initFacebookSdk = () => {
  return new Promise((resolve, reject) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "1010876309986235",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
      // Resolve the promise when the SDK is loaded
      resolve();
    };
  });
};

export const getFacebookLoginStatus = () => {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      resolve(response);
    });
  });
};

export const fbLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login((response) => {
      resolve(response);
    });
  });
};

// const sessionInfoListener = (event) => {
//   if (
//     event.origin !== "https://www.facebook.com" &&
//     event.origin !== "https://web.facebook.com"
//   ) {
//     return;
//   }

//   try {
//     const data = JSON.parse(event.data);
//     console.log(data);
//     if (data.type === "WA_EMBEDDED_SIGNUP") {
//       // if user finishes the Embedded Signup flow
//       if (data.event === "FINISH") {
//         const { phone_number_id, waba_id } = data.data;
//       }
//       // if user cancels the Embedded Signup flow
//       else {
//         const { current_step } = data.data;
//       }
//     }
//   } catch {
//     // Don’t parse info that’s not a JSON
//     console.log("Non JSON Response", event.data);
//   }
// };
