import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { FilterList, Sync } from "@mui/icons-material";
import { IconButton, Badge, Tooltip } from "@mui/material";

import { useAxios } from "../../../hooks";
import Refresh from "../../../assets/refresh.gif";
import FilterDialog from "./filter section components/FilterDialog";

const defaultToolbarStyles = {
  iconButton: {},
};

function FilterSection({
  page,
  labels,
  memberList,
  setUsers,
  searchQuery,
  setSearchQuery,
}) {
  const axios = useAxios();

  const [refresh, setRefresh] = useState(false);

  const [filterDialogState, setFilterDialogState] = useState(false);

  const { activeBot } = useSelector((state) => state.memberDetails);

  function refreshLeads() {
    setRefresh(true);
    axios({
      url: `/livechat_request_list/${activeBot.ID}`,
      params: {
        page: 0,
        limit: 25,
      },
    }).then((response) => {
      if (response.status) {
        setUsers(response.data);

        setRefresh(false);
      }
    });
  }

  return (
    <Fragment>
      <Tooltip title="Filter users">
        <IconButton color="primary" onClick={() => setFilterDialogState(true)}>
          <Badge badgeContent={searchQueryCount()} color="error">
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <Tooltip title={"Refresh Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={refreshLeads}
        >
          {refresh ? (
            <img style={{ height: "22px" }} src={Refresh} alt="refresh" />
          ) : (
            <Sync className={defaultToolbarStyles.deleteIcon} />
          )}
        </IconButton>
      </Tooltip>

      <FilterDialog
        page={page}
        labels={labels}
        memberList={memberList}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterDialogState={filterDialogState}
        setFilterDialogState={setFilterDialogState}
      />
    </Fragment>
  );

  function searchQueryCount() {
    let count = 0;

    for (let query in searchQuery) {
      if (!!searchQuery[query]) {
        count++;
      }
    }

    return count;
  }
}

export default withStyles(defaultToolbarStyles, { name: "FilterSection" })(
  FilterSection
);
