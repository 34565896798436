import React from "react";
import styled from "styled-components/macro";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Grid, Typography, Button } from "@mui/material";

import { ComponentHeader, Paper } from "../../../../components";

const DetailsCard = styled.div`
  border-radius: 10px;
  height: 500px;
  min-width: 350px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const HeaderDiv = styled.div`
  padding: 20px;
  border-radius: 10px 10px 0 0;
  background: #ff9f00;
`;

const ContentDiv = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  height: 450px;
`;

const TermTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
`;

const ScrollBar = styled(PerfectScrollBar)`
  height: calc(100% - 8px) !important;
`;

export default function Preview({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Email Preview"}
          subtitle="This is how your email look like."
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <DetailsCard>
          <React.Fragment>
            <HeaderDiv>
              {formikProps.values.templateID && (
                <Typography variant="h5" style={{ color: "#fff" }}>
                  {" "}
                  Review
                </Typography>
              )}
            </HeaderDiv>

            <ContentDiv>
              <ScrollBar>
                {formikProps.values.templateID && (
                  <React.Fragment>
                    <TermTypography>Hello Shubham,</TermTypography>
                    <br />
                    <TermTypography variant="h5">
                      We would love to get some review from you. Help us improve
                      your experience on SAIL website by taking a short survey.
                      We promise it will take only 2-3 minutes of your time and
                      all your response will be confidential.
                    </TermTypography>

                    <Button variant="contained" color="primary">
                      Take Survey
                    </Button>
                  </React.Fragment>
                )}
              </ScrollBar>
            </ContentDiv>
          </React.Fragment>
        </DetailsCard>
      </Grid>
    </Paper>
  );
}
