import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Fragment, useState, useEffect } from "react";
import {
  Paper as MuiPaper,
  Typography,
  Grid,
  LinearProgress,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { useComponent } from "../../../hooks";
import startDateCalculator from "./startDateCalculator";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale
);

const Paper = styled(MuiPaper)`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export default function LeadsDisplayOne({ startDate, botID }) {
  const { axios } = useComponent();

  const [loading, setLoading] = useState(true);

  const [leads, setLeads] = useState({
    labels: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],

    datasets: [
      {
        label: "Total",
        fill: true,
        borderColor: "#36a2eb",
        data: [20, 30, 40, 50, 60, 70],
        backgroundColor: "#36a2eb",
      },
      {
        label: "Active",
        fill: true,
        borderColor: "#4bc0c0",
        data: [15, 20, 25, 40, 45, 60],
        backgroundColor: "#4bc0c0",
      },
      {
        label: "Important",
        fill: true,
        borderColor: "#ff6384",
        data: [10, 12, 14, 16, 18, 20],
        backgroundColor: "#ff6384",
      },
      {
        label: "Inactive",
        fill: true,
        borderColor: "#ff6384",
        data: [10, 12, 14, 16, 18, 20],
        backgroundColor: "#ff6384",
      },
    ],
  });
  // useEffect(() => {
  //   setLoading(true);

  //   axios({
  //     url: `/insights/leads/${botID}`,
  //     params: {
  //       end_date: new Date().getTime() / 1000,
  //       ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
  //     },
  //   }).then((response) => {
  //     if (response.status) {
  //       setLeads(response.data);

  //       setLoading(false);
  //     }
  //   });
  // }, [axios, botID, startDate]);

  const config = {
    type: "line",
    data: leads,
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: "Value",
          },
          suggestedMin: -10,
          suggestedMax: 200,
        },
      },
    },
  };

  return (
    <Paper style={{ height: { lg: "390px", sm: "350px" } }}>
      {/* {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : ( */}
      <Fragment>
        <Container>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={8}
              sm={10}
              md={12}
              lg={12}
              xl={11}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <ContainerItem>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { lg: 17, md: 17, sm: 17, xs: 15 },
                    alignItems: "center",
                  }}
                >
                  Total Leads
                </Typography>

                <Line data={leads} options={config} />
              </ContainerItem>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
      {/* )} */}
    </Paper>
  );
}
