import * as Yup from "yup";

export const slotObject = {
  ID: "",
  slotName: "",
  type: "",
  minValue: 0,
  maxValue: 100,
  influenceConversation: false,
  mappings: {
    type: "",
    entity: "",
    intent: "",
    notIntent: "",
    role: "",
    group: "",
  },
  conditions: {
    activeLoop: "",
    requestedSlot: "",
  },
};

export function dataFromResponse(response) {
  return {
    ID: response._id,
    slotName: response.slot_name,
    type: response.type,
    minValue: response.minValue,
    maxValue: response.maxValue,
    influenceConversation: response.influence_conversation,
    mappings: {
      type: response.mappings.type,
      entity: response.mappings.entity,
      intent: response.mappings.intent,
      notIntent: response.mappings.not_intent,
      role: response.mappings.role,
      group: response.mappings.group,
    },
    conditions: {
      activeLoop: response?.conditions?.active_loop,
      requestedSlot: response?.conditions?.requested_slot,
    },
    createdBy: response.created_by,
    updatedBy: response.updated_by,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function dataFromFormData(formData) {
  return {
    ...Boolean(
      formData.id && {
        _id: formData.ID,
      }
    ),
    slot_name: formData.slotName,
    type: formData.type,
    min_value: formData.minValue,
    max_value: formData.maxValue,
    influence_conversation: formData.influenceConversation,
    mappings: {
      type: formData.mappings.type,
      entity: formData.mappings.entity,
      intent: formData.mappings.intent,
      not_intent: formData.mappings.notIntent,
      role: formData.mappings.role,
      group: formData.mappings.group,
    },
    conditions: {
      active_loop: formData?.conditions?.activeLoop,
      requested_slot: formData?.conditions?.requestedSlot,
    },
  };
}

export const validationSchema = Yup.object().shape({
  slotName: Yup.string()
    .matches(/^(\d|\w)+$/)
    .required("Please provide slot name."),
  type: Yup.string().required("Please select a type."),
});
