import React from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Tooltip,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import {
  ComponentHeader,
  Paper,
  TextField,
  TextFieldWithCopy,
} from "../../../components";

export default function FormDisplay({
  formikProps,
  groupID,
  editState,
  setEditState,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Group Details"
            subtitle="You can view and edit the details pf this group and can also add/delete the users in this group."
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            disabled={!editState}
            label="Group Name"
            name="groupName"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            required
            disabled={!editState}
            label="Group Status"
            name="groupStatus"
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Total Group Member"
            name="totalUsers"
            disabled
            formikProps={formikProps}
            style={{ padding: "0px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Total number of users existing in the group.">
                    <IconButton style={{ color: "#cbcbcb" }}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Created By"
            name="createdBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Created At"
            name="createdAt"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Updated By"
            name="updatedBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Updated At"
            name="updatedAt"
            disabled
            formikProps={formikProps}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
