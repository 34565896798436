import { Formik } from "formik";
import * as Yup from "yup";
import {
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  TableHead,
  TableFooter,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";

import { useComponent } from "../../../../hooks";
import { TextField } from "../../../../components";

const TableCell = styled(MuiTableCell)`
  text-align: center;
`;

export default function FailedDisplayNameForm({ formData, registrationID }) {
  const { axios, alert } = useComponent({ disableAuthentication: true });

  const initialValues = [];

  for (let whatsappNumber of formData[2].whatsappNumberDetails) {
    if (whatsappNumber.approvalStatus === "failed") {
      initialValues.push({
        phoneNumber: whatsappNumber.phoneNumber,
        oldDisplayName: whatsappNumber.displayName,
        newDisplayName: "",
      });
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={{ whatsappNumberDetails: initialValues }}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/business/update_display_name",
          method: "POST",
          data: {
            registration_id: registrationID,
            whatsapp_number_details: formData.whatsappNumberDetails.map(
              (whatsappNumber) => ({
                mobile_no: whatsappNumber.phoneNumber,
                new_name: whatsappNumber.newDisplayName,
              })
            ),
          },
        }).then((response) => {
          setSubmitting(false);

          if (response.status) {
            alert.success("Data submitted for review!");
          }
        });
      }}
    >
      {(formikProps) => (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>Old Display Name</TableCell>
              <TableCell>New Display Name</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {initialValues.map((whatsappNumber, index) => (
              <TableRow key={whatsappNumber.phoneNumber}>
                <TableCell>{whatsappNumber.phoneNumber}</TableCell>
                <TableCell>{whatsappNumber.oldDisplayName}</TableCell>
                <TableCell>
                  <TextField
                    label={"New Display Name for " + whatsappNumber.phoneNumber}
                    required
                    name={`whatsappNumberDetails[${index}].newDisplayName`}
                    formikProps={formikProps}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} />

              <MuiTableCell align="right">
                <LoadingButton
                  variant="contained"
                  onClick={formikProps.handleSubmit}
                  loading={formikProps.isSubmitting}
                  disabled={!formikProps.isValid}
                >
                  Submit
                </LoadingButton>
              </MuiTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Formik>
  );
}

const validationSchema = Yup.object({
  whatsappNumberDetails: Yup.array().of(
    Yup.object({
      phoneNumber: Yup.string().required(),
      newDisplayName: Yup.string().required("Enter a new name for the number."),
    })
  ),
});
