import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  broadcastName: Yup.string().max(100).required("Enter broadcast name."),
  whenToBroadcast: Yup.string().required("Select when to broadcast."),
  broadcastDate: Yup.string().when("whenToBroadcast", {
    is: (whenToBroadcast) => whenToBroadcast === "later",
    then: Yup.string().required("Please select a date"),
  }),
  broadcastTime: Yup.string().when("whenToBroadcast", {
    is: (whenToBroadcast) => whenToBroadcast === "later",
    then: Yup.string().required("Please select valid time"),
  }),
  subject: Yup.string().required("This field is required."),
  senderEmail: Yup.string().required("This field is required."),
  senderName: Yup.string().required("This field is required."),
  templateID: Yup.string().required("Field required"),
  broadcastFile: Yup.string().required("Please upload a file"),
});

export default validationSchema;
