import { useNavigate } from "react-router-dom";

import { convertDate } from "../../../utils";
import pages from "../../../constants/pages";
import { Chip, DataTable } from "../../../components";

export default function Table({ cannedMsg, groups }) {
  const navigate = useNavigate();
  const options = {
    onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  function handleRowClick(_, rowMetaData) {
    navigate(
      pages.cannedMessagesCreate.route + cannedMsg[rowMetaData.dataIndex]._id
    );
  }

  return <DataTable columns={columns} options={options} data={cannedMsg} />;
}

const columns = [
  {
    name: "title",
    label: "Response Title",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "text",
    label: "Response Text",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "shortcut",
    label: "Response Shortcut Text",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "updated_at",
    label: "Updated At",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
