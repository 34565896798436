import React from "react";
import { Grid } from "@mui/material";

import DropDownComponent from "./DropdownComponent";

export default function DropDownDialog({ formikProps }) {
  return (
    <Grid item xs={12}>
      <DropDownComponent
        fieldDetails={formikProps.values}
        formikProps={formikProps}
        disabled={!formikProps.values.editable}
      />
    </Grid>
  );
}
