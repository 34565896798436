import { useState, useEffect, Fragment, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { IconButton, Menu, Badge } from "@mui/material";
import { Notifications } from "@mui/icons-material";

import { convertDate } from "../../../utils";
import { useComponent, useAxios } from "../../../hooks";
import NotificationMenu from "./notifications components/NotificationMenu";
import { notificationSocket } from "../../../constants/serverPath";

export default function NotificationsButton() {
  const { alert } = useComponent();

  const axios = useAxios({ baseURL: "notification" });

  const [page, setPage] = useState({
    number: 0,
    size: 25,
  });

  const { activeBot, ID } = useSelector((state) => state.memberDetails);

  const memberID = useRef(ID);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = useCallback(() => {
    axios({
      url: "/messaging/notification/get/" + activeBot.ID,
      params: {
        page: page.number,
        limit: page.size,
      },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setNotifications(objectFromResponseData(response.data));
      }
    });
  }, [activeBot.ID, axios, page]);

  useEffect(() => {
    fetchNotifications();

    const socket = io(notificationSocket.address);

    socket.on("notification", (data) => {
      const notification = JSON.parse(data);
      if (
        notification.notification.member_id === memberID.current &&
        notification.notification.agent_id === activeBot.ID
        // notification.notification.agent_id === activeBot.member_id
      ) {
        alert.info(notification.notification.content);
        fetchNotifications();
      }
    });
  }, [activeBot.ID, alert, fetchNotifications]);

  return (
    <Fragment>
      <IconButton
        color="primary"
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      >
        <Badge color="error" overlap="circular" variant="dot">
          <Notifications />
        </Badge>
      </IconButton>

      <Menu
        style={{ marginTop: "15px" }}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NotificationMenu
          notifications={notifications}
          page={page}
          setPage={setPage}
          handleClose={() => setMenuAnchor(null)}
        />
      </Menu>
    </Fragment>
  );
}

export function objectFromResponseData(responseData) {
  const notifications = [];
  for (let notification of responseData.notifications) {
    notifications.push({
      ID: notification._id,
      pageID: notification.page_id,
      displayContent: notification.content,
      payload: {
        page: {
          ID: notification.payload?.page?.id || "",
          pathVariable: notification.payload?.page?.path_variable || "",
        },
      },
      readStatus: false,
      timeStamp: convertDate(notification.updated_at),
    });
  }

  return notifications;
}
