import React from "react";
import { useNavigate } from "react-router-dom";
import { Chip } from "../../../components";

import DataTable from "../../../components/DataTable";
import pages from "../../../constants/pages";
import convertDate from "../../../utils/convertDate";

export default function Table({ customFields, agentID }) {
  const navigation = useNavigate();

  function handleCellClick(cellData, cellMetaData) {
    if (cellMetaData.colIndex !== 5) {
      navigation(
        pages.customFieldDetails.route +
          customFields[cellMetaData.dataIndex]._id
      );
    }
  }

  const options = {
    onCellClick: handleCellClick,

    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "label",
      label: "Custom Field Label",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "description",
      label: "Custom Field Description",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 50) + (value.length > 50 ? "..." : "")
            : "Not Available";
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value === true ? (
            <Chip label="Active" type="success" />
          ) : (
            <Chip label="Inactive" type="error" />
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={customFields} />;
}
