import React from "react";
import styled from "styled-components";
import { Grid, Typography, Paper as MuiPaper } from "@mui/material";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InitiatedPaper = styled(Paper)`
  color: #00529b;
  background-color: #8fcfef30;
`;

const MessageSent = styled(Paper)`
  color: #9f6000;
  background-color: #8fcfef30;
`;

const MessageDelivery = styled(Paper)`
  color: #37b24d;
  background-color: #8fcfef30;
`;

const MessageSeen = styled(Paper)`
  color: #00529b;
  background-color: #8fcfef30;
`;

const MessageFailed = styled(Paper)`
  color: #f03e3e;
  background-color: #8fcfef30;
`;

export default function Stats({ broadcastReport }) {
  return (
    <React.Fragment>
      <Grid item xs={2}>
        <InitiatedPaper>
          <Typography variant="h1">
            {broadcastReport.report?.total_message_initiation}
          </Typography>
          <Typography variant="subtitle1">Messages Initiated</Typography>
        </InitiatedPaper>
      </Grid>

      <Grid item xs={2}>
        <MessageSent>
          <Typography variant="h1">
            {broadcastReport.report?.total_no_message_sent}
          </Typography>
          <Typography variant="subtitle1">Messages Sent</Typography>
        </MessageSent>
      </Grid>

      <Grid item xs={3}>
        <MessageDelivery>
          <Typography variant="h1">
            {broadcastReport.report?.total_no_message_delivered}
          </Typography>
          <Typography variant="subtitle1">Messages Delivered</Typography>
        </MessageDelivery>
      </Grid>

      <Grid item xs={3}>
        <MessageSeen>
          <Typography variant="h1">
            {broadcastReport.report?.total_no_message_seen}
          </Typography>
          <Typography variant="subtitle1">Messages Seen</Typography>
        </MessageSeen>
      </Grid>

      <Grid item xs={2}>
        <MessageFailed>
          <Typography variant="h1">
            {broadcastReport.report?.total_no_message_failed}
          </Typography>
          <Typography variant="subtitle1">Messages Failed</Typography>
        </MessageFailed>
      </Grid>
    </React.Fragment>
  );
}
