import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Divider } from "@mui/material";

import FormDisplay from "./FormDisplay";
import { objectFromFormData } from "./groupDetailsObject";
import { useAxios } from "../../../hooks";
import { ButtonFooter } from "../../../components";
import { alert } from "../../../redux/slices/alertSlice";
import UserListComponent from "./UserListComponent";
import validationSchema from "./validationSchema";
import pages from "../../../constants/pages";

export default function Form({
  formData,
  setFormData,
  userList,
  groupID,
  setListUsers,
  setUser,
  setUserList,
  activeBot,
  allUsers,
  columns,
  setAddUsers,
  editState,
  setEditState,
  setAllUsers,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axios = useAxios();

  const handleDeleteGroup = () => {
    axios({
      url: "/broadcast/delete/group/" + groupID,
      method: "DELETE",
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
            // navigate: pages.groupList.route,
          })
        );
        navigate(pages.groupList.route);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var data = JSON.stringify(objectFromFormData(formData));

        axios({
          url: "/broadcast/update/group/" + groupID,
          method: "PUT",
          data: data,
        }).then((response) => {
          if (response.status) {
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
            setFormData(formData);
          }
          setSubmitting(false);
          setEditState(false);
        });
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            groupID={groupID}
          />

          <ButtonFooter
            ID={formikProps.values.ID}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            deleteButton={[true, handleDeleteGroup, "Delete Group"]}
          />

          <Divider
            style={{
              margin: "16px 0 16px 0",
            }}
          />

          <UserListComponent
            userList={userList}
            setListUsers={setListUsers}
            groupID={groupID}
            setUser={setUser}
            formikProps={formikProps}
            setUserList={setUserList}
            activeBot={activeBot}
            allUsers={allUsers}
            columns={columns}
            setAddUsers={setAddUsers}
            setAllUsers={setAllUsers}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
