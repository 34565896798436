import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/material.css";
import { Grid, Typography, MenuItem, Button } from "@mui/material";

import { useAxios, useComponent } from "../../../hooks";
import { Paper, TextField } from "../../../components";
import Dialog from "./VerifyDialog";

export default function FormDisplay({ formikProps }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const { alert } = useComponent();

  const [sendOTP, setSendOTP] = useState(true);
  const [resendOTP, setResendOTP] = useState(false);

  const [verifyOTP, setVerifyOTP] = useState(false);
  const [verifyDialog, setVerifyDialog] = useState(false);

  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  function sendOtp() {
    axios({
      url: "/business/send_otp",
      method: "POST",
      data: {
        mobile: formikProps.values.phoneNumber,
      },
    }).then((response) => {
      if (response.status) {
        setSendOTP(false);
        setResendOTP(true);
        alert.success("OTP has sent to your mobile.");
      }
    });
  }

  function resendOtp() {
    axios({
      url: "/business/resend_otp",
      method: "POST",
      data: {
        mobile: formikProps.values.phoneNumber,
      },
    }).then((response) => {
      if (response.status) {
        alert.success("OTP has sent to your mobile.");
      }
    });
  }

  function verifyOtp() {
    axios({
      url: "/business/verify_otp",
      method: "POST",
      data: {
        country_code: formikProps.values?.countryCode,
        otp: formikProps.values?.otp,
        mobile: formikProps.values?.mobileNumber,
      },
    }).then((response) => {
      if (response.status) {
        setResendOTP(false);
        setVerifyOTP(true);
        alert.success("OTP has been verified!");
      } else {
        alert.error("Please provide correct otp");
      }
    });
  }

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        phoneNumber: "+" + country.dialCode + "",
        countryCode: country.dialCode,
      }));
    } else {
      formikProps.setValues((prevVal) => {
        if (prevVal.phoneNumber.length <= 3) {
          setSendOTP(true);
          setResendOTP(false);
        }
        return {
          ...prevVal,
          phoneNumber: value,
          mobileNumber: value,
        };
      });
    }
  };

  function onChange(value) {}
  return (
    <Paper style={{ width: "600px" }}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          DolphinChat
        </Typography>

        <Typography variant="body1" align="center">
          Sign up!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="First Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="lastName"
          label="Last Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="gender"
          label="Gender"
          formikProps={formikProps}
          select
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="email"
          label="Email Address"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="businessName"
          label="Business Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={9}>
        <PhoneInput
          value={formikProps.values.phoneNumber}
          onChange={(value, country, e) =>
            handleChangePhoneNumber(value, country, e)
          }
          onBlur={formikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: "currentColor",
            borderColor: !validPhoneNumber ? "#4076d2" : "#f44336",
            padding: "17.5px 14px 18.5px 58px",
            cursor: "default",
          }}
        />
        {validPhoneNumber && (
          <span className="validationError">Number is Required</span>
        )}
      </Grid>

      {sendOTP && !verifyOTP && (
        <Grid item xs={3} alignSelf={"center"} textAlign="end">
          <Button
            variant="contained"
            onClick={sendOtp}
            disabled={!formikProps.values.phoneNumber}
          >
            Send OTP
          </Button>
        </Grid>
      )}

      {resendOTP && !verifyOTP && (
        <Grid
          item
          xs={3}
          alignSelf={"center"}
          textAlign="end"
          onClick={resendOtp}
          disabled={!formikProps.values.phoneNumber}
        >
          <Button variant="contained">Resend OTP</Button>
        </Grid>
      )}

      {!sendOTP && !verifyOTP && (
        <Grid item xs={9}>
          <TextField name="otp" label="OTP" formikProps={formikProps} />
        </Grid>
      )}

      {(!sendOTP || resendOTP) && !verifyOTP && (
        <Grid
          item
          xs={3}
          textAlign="end"
          alignSelf={"center"}
          onClick={verifyOtp}
          disabled={!formikProps.values.phoneNumber}
        >
          <Button variant="contained">Verify OTP</Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <ReCAPTCHA
          sitekey="6LfDPWkfAAAAAEpk9GrZqdEBlYIPvepeaHrZOqZ1"
          onChange={onChange}
          size="normal"
          badge="bottomright"
        />
      </Grid>

      {/* <Grid item alignSelf="center">
        <Link href="/forgot-password">Forgot Password?</Link>
      </Grid> */}

      {resendOTP && (
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid || !verifyOTP}
            loading={formikProps.isSubmitting}
          >
            Sign Up
          </LoadingButton>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="caption1">
          Already have account?{" "}
          <Typography
            variant="button"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>

      <Dialog
        verifyOTP={verifyOTP}
        setVerifyOTP={setVerifyOTP}
        dialogState={verifyDialog}
        setDialogState={setVerifyDialog}
      />
    </Paper>
  );
}
