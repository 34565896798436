import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import FormDisplay from "./FormDisplay";
import WhatsappPreview from "../../template/whatsapp interface/WhatsappPreview";
import validationSchema from "./validationSchema";
import { Paper, ComponentHeader } from "../../../components";
import { objectFromFormData } from "./broadcastDetailsObject";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";
import pages from "../../../constants/pages";
import Buttons from "./Buttons";

export default function Form({
  formData,
  setFormData,
  userList,
  template,
  scheduledTime,
  setTemplateData,
  templateData,
  setScheduledTime,
  groupList,
  activeBot,
  previewData,
  setPreviewData,
  fields,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (Boolean(formData.ID)) {
      setEditState(false);
    }
  }, [formData.ID]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData, "pending");

        let url = "/broadcast/create/campaign/" + activeBot.ID;
        let method = "POST";
        if (formData.ID) {
          url = "/broadcast/update/campaign/" + formData.ID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          data: data,
          disableRedirect: true,
        }).then((res) => {
          if (res.status) {
            setEditState(false);
            dispatch(
              alert({
                type: "success",
                message: res.message.displayMessage,
              })
            );
            setFormData(formData);

            if (activeBot.ID) {
              navigate(pages.broadcastList.route);
            }

            if (formData.whenToBroadcast === "later") {
              navigate(pages.broadcastList.route);
            }
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Grid alignItems="stretch" container spacing={2}>
          <Grid item xs={12} sm={8} display="flex">
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              userList={userList}
              template={template}
              setTemplateData={setTemplateData}
              templateData={templateData}
              scheduledTime={scheduledTime}
              setScheduledTime={setScheduledTime}
              groupList={groupList}
              activeBot={activeBot}
              setPreviewData={setPreviewData}
              fields={fields}
            />
          </Grid>

          <Grid
            style={{ justifyContent: "center" }}
            container
            item
            xs={12}
            sm={4}
          >
            <Paper>
              <Grid item xs={12}>
                <ComponentHeader
                  title={"Message Preview"}
                  subtitle={"The message for user will look like this."}
                />
              </Grid>
              <Grid item xs={12}>
                <WhatsappPreview formikProps={previewData} />
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Buttons
              formikProps={formikProps}
              setFormData={setFormData}
              activeBot={activeBot}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
