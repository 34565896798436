const notificationRoute = {
  "3da0752589fd45ccb29160114e446be2": "/members/",
  "782cf785c1b64f8a85a4645a59de83b1": "/insights",
  "80cff930101d401c9ad384716d59b32d": "/bots/",
  f91270b6e9084d398d494fe0930ff94d: "/templates/",
  a7dcf2b995d645c1b657fdac59f77c45: "/leads/",
  e6b8a1ec5e654db1bcdde4187343cd2a: "/custom-fields/",
  "2d0a592d2af6422db56e3e67859921ee": "/broadcast/",
  "064930d101f649c991a1aeceaa520ef0": "/group/",
  d294e49d915045ac9a088cb746a44bd7: "/labels/",
  fe25df4e34a647d382d8e107ae968404: "/utterance/",
  "5f77b1bdd6f940c6b663cce26a82d37d": "/integrations/",
  "1a538f2b1aaf4600bacc7b24d5181846": "/permission-groups/",
};

export default notificationRoute;
