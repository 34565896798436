import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { ComponentHeader, Paper, TextField } from "../../../../../components";
import convertDateToBackendFormat from "../convertDateToBackendFormat";
import { useAxios } from "../../../../../hooks";

const DetailsCard = styled.div`
  padding: 20px;
  width: 300px;
  height: 300px;
  overflow: scroll;
  border-radius: 10px;
  margin-right: 20px;
  background: #e1ebee;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const TermTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

export default function MessageForm({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  groupList,
  fields,
}) {
  const axios = useAxios();

  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  var date = year + "-" + month_raw + "-" + date_raw;

  const handleChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: e.target.value,
    }));
    var time = convertDateToBackendFormat(e.target.value);

    axios({
      url: "/broadcast/time?schedule_date=" + time,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={8} style={{ display: "grid" }}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Broadcast SMS"
              subtitle={"Provide the details for creating the broadcast."}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              label="Broadcast Name"
              name="broadcastName"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="Select Group"
              name="groupID"
              disabled={!editState}
              formikProps={formikProps}
            >
              {groupList.map((item) => (
                <MenuItem key={item.group_id} value={item.group_id}>
                  {item.group_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="Select Template"
              name="templateID"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value="62652c11ef69880db14037e2">
                Property Tax Notification(Marathi)
              </MenuItem>
              <MenuItem value="6268d2ce5a20e5743140a6a4">
                Property Tax Notification(English)
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="When To Broadcast"
              name="whenToBroadcast"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={"now"}>Now</MenuItem>
              <MenuItem value={"later"}>Later</MenuItem>
            </TextField>
          </Grid>

          {Boolean(formikProps.values.whenToBroadcast) &&
            formikProps.values.whenToBroadcast !== "now" && (
              <React.Fragment>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    name="broadcastDate"
                    label="Select Date"
                    onChange={handleChange}
                    formikProps={formikProps}
                    inputProps={{
                      min: date,
                    }}
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="Select Time"
                    name="broadcastTime"
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    formikProps={formikProps}
                  >
                    {scheduledTime.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.time}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </React.Fragment>
            )}

          <Grid item xs={6}>
            <TextField
              select
              label="Repeat"
              name="repeat"
              required
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value="atleastOnce"> Once</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TermTypography variant="h6">Filters</TermTypography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Select Condition"
              name="ctaClicked"
              disabled={!editState}
              formikProps={formikProps}
            >
              {fields.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Select Value"
              name="option"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"SMS Preview"}
              subtitle="This is how your sms look like."
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <DetailsCard>
              {formikProps.values.templateID === "62652c11ef69880db14037e2" && (
                <TermTypography variant="h5">
                  नमस्कार अश्विन, फक्त एक मैत्रीपूर्ण आठवण करून देतो मालमत्तेचा
                  ID: P20900662007, ₹ 646610.00 चा मालमत्ता कर अजून भरायचा आहे
                  आणि देय आहे तारीख रविवार, 30 एप्रिल आहे. तुम्ही पैसे देऊ शकता
                  आता https://rzp.io/i/6WVbsd19YC वर डिफॉल्टरच्या यादीत येऊ नये
                  म्हणून|
                </TermTypography>
              )}

              {formikProps.values.templateID === "6268d2ce5a20e5743140a6a4" && (
                <TermTypography variant="h5">
                  Hello Ashwin, just a friendly reminder to let you know that
                  for Property ID: P20900662007, the property tax of ₹ 646610.00
                  is yet to be paid and the due date is Sunday, 30th of April.
                  You can pay now at https://rzp.io/i/6WVbsd19YC to avoid
                  getting on the defaulter's list.
                </TermTypography>
              )}
            </DetailsCard>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
