import { Button, Grid, MenuItem } from "@mui/material";
import { Fragment } from "react";

import ErrorCannedMessage from "./Error";
import {
  ComponentHeader,
  CreatedAndUpdatedInfo,
  FileUpload,
  Paper,
  TextField,
} from "../../../components";

export default function FormDisplay({
  formikProps,
  editState,
  activeBotID,
  error,
  cannedMsgErrorData,
}) {
  return (
    <Paper>
      <Grid item container xs={12}>
        <ComponentHeader
          title={"Canned Response Create"}
          buttons={
            formikProps.values.cannedMsgType === "bulk" && [
              <Button
                component="a"
                variant="outlined"
                href={
                  "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/broadcast/8d2ba84196004374a87901c9a9f279b6/1693831570272_1693568777121_canned_message_test.xlsx"
                }
                sx={{ p: "10px 17px 10px 17px", mt: "5px" }}
              >
                Download Sample File
              </Button>,
            ]
          }
        />
      </Grid>

      {!formikProps.values.ID && (
        <Grid item xs={formikProps.values.cannedMsgType === "bulk" ? 6 : 4}>
          <TextField
            select
            required
            name="cannedMsgType"
            label="Select Canned Response Type"
            formikProps={formikProps}
          >
            <MenuItem value={"single"}>Create Single Canned Response</MenuItem>
            <MenuItem value={"bulk"}>Upload Bulk Canned Response</MenuItem>
          </TextField>
        </Grid>
      )}

      {error && formikProps.values.cannedMsgType === "bulk" && (
        <ErrorCannedMessage
          duplicateShortcut={cannedMsgErrorData.duplicateShortcut}
          duplicateTitle={cannedMsgErrorData.duplicateTitle}
          invalidRows={cannedMsgErrorData.invalidRows}
        />
      )}

      {formikProps.values.cannedMsgType === "bulk" ? (
        <Fragment>
          <Grid item container xs={6}>
            <FileUpload
              label="Upload File"
              name="file_url"
              formikProps={formikProps}
              allowedExtension={".xlsx"}
              maxSize={16}
              folderPath={"broadcast/" + activeBotID + "/"}
            />
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={formikProps.values.ID ? 6 : 4}>
            <TextField
              name={"title"}
              label="Response Title"
              disabled={!editState}
              formikProps={formikProps}
              required
            />
          </Grid>
          <Grid item xs={formikProps.values.ID ? 6 : 4}>
            <TextField
              name={"shortcut"}
              label="Response Shortcut Text"
              disabled={!editState}
              formikProps={formikProps}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"text"}
              fullWidth
              required
              multiline
              rows={5}
              inputProps={{
                maxLength: 1024,
              }}
              label="Response Text"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>
          <CreatedAndUpdatedInfo formikProps={formikProps} />
        </Fragment>
      )}
    </Paper>
  );
}
