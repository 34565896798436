import { React, useState } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { PhotoCamera } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Button,
  Divider,
  InputAdornment,
} from "@mui/material";

import CropImage from "./CropImage";
import { Paper, TextField, ComponentHeader } from "../../../../components";

export default function Profile({
  image,
  value,
  category,
  setImage,
  setValues,
  website,
  setWebsite,
}) {
  const validUrlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const [isValid, setIsValid] = useState(true);
  const [url, setUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleChangeImage = (photo) => {
    const file = photo.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      setImage((prev) => ({
        ...prev,
        imageResult: reader.result,
        imageTarget: photo.target.files[0],
      }));
    });

    if (file) {
      reader.readAsDataURL(file);
    }
    setOpenModal(true);
  };

  function handleChangeCategory(e) {
    setValues((prevVal) => ({
      ...prevVal,
      label: e.target.value,
    }));
  }

  function handleChangeValue(e) {
    setValues((prevVal) => ({
      ...prevVal,
      description: e.target.value,
    }));
  }

  function handleChangeEmail(e) {
    setValues((prevVal) => ({
      ...prevVal,
      email_address: e.target.value,
    }));
  }

  function handleChangeAddress(e) {
    setValues((prevVal) => ({
      ...prevVal,
      address: e.target.value,
    }));
  }

  function handleAddFields() {
    setWebsite([...website, ["", ""]]);
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Profile Photo"}
          subtitle={" This will be visible on your business profile"}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} sm={12}>
        <TextField
          required
          // disabled={!editState}
          name="profilePicture"
          label="Display Picture"
          // formikProps={formikProps}
          placeholder="No file chosen"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onChange={handleChangeImage}
              >
                <input
                  hidden
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleChangeImage}
                />
                <PhotoCamera />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">Display Name</Typography>
        <Typography variant="span">Sapio Analytics</Typography>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12} md={6} lg={6} sm={12}>
        <TextField
          select
          label="Category"
          placeholder="Category"
          name="businessVerticalId"
          onChange={handleChangeCategory}
        >
          {category.map((business) => (
            <MenuItem key={business.id} value={business.id}>
              {business.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12} md={6} lg={6} sm={12}>
        <TextField
          label="Description"
          inputProps={{
            maxLength: 512,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value.description?.length}/512
              </InputAdornment>
            ),
          }}
          onChange={handleChangeValue}
          placeholder="Tell your customer about your company"
          multiline
        />
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12} md={8} lg={8} sm={12}>
        <ComponentHeader
          title={"Contact Information"}
          subtitle={"Add some contact details for your business"}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} sm={12}>
        <TextField
          label="Address"
          onChange={handleChangeAddress}
          inputProps={{
            maxLength: 128,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value.address?.length}/128
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}></Grid>
      <Grid item xs={12} md={6} lg={6} sm={12}>
        <TextField
          label="Email Address "
          inputProps={{
            maxLength: 128,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value.email_address?.length}/128
              </InputAdornment>
            ),
          }}
          onChange={handleChangeEmail}
        />
      </Grid>

      <Grid item xs={12}></Grid>
      <Grid item xs={12} md={6} lg={6} sm={12}>
        {website.map((value, index) => (
          <TextField
            key={index}
            value={value}
            required
            name="businessWebsite"
            label="Websites"
            style={{ marginBottom: "18px" }}
            onChange={(e) => {
              const newValues = [...website];
              newValues[index] = e.target.value;
              setWebsite(newValues);
              const inputUrl = e.target.value;
              setUrl(inputUrl);
              setIsValid(validUrlRegex.test(inputUrl));
            }}
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              error: !isValid,
              endAdornment: !isValid && (
                <>
                  {/* <Typography color="secondary">Invalid URL</Typography> */}
                  <InputAdornment position="end">
                    {value.website?.length}/50
                  </InputAdornment>
                </>
              ),
            }}
          />
        ))}
      </Grid>

      <Grid item xs={6} sx={{ paddingTop: "0px" }}></Grid>
      <Grid
        item
        xs={6}
        style={{ paddingTop: "0px", justifyContent: "end", display: "flex" }}
      >
        {website.length < 2 && (
          <Button variant="contained" color="primary" onClick={handleAddFields}>
            Add Website
          </Button>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12} lg={12} sm={12}>
        <ComponentHeader title={"Official business account "} />
        <Typography variant="subtitle2">
          An official business account has green tick next to its name. This
          shows that Whatsapp has confirmed that an authentic and notable brand
          own this account.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="primary">
          Submit request
        </Button>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs></Grid>

      <Grid item>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>

      {image.imageTarget !== "" && (
        <CropImage
          openModal={openModal}
          setOpenModal={setOpenModal}
          image={image}
          setImage={setImage}
        />
      )}
    </Paper>
  );
}
