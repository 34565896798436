import { Fragment } from "react";
import styled from "styled-components";
import { Paper as MuiPaper, Typography, Grid } from "@mui/material";

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: "relative";
  flex-direction: column;
  opacity: 0.15;
`;

const TextLayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContainerLayer = styled.div`
  position: "relative";
`;

export default function WhatsappUtilityUsage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContainerLayer>
          <Paper>
            <Fragment>
              <Typography
                variant="h3"
                mt={5}
                sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
              >
                Whatsapp Usage
              </Typography>

              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} mt={5}>
                    <ContainerItem>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                      >
                        Utility Conversations
                      </Typography>

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "500",
                        }}
                        sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
                      >
                        NA
                      </Typography>
                    </ContainerItem>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} mt={5}>
                    <ContainerItem>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                      >
                        Authentications Conversation
                      </Typography>

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "500",
                        }}
                        sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
                      >
                        NA
                      </Typography>
                    </ContainerItem>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} mt={5}>
                    <ContainerItem>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                      >
                        Marketing Conversation
                      </Typography>

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "500",
                        }}
                        sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
                      >
                        NA
                      </Typography>
                    </ContainerItem>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} mt={5}>
                    <ContainerItem>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                      >
                        Service Conversation
                      </Typography>

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "500",
                        }}
                        sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
                      >
                        NA
                      </Typography>
                    </ContainerItem>
                  </Grid>
                </Grid>
              </Container>
            </Fragment>
          </Paper>

          <TextLayer style={{ position: "relative" }}>
            <Typography
              style={{ position: "absolute", top: "-160px" }}
              variant="h3"
              mt={5}
              sx={{ fontSize: { lg: 17, md: 20, sm: 18, xs: 15 } }}
            >
              Comming Soon Effective 1st June 2023
            </Typography>
          </TextLayer>
        </ContainerLayer>
      </Grid>
    </Grid>
  );
}
