import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import PermissionsGroupsTable from "./permissions groups components/PermissionsGroupsTable";
import AddPermissionGroupDialog from "./permissions groups components/AddPermissionGroupDialog";
import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";

export default function PermissionsGroups({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [permissionsGroups, setPermissionsGroups] = useState([]);
  const [dialogState, setDialogState] = useState(false);

  useEffect(() => {
    loader.start();

    axios({
      url: `/permission/get/group`,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();

        setPermissionsGroups(response.data);
      }
    });
  }, [activeBot.ID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Member Groups"
        buttons={[
          pagePermissions.create && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={openDialog}
            >
              Add New Group
            </Button>
          ),
        ]}
      />

      <AddPermissionGroupDialog
        dialogState={dialogState}
        handleDialogClose={closeDialog}
      />

      <PermissionsGroupsTable permissionsGroups={permissionsGroups} />
    </Loader>
  );

  function openDialog() {
    setDialogState(true);
  }

  function closeDialog() {
    setDialogState(false);
  }
}
