import React from "react";
import { Info } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";

import { TextField } from "../../../../components";

export default function BodyVariable({
  dialogFormikProps,
  templateData,
  formikProps,
  fields,
}) {
  return (
    <Grid container spacing={4} alignItems="center">
      {templateData.template_data?.template_body?.count_of_variable !== 0 &&
        !!formikProps.values.templateID && (
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <Typography variant="subtitle1">Map Body Variables</Typography>
              <Tooltip title="Values will be used in place of variables in body while sending message to the users.">
                <IconButton disableRipple style={{ padding: "0 0 0 5px" }}>
                  <Info
                    style={{ color: "rgb(203 203 203 / 0.63)" }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        )}

      {templateData.template_data?.template_body?.count_of_variable !== 0 &&
        !!formikProps.values.templateID &&
        templateData.variables?.body.map((_, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <TextField
                select
                label="Body Variable Name"
                name={"body_variable[" + index + "].variable_name"}
                formikProps={dialogFormikProps}
              >
                {templateData.variables?.body?.map((item, index) => (
                  <MenuItem
                    value={item}
                    disabled={dialogFormikProps.values?.body_variable.some(
                      (name) => name.variable_name === item
                    )}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={
                dialogFormikProps.values?.body_variable[index]
                  ?.variable_value_type === "custom"
                  ? 4
                  : 3
              }
            >
              <TextField
                select
                label="Body Variable Type"
                name={"body_variable[" + index + "].variable_value_type"}
                formikProps={dialogFormikProps}
              >
                <MenuItem value="user_variable">Custom Fields</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>

            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  select
                  label="Custom Fields"
                  name={"body_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone_number">Phone Number</MenuItem>
                  {fields.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "custom" && (
              <Grid item xs={5}>
                <TextField
                  label="Custom Value"
                  name={"body_variable[" + index + "].variable_value"}
                  s
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}

            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  label={"Fallback Value"}
                  name={"body_variable[" + index + "].variable_default_value"}
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}

            {(!Boolean(
              dialogFormikProps.values?.body_variable[index]
                ?.variable_value_type
            ) ||
              dialogFormikProps.values?.body_variable.length === 0) && (
              <Grid item xs={3}></Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
}
