import React, { useState } from "react";
import { Grid, MenuItem, Button } from "@mui/material";
import { titleCase } from "change-case-all";

import { useAxios } from "../../../hooks";
import { ComponentHeader, Paper, TextField } from "../../../components";
import {
  switchControl,
  controlText,
  dateTime,
  dropDown,
} from "./customFieldsDetailsObject";
import MoveFieldDialog from "./custom field move components/MoveField";

export default function MainForm({
  formikProps,
  controlTypes,
  setControlTypes,
  editState,
}) {
  const axios = useAxios();

  const [moveField, setMoveField] = useState(false);

  const handleChangeControl = (event) => {
    var controlID = event.target.value;
    axios({
      url: "/custom_fields/get_controls_data?control_id=" + controlID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        if (controlID === "61dd2dfa1fb38be82e077ed7") {
          controlText(formikProps, response);
        }
        if (controlID === "61dd2dfa1fb38be82e077ed9") {
          dateTime(formikProps, response);
        }
        if (controlID === "61dd2dfa1fb38be82e077ed8") {
          dropDown(formikProps, response);
        }
        if (controlID === "61e13a0bf2c60729b06385f3") {
          switchControl(formikProps, response);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Custom Field Details"
            subtitle="Here you can customize and create the fields according to your own way and these fields will be visible to all the users."
            buttons={[<MoveField />]}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            required
            name="controlID"
            label="Component Type"
            formikProps={formikProps}
            disabled={!editState || !!formikProps.values.ID}
            onChange={handleChangeControl}
          >
            {controlTypes.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {titleCase(item.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            formikProps={formikProps}
            name="isActive"
            label="Custom Field Status"
            disabled={!editState}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            formikProps={formikProps}
            name="label"
            label="Custom Field Label"
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            formikProps={formikProps}
            name="tooltip"
            label="Custom Field Tooltip"
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            formikProps={formikProps}
            name="description"
            label="Custom Field Description"
            disabled={!editState}
          />
        </Grid>
      </Paper>

      {Boolean(formikProps.values.ID) && (
        <MoveFieldDialog
          moveField={moveField}
          setMoveField={setMoveField}
          formikProps={formikProps}
        />
      )}
    </React.Fragment>
  );

  function MoveField() {
    return (
      Boolean(formikProps.values.ID) && (
        <Button
          onClick={() => setMoveField(true)}
          variant="outlined"
          color="primary"
        >
          Copy Field
        </Button>
      )
    );
  }
}
