import React from "react";

import { Grid, Typography, Paper as MuiPaper } from "@mui/material";

import styled from "styled-components";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Stats({ list }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Paper style={{ color: "#00529B", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">{list.total_template}</Typography>
          <Typography variant="subtitle1">Total Templates</Typography>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper style={{ color: "#37b24d", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">{list.approved}</Typography>
          <Typography variant="subtitle1">Total Templates Approved</Typography>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper style={{ color: "#9F6000", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">{list.pending}</Typography>
          <Typography variant="subtitle1">Total Templates Pending</Typography>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper style={{ color: "#f03e3e", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">{list.rejected}</Typography>
          <Typography variant="subtitle1">Total Templates Rejected</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} />
    </Grid>
  );
}
