import { Fragment } from "react";
import { Grid } from "@mui/material";

import { TextField } from "../../../../components";

export default function MultimediaDisplay({
  formikProps,
  editState,
  pagePermissions,
  type,
}) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          required
          name="payload.url"
          label={type + " URL"}
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.name"
          label={type + " Name"}
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.caption"
          label={type + " Caption"}
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.attachment_id"
          label={type + " Attachment ID"}
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>
    </Fragment>
  );
}
