import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Form from "./group details components/Form";
import { useAxios, useComponent } from "../../hooks";
import { PageHeader, Loader } from "../../components";
import {
  groupDetailsObject,
  objectFromResponse,
} from "./group details components/groupDetailsObject";
import pages from "../../constants/pages";

export default function GroupDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();
  const { loader, pagePermissions } = useComponent({ pageID });

  const groupID = params.groupID || "";

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(groupDetailsObject);

  //all users of the group
  const [allUsers, setAllUsers] = useState([]);
  const [columns, setColumns] = useState([]);

  const [userList, setUserList] = useState([]);
  //for deletion
  const [listUsers, setListUsers] = useState([]);

  //for addition
  const [addUsers, setAddUsers] = useState(false);

  useEffect(() => {
    if (Boolean(groupID)) {
      setEditState(false);
    }
  }, [groupID]);

  useEffect(() => {
    loader.start(4);
    if (Boolean(groupID)) {
      axios({
        url: "/broadcast/get/group/" + activeBot.ID + "?group_id=" + groupID,
        method: "GET",
      })
        .then((response) => {
          if (response.status) {
            setFormData(objectFromResponse(response.data));
            loader.apiComplete();
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios({
        url:
          "/custom_fields/get_fields_for_agent?agent_id=" +
          activeBot.ID +
          "&is_active=1",
        method: "GET",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setColumns(response.data);
          loader.apiComplete();
        }
      });

      axios({
        url:
          "/leads/get_leads?agent_id=" + activeBot.ID + "&skip=0&limit=250000",
        method: "POST",
        data: {},
      }).then((response) => {
        if (response.status) {
          setAllUsers(response.data.paginatedResults);
          loader.apiComplete();
        }
      });

      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?start=" +
          0 +
          "&length=" +
          20,
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete(4);
    }
  }, [groupID, listUsers, dispatch, axios, loader, activeBot, addUsers]);
  return (
    <Loader>
      <PageHeader
        pageName="Group Details"
        breadcrumbs={[
          {
            name: "Groups",
            location: pages.groupList.route,
          },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        groupID={groupID}
        columns={columns}
        formData={formData}
        setFormData={setFormData}
        userList={userList}
        setUserList={setUserList}
        setListUsers={setListUsers}
        editState={editState}
        setEditState={setEditState}
        activeBot={activeBot}
        allUsers={allUsers}
        setAllUsers={setAllUsers}
        setAddUsers={setAddUsers}
      />
    </Loader>
  );
}
