import React from "react";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";

import SequenceFieldForm from "./SequenceFieldForm";
import { useAxios, useComponent } from "../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "600px" },
}));

export default function SetSequenceField({
  popUp,
  setPopUp,
  activeBot,
  pagePermissions,
  sequence,
}) {
  const classes = useStyles();
  const axios = useAxios();

  const { alert } = useComponent();

  var data = {
    sequence_fields: sequence,
  };

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <React.Fragment>
      <Dialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Custom Field Sequence</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
              disableRipple
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          enableReinitialize
          initialValues={data}
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            var sequenceData = {
              agent_id: activeBot.ID,
              sequence_fields: [],
            };
            for (let [index, sequence] of formData.sequence_fields.entries()) {
              sequenceData.sequence_fields.push({
                field_id: sequence._id,
                sequence: index + 1,
              });
            }
            axios({
              url: "/custom_fields/sequence",
              method: "POST",
              data: sequenceData,
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                alert.success(response.message?.displayMessage);
                handleClose();
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  paddingTop: "10px",
                  overflowY: "unset",
                  backgroundColor: "#F7F9FC",
                }}
              >
                <Grid style={{ paddingTop: "10px" }} container spacing={4}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Drag and drop the card to set the sequence order of the
                      custom fields..
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <SequenceFieldForm
                      customFields={sequence}
                      dialogFormikProps={dialogFormikProps}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                {pagePermissions.update && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs></Grid>

                    <Grid item xs style={{ textAlign: "end" }}>
                      <Button
                        onClick={dialogFormikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                      >
                        Set Sequence
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
