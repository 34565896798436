import convertDateToBackendFormat from "../../other components/convertDateToBackendFormat";

export const broadcastDetailsObject = {
  ID: "",
  broadcastName: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
  senderName: "",
  broadcastFile: "",
  flowId: "",
};

export function objectFromFormData(formData) {
  if (formData.whenToBroadcast === "now") {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      sender_id: formData.senderName,
      flow_id: formData.flowId,
      file_url: formData.broadcastFile,
    };
  } else {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time: formData.broadcastTime,
      sender_id: formData.senderName,
      flow_id: formData.flowId,
      file_url: formData.broadcastFile,
    };
  }
}
