// import AWS from "aws-sdk";
// import { Buffer } from "buffer";
import { LoadingButton } from "@mui/lab";
import Cropper from "react-easy-crop";
import { useDispatch } from "react-redux";
import { Cancel, Upload } from "@mui/icons-material";
import React, { useState, useCallback } from "react";
import { Paper, Modal, Button, Typography, Slider } from "@mui/material";

import getCroppedImg from "./CropedImage";
import { useComponent } from "../../../../hooks";
import { uploadToS3 } from "../../../../utils";

export default function CropImage({
  openModal,
  setOpenModal,
  formikProps,
  image,
  setImage,
  bucket = {},
  file,
}) {
  const dispatch = useDispatch();
  const { alert } = useComponent();
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadOnS3 = () => {
    setLoading(true);
    // const base64Data = Buffer.from(
    //   croppedImage.replace(/^data:image\/\w+;base64,/, ""),
    //   "base64"
    // );

    // const type = croppedImage.split(";")[0].split("/")[1];
    // var bucketName = "dc-chat-media";
    // var bucketRegion = "ap-south-1";
    // var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

    // AWS.config.update({
    //   region: bucketRegion,
    //   credentials: new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: identityPoolID,
    //   }),
    // });

    // var s3 = new AWS.S3({
    //   apiVersion: "2006-03-01",
    //   params: {
    //     Bucket: bucketName,
    //   },
    // });

    // var filePath =
    //   formikProps.values.agentID +
    //   "/" +
    //   new Date().getTime() +
    //   "/" +
    //   image.imageTarget.name;

    // var upload = s3.upload({
    //   Key: filePath,
    //   ACL: "public-read",
    //   Body: base64Data,
    //   ContentEncoding: "base64",
    //   ContentType: `image/${type}`,
    // });

    const uploadBucket = {
      name: "dc-chat-media",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103",
      ...bucket,
    };

    // var promise = upload.promise();

    uploadToS3({
      bucket: uploadBucket,
      filePath: "sample_data_files/" + file.name,
      file,
      bucketRegion: "ap-south-1",
    }).then(
      function (data) {
        dispatch(alert.success("File uploaded successfully!"));

        formikProps.setValues((prevVal) => ({
          ...prevVal,
          pictureCustom: data.Location,
        }));

        setImage((prevVal) => ({
          ...prevVal,
          originalImage: data.Location,
        }));
        setLoading(false);
        setOpenModal(false);
        setCroppedImage(null);
      },
      function (err) {
        dispatch(alert.error("Failed to upload file!"));
        return alert("There was an error uploading your photo: ", err.message);
      }
    );
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image.imageResult,
        croppedAreaPixels
      );

      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image.imageResult]);

  function handleModalClose() {
    setOpenModal(false);
    setCroppedImage(null);
  }

  return (
    <React.Fragment>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={openModal}
      >
        <Paper
          style={{
            width: croppedImage === null ? "50%" : "35%",
            maxWidth: "900px",
            maxHeight: "900px",
            height: croppedImage === null ? "80%" : "70%%",
            padding: "10px",
            position: croppedImage !== null && "relative",
          }}
          elevation={2}
        >
          <Cancel
            style={{
              marginLeft: "95%",
              color: "#cbcbcb",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          {croppedImage === null && (
            <React.Fragment>
              <div
                style={{
                  padding: "10px",
                  margin: "5px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6">Crop your image here!</Typography>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "200px",
                  left: "200px",
                  right: "200px",
                  bottom: "200px",
                  margin: "auto 260px",
                }}
              >
                <Cropper
                  image={image.imageResult}
                  zoom={zoom}
                  onZoomChange={setZoom}
                  crop={crop}
                  aspect={1}
                  showGrid={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  top: "calc(100% - 215px)",
                  width: "15%",
                  transform: "translateX(-50%)",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  classes={{ root: "slider" }}
                />
              </div>
              <p
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    top: "calc(100% - 165px)",
                    position: "absolute",
                    display: "inline-flex",
                  }}
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </p>
            </React.Fragment>
          )}
          {croppedImage !== null && (
            <React.Fragment>
              <div
                style={{
                  padding: "10px",
                  margin: "5px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6">Here's your cropped Image.</Typography>
              </div>
              <div
                style={{
                  height: "250px",
                  width: "250px",
                  position: "relative",
                  display: "flex",
                  overflow: "hidden",
                  margin: "20px auto",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "inherit",
                    display: "block",
                  }}
                  src={croppedImage}
                  alt=""
                />
              </div>

              <LoadingButton
                style={{
                  position: "absolute",
                  right: "20px",
                  bottom: "20px",
                  margin: "10px 0",
                }}
                onClick={uploadOnS3}
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
                startIcon={<Upload />}
              >
                Upload
              </LoadingButton>
            </React.Fragment>
          )}
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
