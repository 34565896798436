import React from "react";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import { useComponent } from "../../../hooks";
import {
  objectFromFormData,
  validationSchema,
} from "./customFieldsDetailsObject";
import { ButtonFooter, Paper, ComponentHeader } from "../../../components";
import pages from "../../../constants/pages";
import { alert } from "../../../redux/slices/alertSlice";
import SwitchDialog from "../preview fields/switch dialog components/SwitchDialog";
import DropDownDialog from "../preview fields/dropdown dialog components/DropdownDialog";
import TextFieldDialog from "../preview fields/textfield dialog components/TextFieldDialog";
import DateTestDialog from "../preview fields/date dialog components/DateTestDialog";

export default function Form({
  formData,
  setFormData,
  controlTypes,
  setControlTypes,
  editState,
  setEditState,
  pagePermissions,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { axios } = useComponent();

  const { activeBot } = useSelector((state) => state.memberDetails);

  const handleDelete = () => {
    axios({
      url:
        "/custom_fields/delete_field?field_id=" +
        formData.ID +
        "&agent_id=" +
        activeBot.ID,
      method: "POST",
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: response.message.displayMessage,
          })
        );
        navigate(pages.customFieldList.route);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData, activeBot.ID, formData.ID);
        var url = "/custom_fields/create_custom_fields";

        if (formData.ID) {
          url = "/custom_fields/update_custom_fields";
        }

        axios({
          url: url,
          method: "POST",
          data: data,
        }).then((response) => {
          setEditState(false);

          if (response.status) {
            setSubmitting(false);
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
            navigate(pages.customFieldDetails.route + response.data._id);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FormDisplay
              formikProps={formikProps}
              controlTypes={controlTypes}
              setControlTypes={setControlTypes}
              editState={editState}
              activeBot={activeBot}
            />
          </Grid>

          <Grid item xs={3} style={{ display: "flex" }}>
            <Paper style={{ flexGrow: "1" }}>
              <Grid item xs={12}>
                <ComponentHeader
                  title={"Component Preview"}
                  subtitle={
                    formikProps.values.controlID
                      ? "The component will look like this when rendered on leads page."
                      : "Please select a component to se the preview."
                  }
                />
              </Grid>

              {formikProps.values.controlID === "61e13a0bf2c60729b06385f3" && (
                <SwitchDialog formikProps={formikProps} />
              )}

              {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed8" && (
                <DropDownDialog formikProps={formikProps} />
              )}

              {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed7" && (
                <TextFieldDialog formikProps={formikProps} />
              )}

              {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed9" && (
                <DateTestDialog formikProps={formikProps} />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <ButtonFooter
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              deleteButton={[
                pagePermissions.delete,
                handleDelete,
                "Delete Custom Field",
              ]}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
