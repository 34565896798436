import * as Yup from "yup";

export const botDetailsObject = {
  botID: "",
  botName: "",
  planName: "",
  channelName: "",
  authentication: {},
  pagePermissions: {},
  membersConfig: { members: [] },
  messageCount: 0,
  integrationCount: 0,
  userCount: 0,
  chatAutoEndTime: 0,
  autoAssignment: true,
  assignedToMessage: "",
  transferMessage: "",
  agentUnavailableMessage: "",
  endChatMessage: "",
  agentLostConnectionMessage: "",
};

export function formatBotDetailsResponse(responseData) {
  return {
    botID: responseData.agent_id,
    botName: responseData.agent_name,
    planName: responseData.plan_name,
    channelName: responseData.channel_name,
    authentication: responseData.authentication,
    pagePermissions: responseData.page_permission,
  };
}

export function formatLiveChatDetailsResponse(responseData) {
  return {
    chatAutoEndTime: responseData.auto_end_livechat_time,
    autoAssignment: responseData.auto_assignment,
    assignedToMessage: responseData.assigend_to_message,
    transferMessage: responseData.transferred_message,
    agentUnavailableMessage: responseData.no_agent_available_message,
    endChatMessage: responseData.end_chat_message,
    agentLostConnectionMessage: responseData.end_chat_forcefully,
  };
}

export function memberConfigResponse(responseData) {
  return {
    membersConfig: {
      members: responseData,
    },
  };
}

export function objectFromFormData(formData) {
  return {
    is_active: true,
    auto_end_livechat_time: formData.chatAutoEndTime,
    auto_assignment: formData.autoAssignment,
    assigend_to_message: formData.assignedToMessage,
    transferred_message: formData.transferMessage,
    no_agent_available_message: formData.agentUnavailableMessage,
    end_chat_message: formData.endChatMessage,
    end_chat_forcefully: formData.agentLostConnectionMessage,
  };
}

export const validationSchema = Yup.object().shape({
  botName: Yup.string()
    .max(50, "Maximum 50 characters only!")
    .required("Please enter a bot name!"),
  chatAutoEndTime: Yup.string().matches(/^[0-9]*$/, "Please enter a number"),
  autoAssignment: Yup.string(),
  assignedToMessage: Yup.string(),
  transferMessage: Yup.string(),
  agentUnavailableMessage: Yup.string(),
  endChatMessage: Yup.string(),
  agentLostConnectionMessage: Yup.string(),
});
