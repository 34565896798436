import React from "react";
import { Add } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";

import "emoji-mart/css/emoji-mart.css";

import {
  ComponentHeader,
  Paper,
  TextField,
} from "../../../../../../components";

export default function TemplateSubject({ formikProps, addSample, editState }) {
  const handleBodyChange = (event) => {
    var textHeader = document.getElementsByName("emailSubject")[0].value;

    var count_var_1 = (textHeader.match(/\{/g) || []).length;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      emailSubject: event.target.value,
      emailSubjectVariableCount: count_var_1,
    }));
  };

  const handleAddVariable = () => {
    var textHeader = document.getElementsByName("emailSubject")[0].value;
    var count_var_1 = (textHeader.match(/\{/g) || []).length;

    if (count_var_1 >= 2) {
      return;
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      emailSubject: textHeader + "{" + (count_var_1 + 1) + "}",
      emailSubjectVariableCount: count_var_1 + 1,
    }));
  };

  return (
    <Paper>
      <Grid style={{ alignSelf: "center" }} item xs={12}>
        <ComponentHeader
          title={"Template Subject"}
          subtitle={
            "Email subject templates are concise, pre-crafted headings businesses use to grab recipients' attention and summarize message content effectively."
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Template Subject"
          id="bodyText1"
          name="emailSubject"
          fullWidth
          required
          onChange={handleBodyChange}
          formikProps={formikProps}
          disabled={Boolean(formikProps.values.ID) && !editState}
        />
      </Grid>

      <Grid item />

      <Grid textAlign={"end"} item xs>
        <Button
          disabled={Boolean(formikProps.values.ID) && !editState}
          variant="outlined"
          color="primary"
          onClick={handleAddVariable}
        >
          <Add /> &nbsp;Add Variable
        </Button>
      </Grid>
    </Paper>
  );
}
