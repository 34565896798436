import { Fragment } from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({ formData }) {
  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <Fragment>
          <FormDisplay formikProps={formikProps} />
        </Fragment>
      )}
    </Formik>
  );
}
