import { Tooltip, Typography } from "@mui/material";
import styled from "styled-components";

import { Chip } from "../../../../../../../components";

const Wrapper = styled.div`
  width: 175px;
`;

export default function UserPillInfo({ user }) {
  return (
    <Wrapper>
      <div style={{ display: "flex" }}>
        <Typography noWrap variant="subtitle2" textOverflow="ellipsis">
          {user.name}
        </Typography>
        {user.chatActive && (
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50%",
              margin: "7px 0 0 7px",
              backgroundColor: "rgb(79, 138, 16)",
            }}
          ></div>
        )}
      </div>

      <Typography
        noWrap
        display="block"
        variant="caption"
        textOverflow="ellipsis"
      >
        {user.latestMessage}
      </Typography>

      <Tooltip title={user.assignedTo}>
        <Typography variant="caption">
          {convertDate(user.latestMessageTime)}
          {user.assignedTo && (
            <Chip
              label={
                (user.assignedTo.split(" ")?.[0]?.[0] || "") +
                (user.assignedTo.split(" ")?.[1]?.[0] || "")
              }
              variant="contained"
              style={{
                height: "20px",
                background: "#0089ff",
                color: "#fff",
                borderRadius: "20px",
                borderColor: "#0089ff",
                marginLeft: "5px",
              }}
            />
          )}
        </Typography>
      </Tooltip>
    </Wrapper>
  );
}

function convertDate(unixTimeStamp) {
  var timeStamp = new Date(unixTimeStamp * 1000);

  if (!(timeStamp > 0)) {
    return null;
  }

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = months[timeStamp.getMonth()];

  var day =
    timeStamp.getDate() < 10 ? "0" + timeStamp.getDate() : timeStamp.getDate();

  var hour =
    timeStamp.getHours() < 10
      ? "0" + timeStamp.getHours()
      : timeStamp.getHours();

  var min =
    timeStamp.getMinutes() < 10
      ? "0" + timeStamp.getMinutes()
      : timeStamp.getMinutes();

  var time = day + " " + month + " | " + hour + ":" + min;

  return time;
}
