import * as Yup from "yup";

export const businessDetailsObject = {
  businessName: "",
  gstNumber: "",
  contactNumber: "",
  businessEmailAddress: "",
  registeredCountry: "",
  registeredState: "",
  registeredCity: "",
  corporateCountry: "",
  corporateState: "",
  corporateCity: "",
};

export function objectFromResponseData(responseData) {
  return {
    memberID: responseData.member_id,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    emailAddress: responseData.email,
    businessName: responseData.business_name,
    gender: responseData.gender,
    groupName: responseData.member_group_name,
    preferredTimezone: responseData.preferred_timezone,
    bots: responseData.bots,
  };
}

export function objectFromFormData(formData) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    gender: formData.gender,
    preferred_timezone: formData.preferredTimezone,
    profile_pic_url:
      "https://civilcopsbotprofilepictures.s3.ap-south-1.amazonaws.com/civilcops_bot_profile_pics/bot@dolphinchat.ai_1624986797900_Bot Face Only Logo.png",
  };
}

export const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .max(255)
    .required("Please enter your business name!"),
  // gstNumber: Yup.string().max(15).required("Please enter your GST Number!"),
  registeredCountry: Yup.string()
    .max(16)
    .required("Please enter your valid registered Country!"),
  registeredState: Yup.string()
    .max(16)
    .required("Please enter your valid registered State!"),
  registeredCity: Yup.string()
    .max(16)
    .required("Please enter your valid registered City!"),
  corporateCountry: Yup.string()
    .max(16)
    .required("Please enter your valid corporate Country!"),
  corporateState: Yup.string()
    .max(16)
    .required("Please enter your valid corporate State!"),
  corporateCity: Yup.string()
    .max(16)
    .required("Please enter your valid corporate City!"),
  businessEmailAddress: Yup.string()
    .email("Enter a valid email")
    .max(255)
    .required("First Name is required"),
});
