import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import { useComponent } from "../../../hooks";
import pages from "../../../constants/pages";
import { ButtonFooter } from "../../../components";
import { alert } from "../../../redux/slices/alertSlice";

export default function Form({
  formData,
  editState,
  fields,
  setEditState,
  templates,
  pagePermissions,
  integrationDetails,
  userInfo,
  activeBot,
  leadProperties,
  conversation,
  setChangedLeadProperty,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { axios } = useComponent();

  const handleDelete = () => {
    axios({
      url:
        "/leads/deactivate_lead?lead_id=" +
        formData.ID +
        "&agent_id=" +
        activeBot.ID,
      method: "POST",
      data: {},
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: response.message?.displayMessage,
          })
        );
        navigate(pages.leadList.route);
      }
    });
  };
  let baseSchema = Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().when("phone", {
      is: (type) => type === undefined,
      then: Yup.string().required(
        "Either give email or phone number. Both can not be blank."
      ),
      otherwise: Yup.string().email("Must be a valid email"),
    }),
  });

  for (let field of fields) {
    if (field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string()
            .required(field.label + " is required field.")
            .matches(field.regex, field.error_message),
        })
      );
    } else if (
      field.mandatory &&
      !Boolean(field.regex) &&
      !Boolean(field.multi_Select)
    ) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().required(
            field.label + " is required field."
          ),
        })
      );
    } else if (!field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().matches(field.regex, field.error_message),
        })
      );
    } else if (field.mandatory && Boolean(field.multi_Select)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.array()
            .min(1, "Choose at least one " + field.label)
            .required(field.label + "is required field."),
        })
      );
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={baseSchema}
      onSubmit={(formData, { setSubmitting }) => {
        if (!formData.ID) {
          formData = {
            ...formData,
            channel: "rest",
          };
        }

        var url = "/leads/create_lead?agent_id=" + activeBot.ID;

        if (formData.ID) {
          url =
            "/leads/update_lead?lead_id=" +
            formData.ID +
            "&agent_id=" +
            activeBot.ID;
        }

        for (let field of fields) {
          if (field.control_id === "61dd2dfa1fb38be82e077ed8") {
            function setValue(options) {
              var arr = [];

              if (Boolean(options)) {
                var dropDownOption = [];

                if (Boolean(field.multi_Select)) {
                  dropDownOption = options.map((option) =>
                    field.options.find((item) => item.id === option)
                  );

                  for (let option of dropDownOption) {
                    arr.push({
                      id: option.id,
                      label: option.label,
                    });
                  }
                } else {
                  dropDownOption = field.options.find(
                    (item) => item.id === options
                  );
                  arr.push({
                    id: dropDownOption.id,
                    label: dropDownOption.label,
                  });
                }
              }
              return arr;
            }

            formData = {
              ...formData,
              [field.name]: setValue(formData[field.name]),
            };
          }
        }

        axios({
          url: url,
          method: "POST",
          data: formData,
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);
            setEditState(false);
            setChangedLeadProperty(
              formData.name + formData.email + formData.phone
            );
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );

            navigate(pages.leadDetails.route + response.data._id);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay
            fields={fields}
            formikProps={formikProps}
            editState={editState}
            templates={templates}
            pagePermissions={pagePermissions}
            integrationDetails={integrationDetails}
            activeBot={activeBot}
            userInfo={userInfo}
            conversation={conversation}
            leadProperties={leadProperties}
          />

          <ButtonFooter
            ID={formikProps.values.ID}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            deleteButton={[pagePermissions.delete, handleDelete, "Delete Lead"]}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
