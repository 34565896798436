import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../components";
import pages from "../../constants/pages";
import TabsList from "./lead detail components/Tabs";
import convertDate from "../../utils/convertDate";
import { useAxios, useComponent } from "../../hooks";
import PageHeader from "../../components/PageHeader";
import UserPrimaryInformation from "./lead detail components/lead information components/LeadPrimaryInformation";

export default function LeadDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();

  const dispatch = useDispatch();

  const leadID = params.leadID || "";

  const { activeBot } = useSelector((state) => state.memberDetails);
  const { loader, pagePermissions } = useComponent({ pageID });

  const [fields, setFields] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState({});

  const [templates, setTemplates] = useState([]);
  const [editState, setEditState] = useState(true);

  const [memberList, setMemberList] = useState([]);

  const [conversation, setConversation] = useState();

  const [leadProperties, setLeadProperties] = useState({});
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [changedLeadProperty, setChangedLeadProperty] = useState("");

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (activeBot.channel === "whatsapp") {
      axios({
        url: "/wa/template/" + activeBot.ID + "?verified_status=3",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setTemplates(response.data.temp_obj);
        }
      });
    }

    if (!!leadID) {
      axios({
        url: "/leads/mailgun_integration_status?agent_id=93f8b23a87164f5a88fdff7eba922c7a",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setIntegrationDetails(response.data);
        }
      });

      axios({
        url: "/label/" + activeBot.ID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setLabels(response.data);
        }
      });

      axios({
        url: "/leads/members_list",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setMemberList(response.data);
        }
      });
    }
  }, [axios, leadID, activeBot]);

  useEffect(() => {
    loader.start(2);

    axios({
      url:
        "/custom_fields/get_fields_for_agent?agent_id=" +
        activeBot.ID +
        "&is_active=1",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          var fields = response.data;
          setFields(response.data);

          if (!!leadID) {
            setEditState(false);

            axios({
              url:
                "/leads/get_leads?agent_id=" +
                activeBot.ID +
                "&lead_id=" +
                leadID,
              method: "POST",
              data: {},
            }).then((responses) => {
              if (responses.status) {
                var fieldsValue = responses.data?.lead_details;

                axios({
                  url: `/conversation/${fieldsValue.sender_id}`,
                  disableRedirect: true,
                  disableError: true,
                  params: {
                    bot_id: activeBot.ID,
                  },
                }).then((response) => {
                  if (response.status) {
                    setConversation(true);
                  }
                });

                getFields();

                async function getFields() {
                  for await (let field of response.data) {
                    if (field.control_id === "61dd2dfa1fb38be82e077ed8") {
                      setFormData((formData) => ({
                        ...formData,
                        [field.name]: setValue(fieldsValue[field.name]),
                      }));

                      function setValue(options) {
                        if (Boolean(field.multi_Select)) {
                          var arr = [];

                          if (Boolean(options)) {
                            var dropDownOption = options.map((option) =>
                              field.options.find(
                                (item) => item.id === option.id
                              )
                            );
                            for (let option of dropDownOption) {
                              arr.push(option.id);
                            }
                          }
                          return arr;
                        } else {
                          var value = "";

                          if (Boolean(options)) {
                            var dropDownSingle = options.map((option) =>
                              field.options.find(
                                (item) => item.id === option.id
                              )
                            );
                            for (let option of dropDownSingle) {
                              value = option.id;
                            }
                          }
                          return value;
                        }
                      }
                    } else {
                      setFormData((formData) => ({
                        ...formData,
                        [field.name]: fieldsValue[field.name],
                      }));
                    }
                  }
                  setFormData((formData) => ({
                    ...formData,
                    ID: fieldsValue._id,
                    senderID: fieldsValue.sender_id,
                    name: fieldsValue.name,
                    phone: fieldsValue.phone,
                    email: fieldsValue.email,
                    lead_status: fieldsValue.lead_status,
                    lead_score: fieldsValue.lead_score,
                    important: fieldsValue.important,
                  }));

                  loader.apiComplete();
                  loader.apiComplete();
                }
              }

              setUserInfo(responses.data?.profile_data);
              setLeadProperties({
                owner_name: responses.data?.lead_properties.owner_name,
                source: responses.data?.lead_properties.source,
                lead_status: responses.data?.lead_properties.lead_status,
                owner: responses.data?.lead_properties.owner,
                lead_age: responses.data?.lead_properties.lead_age,
                createdBy: fieldsValue.created_by,
                updatedBy: fieldsValue.updated_by,
                createdAt: convertDate(fieldsValue.created_at),
                updatedAt: convertDate(fieldsValue.updated_at),
              });
            });
          } else {
            loader.apiComplete();

            getFields();

            async function getFields() {
              for await (let field of fields) {
                if (field.control_id !== "61e13a0bf2c60729b06385f3") {
                  if (
                    field.control_id === "61dd2dfa1fb38be82e077ed8" &&
                    field.multi_Select
                  ) {
                    setFormData((formData) => ({
                      ...formData,
                      [field.name]: [],
                    }));
                  } else if (field.control_id === "61dd2dfa1fb38be82e077ed9") {
                    setFormData((formData) => ({
                      ...formData,
                      [field.name]: new Date().getTime() / 1000,
                    }));
                  } else {
                    setFormData((formData) => ({
                      ...formData,
                      [field.name]: "",
                    }));
                  }
                } else {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: false,
                  }));
                }
              }

              loader.apiComplete();
            }
          }
        }
      })
      .catch((err) => console.error(err));
  }, [axios, dispatch, leadID, activeBot, loader, changedLeadProperty]);

  return (
    <Loader>
      <PageHeader
        pageName="Lead Details"
        breadcrumbs={[
          {
            name: "Leads",
            location: pages.leadList.route,
          },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      {formData.ID && (
        <UserPrimaryInformation
          pagePermissions={pagePermissions}
          formData={formData}
          templates={templates}
          integrationDetails={integrationDetails}
          userInfo={userInfo}
          leadProperties={leadProperties}
          setFormData={setFormData}
          activeBot={activeBot}
          setChangedLeadProperty={setChangedLeadProperty}
          memberList={memberList}
        />
      )}

      <TabsList
        fields={fields}
        leadID={leadID}
        userInfo={userInfo}
        formData={formData}
        activeBot={activeBot}
        editState={editState}
        templates={templates}
        leadProperties={leadProperties}
        pagePermissions={pagePermissions}
        integrationDetails={integrationDetails}
        setEditState={setEditState}
        setFormData={setFormData}
        conversation={conversation}
        labels={labels}
        setChangedLeadProperty={setChangedLeadProperty}
      />
    </Loader>
  );
}
