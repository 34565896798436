import styled from "styled-components";
import { Fragment, useState } from "react";
import { DataTable } from "../../../components";
import { Paper as MuiPaper, Typography, Grid } from "@mui/material";
import { Chart as ChartJs, Tooltip, ArcElement, Legend } from "chart.js";

import { useComponent } from "../../../hooks";

ChartJs.register(Tooltip, ArcElement, Legend);

const Paper = styled(MuiPaper)`
  height: "350px";
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export default function AgentPerformance({ startDate, botID }) {
  const { axios } = useComponent();

  const [loading, setLoading] = useState(true);
  const [agentPerformance, setAgentPerformance] = useState([
    {
      agentName: "Arun",
      liveChatsClosed: 18,
      averageResolutionTime: "23",
      totalActiveHours: 8,
      mostActive: 6,
      averageSessionDuration: 10,
      Rating: "3.0",
    },
    {
      agentName: "Ajay",
      liveChatsClosed: 14,
      averageResolutionTime: "10",
      totalActiveHours: 9,
      mostActive: 3,
      averageSessionDuration: 15,
      Rating: "4.0",
    },
    {
      agentName: "Aman",
      liveChatsClosed: 4,
      averageResolutionTime: "20",
      totalActiveHours: 5,
      mostActive: 8,
      averageSessionDuration: 2,
      Rating: "5.0",
    },
    {
      agentName: "Ravi",
      liveChatsClosed: 8,
      averageResolutionTime: "23",
      totalActiveHours: 4,
      mostActive: 6,
      averageSessionDuration: 7,
      Rating: "5.0",
    },
    {
      agentName: "Abhishek",
      liveChatsClosed: 7,
      averageResolutionTime: "34",
      totalActiveHours: 3,
      mostActive: 7,
      averageSessionDuration: 9,
      Rating: "3.0",
    },
  ]);

  const options = {
    print: false,
    elevation: 6,
    search: false,
    download: false,
    rowsPerPageOptions: false,
    sort: false,
    responsive: "simple",
    tableBodyMaxHeight: "45vh",
    viewColumns: false,
    filter: false,
    pagination: false,
    setRowProps: () => {
      return {
        style: { height: "10px", padding: "dense" },
        padding: "default",
      };
    },
  };

  const columns = [
    {
      name: "agentName",
      label: "Agent Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "liveChatsClosed",
      label: "Total Chats",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "averageResolutionTime",
      label: "Average Resolution Time",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "totalActiveHours",
      label: "Total Active Hours",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "mostActive",
      label: "Most Active",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "averageSessionDuration",
      label: "Average Session Duration",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "Rating",
      label: "Rating",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return (
    <Paper>
      <Fragment>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={12}>
              <Typography
                variant="h5"
                sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
              >
                Agent Performance
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={12}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                alignSelf: "center",
                marginBottom: "10px",
              }}
            >
              <DataTable
                data={agentPerformance}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </Paper>
  );
}
