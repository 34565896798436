import React from "react";

import WhatsappForm from "./WhatsappForm";

export default function FormDisplay({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  groupList,
  template,
  activeBot,
  fields,
}) {
  return (
    <WhatsappForm
      formikProps={formikProps}
      editState={editState}
      scheduledTime={scheduledTime}
      setScheduledTime={setScheduledTime}
      groupList={groupList}
      template={template}
      activeBot={activeBot}
      fields={fields}
    />
  );
}
