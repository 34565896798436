import { Avatar } from "@mui/material";

export default function TextAvatar({ userName, size, ...props }) {
  if (typeof userName === "string") {
    var userInitials =
      (userName.split(" ")?.[0]?.[0] || "") +
      (userName.split(" ")?.[1]?.[0] || "");
    var colorIndex =
      ((userInitials.charCodeAt(0) || 0) + (userInitials.charCodeAt(1) || 0)) %
      5;
  }
  //@mohit_bhatt

  return (
    <Avatar
      style={{
        cursor: props.onClick && "pointer",
        height: size?.height || "50px",
        width: size?.width || "50px",
        fontSize: size?.fontSize || "0.9rem",
        textTransform: "uppercase",
        background: backgroundColors[colorIndex],
      }}
      {...props}
    >
      {userInitials}
    </Avatar>
  );
}

const backgroundColors = [
  "#b39ddb",
  "#0277bd",
  "#a5d6a7",
  "#5c6bc0",
  "#ffab91",
];
