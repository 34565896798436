import { Fragment } from "react";
import { Grid, MenuItem, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";

import { Paper, ComponentHeader, TextField } from "../../../components";
import UtterancePayload from "./UtterancePayload";

export default function FormDisplay({
  editState,
  formikProps,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title="Utterance Details" />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="name"
            label="Utterance Name"
            disabled={!!formikProps.values.utteranceID}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="type"
            label="Utterance Type"
            disabled={!!formikProps.values.utteranceID}
            formikProps={formikProps}
            onChange={handleTypeChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="quick_replies">Quick Replies</MenuItem>
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="audio">Audio</MenuItem>
            <MenuItem value="document">Document</MenuItem>
            <MenuItem value="button">Button</MenuItem>
            <MenuItem value="location">Location</MenuItem>
            <MenuItem value="contact">Contact</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="activeStatus"
            label="Utterance Status"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Utterance Payload"
            buttons={[
              <AddButton
                type={formikProps.values.type}
                setValues={formikProps.setValues}
              />,
            ]}
          />
        </Grid>

        <UtterancePayload
          editState={editState}
          formikProps={formikProps}
          pagePermissions={pagePermissions}
        />
      </Paper>
    </Fragment>
  );

  function handleTypeChange({ target }) {
    const { value } = target;

    formikProps.setValues((prevValue) => ({
      ...prevValue,
      type: value,
      payload: payloadValue(value),
    }));

    function payloadValue(type) {
      switch (type) {
        case "text":
          return {
            text: "",
          };

        case "quick_replies":
          return {
            text: "",
            buttons: [
              {
                type: "postback",
                title: "",
                payload: "",
              },
            ],
          };

        case "image":
        case "video":
        case "audio":
        case "document":
          return {
            url: "",
            name: "",
            caption: "",
            attachment_id: "",
          };

        case "button":
          return [
            {
              type: "web_url",
              title: "",
              url: "",
              extension: true,
            },
          ];

        case "location":
          return {
            longitude: "",
            latitude: "",
            address: "",
            image_url: "",
          };

        case "contact":
          return {
            name: {
              first_name: "",
              last_name: "",
              formatted_name: "",
            },
            birthday: "",
            phones: [
              {
                phone: "",
              },
            ],
            emails: [
              {
                email: "",
              },
            ],
            addresses: [
              {
                city: "",
                country: "",
                state: "",
                street: "",
                zip: "",
              },
            ],
            org: {
              company: "",
              department: "",
              title: "",
            },
            urls: [
              {
                url: "",
              },
            ],
          };

        default:
          return {};
      }
    }
  }
}

function AddButton({ setValues, type }) {
  return (
    (type === "quick_replies" || type === "button") && (
      <IconButton color="primary" onClick={addNewObject}>
        <Add />
      </IconButton>
    )
  );

  function addNewObject() {
    setValues((prevValue) => ({
      ...prevValue,
      payload: [...prevValue.payload, payloadToBeAdded(type)],
    }));

    function payloadToBeAdded(type) {
      if (type === "quick_replies") {
        return {
          type: "postback",
          title: "",
          payload: "",
        };
      }

      if (type === "button") {
        return {
          type: "",
          title: "",
          url: "",
          extensions: true,
        };
      }
    }
  }
}
