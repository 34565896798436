import * as Yup from "yup";

export const memberDetailsObject = {
  memberID: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  businessName: "",
  gender: "",
  groupName: "",
  preferredTimezone: "",
  bots: [],
};

export function objectFromResponseData(responseData) {
  return {
    memberID: responseData.member_id,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    emailAddress: responseData.email,
    businessName: responseData.business_name,
    gender: responseData.gender,
    groupName: responseData.member_group_name,
    preferredTimezone: responseData.preferred_timezone,
    bots: responseData.bots,
  };
}

export function objectFromFormData(formData) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    gender: formData.gender,
    preferred_timezone: formData.preferredTimezone,
    profile_pic_url:
      "https://civilcopsbotprofilepictures.s3.ap-south-1.amazonaws.com/civilcops_bot_profile_pics/bot@dolphinchat.ai_1624986797900_Bot Face Only Logo.png",
  };
}

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required("Please enter your first name!"),
  lastName: Yup.string().max(255).required("Please enter your last name!"),
  gender: Yup.string().max(255).required("Please enter your last name!"),
});
