import { Fragment } from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import CorporateAddressForm from "./CorporateAddressForm";
import RegisteredAdderssForm from "./RegisteredAdderssForm";
import { validationSchema, objectFromFormData } from "./businessDetailsObject";

export default function Form({
  editState,
  setEditState,
  formData,
  setFormData,
}) {
  const { axios, alert } = useComponent();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      // onSubmit={(formData, { setSubmitting }) => {
      //   axios({
      //     url: "/update/profile",
      //     method: "PUT",
      //     data: objectFromFormData(formData),
      //   })
      //     .then((response) => {
      //       if (response.status) {
      //         setSubmitting(false);

      //         setFormData(formData);
      //         setEditState(false);

      //         alert.success(response.message.displayMessage);
      //       } else {
      //         setSubmitting(false);
      //       }
      //     })
      //     .catch((err) => console.error(err));
      // }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            formData={formData}
            setFormData={setFormData}
          />
          <RegisteredAdderssForm
            formikProps={formikProps}
            formData={formData}
            setFormData={setFormData}
          />
          <CorporateAddressForm
            formikProps={formikProps}
            formData={formData}
            setFormData={setFormData}
          />

          <ButtonFooter
            ID={formikProps.values.memberID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );
}
