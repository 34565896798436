import React from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import {
  Paper,
  TextField,
  Autocomplete,
  ComponentHeader,
  CreatedAndUpdatedInfo,
} from "../../../../components";

export default function FormDisplay({
  formikProps,
  editState,
  intents,
  entities,
  setEditState,
}) {
  const handleChangeIntent = (value, type) => {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        mappings: setIntent(prevVal.mappings),
      }));

      function setIntent(val) {
        val.intent = value._id;
        return val;
      }
    } else {
      value = {
        _id: "",
        intent: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        intent: value._id,
      }));
    }
  };

  const handleChangeEntity = (value, type) => {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        mappings: setEntity(prevVal.mappings),
      }));

      function setEntity(val) {
        val.entity = value._id;
        return val;
      }
    } else {
      value = {
        _id: "",
        intent: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        intent: value._id,
      }));
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Slot Details"}
              subtitle={"Details of the slot."}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Slot Name"
              name="slotName"
              formikProps={formikProps}
              disabled={!editState}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <Tooltip title="This field cannot contain white spaces and special characters.">
                      <IconButton>
                        <Info style={{ color: "#808080" }} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Slot Type"
              name="type"
              select
              formikProps={formikProps}
              disabled={!editState}
            >
              <MenuItem value="integer">Text</MenuItem>
              <MenuItem value="bool">Boolean</MenuItem>
              <MenuItem value="float">Float</MenuItem>
              <MenuItem value="list">List</MenuItem>
              <MenuItem value="categorical">Categorical</MenuItem>
              <MenuItem value="any">Any</MenuItem>
            </TextField>
          </Grid>

          {formikProps.values.type === "float" && (
            <React.Fragment>
              <Grid item xs={4}>
                <TextField
                  label="Min Value"
                  name="minValue"
                  formikProps={formikProps}
                  disabled={!editState}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Max Value"
                  name="maxValue"
                  formikProps={formikProps}
                  disabled={!editState}
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item xs={4}>
            <TextField
              label="Influence Conversation"
              name="influenceConversation"
              formikProps={formikProps}
              disabled={!editState}
              select
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              select
              label="Type"
              name="mappings.type"
              formikProps={formikProps}
              disabled={!editState}
            >
              <MenuItem value="from_entity">From Entity</MenuItem>
              <MenuItem value="from_text">From Text</MenuItem>
              <MenuItem value="from_intent">From Intent</MenuItem>
              <MenuItem value="from_triggered_intent">
                From Triggered Intent
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              name="mappings.intent"
              label="Tag Intent"
              defaults={{
                primaryKey: "_id",
                displayLabel: "intent_name",
              }}
              options={intents}
              disabled={!editState}
              onChange={(_, value, type) => handleChangeIntent(value, type)}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              name="mappings.entity"
              label="Tag Entity"
              defaults={{
                primaryKey: "_id",
                displayLabel: "entity_name",
              }}
              options={entities}
              disabled={!editState}
              onChange={(_, value, type) => handleChangeEntity(value, type)}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Not Intent"
              name="mappings.notIntent"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Role"
              name="mappings.role"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Group"
              name="mappings.group"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Active Loop"
              name="mappings.activeLoop"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Requested Slot"
              name="mappings.requestedSlot"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          {formikProps.values.ID && (
            <CreatedAndUpdatedInfo formikProps={formikProps} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
