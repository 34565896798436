import React from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { TextField, Autocomplete } from "../../../../components";
import { useAxios, useComponent } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function LabelDialog({
  labels,
  labelDialog,
  setLabelDialog,
  pagePermissions,
  component = "",
  data,
  setAssignUnassignLabel,
  assignLabel,
  setAssignLabel,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const { alert } = useComponent();

  var label = {
    labelID: [],
  };

  const handleClose = () => {
    setLabelDialog(false);
  };
  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={labelDialog}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">{"Assign Label"}</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={label}
          validateOnMount
          enableReinitialize
          // /  validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            var url =
              "/label/assign/label/" + formData.labelID + "?lead_id=" + data.ID;
            if (component === "chats") {
              url = "/label/assign/label/?sender_id=" + data.ID;
            }

            for (let data of formData.labelID) {
              label.labelID.push(data._id);
            }
            axios({
              url: url,
              disableRedirect: true,
              method: "PATCH",
              data: {
                label_ids: label.labelID,
                action: "assign",
              },
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                alert.success(response.message.displayMessage);
                setAssignUnassignLabel(formData.labelID);
                setAssignLabel([...assignLabel, ...formData.labelID]);
                handleClose();
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  height: "270px",
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Assign label to the lead.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <TextField
                      select
                      name="labelID"
                      label="Label"
                      disabled={labels.length === 0}
                      formikProps={dialogFormikProps}
                    >
                      {labels.map((item) => (
                        <MenuItem
                          value={item._id}
                          key={item._id}
                          disabled={assignLabel.some(
                            (label) => label._id === item._id
                          )}
                        >
                          <Chip label={item.title} background={item.color} />
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <Autocomplete
                      multiple={true}
                      name="labelID"
                      label="Select Label"
                      defaults={{
                        primaryKey: "title",
                        displayLabel: "title",
                      }}
                      required={false}
                      options={labels}
                      formikProps={dialogFormikProps}
                      sx={{ mt: "10px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: "13px", color: "#808080" }}>
                      You need to create the labels to assign them to users.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                {
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid item xs />

                    <Grid item xs style={{ textAlign: "end" }}>
                      <Button
                        onClick={dialogFormikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={
                          dialogFormikProps.dirty
                            ? dialogFormikProps.isSubmitting ||
                              !dialogFormikProps.isValid
                            : !dialogFormikProps.dirty
                        }
                      >
                        Assign Label
                      </Button>
                    </Grid>
                  </Grid>
                }
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}

// const validationSchema = Yup.object().shape({
//   labelID: Yup
// });
