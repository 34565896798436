import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Cancel, Info } from "@mui/icons-material";

import { FileUpload } from "./../../../../components";
import { alert } from "./../../../../redux/slices/alertSlice";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  divStyle: {
    display: "flex",
    backgroundColor: "rgb(203 203 203 / 34%)",
    padding: "10px",
  },
  downloadFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
  },
  generateFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
    margin: "0 10px",
  },
}));

export default function PublicURLDialog({ popUp, setPopUp }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const handleClose = () => {
    setPopUp(false);
  };

  const data = {
    getURL: "",
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Get Public URL</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
              disableRipple
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik initialValues={data} enableReinitialize validateOnMount>
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent style={{ height: "200px", paddingTop: "10px" }}>
                <Grid style={{ paddingTop: "10px" }} container spacing={4}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Upload the file to get the public URL you can use it
                      anywhere and can access the file .
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FileUpload
                      name="getURL"
                      label="Upload File"
                      maxSize={100}
                      allowedExtension=".jpeg,.jpg,.png,.gif,.svg,.webp,.avif,.mp4,.mkv,.webm,.doc,.pdf,.xls,.xslx,.csv,.xml,.txt,.mp3,.wav"
                      folderPath={"publicURLS/" + activeBot.ID + "/"}
                      formikProps={dialogFormikProps}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <div className={classes.divStyle}>
                      <Info
                        fontSize="small"
                        color="primary"
                        style={{ marginRight: "10px" }}
                      />

                      <Typography variant="body1">
                        The attachment size limit is 25 MB per file. You can add
                        multiple files as long as their combined size does not
                        exceed 25 MB.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                {dialogFormikProps.values.getURL && (
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        dialogFormikProps.values.getURL
                      );
                      dispatch(
                        alert({
                          type: "success",
                          message: "Link copied successfully",
                        })
                      );
                    }}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Copy Link
                  </Button>
                )}
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
