import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import pages from "../../../constants/pages";
import { signOutMember } from "../../../redux/slices/memberSlice";
import { ThemeContext } from "../../../components/ThemeProvider";
import { IconButton, Grid, Paper, Tooltip } from "@mui/material";
import {
  AccountCircle,
  Help,
  PowerSettingsNew,
  LightMode,
  DarkMode,
} from "@mui/icons-material";

const Wrapper = styled(Paper)`
  flex: 1;
  max-height: 45px;
  background: #1e2a38;
  color: #fff;
  transition: 0.25s linear;
`;

const GridWrapper = styled(Grid)`
  height: 100%;
  align-content: center;
  justify-content: center;
  gap: 4px;
`;

export default function Shortcuts({ sidebarState }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <Wrapper
      elevation={2}
      style={{
        transform: !sidebarState.open && "translateY(200px)",
      }}
    >
      <GridWrapper container>
        <Grid item>
          <Tooltip title="Profile">
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => navigate(pages.profile.route)}
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Help">
            <IconButton
              style={{ color: "#fff" }}
              onClick={() =>
                window.open(
                  "https://dolphinch.freshdesk.com/support/home",
                  "_blank"
                )
              }
            >
              <Help />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Switch Theme">
            <IconButton
              style={{ color: "#fff" }}
              onClick={() =>
                theme === "DARK" ? setTheme("DEFAULT") : setTheme("DARK")
              }
            >
              {theme === "DARK" ? <LightMode /> : <DarkMode />}
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Sign Out">
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => dispatch(signOutMember())}
            >
              <PowerSettingsNew />
            </IconButton>
          </Tooltip>
        </Grid>
      </GridWrapper>
    </Wrapper>
  );
}
