import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  IconButton,
  Dialog as MUIDialog,
  Typography,
} from "@mui/material";
import Form from "./Form";
import { Cancel } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "900px" },
}));

export default function OnboardingDialog({ onboardDialog, setOnboardDialog }) {
  const classes = useStyles();

  const handleClose = () => {
    setOnboardDialog(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={onboardDialog}
        onClose={handleClose}
      >
        <Grid container p={5} spacing={3}>
          <Grid item container>
            <Grid item xs={8}>
              <Typography variant="h4">Onboard Number</Typography>
            </Grid>

            <Grid item xs={4}>
              <IconButton
                style={{ float: "right", padding: "16px 24px" }}
                onClick={handleClose}
                disableRipple
              >
                <Cancel />
              </IconButton>
            </Grid>
          </Grid>

          <Form />
        </Grid>
      </MUIDialog>
    </React.Fragment>
  );
}
