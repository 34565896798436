import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import { FilterList, CloudDownload, Sync } from "@mui/icons-material";

import Refresh from "../../../assets/refresh.gif";
import FilterDataDialog from "./FilterDataDialog";
import { useAxios, useComponent } from "../../../hooks";

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomToolbar({
  memberList,
  setFilter,
  filter,
  setUsers,
  filterColumns,
  activeBot,
  userData,
  labels,
  applyFilter,
  setApplyFilter,
}) {
  const axios = useAxios();

  const { alert } = useComponent();

  const [refresh, setRefresh] = useState(false);

  function handleClick() {
    setFilter(true);
  }

  function handleDownloadCSV() {
    axios({
      url: "/leads/export_leads?agent_id=" + activeBot.ID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
      }
    });
  }

  function refreshLeads() {
    setRefresh(true);
    axios({
      url: "/leads/get_leads?agent_id=" + activeBot.ID + "&skip=0&limit=50",
      method: "POST",
      data: {},
    }).then((response) => {
      if (response.status) {
        setUsers({
          users: response.data.paginatedResults,
          totalCount: response.data.totalCount[0]?.count || 0,
        });

        setRefresh(false);
      }
    });
  }

  return (
    <React.Fragment>
      <Tooltip title={"Filter Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={handleClick}
        >
          <FilterList className={defaultToolbarStyles.deleteIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Download CSV"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={handleDownloadCSV}
        >
          <CloudDownload className={defaultToolbarStyles.deleteIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Refresh Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={refreshLeads}
        >
          {refresh ? (
            <img style={{ height: "22px" }} src={Refresh} alt="refresh" />
          ) : (
            <Sync className={defaultToolbarStyles.deleteIcon} />
          )}
        </IconButton>
      </Tooltip>

      <FilterDataDialog
        filter={filter}
        setFilter={setFilter}
        setUsers={setUsers}
        filterColumns={filterColumns}
        memberList={memberList}
        activeBot={activeBot}
        userData={userData}
        labels={labels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
      />
    </React.Fragment>
  );
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
