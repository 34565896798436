import React from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Tooltip,
  Switch,
  MenuItem,
} from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";
import DropDownTableControl from "./drop down controls/DropDownTableControl";

export default function DropDownControl({ formikProps, editState }) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Drop Down Features"
            subtitle="These are the features which can be useful and important to your
          component"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            select
            required
            formikProps={formikProps}
            name="sortingOrder"
            label="Sorting Order"
            disabled={!editState}
          >
            <MenuItem value="1">Ascending</MenuItem>
            <MenuItem value="2">Descending</MenuItem>
            <MenuItem value="3">None</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Tooltip title="Enable this to make drop down as multi select.">
                <Switch
                  checked={formikProps.values.multi_Select}
                  onChange={formikProps.handleChange}
                  disabled={!editState}
                  name="multi_Select"
                />
              </Tooltip>
            }
            label="Multi Select"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography style={{ fontSize: "13px", color: "#808080" }}>
            Provide an order in which you want to sort your dropdown list.
          </Typography>
        </Grid>
      </Paper>
      <DropDownTableControl formikProps={formikProps} editState={editState} />
    </React.Fragment>
  );
}
