import React from "react";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";

import { useAxios } from "../../../../hooks";
import CreateRegex from "./CreateRegex";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function RegexDialog({
  popUp,
  setPopUp,
  formikProps,
  component,
}) {
  const classes = useStyles();
  const axios = useAxios();

  var regexFields = {};

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <DialogTitle
          style={{ paddingBottom: "10px", borderBottom: "1px solid #cbcbcb" }}
        >
          Create Regex
        </DialogTitle>
        <Formik
          initialValues={regexFields}
          enableReinitialize
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "custom_fields/get_regex",
              method: "POST",
              data: formData,
            }).then((response) => {
              if (response.status) {
                formData.regex = response.data.regex;
                formData.DataType = "";
                setSubmitting(false);
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
              <DialogContent>
                <CreateRegex
                  formikProps={dialogFormikProps}
                  formProps={formikProps}
                  handleClose={handleClose}
                />
              </DialogContent>
              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>
                  {dialogFormikProps.values.DataType && (
                    <Grid item>
                      <Button
                        onClick={dialogFormikProps.handleReset}
                        variant="outlined"
                        color="primary"
                      >
                        Reset Form
                      </Button>
                    </Grid>
                  )}

                  {dialogFormikProps.values.regex ? (
                    <React.Fragment>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => createNewRegex(dialogFormikProps)}
                        >
                          Create New Regex
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUseRegex(dialogFormikProps)}
                        >
                          Use Regex
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item>
                      <Button
                        onClick={dialogFormikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={
                          dialogFormikProps.dirty
                            ? dialogFormikProps.isSubmitting ||
                              !dialogFormikProps.isValid
                            : !dialogFormikProps.dirty
                        }
                      >
                        Create Regex
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </DialogActions>
            </form>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );

  function handleUseRegex(dialogFormikProps) {
    if (component === "botBuilder") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        regex: getRegex(prevVal.regex),
      }));

      function getRegex(prevVal) {
        // var regex = [...prevVal];

        prevVal.push({
          regex: dialogFormikProps.values.regex,
        });

        return prevVal;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        regex: dialogFormikProps.values.regex,
      }));
    }

    handleClose();
  }

  function createNewRegex(dialogFormikProps) {
    dialogFormikProps.setValues(() => ({
      DataType: "",
    }));
  }
}
