import { Fragment } from "react";
import { Formik } from "formik";

import { validationSchema, objectFromFormData } from "./botDetailsObject";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";

export default function Form({
  editState,
  setEditState,
  formData,
  setFormData,
  pagePermissions,
  memberList,
  botID,
}) {
  const { axios, alert } = useComponent();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/livechat/config/" + botID,
          method: "POST",
          data: objectFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setFormData(formData);

            setEditState(false);

            alert.success(response.message.displayMessage);
          }
        });

        axios({
          url: "/livechat/member-config?bot_id=" + botID,
          method: "POST",
          data: formData.membersConfig,
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);

            setFormData(formData);

            setEditState(false);

            alert.success(response.message.displayMessage);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
            memberList={memberList}
          />

          <ButtonFooter
            ID={formikProps.values.botID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
