/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

//Misc Pages
import Page404 from "../../pages/misc/Page404";
import pages from "../../constants/pages";

//Test Page
import TestPage from "../../pages/test page/TestPage";

//Whatsapp Business Registration
import WhatsAppBusinessRegistration from "../../pages/whatsapp business registration/WhatsAppBusinessRegistration";
import WhatsAppBusinessRegistrationDetails from "../../pages/whatsapp business registration/WhatsAppBusinessRegistrationDetails";
import Story from "../../pages/bot builder components/story components/Story";
import OnboardingPage from "../../pages/onboarding component/OnboardingPage";

const emptyLayoutRoutes = {
  //Error 404
  error404Route: {
    ID: "error404",
    path: "*",
    element: Page404,
  },

  //Test page
  testPageRoute: {
    ID: "testPage",
    path: "test",
    element: TestPage,
  },

  //bot flow builder
  botFlowBuilderRoute: {
    ID: pages.botFlowBuilder.ID,
    path: pages.botFlowBuilder.route,
    element: Story,
  },

  //Whatsapp Business Registration
  whatsappBusinessRegistrationRoute: {
    ID: pages.whatsappBusinessRegistration.ID,
    path: pages.whatsappBusinessRegistration.route,
    element: WhatsAppBusinessRegistration,
  },
  whatsappBusinessRegistrationCreateRoute: {
    ID: pages.whatsappBusinessRegistrationCreate.ID,
    path: pages.whatsappBusinessRegistrationCreate.route,
    element: WhatsAppBusinessRegistrationDetails,
  },
  whatsappBusinessRegistrationDetailsRoute: {
    ID: pages.whatsappBusinessRegistrationDetails.ID,
    path: pages.whatsappBusinessRegistrationDetails.route + ":registrationID",
    element: WhatsAppBusinessRegistrationDetails,
  },
  onboardingPageRoute: {
    ID: pages.createOnboardLink.ID,
    path: pages.createOnboardLink.route,
    element: OnboardingPage,
  },
};

export default emptyLayoutRoutes;
