import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { get } from "lodash";
import { useParams } from "react-router-dom";

import { useComponent } from "../../../../hooks";
import { Paper, TextField, ComponentHeader } from "../../../../components";

export default function PersonalDetailsFormDisplay({ formikProps }) {
  const { registrationID } = useParams();
  const { alert, axios } = useComponent({ disableAuthToken: true });

  const [dialogState, setDialogState] = useState(false);
  const [OTP, setOTP] = useState("");

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Personal Details"
          subtitle="Please enter your personal details. You can continue anytime where you left off."
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="First Name"
          name="firstName"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Last Name"
          name="lastName"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Contact Email"
          name="contactEmail"
          formikProps={formikProps}
          disabled={!!registrationID}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Contact Phone Number"
          name="phoneNumber"
          onChange={handlePhoneNumberChange}
          formikProps={formikProps}
          InputProps={{
            endAdornment: !formikProps.errors.phoneNumber &&
              !formikProps.values.numberVerified && (
                <Button onClick={sendOTP}>Verify</Button>
              ),
          }}
          disabled={!!registrationID}
          helperText={
            get(formikProps.touched, "phoneNumber") &&
            (get(formikProps.errors, "phoneNumber") ||
              get(formikProps.errors, "numberVerified"))
          }
        />
      </Grid>

      <Dialog fullWidth open={dialogState} maxWidth="sm">
        <DialogTitle>Verify OTP</DialogTitle>

        <DialogContent dividers>
          <TextField
            required
            value={OTP}
            label="OTP"
            name="OTP"
            onChange={handleOTPChange}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={resendOTP}
            style={{ marginRight: "auto" }}
          >
            Resend OTP
          </Button>

          <Button onClick={handleDialogClose}>Cancel</Button>

          <Button variant="contained" onClick={verifyOTP} disabled={!OTP}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

  function handlePhoneNumberChange(event) {
    formikProps.setFieldValue("numberVerified", false);
    formikProps.handleChange(event);
  }

  function handleDialogClose() {
    setDialogState(false);
    setOTP("");
  }

  function handleOTPChange({ target }) {
    setOTP(target.value);
  }

  function sendOTP() {
    setDialogState(true);

    axios({
      url: "/business/send_otp",
      data: {
        mobile: formikProps.values.phoneNumber,
      },
      method: "POST",
    }).then((response) => {
      if (response.status) {
        alert.success("OTP Sent");
      }
    });
  }

  function resendOTP() {
    axios({
      url: "/business/resend_otp",
      data: {
        mobile: formikProps.values.phoneNumber,
      },
      method: "POST",
    }).then((response) => {
      if (response.status) {
        alert.success("OTP Sent");
      }
    });
  }

  function verifyOTP() {
    axios({
      url: "/business/verify_otp",
      data: {
        otp: OTP,
        mobile: formikProps.values.phoneNumber,
      },
      method: "POST",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        if (response.data.type === "success") {
          alert.success("OTP Verified.");

          formikProps.setFieldValue("numberVerified", true);

          handleDialogClose();
        }
      } else {
        alert.error("Invalid OTP, please try again.");
      }
    });
  }
}
