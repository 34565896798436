import * as Yup from "yup";

export const intentObject = {
  ID: "",
  intentName: "",
  regex: [],
  intentExample: [],
};

export function dataFromResponse(response) {
  return {
    ID: response._id,
    intentName: response.intent_name,
    regex: response.regex,
    intentExample: response.examples,
    isActive: response.is_active,
    createdBy: response.created_by,
    updatedBy: response.updated_by,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function dataFromFormData(formData) {
  return {
    ...Boolean(
      formData.id && {
        _id: formData.ID,
      }
    ),
    intent_name: formData.intentName,
    regex: formData.regex,
    examples: formData.intentExample,
    is_active: formData.isActive,
  };
}

export const validationSchema = Yup.object().shape({
  intentName: Yup.string()
    .required("This field is required.")
    .matches(
      /^[a-zA-Z0-9@_]+$/,
      "This field cannot contain white space and special character"
    ),
  isActive: Yup.string().required("This field is required"),
});
