import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../../../hooks";
import validationSchema from "../validationSchema";
import pages from "./../../../../../constants/pages";
import { ButtonFooter } from "../../../../../components";
import { alert } from "../../../../../redux/slices/alertSlice";
import { objectFromFormData } from "../broadcastDetailsObject";

export default function EmailDisplayForm({
  fields,
  channel,
  formData,
  activeBot,
  setFormData,
  groupList,
  scheduledTime,
  setScheduledTime,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (Boolean(formData.ID)) {
      setEditState(false);
    }

    axios({
      url: "/broadcast_email/template?agent_id=" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplates(response.data instanceof Array ? response.data : []);
      }
    });
  }, [formData.ID]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData, channel);

        axios({
          url: "/broadcast_email/create_broadcast?agent_id=" + activeBot.ID,
          method: "POST",
          data: data,
          disableRedirect: true,
        }).then((res) => {
          if (res.status) {
            setFormData(formData);
            setEditState(false);
            dispatch(
              alert({
                type: "success",
                message: res.message.displayMessage,
              })
            );

            navigate(pages.emailBroadcastList.route);
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            scheduledTime={scheduledTime}
            setScheduledTime={setScheduledTime}
            groupList={groupList}
            activeBot={activeBot}
            templates={templates}
            fields={fields}
            channel={channel}
          />

          <ButtonFooter
            ID={formikProps.values?.ID}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
