import React, { useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  Button,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import RegexTable from "../regex components/RegexTable";
import {
  Paper,
  TextField,
  ComponentHeader,
  CreatedAndUpdatedInfo,
} from "../../../../components";
import RegexDialog from "../../../custom fields/custom field details component/regex/RegexDialog";
import Insights from "./Insights";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  const [popUp, setPopUp] = useState(false);

  return (
    <Grid container spacing={2}>
      {formikProps.values.ID && <Insights formikProps={formikProps} />}

      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Intent Details"}
              subtitle={"Details of the intent."}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Intent Name"
              name="intentName"
              formikProps={formikProps}
              disabled={!editState}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <Tooltip title="This field cannot contain white spaces and special characters.">
                      <IconButton>
                        <Info style={{ color: "#808080" }} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Intent Status"
              name="isActive"
              select
              formikProps={formikProps}
              disabled={!editState}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>

          {formikProps.values.ID && (
            <CreatedAndUpdatedInfo formikProps={formikProps} />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              subtitle={"Add Regex to the intent."}
              buttons={[<AddRegex />]}
            />
          </Grid>

          <Grid item xs={12}>
            <RegexTable formikProps={formikProps} editState={editState} />
          </Grid>
        </Paper>
      </Grid>

      <RegexDialog
        popUp={popUp}
        setPopUp={setPopUp}
        formikProps={formikProps}
        component={"botBuilder"}
      />
    </Grid>
  );

  function AddRegex() {
    return (
      <Button
        disabled={!editState}
        variant="outlined"
        onClick={() => setPopUp(true)}
      >
        Add Regex
      </Button>
    );
  }
}
