/*
Contains all the routes pertaining to Auth Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

import pages from "../../constants/pages";

// Sign In Module
import SignIn from "../../pages/auth/SignIn";

//Member Registration
import MemberRegistration from "../../pages/member/MemberRegistration";

//Sign Up
import SignUp from "../../pages/auth/SignUp";

const authLayoutRoutes = {
  signInRoute: {
    ID: pages.signIn.ID,
    path: pages.signIn.route,
    element: SignIn,
  },

  signUpRoute: {
    ID: pages.signUp.ID,
    path: pages.signUp.route,
    element: SignUp,
  },

  //Accept Member Invite
  memberRegistration: {
    ID: pages.memberRegistration.ID,
    path: pages.memberRegistration.route + ":registrationID",
    element: MemberRegistration,
  },
};

export default authLayoutRoutes;
