import * as Yup from "yup";
import { sortBy } from "lodash";

export const permissionsGroupDetailsObject = {
  permissionsGroupID: "",
  groupName: "",
  memberCount: 0,
  activeStatus: true,
  botPermissions: [],
  createdBy: "",
  createdAt: "",
  updatedBy: "",
  updatedAt: "",
};

export function groupDetailsObjectFromFormData(formData) {
  return {
    name: formData.groupName,
    is_active: formData.activeStatus,
  };
}

export function permissionsObjectFromFormData(botPermissions) {
  return {
    agents_permission: exportBotPermissions(botPermissions),
  };
}

export function objectFromResponseData(responseData) {
  return {
    permissionsGroupID: responseData.member_group_id,
    groupName: responseData.group_name,
    memberCount: responseData.users.length || 0,
    activeStatus: responseData.is_active,
    botPermissions: importBotPermissions(responseData.permissions),
    createdBy: responseData.created_by,
    createdAt: responseData.created_at,
    updatedBy: responseData.updated_by,
    updatedAt: responseData.updated_at,
  };
}

export const validationSchema = Yup.object({
  groupName: Yup.string().required("Please enter a Group Name"),
  activeStatus: Yup.string().required("Please select Group Status"),
  botPermissions: Yup.array().min(
    1,
    "Please add permission for at least one permissions"
  ),
});

function importBotPermissions(botPermissions) {
  const botPermissionsArray = [];

  for (let botPermissionValue of botPermissions) {
    const pagePermissionsArray = [];

    for (let pagePermissionValue of botPermissionValue.pages) {
      pagePermissionsArray.push({
        ID: pagePermissionValue.page_id,
        read: pagePermissionValue.permissions.read,
        create: pagePermissionValue.permissions.create,
        update: pagePermissionValue.permissions.update,
        delete: pagePermissionValue.permissions.delete,
      });
    }

    botPermissionsArray.push({
      ID: botPermissionValue.agent_id,
      pagePermissions: sortBy(pagePermissionsArray, ["ID"]),
    });
  }

  return sortBy(botPermissionsArray, ["ID"]);
}

function exportBotPermissions(botPermissions) {
  const botPermissionsArray = [];

  for (let botPermissionValue of botPermissions) {
    const pagePermissionsArray = [];

    for (let pagePermissionValue of botPermissionValue.pagePermissions) {
      pagePermissionsArray.push({
        page_id: pagePermissionValue.ID,
        permissions: {
          access: true,
          read: pagePermissionValue.read,
          create: pagePermissionValue.create,
          update: pagePermissionValue.update,
          delete: pagePermissionValue.delete,
        },
      });
    }

    botPermissionsArray.push({
      is_active: true,
      agent_id: botPermissionValue.ID,
      pages: pagePermissionsArray,
    });
  }

  return botPermissionsArray;
}
