import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import SignInComponent from "./whatsapp business registration components/SignInComponent";

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridContainer = styled(Grid)`
  width: 60%;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  background: ${({ theme }) => {
    return theme.palette.background.paper;
  }};
`;

export default function WhatsAppBusinessRegistration() {
  return (
    <Wrapper>
      <Helmet title="Business Registration" />

      <GridContainer container>
        <GridItem item xs={6} style={{ background: "#f1f1f1" }}>
          <Typography variant="h1" align="center" color="#0171e2" gutterBottom>
            DolphinChat
          </Typography>

          <Typography variant="subtitle2" align="justify" gutterBottom>
            Grow your business with official WhatsApp business API
          </Typography>

          <Typography variant="subtitle2" align="justify" gutterBottom>
            Broadcast & Bulk Messages
          </Typography>

          <Typography variant="subtitle2" align="justify" gutterBottom>
            Green tick verification
          </Typography>
        </GridItem>

        <GridItem item xs={6}>
          <SignInComponent />
        </GridItem>
      </GridContainer>
    </Wrapper>
  );
}
