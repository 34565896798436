import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Table from "./broadcast list components/Table";
import { PageHeader, Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import pages from "../../constants/pages";

export default function BroadcastList({ pageID }) {
  const axios = useAxios();
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { loader } = useComponent({ pageID });

  const [broadcasts, setBroadcasts] = useState([]);
  const [cancelBroadcast, setCancelBroadcast] = useState("");

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/broadcast/get/campaign/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setBroadcasts(response.data);
        loader.apiComplete(1);
      } else {
        loader.apiComplete(1);
      }
    });
  }, [cancelBroadcast, axios, activeBot, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Broadcasts"
        addIcon={["Create Broadcast", pages.broadcastCreate.route, true]}
      />

      <Table broadcasts={broadcasts} setCancelBroadcast={setCancelBroadcast} />
    </Loader>
  );
}
