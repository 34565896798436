import React from "react";
import { Info } from "@mui/icons-material";
import { Grid, InputAdornment, Tooltip, IconButton } from "@mui/material";

import { Paper, ComponentHeader, TextField } from "./../../../../components";

export default function FormDisplay({ formikProps, addSample, editState }) {
  return (
    <React.Fragment>
      <Paper className="topElement">
        <Grid item container xs={12}>
          <ComponentHeader
            title="Template Details"
            subtitle="Email templates are pre-designed message formats businesses use for efficient, consistent communication. They streamline notifications, customer care, and marketing while saving time."
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="templateName"
            label="Email Template Name"
            required
            disabled={Boolean(formikProps.values.ID) && !editState}
            formikProps={formikProps}
            inputProps={{
              maxLength: 512,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formikProps.values?.templateName.length}/512
                  <IconButton>
                    <Tooltip title="When creating a message template, it is mandatory to give it a name. It is advised that the name reflect the template’s content. This name must only contain lowercase letters, numbers, and underscores.">
                      <Info />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* <Grid item xs={6}>
          <TextField
            name="emailSubject"
            label="Email Subject"
            required
            disabled={Boolean(formikProps.values.ID) && !editState}
            formikProps={formikProps}
            inputProps={{
              maxLength: 512,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formikProps.values?.templateName.length}/512
                  <IconButton>
                    <Tooltip title="When creating a message template, it is mandatory to give it a name. It is advised that the name reflect the template’s content. This name must only contain lowercase letters, numbers, and underscores.">
                      <Info />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
      </Paper>
    </React.Fragment>
  );
}
