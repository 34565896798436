import React from "react";
import { useDispatch } from "react-redux";

import DataTable from "../../../../components/DataTable";
import { useAxios } from "../../../../hooks";
import { alert } from "../../../../redux/slices/alertSlice";

export default function UserListTable({
  userList,
  setListUsers,
  groupID,
  setAddUserPopUp,
  setUserList,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  function handleRowClick(_, rowMetaData) {
    setAddUserPopUp({
      state: true,
      groupUserID: userList[rowMetaData.dataIndex].group_user_id,
    });
  }

  const options = {
    viewColumns: false,
    selectableRows: "multiple",
    rowsPerPageOptions: [10, 20, 25, 50, 100, 150, 200, 250, 500],
    download: false,
    print: false,
    pagination: true,

    onSearchChange(searchText) {
      if (searchText) {
        axios({
          url: "/broadcast/get/group/user/" + groupID + "?search=" + searchText,
          method: "GET",
        }).then((response) => {
          if (response.status) {
            setUserList(response.data);
          }
        });
      } else {
        axios({
          url:
            "/broadcast/get/group/user/" +
            groupID +
            "?start=" +
            0 +
            "&length=" +
            20,
          method: "GET",
        }).then((response) => {
          if (response.status) {
            setUserList(response.data);
          }
        });
      }
    },

    onChangePage(currentPage) {
      var start = currentPage * 15;
      var end = currentPage * 20;
      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?start=" +
          start +
          "&length=" +
          end,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
        }
      });
    },

    onChangeRowsPerPage(numberOfRows) {
      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?start=" +
          0 +
          "&length=" +
          numberOfRows,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
        }
      });
    },

    onRowClick: handleRowClick,

    onRowsDelete: (rowsDeleted) => {
      var listOfUserIds = [];
      rowsDeleted.data.map((item) =>
        listOfUserIds.push(userList[item.dataIndex].group_user_id)
      );
      if (
        listOfUserIds.length !== 0 &&
        !window.confirm("Are you sure you want to delete these group users?")
      ) {
        return;
      } else {
        if (listOfUserIds.length !== 0) {
          axios({
            url: "/broadcast/delete/group/mapped/users",
            method: "DELETE",
            data: {
              group_user_ids: listOfUserIds,
            },
          }).then((res) => {
            if (res.status) {
              dispatch(
                alert({
                  type: "success",
                  message: res.message.displayMessage,
                })
              );
              setListUsers(listOfUserIds);
            }
          });
        }
      }
    },
  };

  const columns = [
    {
      name: "mobile_number",
      label: "Phone Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={userList} />;
}
