import * as Yup from "yup";

export const memberRegistrationObject = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  password: "",
  confirmPassword: "",
};

export function objectFromFormData(formData) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: formData.email,
    gender: formData.gender,
    password: formData.password,
  };
}

export function objectFromResponseData(responseData) {
  return {
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    businessName: responseData.business_name,
    email: responseData.email,
    gender: responseData.gender,
    password: "",
  };
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  gender: Yup.string().required("Please select a gender"),
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#@$!%*?&])[A-Za-z0-9#@$!%*?&]{8,}$/,
      "Password should have a small letter, capital letter, number and a special character"
    ),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref("password")], "Both password need to be the same")
      .required("Please enter confirm password"),
  }),
});
