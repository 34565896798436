import { Fragment } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { validationSchema, objectFromFormData } from "./webhookDetailsObject";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";

export default function Form({
  botID,
  editState,
  setEditState,
  formData,
  setFormData,
  pagePermissions,
}) {
  const { axios, alert } = useComponent();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let method = "POST";
        let URL = "/webhook/" + botID;

        if (!!formData.webhookID) {
          method = "PUT";
          URL = URL + "/" + formData.webhookID;
        }

        axios({
          url: URL,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          setSubmitting(false);

          if (response.status) {
            alert.success(response.message.displayMessage);
            setEditState(false);

            if (!!formData.webhookID) {
              setFormData(formData);
            } else {
              navigate(pages.webhookDetails.route + response.data.webhook_id);
            }
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
          />

          <ButtonFooter
            ID={formikProps.values.webhookID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              () => handleWebhookDelete(formikProps.values.webhookID),
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );

  function handleWebhookDelete(webhookID) {
    axios({
      url: "/webhook/" + webhookID,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.data.message);
      }
    });
  }
}
