import { Grid } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function CorporateAddressForm({ editState, formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Corporate  Address"
          subtitle="You can view and edit your details here!"
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="Corporate Address"
          name="corporateAddress"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="City"
          name="corporateCity"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="State"
          name="corporateState"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="Country"
          name="corporateCountry"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
