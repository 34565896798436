import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FileCopy, Cancel, Sync, Summarize } from "@mui/icons-material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";

import Stats from "./Stats";
import { useAxios } from "./../../../../hooks";
import { convertDate } from "./../../../../utils";
import { alert } from "./../../../../redux/slices/alertSlice";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "14px";
  font-weight: "500";
`;

const ColoredTypography = styled(FontSizeTypography)`
  color: "#9e9e9e !important";
`;

export default function BroadcastReport({
  broadcastReport,
  popup,
  setPopup,
  setLoadReport,
}) {
  const classes = useStyles();

  const axios = useAxios();
  const dispatch = useDispatch();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(
      alert({
        type: "success",
        message: "Text copied to clipboard successfully!",
      })
    );
  };

  const downloadFile = () => {
    axios({
      url:
        "/push_notification/report?agent_id=" +
        activeBot.ID +
        "&broadcast_id=" +
        broadcastReport._id,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        window.location.assign(res.data.report_url);
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={popup}
      onClose={handleClose}
      fullWidth
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">
            Broadcast Report
            <Tooltip title="Refresh Report">
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                style={{ padding: "0" }}
                onClick={() => setLoadReport((prev) => prev + 1)}
              >
                <Sync />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} />

          <Grid item xs={3}>
            <FontSizeTypography>Broadcast ID</FontSizeTypography>
          </Grid>

          <Grid item xs={2}>
            <Typography>
              {broadcastReport?._id?.slice(0, 15) +
                (broadcastReport?._id?.length > 15 ? "..." : "")}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport._id)}
            >
              <Tooltip title="Copy broadcast ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Template ID</FontSizeTypography>
          </Grid>

          <Grid item xs={2}>
            <ColoredTypography>
              {broadcastReport?.template_id?.slice(0, 15) +
                (broadcastReport?.template_id?.length > 15 ? "..." : "")}
            </ColoredTypography>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport.template_id)}
            >
              <Tooltip title="Copy template ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Broadcast Name</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport?.broadcast_name}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created At</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {convertDate(broadcastReport?.created_at)}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Scheduled At</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport?.schedule_date}
              &nbsp;|&nbsp;
              {broadcastReport?.schedule_time}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Status</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>{broadcastReport?.status}</ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created By</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>{broadcastReport?.created_by}</ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Updated By</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>{broadcastReport?.updated_by}</ColoredTypography>
          </Grid>

          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadFile}
            startIcon={<Summarize />}
          >
            Get Report
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setPopup(false);
  }
}
