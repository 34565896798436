import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { signInDetailsObject, validationSchema } from "./signInDetailsObject";
import { useAxios, useAlert } from "../../../hooks";

import { signInMember } from "../../../redux/slices/memberSlice";

export default function Form() {
  const axios = useAxios({ disableAuthToken: true });
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state } = useLocation();

  return (
    <Formik
      enableReinitialize
      initialValues={signInDetailsObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/login",
          method: "POST",
          data: {
            email: formData.email,
            password: formData.password,
          },
          disableRedirect: true,
        })
          .then(async (res) => {
            if (res.status) {
              state?.from.includes("/chats/") &&
                localStorage.setItem(
                  "activeBot",
                  state?.from.replace(`/chats/`, "").split("*")[0]
                );

              await dispatch(
                signInMember({
                  authToken: res.data.auth_token,
                  rememberMe: state?.from.includes("/chats/")
                    ? true
                    : formData.rememberMe,
                })
              );

              dispatch(alert({ message: "Welcome!", type: "success" }));

              if (state?.from.includes("/chats/")) {
                navigate(state?.from);
              } else {
                navigate(search === "?redirect=back" ? "/" : "/");
              }

              setSubmitting(false);
            } else {
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
