import React, { useState } from "react";
import { Info, DeleteOutlined, Check } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Switch,
  MenuItem,
  Button,
} from "@mui/material";

import { TextField, Paper, ComponentHeader } from "../../../../components";

export default function Footer({ formikProps, editState }) {
  const [options, setOptions] = useState(true);

  const [optionalMenuFields] = useState({
    text: "Visit Website",
    type: "url",
    url: "www.dolphinchat.ai",
  });

  const inputList = {
    text: "",
    type: "",
    payload: "",
    url: "",
  };

  const handleChangeMenu = (event, index) => {
    const { name, value } = event.target;
    const nameText = name.split(".").pop();
    let newArr = formikProps.values.menu.map((item, i) => {
      if (index === i) {
        if (name === "type") {
          if (value === "url") delete item.payload;
          if (value === "postback") delete item.url;
        }
        return { ...item, [nameText]: value };
      } else {
        return item;
      }
    });

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: newArr,
    }));
  };

  const handleAddField = () => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: [...formikProps.values.menu, inputList],
    }));
  };

  function handleSubmit() {
    setOptions(false);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: [...formikProps.values.menu, optionalMenuFields],
    }));
  }

  const removeField = (index) => {
    const newMenuValues = [...formikProps.values.menu];
    newMenuValues.splice(index, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: newMenuValues,
    }));
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Footer Settings"}
          subtitle="Add menus, restart, home buttons to the bot footer."
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          White Label &nbsp;
          <Tooltip title="WWhen enabled, Powered by Dolphinchat will be displayed to the users. To disable this contact support@dolphinchat.ai">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.whiteLabel}
          name="whiteLabel"
          onChange={formikProps.handleChange}
          disabled
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Emoji &nbsp;
          <Tooltip title="Enable this to allow users to share emoji’s along with text.">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.emoji}
          name="emoji"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Audio &nbsp;
          <Tooltip title="This option enable notification sound for new messages when bot is minimised or closed.">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.audio}
          name="audio"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{
            fontsize: "0.9rem",
          }}
          variant="h5"
        >
          Attachments
        </Typography>

        <Typography variant="body2" style={{ color: "#808080" }}>
          You can enable the attachments in the attachments section given in the
          footer.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Image &nbsp;
          <Tooltip title="Enable this to allow users to share Image from chatbot">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.attachmentImage}
          name="attachmentImage"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Video &nbsp;
          <Tooltip title="Enable this to allow users to share audio from chatbot">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.attachmentVideo}
          onChange={formikProps.handleChange}
          name="attachmentVideo"
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Documents &nbsp;
          <Tooltip title="Enable this to allow users to share video from chatbot">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.attachmentDocuments}
          onChange={formikProps.handleChange}
          name="attachmentDocuments"
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Location &nbsp;
          <Tooltip title="Enable this to allow users to share location from chatbot">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.attachmentLocation}
          name="attachmentLocation"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontSize: "0.9rem" }} variant="h5">
          Menu
        </Typography>
        <Typography
          style={{
            color: "#808080",
          }}
          variant="body2"
        >
          Allow users to select from a list of options for example Restart,
          Visit Website, Know more etc
        </Typography>
        <Typography
          style={{ padding: "0px 10px 10px 0", color: "#808080" }}
          variant="body2"
        >
          There are two menu options POSTBACK which will perform some action on
          the bot itself and the URL which will redirect you to the url you had
          provided. Click on the in bot footer to view the option.
        </Typography>
      </Grid>

      {formikProps.values.menu.length === 0 && options && (
        <React.Fragment key={0}>
          <Grid item xs={3}>
            <TextField
              name="text"
              label="Text"
              case="none"
              value={optionalMenuFields.text}
              disabled={!editState}
              formikProps={formikProps}
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="type"
              label="Type"
              case="none"
              value={"URL"}
              formikProps={formikProps}
              disabled={!editState}
            ></TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              type="text"
              name="url"
              label="URL"
              case="none"
              value={optionalMenuFields.url}
              disabled={!editState}
              formikProps={formikProps}
              inputProps={{
                maxLength: 40,
              }}
            />
          </Grid>

          {editState && (
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              item
              alignSelf="center"
              xs={1}
            >
              <Tooltip title="Delete Menu">
                <IconButton
                  disableRipple
                  style={{ marginRight: "3px", padding: "0px" }}
                  onClick={() => setOptions(false)}
                >
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#ff0000" }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Menu">
                <IconButton
                  disableRipple
                  style={{ padding: "0px" }}
                  onClick={() => handleSubmit()}
                >
                  <Check style={{ fontSize: "20px" }} color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </React.Fragment>
      )}

      {formikProps.values.menu.map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={3}>
            <TextField
              name={"menu[" + index + "].text"}
              label="Text"
              value={formikProps.values.menu[index].text}
              required
              fullWidth
              error={Boolean(
                formikProps.touched.menu?.[index]?.text &&
                  formikProps.errors.menu?.[index]?.text
              )}
              helperText={
                formikProps.touched.menu?.[index]?.text &&
                formikProps.errors.menu?.[index]?.text
              }
              onBlur={formikProps.handleBlur}
              onChange={(event, name) =>
                handleChangeMenu(event, index, event.target.name)
              }
              disabled={!editState}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              name={"menu[" + index + "].type"}
              label="Type"
              fullWidth
              case="none"
              onChange={(event, name) =>
                handleChangeMenu(event, index, event.target.name)
              }
              value={formikProps.values.menu[index].type}
              onBlur={formikProps.handleBlur}
              disabled={!editState}
            >
              <MenuItem disabled value={"postback"}>
                Postback
              </MenuItem>
              <MenuItem value={"url"}>URL</MenuItem>
            </TextField>
          </Grid>

          {item.type === "postback" ? (
            <Grid item xs={4}>
              <TextField
                name={"menu[" + index + "].payload"}
                label="Payload"
                value={formikProps.values.menu[index].payload}
                required
                fullWidth
                error={Boolean(
                  formikProps.touched.menu?.[index]?.payload &&
                    formikProps.errors.menu?.[index]?.payload
                )}
                helperText={
                  formikProps.touched.menu?.[index]?.payload &&
                  formikProps.errors.menu?.[index]?.payload
                }
                onBlur={formikProps.handleBlur}
                onChange={(event, name) =>
                  handleChangeMenu(event, index, event.target.name)
                }
                disabled={!editState}
              />
            </Grid>
          ) : item.type === "url" ? (
            <Grid item xs={4}>
              <TextField
                name={"menu[" + index + "].url"}
                label="URL"
                value={formikProps.values.menu[index].url}
                required
                fullWidth
                error={Boolean(
                  formikProps.touched.menu?.[index]?.url &&
                    formikProps.errors.menu?.[index]?.url
                )}
                helperText={
                  formikProps.touched.menu?.[index]?.url &&
                  formikProps.errors.menu?.[index]?.url
                }
                onBlur={formikProps.handleBlur}
                onChange={(event, name) =>
                  handleChangeMenu(event, index, event.target.name)
                }
                disabled={!editState}
              />
            </Grid>
          ) : (
            <Grid item xs={4}></Grid>
          )}

          {editState && (
            <Grid style={{ textAlign: "end", alignSelf: "center" }} item xs={1}>
              <Tooltip title="Delete Menu">
                <IconButton onClick={() => removeField(index)}>
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#ff0000" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </React.Fragment>
      ))}

      {editState && (
        <React.Fragment>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={Boolean(formikProps.errors?.menu)}
              onClick={handleAddField}
            >
              Add Item
            </Button>
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );
}
