import React from "react";
import { get } from "lodash";
import {
  MenuItem,
  Checkbox,
  ListItemText,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { TextField } from "../../../../components";

export default function DropDownComponent({
  formikProps,
  fieldDetails,
  disabled,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [fieldDetails.name]: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  };

  function renderingValue(value) {
    var arr = [];

    if (value.length > 0) {
      value.map((item) =>
        arr.push(fieldDetails.options.find((x) => x.id === item).label)
      );
    }

    return arr;
  }

  if (!!fieldDetails) {
    if (Boolean(fieldDetails?.multi_Select)) {
      return (
        <TextField
          select
          required={fieldDetails.mandatory}
          name={fieldDetails.name}
          label={fieldDetails.label}
          disabled={disabled}
          formikProps={formikProps}
          SelectProps={{
            renderValue: (selected) => renderingValue(selected).join(","),
            multiple: true,
            value: get(formikProps.values, fieldDetails.name, []),
            onChange: handleChange,
          }}
        >
          {fieldDetails.options?.map(
            (item, _) =>
              item.is_active && (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={
                      get(formikProps.values, fieldDetails.name, "").indexOf(
                        item.id
                      ) > -1
                    }
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              )
          )}
        </TextField>
      );
    } else {
      return (
        <TextField
          select
          required={fieldDetails.mandatory}
          name={fieldDetails.name}
          label={fieldDetails?.label}
          disabled={disabled}
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!!fieldDetails.tooltip && (
                  <Tooltip
                    style={{ marginRight: "22px" }}
                    title={fieldDetails.tooltip}
                  >
                    <Info size="small" />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        >
          {fieldDetails.options?.map(
            (item, index) =>
              item.is_active && (
                <MenuItem value={item.id} key={item.id}>
                  {item.label}
                </MenuItem>
              )
          )}
        </TextField>
      );
    }
  }
}
