import styled from "styled-components/macro";
import { Typography as MuiTypography } from "@mui/material";
import React from "react";

const Wrapper = styled.div`
  flex: 1;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
`;

const Typography = styled(MuiTypography)`
  flex: 1;
  padding: 20px;
  background: #00000080;
  color: white;
  line-height: 1.5;
  font-size: 3.5rem;
  display: flex;
  align-items: center;
`;

export default function Slides({ slideNo }) {
  const slideQuotes = [
    "Educate and qualify your leads with automated FAQ’s!",
    "Optimize your sales funnel with CRM integration!",
    "Precise and crisp analytics dashboard to get the most out of your chatbot",
    "Integrate your human team into your chatbot lead generation strategy",
    "Monitor real-time live agent interactions with easy takeover functionality.",
    "A Lead is 14 times more likely to provide data to a Conversational Platform than Traditional forms.",
  ];

  return (
    <Wrapper src={require(`../../../assets/auth carousel pics/${slideNo}.jpg`)}>
      <Typography variant="h1" align="center">
        {slideQuotes[slideNo]}
      </Typography>
    </Wrapper>
  );
}
