import { Fragment } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import { useAxios, useComponent } from "../../../../hooks";
import pages from "../../../../constants/pages";
import { dataFromFormData, validationSchema } from "./entityObjectDetails";
import { ButtonFooter } from "../../../../components";

export default function Form({
  formData,
  setEditState,
  editState,
  setFormData,
  pagePermissions,
}) {
  const navigate = useNavigate();
  const { alert } = useComponent();
  const axios = useAxios({ baseURL: "botBuilder" });
  const { activeBot } = useSelector((state) => state.memberDetails);

  function handleDelete() {
    axios({
      url: "/delete-entity/" + activeBot.ID + "/" + formData.entityName,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message);
      }
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/create-entity/" + activeBot.ID;
        if (!!formData.ID) {
          url = "/update-entity/" + activeBot.ID;
        }

        axios({
          url: url,
          method: "POST",
          data: dataFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);

            if (formData.ID) {
              navigate(
                pages.botEntityDetails.route + response.data.entity_name
              );
            }
            setEditState(false);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            formikProps={formikProps}
            setEditState={setEditState}
            editState={editState}
          />
          <ButtonFooter
            ID={formData.ID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              handleDelete,
              "Delete Entity",
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );
}
