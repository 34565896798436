import { convertDate } from "./../../../../utils";

export const templateDetailsObject = {
  ID: "",
  agentID: "",
  templateName: "",
  emailTitle: "",
  templateBody: {
    example: [],
  },
  createdAt: "",
  emailTitleVariableCount: 0,
};

export function objectFromResponse(responseData) {
  let bodyExamples = [];

  if (responseData?.template_body?.example?.length > 0) {
    for (let example of responseData.template_body.example) {
      bodyExamples.push({
        example: example,
      });
    }
  }

  return {
    ID: responseData._id || "",
    templateName: responseData.template_name || "",
    emailTitle: responseData.title || "",
    templateBody:
      {
        text: responseData.template_body.replaceAll("<br />", "\n"),
        count_of_variable: responseData.body_variable_count,
        example: bodyExamples,
      } || {},
    emailTitleVariableCount: responseData.title_variable_count,
    createdAt: convertDate(responseData.created_at) || "",
    emailTitleVariableCount: responseData.title_variable_count || 0,
  };
}

export function objectFromFormData(formData) {
  let bodyExamples = [];

  if (formData.templateBody.example?.length > 0) {
    for (let example of formData.templateBody.example) {
      bodyExamples.push(example.example);
    }
  }

  let templateBody = formData.templateBody.text.replaceAll("\n", "<br />");

  return {
    template_name: formData.templateName,
    template_body: templateBody,
    title: formData.emailTitle,
    title_variable_count: formData.emailTitleVariableCount,
    body_variable_count: formData.templateBody.count_of_variable,
  };
}
