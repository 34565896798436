import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";

import { Chip } from "../../../../../../components";

export default function FilterDialog({
  filterDialogState,
  setFilterDialogState,
  searchQuery,
  setSearchQuery,
  memberList,
  labels,
}) {
  const [filterParams, setFilterParams] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    label: "",
    assignedTo: "",
    conversation: "",
    unReadConversation: "",
    assign: "",
  });

  useEffect(() => {
    setFilterParams({
      name: searchQuery.name,
      email: searchQuery.email,
      phoneNumber: searchQuery.phoneNumber,
      label: searchQuery.label,
      assignedTo: searchQuery.assignedTo,
      conversation: searchQuery.conversation,
      unReadConversation: searchQuery.unReadConversation,
      assign: searchQuery.assign,
    });
  }, [
    searchQuery.email,
    searchQuery.name,
    searchQuery.phoneNumber,
    searchQuery.label,
    searchQuery.assignedTo,
    searchQuery.conversation,
    searchQuery.unReadConversation,
    searchQuery.assign,
  ]);

  return (
    <Dialog fullWidth open={filterDialogState} onClose={handleClose}>
      <DialogTitle>Filter Users</DialogTitle>

      <DialogContent dividers style={{ padding: "20px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Name"
              value={filterParams.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              label="Email"
              value={filterParams.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              value={filterParams.phoneNumber}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="label"
              label="Label"
              value={filterParams.label}
              onChange={handleChange}
              disabled={labels.length < 0}
            >
              {labels.map((item) => (
                <MenuItem value={item._id} key={item._id}>
                  <Chip label={item.title} background={item.color} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="assignedTo"
              label="Assign To"
              value={filterParams.assignedTo}
              onChange={handleChange}
              disabled={memberList.length < 0}
            >
              {memberList.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="conversation"
              label="Conversation"
              value={filterParams.conversation}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="unReadConversation"
              label="Conversation Status"
              value={filterParams.unReadConversation}
              onChange={handleChange}
            >
              <MenuItem value="True">Read Conversation</MenuItem>
              <MenuItem value="False">Unread Conversation</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="assign"
              label="Assign"
              value={filterParams.assign}
              onChange={handleChange}
            >
              <MenuItem value="1">Assign To Anyone</MenuItem>
              <MenuItem value="0">No Assign To Anyone</MenuItem>
            </TextField>
          </Grid>

          <Grid item onClick={handleClose}>
            <Button variant="outlined">Cancel</Button>
          </Grid>

          <Grid item xs />

          <Grid item onClick={clearFilters}>
            <Button variant="contained">Clear Filters</Button>
          </Grid>

          <Grid item onClick={filterUsers}>
            <Button variant="contained">Filter Users</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  function handleChange({ target }) {
    const { name, value } = target;

    setFilterParams((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function clearFilters() {
    setSearchQuery({
      search: "",
      name: "",
      email: "",
      phoneNumber: "",
      assignedTo: "",
      label: "",
      conversation: "",
    });
    setFilterParams({
      name: searchQuery.name,
      email: searchQuery.email,
      phoneNumber: searchQuery.phoneNumber,
      label: searchQuery.label,
      assignedTo: searchQuery.assignedTo,
      conversation: searchQuery.conversation,
    });
  }

  function filterUsers() {
    setSearchQuery((prevValue) => ({
      ...prevValue,
      ...filterParams,
    }));

    handleClose();
  }

  function handleClose() {
    setFilterDialogState(false);
  }
}
