import { titleCase } from "change-case-all";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function WebhooksTable({ webhooks }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(pages.webhookDetails.route + webhooks[dataIndex]._id),
  };

  return <DataTable columns={columns} data={webhooks} options={options} />;
}

const columns = [
  {
    name: "title",
    label: "Webhook Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "event_name",
    label: "Event Name",
    options: {
      customBodyRender: (value) => {
        return value ? titleCase(value) : "Not Available";
      },
    },
  },
  {
    name: "event_topic",
    label: "Event Topic",
    options: {
      customBodyRender: (value) => {
        return value ? titleCase(value) : "Not Available";
      },
    },
  },
  {
    name: "method",
    label: "Method",
    options: {
      customBodyRender: (value) => {
        return value ? titleCase(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
