import { titleCase } from "change-case-all";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function BotsTable({ bots }) {
  const memberDetails = useSelector((state) => state.memberDetails);

  const navigate = useNavigate();

  const options = {
    onRowClick: (rowData, { dataIndex }) =>
      memberDetails.activeBot.channel === "whatsapp" &&
      rowData[1] === "Whatsapp"
        ? navigate(pages.whatsappNumberDetail.route)
        : navigate(pages.botDetails.route + bots[dataIndex].agent_id),
  };

  return <DataTable columns={columns} data={bots} options={options} />;
}

const columns = [
  {
    name: "agent_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "channel_name",
    label: "Channel",
    options: {
      customBodyRender: (value) => {
        return value ? titleCase(value) : "Not Available";
      },
    },
  },
  {
    name: "no_of_message",
    label: "Message Count",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "no_of_user",
    label: "User Count",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "no_of_integration",
    label: "Integration Count",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "plan_name",
    label: "Plan Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
