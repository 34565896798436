import { Formik } from "formik";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import SubmitButton from "./SubmitButtons";
import CreateButton from "./CreateButtons";
import validationSchema from "./validationSchema";
import EmailPreview from "./EmailTemplatePreview";
import pages from "./../../../../../constants/pages";
import { useAxios, useComponent } from "../../../../../hooks";
import { objectFromFormData } from "./emailTemplateDetailsObject";
import TemplateBody from "./email template body components/TemplateBody";
import TemplateSubject from "./email template subject components/TemplateSubject";
import TemplateAttachment from "./email template attachment components/TemplateAttachment";
import { Paper } from "../../../../../components";

export default function Form({
  formData,
  templateData,
  agentID,
  language,
  category,
  botDetails,
  html,
  setHtml,
  pagePermissions,
}) {
  const axios = useAxios();
  const { alert } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);
  const navigation = useNavigate();

  const [addSample, setAddSample] = useState(false);
  const [editState, setEditState] = useState(false);

  const iframeRef = useRef(null);
  const [flag, setFlag] = useState(false);

  const sendMessageToIframe = () => {
    document.querySelector("iframe").onload = function () {
      if (iframeRef?.current) {
        let data = {
          json: html.json,
          variableCount: html.variableCount,
        };
        iframeRef.current.contentWindow.postMessage(
          data,
          "https://email-builder.dolphinchat.ai/"
        );

        setFlag(true);
      }
    };
  };

  useEffect(() => {
    const iframeLoadHandler = () => {
      // Add the message event listener only after the iframe has loaded
      window.addEventListener("message", handleMessage, { once: true });
    };

    const handleMessage = (event) => {
      setHtml(event?.data);
    };

    // Add onload handler for iframe and listen to iframe load event only once
    if (iframeRef?.current) {
      iframeRef.current.onload = iframeLoadHandler;
    }

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeRef]);

  useEffect(() => {
    if (html.json) {
      sendMessageToIframe();
    }
  }, [html]);

  useEffect(() => {
    if (flag) {
      const handleMessage2 = (event) => {
        if (!Object.keys(event?.data).includes("payload")) {
          setHtml(event?.data);
        }
      };
      window.addEventListener("message", handleMessage2);
    }
  });

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData, html);
        axios({
          url:
            "/broadcast_email/template/" +
            (formData.ID ? formData.ID : "") +
            "?agent_id=" +
            activeBot.ID,
          method: !formData.ID ? "POST" : "PATCH",
          disableRedirect: true,
          data: data,
        }).then((response) => {
          if (response.status) {
            alert.success(response.message.displayMessage);

            navigation(pages.emailTemplateList.route);
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormDisplay
                formikProps={formikProps}
                templateData={templateData}
                agentID={agentID}
                language={language}
                category={category}
                addSample={addSample}
                botDetails={botDetails}
                pagePermissions={pagePermissions}
                editState={editState}
              />

              <TemplateSubject
                formikProps={formikProps}
                addSample={addSample}
                editState={editState}
              />

              <Paper>
                <Grid
                  style={{ alignSelf: "center", position: "relative" }}
                  item
                  xs={12}
                >
                  <iframe
                    ref={iframeRef}
                    src="https://email-builder.dolphinchat.ai/"
                    style={{
                      width: "100%",
                      height: "700px",
                      border: "none",
                      pointerEvents:
                        Boolean(formikProps.values.ID) && !editState
                          ? "none"
                          : "auto",
                    }}
                  ></iframe>
                  <div
                    style={{
                      position: "absolute",
                      background: "#5a5a5a24",
                      width: "100%",
                      height: "100%",
                      top: "10px",
                      left: "10px",
                      display:
                        !(Boolean(formikProps.values.ID) && !editState) &&
                        "none",
                    }}
                  ></div>
                </Grid>
              </Paper>

              <TemplateAttachment
                formikProps={formikProps}
                editState={editState}
              />

              {!formikProps.values.ID && (
                <SubmitButton
                  formikProps={formikProps}
                  addSample={addSample}
                  setAddSample={setAddSample}
                  html={html}
                />
              )}

              {!!formikProps.values.ID && (
                <CreateButton
                  formikProps={formikProps}
                  editState={editState}
                  setEditState={setEditState}
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}
