import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { useAxios, useComponent } from "./../../hooks";

import Table from "./report list components/Table";
import { Paper } from "./../../components";
import GenerateReportDialog from "./generate chat report dialog/GenerateReportDialog";

export default function ChatReportList({ pageID }) {
  const axios = useAxios();

  const { loader } = useComponent({ pageID });
  const [chatReport, setChatReport] = useState([]);
  const { ID, activeBot } = useSelector((state) => state.memberDetails);

  const [generateReportDialog, setGenerateReportDialog] = useState(false);
  const [members, setMembers] = useState([]);

  const generateList = () => {
    axios({
      url: `/insights/list/${ID}`,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        if (Array.isArray(response.data)) {
          setChatReport(response.data);
        }
      }
      loader.apiComplete();
    });
  };

  useEffect(() => {
    loader.start();
    generateList();

    axios({
      url: `/member/list?bot_id=${activeBot.ID}`,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setMembers(response.data);
      }
      loader.apiComplete();
    });

    loader.apiComplete();
  }, [axios, ID]);

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12} display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setGenerateReportDialog(true)}
          >
            Generate Chat Report
          </Button>
        </Grid>
      </Paper>

      <Table chatReport={chatReport} setChatReport={setChatReport} />

      {generateReportDialog && (
        <GenerateReportDialog
          generateReportDialog={generateReportDialog}
          setGenerateReportDialog={setGenerateReportDialog}
          members={members}
          loader={loader}
          generateList={generateList}
        />
      )}
    </Fragment>
  );
}
