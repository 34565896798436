import React from "react";
import { BsDot } from "react-icons/bs";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
    backgroundColor: "rgb(203 203 203 / 34%)",
    padding: "5px 5px 5px 10px",
    marginBottom: "4px",
  },
}));

export default function Instructions() {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className={classes.divStyle}>
        <BsDot fontSize="large" style={{ marginRight: "10px" }} />

        <Typography variant="body1">
          You need to select the template to choose group or for mapping
          variables.
        </Typography>
      </div>

      <div className={classes.divStyle}>
        <BsDot fontSize="large" style={{ marginRight: "10px" }} />

        <Typography variant="body1">
          The mapping variable fundamental has been provided to generate the
          file as per the data you want in the file.
        </Typography>
      </div>

      <div className={classes.divStyle}>
        <BsDot fontSize="large" style={{ marginRight: "10px" }} />

        <Typography variant="body1">
          You can also download the blank file with only headers by clicking on
          Create File.
        </Typography>
      </div>

      <div className={classes.divStyle}>
        <BsDot fontSize="large" style={{ marginRight: "10px" }} />

        <Typography variant="body1">
          Fallback value is been asked as a default value which will be mapped
          for the users if doesn't found mapped pre-defined value for them , so
          provide logical value.
        </Typography>
      </div>

      <div className={classes.divStyle}>
        <BsDot fontSize="large" style={{ marginRight: "10px" }} />

        <Typography variant="body1">
          System variables are the values which exist in the system e.g. if you
          select name then the generated file will have names of the users as
          per your filter selection where as custom values are the value you
          want to provide.
        </Typography>
      </div>
    </Grid>
  );
}
