import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  templateName: Yup.string()
    .max(100)
    .matches(
      /^[a-z0-9_]+$/,
      "It sould only contain letters, numbers and underscores"
    )
    .required("Enter template name."),

  templateBody: Yup.object().shape({
    text: Yup.string().required("Body is required"),
    count_of_variable: Yup.number(),
    example: Yup.array(
      Yup.object().shape({
        example: Yup.string().when("count_of_variable", {
          is: (number) => number > 0,
          then: Yup.string().required("Fill sample values."),
        }),
      })
    ),
  }),
});

export default validationSchema;
