import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import { DeleteDialog, Paper } from "../../../components";
import { LoadingButton } from "@mui/lab";

import { objectFromFormData } from "./broadcastDetailsObject";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";
import pages from "../../../constants/pages";

export default function Buttons({ setFormData, formikProps, activeBot }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  const [editState, setEditState] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    axios({
      url: "/broadcast/delete/campaign/" + formikProps.values.ID,
      method: "DELETE",
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res?.displayMessage,
          })
        );
        navigate(pages.broadcastList.route);
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.errorMessage,
          })
        );
      }
    });
  };

  const handleCancel = (formikProps) => {
    if (
      !window.confirm(
        "Selecting OK, will reset the form and redirect you to the broadcast list page. Are you sure want to do it ?"
      )
    ) {
      return;
    } else {
      formikProps.handleReset();
      navigate(pages.broadcastList.route);
    }
  };

  const saveAsDraft = (values) => {
    setLoading(true);
    var data = objectFromFormData(values, "draft");
    axios({
      url: "/broadcast/create/campaign/" + activeBot.ID,
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        setFormData(values);
        setEditState(false);
        navigate(pages.broadcastDetails.route + res.data.sbcid);
      }
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Paper>
        {Boolean(formikProps.values.ID) && (
          <Grid item>
            <Button
              variant="contained"
              style={{ background: "#d32f2f", color: "#fff" }}
              onClick={() => setDialogState(true)}
            >
              Delete Broadcast
            </Button>
          </Grid>
        )}

        {Boolean(editState) &&
          formikProps.values.whenToBroadcast !== "now" &&
          formikProps.values.broadcastStatus !== "pending" && (
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={
                  formikProps.dirty
                    ? formikProps.isSubmitting || !formikProps.isValid
                    : !formikProps.dirty
                }
                onClick={() => saveAsDraft(formikProps.values)}
              >
                Draft
              </LoadingButton>
            </Grid>
          )}

        <Grid item xs />

        {Boolean(editState) && (
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={formikProps.isSubmitting}
              disabled={
                formikProps.dirty
                  ? formikProps.isSubmitting || !formikProps.isValid
                  : !formikProps.dirty
              }
              onClick={formikProps.handleSubmit}
            >
              Create Broadcast
            </LoadingButton>
          </Grid>
        )}

        {Boolean(editState) && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (Boolean(formikProps.values.ID)) {
                  setEditState(false);
                  handleCancel(formikProps);
                } else {
                  navigate(-1);
                }
              }}
            >
              {Boolean(formikProps.values.ID) ? "Cancel" : "Back"}
            </Button>
          </Grid>
        )}
      </Paper>
      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </React.Fragment>
  );
}
