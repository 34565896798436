import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  memberRegistrationObject,
  objectFromResponseData,
} from "./member registration components/memberRegistrationObject";
import { useComponent } from "../../hooks";
import { Loader } from "../../components";
import Form from "./member registration components/Form";

export default function MemberRegistration() {
  const { registrationID } = useParams();
  const { axios, loader } = useComponent({ disableAuthToken: true });

  const [formData, setFormData] = useState(memberRegistrationObject);
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    loader.start();

    axios({
      url: `/member/check/token/${registrationID}`,
    }).then((response) => {
      if (response.status) {
        setAccessToken(response.data.token);
        setFormData(objectFromResponseData(response.data));
      }

      loader.apiComplete();
    });
  }, [axios, loader, registrationID]);

  return (
    <Loader disableHomeButton>
      <Form formData={formData} accessToken={accessToken} />
    </Loader>
  );
}
