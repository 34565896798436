import * as Yup from "yup";

export const webhookDetailsObject = {
  webhookID: "",
  name: "",
  activeStatus: true,
  method: "",
  webhookURL: "",
  event: {
    name: "",
    topic: "",
  },
  header: [],
};

export function objectFromFormData(formData) {
  return {};
}

export function objectFromResponseData(responseData) {
  return {};
}

export const validationSchema = Yup.object({
  name: Yup.string().required("Webhook name is required"),
  activeStatus: Yup.boolean().required("Please set webhook status"),
  method: Yup.string().required("Select the webhook method"),
  webhookURL: Yup.string()
    .required("Please enter a webhook URL")
    .url("Please enter a valid URL"),
  event: Yup.object({
    name: Yup.string().required("Please select an event name"),
    topic: Yup.string().required("Please select an event topic"),
  }),
  header: Yup.array().of(
    Yup.object({
      key: Yup.string().required("Please enter the key for header object"),
      value: Yup.string().required("Please enter the value for header object"),
    })
  ),
});
