import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import { FilterList, Sync } from "@mui/icons-material";

import { useAxios } from "../../hooks";
import Refresh from "../../assets/refresh.gif";

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomFilter({ setInsightData }) {
  const axios = useAxios();

  const [refresh, setRefresh] = useState(false);

  const { activeBot } = useSelector((state) => state.memberDetails);

  function refreshLeads() {
    setRefresh(true);

    axios({
      url: "/livechat/livechat_details/" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setInsightData(response.data);
      }
      setRefresh(false);
    });
  }
  return (
    <React.Fragment>
      <Tooltip title={"Refresh Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={refreshLeads}
        >
          {refresh ? (
            <img style={{ height: "22px" }} src={Refresh} alt="refresh" />
          ) : (
            <Sync className={defaultToolbarStyles.deleteIcon} />
          )}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default withStyles(defaultToolbarStyles, { name: "CustomFilter" })(
  CustomFilter
);
