import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Sync } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { useAxios } from "../../../hooks";
import Refresh from "../../../assets/refresh.gif";

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomFilter({ setChatReport }) {
  const axios = useAxios();

  const [refresh, setRefresh] = useState(false);

  const { ID } = useSelector((state) => state.memberDetails);

  function refreshLeads() {
    setRefresh(true);
    axios({
      url: `/insights/list/${ID}`,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        if (Array.isArray(response.data)) {
          setChatReport(response.data);
        }
        setRefresh(false);
      }
    });
  }
  return (
    <React.Fragment>
      <Tooltip title={"Refresh Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={refreshLeads}
        >
          {refresh ? (
            <img style={{ height: "22px" }} src={Refresh} alt="refresh" />
          ) : (
            <Sync className={defaultToolbarStyles.deleteIcon} />
          )}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default withStyles(defaultToolbarStyles, { name: "CustomFilter" })(
  CustomFilter
);
