import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { Cancel } from "@mui/icons-material";

import { useAxios, useComponent } from "../../../../hooks";
import FormDisplay from "../../../leads/lead detail components/FormDisplay";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function AddNewUserDialog({
  addNewUserDialog,
  setAddNewUserDialog,
  activeBot,
  setAddUser,
}) {
  let pageID = "a7dcf2b995d645c1b657fdac59f77c45";
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});
  const { pagePermissions } = useComponent({ pageID });

  var baseSchema = Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().when("phone", {
      is: (type) => type === undefined,
      then: Yup.string().required(
        "Either give email or phone number. Both can not be blank."
      ),
      otherwise: Yup.string().email("Must be a valid email"),
    }),
  });

  for (let field of fields) {
    if (field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string()
            .required(field.label + " is required field.")
            .matches(field.regex, field.error_message),
        })
      );
    } else if (
      field.mandatory &&
      !Boolean(field.regex) &&
      !Boolean(field.multi_Select)
    ) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().required(
            field.label + " is required field."
          ),
        })
      );
    } else if (!field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().matches(field.regex, field.error_message),
        })
      );
    } else if (field.mandatory && Boolean(field.multi_Select)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.array()
            .min(1, "Choose at least one " + field.label)
            .required(field.label + "is required field."),
        })
      );
    }
  }

  const dispatch = useDispatch();

  const axios2 = useAxios();

  const handleClose = () => {
    setAddNewUserDialog(false);
  };

  useEffect(() => {
    axios2({
      url:
        "/custom_fields/get_fields_for_agent?agent_id=" +
        activeBot.ID +
        "&is_active=1",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          var fields = response.data;
          setFields(response.data);

          getFields();

          async function getFields() {
            for await (let field of fields) {
              if (field.control_id !== "61e13a0bf2c60729b06385f3") {
                if (
                  field.control_id === "61dd2dfa1fb38be82e077ed8" &&
                  field.multi_Select
                ) {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: [],
                  }));
                } else if (field.control_id === "61dd2dfa1fb38be82e077ed9") {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: new Date().getTime() / 1000,
                  }));
                } else {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: "",
                  }));
                }
              } else {
                setFormData((formData) => ({
                  ...formData,
                  [field.name]: false,
                }));
              }
            }
          }
        }
      })
      .catch((err) => console.error(err));
  }, [axios2, dispatch, activeBot]);

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={addNewUserDialog}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Create User</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={formData}
          enableReinitialize
          validateOnMount
          validationSchema={baseSchema}
          onSubmit={(formData, { setSubmitting }) => {
            for (let field of fields) {
              if (field.control_id === "61dd2dfa1fb38be82e077ed8") {
                function setValue(options) {
                  var arr = [];

                  if (Boolean(options)) {
                    var dropDownOption = [];

                    if (Boolean(field.multi_Select)) {
                      dropDownOption = options.map((option) =>
                        field.options.find((item) => item.id === option)
                      );

                      for (let option of dropDownOption) {
                        arr.push({
                          id: option.id,
                          label: option.label,
                        });
                      }
                    } else {
                      dropDownOption = field.options.find(
                        (item) => item.id === options
                      );
                      arr.push({
                        id: dropDownOption.id,
                        label: dropDownOption.label,
                      });
                    }
                  }
                  return arr;
                }

                formData = {
                  ...formData,
                  [field.name]: setValue(formData[field.name]),
                };
              }
            }
            axios2({
              url: "/leads/create_lead?agent_id=" + activeBot.ID,
              method: "POST",
              data: formData,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                setAddUser(response.data._id);
              } else {
                setSubmitting(false);
              }
              handleClose();
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  padding: "10px",
                  background: "#cbcbcb38",
                }}
              >
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={12}>
                    <FormDisplay
                      fields={fields}
                      formikProps={dialogFormikProps}
                      editState={true}
                      pagePermissions={pagePermissions}
                      activeBot={activeBot}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item xs style={{ textAlign: "end" }}>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
const validationSchema = Yup.object().shape({
  name: Yup.string(),
  phone: Yup.string(),
  email: Yup.string().when("phone", {
    is: (type) => type === undefined,
    then: Yup.string().required(
      "Either give email or phone number. Both can not be blank."
    ),
    otherwise: Yup.string().email("Must be a valid email"),
  }),
});
