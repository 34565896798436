import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import {
  Publish,
  GetApp,
  ArrowDropDownCircleOutlined,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
// import AWS from "aws-sdk";
import uploadToS3 from "../../../../utils/uploadToS3";

import OptionList from "./OptionList";
import { useAxios } from "../../../../hooks";
import { Paper, ComponentHeader } from "../../../../components";
import OptionPopUp from "./OptionPopUp";

export default function DropDownTableControl({
  formikProps,
  editState,
  folderPath,
  bucket = {},
}) {
  const axios = useAxios();
  var list = [];

  const [addOptionPopUp, setAddOptionPopUp] = useState({
    state: false,
    mode: "add",
    data: {
      id: "",
      label: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState("initial");

  const downloadDummyCSV = () => {
    window.location.href =
      "https://static-assets-dc.s3.ap-south-1.amazonaws.com/sample_data_files/sample_options_format.xlsx";
  };

  const handleUploadFile = (CSV) => {
    const file = CSV.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function () {
        // var bucketName = "dc-chat-media";
        // var bucketRegion = "ap-south-1";
        // var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

        // AWS.config.update({
        //   region: bucketRegion,
        //   credentials: new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: identityPoolID,
        //   }),
        // });

        // var s3 = new AWS.S3({
        //   apiVersion: "2006-03-01",
        //   params: {
        //     Bucket: bucketName,
        //   },
        // });

        // var filePath = "sample_data_files/" + file.name;
        // var upload = s3.upload({
        //   Key: filePath,
        //   ACL: "public-read",
        //   Body: file,
        // });

        // var promise = upload.promise(); //till here
        const uploadBucket = {
          name: "dc-chat-media",
          region: "ap-south-1",
          identityPoolID: "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103",
          ...bucket,
        };

        uploadToS3({
          bucket: uploadBucket,
          filePath: "sample_data_files/" + file.name,
          file,
          bucketRegion: "ap-south-1",
        }).then(function (data) {
          //     console.log(data, "upload data");
          setUploadState("success");
          setLoading(false);
          var option = {
            url:
              "https://" +
              uploadBucket.name +
              ".s3.ap-south-1.amazonaws.com/" +
              folderPath +
              file.name,
          };

          axios({
            url: "/custom_fields/get_excel_data_from_url",
            method: "POST",
            data: option,
          }).then((response) => {
            if (response.status) {
              setLoading(false);
              formikProps.setValues((prevVal) => {
                let newOptions = [...prevVal.options];
                for (var option of response.data) {
                  newOptions.push(option);
                }
                return {
                  ...prevVal,
                  options: newOptions,
                };
              });
            }
          });
        });
      };
      reader.onerror = function (error) {
        console.error("Error: ", error);
      };
    }
  };

  const handleAddNewOption = () => {
    setAddOptionPopUp({
      state: true,
      mode: "add",
      data: {
        id: "",
        label: "",
      },
    });
  };

  return (
    <Paper>
      <Grid item xs={4}>
        <ComponentHeader
          title="Upload Dropdown Options"
          subtitle="Upload Options for your dropdown."
        />
      </Grid>

      <Grid item xs />

      <Grid item>
        <Button
          disabled={!editState}
          variant="outlined"
          color="primary"
          onClick={downloadDummyCSV}
        >
          <GetApp /> &nbsp; Download Sample Format
        </Button>
      </Grid>

      <Grid item>
        <Button
          onClick={handleAddNewOption}
          variant="outlined"
          color="primary"
          component="span"
          disabled={!editState}
        >
          <ArrowDropDownCircleOutlined />
          &nbsp; Add New Option
        </Button>
      </Grid>

      <Grid item>
        <label htmlFor="contained-button-file">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            disabled={!editState}
            onChange={(event) => handleUploadFile(event)}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />

          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<Publish />}
            disabled={!editState}
            variant="outlined"
            component="span"
          >
            Upload Options
          </LoadingButton>
        </label>
      </Grid>

      <Grid item xs={12}>
        <OptionList
          editState={editState}
          list={list}
          formikProps={formikProps}
        />
      </Grid>

      <OptionPopUp
        addOptionPopUp={addOptionPopUp}
        setAddOptionPopUp={setAddOptionPopUp}
        formikProps={formikProps}
      />
    </Paper>
  );
}
