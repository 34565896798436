import styled from "styled-components";
import GaugeChart from "react-gauge-chart";
import { GrCircleInformation } from "react-icons/gr";
import { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
  Paper as MuiPaper,
} from "@mui/material";

import { useComponent } from "../../../hooks";
import startDateCalculator from "./startDateCalculator";

const Paper = styled(MuiPaper)`
  height: 200px;
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const chartStyle = {
  height: 120,
};

export default function ChatbotAvailablity({ botID, startDate }) {
  const { axios } = useComponent();
  const [loading, setLoading] = useState(true);
  const [chatBotAvailablity, setChatBotAvailablity] = useState({});

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/availability/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        end_date: new Date().getTime() / 1000,
        ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
      },
    }).then((response) => {
      if (response.status) {
        setChatBotAvailablity(response.data.availability);

        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Typography
            variant="h5"
            sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
          >
            ChatBot Availablity
            <Tooltip
              placement="top-start"
              title="the percentage of utterances that had the correct intent returned."
            >
              <IconButton>
                <GrCircleInformation size={18} />
              </IconButton>
            </Tooltip>
          </Typography>

          <Grid container spacing={2}>
            <Grid
              item
              xs={8}
              sm={8}
              md={9}
              lg={9}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                alignSelf: "center",
              }}
            >
              <GaugeChart
                id="gauge-chart4"
                percent={chatBotAvailablity}
                colors={["#f56d90", "#ffff80", "#6ed4b5"]}
                style={chartStyle}
                arcPadding={0.1}
                textColor={"black"}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
}
