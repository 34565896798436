import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const BASE_URL = {
  superadmin: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "/v2/superadmin",
  },

  adminV1: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "",
  },

  adminV2: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "/v2/admin",
  },

  messagingV2: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "/v2/messaging",
  },

  notification: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "/v2",
  },

  component: {
    url: "https://" + URL_PREFIX + "component.dolphinchat.ai",
    version: "",
  },

  botBuilder: {
    url: "https://" + URL_PREFIX + "backend-bot-builder.dolphinchat.ai",
    version: "/v1",
  },
};

export default BASE_URL;
