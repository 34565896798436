import { Fragment } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  Typography as MuiTypography,
} from "@mui/material";
import styled from "styled-components";

import {
  Paper,
  TextField,
  ButtonFooter,
  ComponentHeader,
} from "./../../../components";

const Typography = styled(MuiTypography)`
  color: #808080b3;
`;

export default function FormDisplay({ editState, formikProps, setEditState }) {
  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Live Chat Configuration"
            subtitle="You can configure how default settings for the chat bot here"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Live Chat automatic end time"
            name="auto_end_livechat_time"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Minutes</InputAdornment>
              ),
            }}
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            In how many minutes of inactivity, will the chat end?
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            label="Auto Assignment"
            name="auto_assignment"
            disabled={!editState}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Enabled</MenuItem>
            <MenuItem value={false}>Disabled</MenuItem>
          </TextField>

          <Typography variant="subtitle2">
            Should new chat requests be auto assigned to agents?
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Assigned to Agent Message"
            name="assigend_to_message"
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            Message to send when user is assigned to an agent
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Transfer Message"
            name="transferred_message"
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            Message to send when user is transferred from one agent to another
            agent
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Agent Unavailable Message"
            name="no_agent_available_message"
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            Message to display when no agent is online or available
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Chat End Message"
            name="end_chat_message"
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            Message to display when the chat ends
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Agent Lost Connection Message"
            name="end_chat_forcefully"
            disabled={!editState}
            formikProps={formikProps}
          />

          <Typography variant="subtitle2">
            Message to display when agent quits a chat abruptly
          </Typography>
        </Grid>
      </Paper>

      <ButtonFooter />
    </Fragment>
  );
}
