import { Fragment } from "react";
import styled from "styled-components";
import { GrCircleInformation } from "react-icons/gr";
import {
  Paper as MuiPaper,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Paper = styled(MuiPaper)`
  height: 200px;
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function ChatBotResponse() {
  return (
    <Paper>
      <Fragment>
        <Typography
          variant="h5"
          sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
        >
          Average Response Time
          <Tooltip
            placement="top-start"
            title="the average amount of time bot takes to respond to a customer query."
          >
            <IconButton>
              <GrCircleInformation size={18} />
            </IconButton>
          </Tooltip>
        </Typography>
        <AccessTimeIcon
          style={{
            color: "#b3b3b3",
            verticalAlign: "text-top",
            fontSize: "60px",
            marginTop: "10px",
          }}
        ></AccessTimeIcon>

        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ alignItems: "center", alignSelf: "center" }}
            >
              <ContainerItem>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "500",
                  }}
                  sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
                >
                  0.7 ms
                </Typography>
              </ContainerItem>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </Paper>
  );
}
