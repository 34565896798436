import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signIn({ authToken, rememberMe = false }) {
  var activeBot = localStorage.getItem("activeBot");

  if (rememberMe) {
    localStorage.setItem("authToken", authToken);
  } else {
    sessionStorage.setItem("authToken", authToken);
  }

  const axiosInstance = axios.create({
    baseURL: BASE_URL.adminV2.url + BASE_URL.adminV2.version,
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
  });

  const profileData = await axiosInstance({
    url: "/profile",
    method: "GET",
  }).then((res) => res.data.data);

  const permissionData = await axiosInstance({
    url: "/agent/permission",
    method: "GET",
  }).then((res) => res.data.data);

  const bots = getBots(profileData.bots, permissionData);

  return {
    authToken: authToken,
    ID: profileData.member_id,
    firstName: profileData.first_name,
    lastName: profileData.last_name,
    email: profileData.email,
    timezone: profileData.preferred_timezone,
    gender: profileData.gender,
    businessID: profileData.business_id,
    businessName: profileData.business_name,
    groupID: profileData.member_group_id,
    groupName: profileData.member_group_name,
    bots: bots,
    activeBot: bots.find((bot) => bot.ID === activeBot) || bots[0],
  };
}

function getBots(bots, permissions) {
  var botList = [];

  for (const bot of bots) {
    const botPermissions = permissions.find(
      (permission) => permission.agent_id === bot.agent_id
    );

    if (!!botPermissions) {
      botList.push({
        ID: bot.agent_id,
        name: bot.agent_name,
        whatsapp: bot.wa_number,
        serviceID: bot.bot_service_id,
        channel: channelName(bot.channel_id),
        //Todo: remove access token from here and api when not using v1
        accessToken: bot.access_token,
        URL: bot.url,
        pagePermissions: getBotPermissions(botPermissions.pages),
      });
    }

    function getBotPermissions(pages) {
      var pageList = [];

      for (const page of pages) {
        pageList.push({
          ID: page.page_id,
          name: page.page_name,
          read: page.permissions.read,
          create: page.permissions.create,
          update: page.permissions.update,
          delete: page.permissions.delete,
        });
      }

      return pageList;
    }

    function channelName(channelID) {
      switch (channelID) {
        case "f5145f46752c41e48a7cf51bd958b921":
          return "web";

        case "606fdbaaf169cd7446773b7e":
          return "whatsapp";

        case "32dc9508b1c249148a5af9e77ae0466d":
          return "facebook";

        case "c28615e8d26d443cb9610b14d86d77a1":
          return "instagram";

        default:
          return "";
      }
    }
  }

  return botList;
}
