import React from "react";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";

import { useAxios } from "../../../../../hooks";
import CreateLookup from "./CreateLookup";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "500px" },
}));

export default function LookupDialog({ popUp, setPopUp, formikProps }) {
  const classes = useStyles();
  const axios = useAxios();

  var lookup = {
    lookup: "",
  };

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <DialogTitle
          style={{ paddingBottom: "10px", borderBottom: "1px solid #cbcbcb" }}
        >
          Create Lookup
        </DialogTitle>
        <Formik
          initialValues={lookup}
          enableReinitialize
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              entityLookups: getLookup(prevVal.entityLookups),
            }));

            function getLookup(prevVal) {
              var looks = [...prevVal];
              looks.push({
                lookup: formData.lookup,
              });
              return looks;
            }
            handleClose();
            setSubmitting(false);
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent style={{ marginTop: "20px" }}>
                <CreateLookup
                  formikProps={dialogFormikProps}
                  formProps={formikProps}
                  handleClose={handleClose}
                />
              </DialogContent>
              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Create Lookup
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
