import React from "react";

import { Grid, Typography } from "@mui/material";

import { TextField } from "../../../../components";

export default function AddButtonVariable({ formikProps }) {
  return (
    <React.Fragment>
      {formikProps.values.templateButtons?.count_of_variable !== 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Button Variable</Typography>
        </Grid>
      )}
      {formikProps.values.templateButtons?.buttons?.map((item, index) => (
        <React.Fragment key={index}>
          {(item.url_type === "dynamic" || item.url_type === "Dynamic") && (
            <Grid item xs={4}>
              <TextField
                formikProps={formikProps}
                label="Button Variable Value"
                disabled={Boolean(formikProps.values.ID)}
                name={"templateButtons.buttons[" + index + "].example"}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
