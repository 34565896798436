import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import pages from "../../constants/pages";
import {
  webhookDetailsObject,
  objectFromResponseData,
} from "./webhook details components/webhookDetailsObject";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./webhook details components/Form";

export default function WebhookDetails({ pageID }) {
  const { axios, loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { webhookID } = useParams();

  const pagePermissions = {
    create: true,
    read: true,
    update: true,
    delete: true,
  };

  const [editState, setEditState] = useState(!webhookID);
  const [formData, setFormData] = useState(webhookDetailsObject);

  useEffect(() => {
    loader.start();

    if (!!webhookID) {
      axios({
        url: "/webhook/" + activeBot.ID + "?webhook_id=" + webhookID,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));

          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [loader, axios, webhookID, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Webhook Details"
        breadcrumbs={[{ location: pages.webhookList.route, name: "Webhooks" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        botID={activeBot.ID}
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
