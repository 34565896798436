import { useState } from "react";

import { Grid, TextField, Button } from "@mui/material";
import FileUploadNew from "../../../../../../../components/FileUploadNew";

export default function Multimedia({
  contentType,
  sendMessage,
  handleDialogClose,
}) {
  const [caption, setCaption] = useState("");
  const [file, setFile] = useState({
    name: "",
    URL: "",
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FileUploadNew
          name="file.name"
          allowedExtension={extensions(contentType)}
          formikProps={{
            setFieldValue: (_, value) => setFile(value),
            values: { file },
            setFieldTouched: () => {},
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          value={caption}
          label="Caption"
          name="caption"
          onChange={({ target }) => setCaption(target.value)}
          inputProps={{ maxLength: 1000 }} //@mohit bhatt
        />
      </Grid>

      <Grid item xs />

      <Grid item>
        <Button variant="contained" disabled={!file.URL} onClick={handleSubmit}>
          Send
        </Button>
      </Grid>

      <Grid item>
        <Button onClick={handleDialogClose}>Cancel</Button>
      </Grid>
    </Grid>
  );

  function handleSubmit() {
    sendMessage({
      type: contentType,
      caption: caption,
      URL: file.URL,
      name: file.name,
    }).then((response) => {
      if (response.status) {
        handleDialogClose();
      }
    });
  }
}

function extensions(type) {
  switch (type) {
    case "image":
      return ".png, .jpg, .jpeg";

    case "video":
      return ".mp4, .gif";

    case "audio":
      return ".mp3";

    case "document":
      return ".doc, .docx, .pdf, .csv, .xlx";

    default:
      return "*";
  }
}
