import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper as MuiPaper,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { Mail, StarOutline, Phone, Star } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";

import { useAxios, useComponent } from "../../../../hooks";
import LeadInformation from "./LeadInformation";
import EmailDialog from "../../message send components/email message components/EmailDialog";
import TotalMessage from "./TotalMessage";
import StatsOfUser from "./StatsOfUser";
import LeadScore from "./LeadScore";
import MessageSent from "../../message send components/MessageSent";

const Paper = styled(MuiPaper)`
  height: 150px;
  display: flex;
  border-radius: 0;
  flex-direction: column;
  padding: 15px 0 15px 10px;
`;

const useStyles = makeStyles(() => ({
  background: {
    background: "rgb(208, 208, 208, 51%)",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  chip: {
    borderRadius: "20px",
    fontWeight: "200",
    background: "#fff",
    color: "rgb(35, 48, 68)",
  },
  padding: { paddingTop: "8px", paddingBottom: "8px" },
}));

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;
export default function UserPrimaryInformation({
  userInfo,
  pagePermissions,
  formData,
  activeBot,
  setFormData,
  templates,
  setChangedLeadProperty,
  leadProperties,
  integrationDetails,
  memberList,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const { alert } = useComponent();

  const [popUp, setPopUp] = useState(false);
  const [insightData, setInsightData] = useState({});
  const [leadScore, setLeadScore] = useState(formData.lead_score);
  const [templatePopUp, setTemplatePopUp] = useState(false);

  function handleImportant(important) {
    axios({
      url: "/leads/mark_lead_as_important?lead_id=" + formData.ID,
      method: "POST",
      data: important,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message?.displayMessage);
        setFormData((prevVal) => ({
          ...prevVal,
          important: response.data.important,
        }));
      }
    });
  }

  useEffect(() => {
    axios({
      url: "/insights/user-chats/" + formData.senderID,
    }).then((response) => {
      if (response.status) {
        setInsightData(response.data);
      }
    });
  }, [formData.senderID, axios]);

  return (
    <React.Fragment>
      <Grid container spacing={4} pb={5}>
        <Grid item xs={3}>
          <Paper className={classes.userInformation}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingRight: "8px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar sx={{ width: 70, height: 70 }}>
                      <Typography variant="h4" style={{ fontSize: "30px" }}>
                        {!!formData?.name && formData?.name.charAt(0)}
                      </Typography>
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8}>
                <Grid container spacing={4}>
                  <Grid item xs={2}>
                    {formData.important ? (
                      <IconButton
                        disableRipple
                        style={{ padding: "0" }}
                        onClick={() => handleImportant(false)}
                      >
                        <Tooltip title={"Mark lead as unimportant."}>
                          <Star
                            style={{
                              color: "#fbbc04",
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    ) : (
                      <IconButton
                        disableRipple
                        style={{ padding: "0" }}
                        onClick={() => handleImportant(true)}
                      >
                        <Tooltip title={"Mark lead as important."}>
                          <StarOutline />
                        </Tooltip>
                      </IconButton>
                    )}
                  </Grid>

                  <Grid item xs={10} style={{ paddingLeft: "2px" }}>
                    <Tooltip title={formData.name}>
                      <ColoredTypography variant="h4">
                        {formData?.name?.slice(0, 15) +
                          (formData?.name?.length > 15 ? "..." : "")}

                        <Chip
                          label={formData?.lead_status}
                          variant="outlined"
                          style={{
                            height: "23px",
                            background: "#0089ff",
                            color: "#fff",
                            borderRadius: "20px",
                            borderColor: "#0089ff",
                            marginLeft: "7px",
                          }}
                        />
                      </ColoredTypography>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => setTemplatePopUp(true)}
                      disableRipple
                      style={{ padding: "0", color: "#0089ff" }}
                      disabled={!formData?.phone}
                    >
                      <Tooltip title={"Send message to user."}>
                        <Phone style={{ fontSize: "16px" }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>

                  <Grid item xs={10} style={{ paddingLeft: "2px" }}>
                    <Typography variant="h4" style={{ fontSize: "15px" }}>
                      {formData?.phone}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => setPopUp(true)}
                      disableRipple
                      style={{ padding: "0", color: "#0089ff" }}
                      disabled={
                        !integrationDetails?.integration_id || !userInfo?.email
                      }
                    >
                      <Tooltip title={"Send mail to user."}>
                        <Mail style={{ fontSize: "16px" }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>

                  <Grid item xs={10} style={{ paddingLeft: "2px" }}>
                    <Tooltip title={formData?.email}>
                      <Typography
                        variant="h4"
                        style={{ fontSize: "15px", overflowWrap: "break-word" }}
                      >
                        {!!formData?.email
                          ? formData?.email?.slice(0, 25) +
                            (formData?.email?.length > 25 ? "..." : "")
                          : "Not Available"}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <LeadInformation
            pagePermissions={pagePermissions}
            leadProperties={leadProperties}
            formData={formData}
            activeBot={activeBot}
            setChangedLeadProperty={setChangedLeadProperty}
            memberList={memberList}
          />
        </Grid>

        <Grid item xs={2}>
          <TotalMessage insightData={insightData} />
        </Grid>

        <Grid item xs={2}>
          <StatsOfUser insightData={insightData} />
        </Grid>

        <Grid item xs={2}>
          <LeadScore
            leadScore={leadScore}
            setLeadScore={setLeadScore}
            formData={formData}
            pagePermissions={pagePermissions}
          />
        </Grid>
      </Grid>

      <EmailDialog popUp={popUp} setPopUp={setPopUp} userInfo={userInfo} />

      <MessageSent
        popUp={templatePopUp}
        setPopUp={setTemplatePopUp}
        formikProps={formData}
        templates={templates}
        userInfo={userInfo}
      />
    </React.Fragment>
  );
}
