import React from "react";
import { Grid, Typography } from "@mui/material";
import { Paper } from "../../../../components";

import "../../whatsapp interface/WhatsappPreview.css";
import AddSampleMedia from "./AddSampleMedia";
import AddBodyVariable from "./AddBodyVariableSample";
import AddButtonVariable from "./AddButtonVariable";

export default function AddSample({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Typography variant="h6">Add Sample Content</Typography>
      </Grid>

      <Grid style={{ paddingTop: "5px" }} item xs={12}>
        <Typography style={{ fontSize: "13px", color: "#808080" }}>
          This helps us during the review and approval process, so we can
          understand what kind of message you plan to send. Make sure these are
          examples and do not include any actual user or customer information.
        </Typography>
      </Grid>

      <AddSampleMedia formikProps={formikProps} />
      <AddBodyVariable formikProps={formikProps} />
      <AddButtonVariable formikProps={formikProps} />
    </Paper>
  );
}
