import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signOut(_, { getState }) {
  const { memberDetails } = getState();

  let LiveChatPagePermissions = memberDetails.activeBot.pagePermissions.filter(
    (items) => {
      return "Livechat page" === items.name;
    }
  );

  if (
    LiveChatPagePermissions[0]?.create &&
    LiveChatPagePermissions[0]?.delete
  ) {
    await axios({
      baseURL: BASE_URL.adminV2.url + BASE_URL.adminV2.version,
      url: `/livechat/member-availabilty/${memberDetails.ID}`,
      method: "POST",
      headers: {
        Authorization: "Bearer " + memberDetails.authToken,
        "Content-Type": "application/json",
      },
      params: {
        bot_id: memberDetails.activeBot.ID,
      },
      data: {
        is_online: false,
      },
    });
  }

  await axios({
    baseURL: BASE_URL.adminV2.url + BASE_URL.adminV2.version,
    url: "/logout",
    method: "POST",
    headers: {
      Authorization: "Bearer " + memberDetails.authToken,
      "Content-Type": "application/json",
    },
  });
}
