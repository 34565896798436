import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Grid, Typography } from "@mui/material";

export default function PhoneNumberForm({ dialogFormikProps }) {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        mobile_number: setValidPhoneNumber(prevVal.mobile_number),
      }));
      function setValidPhoneNumber(mobile_number) {
        mobile_number = "+" + country.dialCode + "";
        return mobile_number;
      }
    } else {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        mobile_number: setValidPhoneNumber(prevVal.mobile_number),
      }));
      function setValidPhoneNumber(mobile_number) {
        mobile_number = value;
        return mobile_number;
      }
    }
  };
  return (
    <Grid container spacing={4} alignItems="center">
      <Grid style={{ paddingTop: "4px" }} item xs={12}>
        <Typography variant="subtitle2">
          You can test the template by sending it to whatsapp number
        </Typography>
      </Grid>
      <Grid style={{ paddingTop: "6px" }} item xs={12}>
        <PhoneInput
          value={dialogFormikProps.values.mobile_number}
          onChange={(value, country, e) =>
            handleChangePhoneNumber(value, country, e)
          }
          inputProps={{
            name: "mobileNumber",
          }}
          onBlur={dialogFormikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: "currentColor",
            borderColor: !validPhoneNumber ? "#4076d2" : "#f44336",
            padding: "17.5px 14px 18.5px 58px",
            cursor: "default",
          }}
        />
        {validPhoneNumber && (
          <span className="validationError">Number is Required</span>
        )}
      </Grid>
    </Grid>
  );
}
