import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Tooltip, IconButton } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { FaRegClone } from "react-icons/fa";

import DataTable from "../../../components/DataTable";
import convertDate from "../../../utils/convertDate";
import DeleteDialog from "./DeleteDailogue";
import { useComponent } from "../../../hooks";
import Details from "./DetailsDialog";
import pages from "../../../constants/pages";

export default function Table({ templates, setTemplateDelete }) {
  const navigation = useNavigate();
  const { axios, alert } = useComponent();

  const [anchorEl, setAnchorEl] = useState(null);

  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  const [popUp, setPopUp] = useState({
    state: false,
    details: {},
  });

  const handleRowHover = (e, col, colIndex, rowIndex) => {
    if (colIndex === 4) {
      setPopUp({
        state: true,
        details: templates.temp_obj[rowIndex],
      });
      setAnchorEl(e.currentTarget);
    }
  };

  const handleRowHoverLeave = (e, col, colIndex, rowIndex) => {
    setPopUp({
      state: false,
      details: {},
    });
    setAnchorEl(null);
  };

  function handleCellClick(cellData, cellMetaData) {
    if (cellMetaData.colIndex !== 5) {
      navigation(
        pages.templateDetails.route +
          templates.temp_obj[cellMetaData.dataIndex]._id
      );
    }
  }

  const options = {
    onCellClick: handleCellClick,

    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Template ID",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "name",
      label: "Template Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "category",
      label: "Template Category",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "verified_status_id",
      label: "Template status",
      options: {
        setCellProps: (col, rowIndex, colIndex) => {
          return {
            onMouseEnter: (e) => handleRowHover(e, col, colIndex, rowIndex),
            onMouseLeave: (e) =>
              handleRowHoverLeave(e, col, colIndex, rowIndex),
          };
        },
        customBodyRender: (value) => {
          if (value) {
            if (value === "8360b5eca314424a9d56c37004ccecc9") {
              return (
                <Chip
                  label={"Approved"}
                  style={{ color: "#4F8A10", backgroundColor: "#DFF2BF" }}
                />
              );
            } else if (value === "531c1247d1ca4329b4eef4596a099b52") {
              return (
                <Chip
                  label={"Pending"}
                  style={{ color: "#9F6000", backgroundColor: "#FEEFB3" }}
                />
              );
            } else if (value === "c280844cac4643698021059955d6bbe5") {
              return (
                <Chip
                  label={"Rejected"}
                  style={{ color: "#D8000C", backgroundColor: "#FFD2D2" }}
                />
              );
            }
          } else {
            return "Not Available";
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (_, index) => (
          <React.Fragment>
            <Tooltip title="Delete Template">
              <IconButton
                onClick={() =>
                  setDialogState({
                    state: true,
                    id: index.rowData[0],
                  })
                }
                style={{ color: "#ff0000" }}
                size="small"
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Tooltip title="Make Clone">
              <IconButton
                onClick={() =>
                  navigation(pages.templateClone.route + index.rowData[0])
                }
                color="primary"
                size="small"
              >
                <FaRegClone />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ),
      },
    },
  ];

  const handleDelete = () => {
    axios({
      url: "/wa/template/delete/" + dialogState.id,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        setTemplateDelete(dialogState.id);
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        options={options}
        data={templates.temp_obj}
      />

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />

      <Details
        popUp={popUp}
        setPopUp={setPopUp}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </React.Fragment>
  );
}
