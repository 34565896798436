import * as Yup from "yup";

export const whatsappDetailObject = {
  widgetID: "",
  align: "",
  bubbleBackgroundColor: "",
  bubbleIconColor: "",
  buttonColor: "",
  buttonText: "",
  headerBackgroundColor: "",
  hideDolphinchatLogo: 1,
  nameCaptionReplyTime: "",
  parentUrl: "www.dolphinchat.ai",
  pictureCustom: "",
  preFilledMessage: "",
  welcomeMessage: "",
  windowBackgroundColor: "",
  windowBackgroundPattern: "",
  TitleName: "",
  whatsappNumber: "",
};

export function configureDetailFromResponseData(responseData) {
  if (responseData.widget) {
    return {
      widgetID: responseData.widget?.widgetId,
      align: responseData.widget?.align || "right",
      bubbleBackgroundColor:
        responseData.widget?.bubbleBackgroundColor || "rgb(255, 255, 255)",
      bubbleIconColor:
        responseData.widget?.bubbleIconColor || "rgb(79, 206, 93)",
      buttonColor: responseData.widget?.buttonColor || "rgb(79, 206, 93)",
      buttonText: responseData.widget?.buttonText || "Start Chat",
      headerBackgroundColor:
        responseData.widget?.headerBackgroundColor || "rgb(9, 94, 84)",
      hideDolphinchatLogo: responseData.widget?.hide_dolphinchat_logo || 1,
      nameCaptionReplyTime:
        responseData.widget?.nameCaptionReplyTime ||
        "Typically replies in a minute.",
      parentUrl: responseData.widget?.parent_url || "www.dolphinchat.ai",
      pictureCustom:
        responseData.widget?.pictureCustom ||
        "https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632117713967/defaultImage.png",
      preFilledMessage:
        responseData.widget?.preFilledMessage || "Hey @dolphinchat",
      welcomeMessage:
        responseData.widget?.welcomeMessage || "Hey there, How can I help you?",
      windowBackgroundColor:
        responseData.widget?.windowBackgroundColor || "rgb(230, 221, 212)",
      windowBackgroundPattern:
        responseData.widget?.windowBackgroundPattern ||
        "https://static.dolphinchat.ai/plugin/wa/img/whatsapp.png",
      whatsappNumber: responseData.widget?.phone || "910000000000",
      TitleName: responseData.widget?.name || "DolphinChat",
    };
  } else {
    return {
      widgetID: responseData.agent_id,
      align: "right",
      bubbleBackgroundColor: "rgb(255, 255, 255)",
      bubbleIconColor: "rgb(79, 206, 93)",
      buttonColor: "rgb(79, 206, 93)",
      buttonText: "Start Chat",
      headerBackgroundColor: "rgb(9, 94, 84)",
      hideDolphinchatLogo: 1,
      nameCaptionReplyTime: "Typically replies in a minute.",
      parentUrl: "www.dolphinchat.ai",
      pictureCustom:
        "https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632117713967/defaultImage.png",
      preFilledMessage: "Hey @dolphinchat",
      welcomeMessage: "Hey there, How can I help you?",
      windowBackgroundColor: "rgb(230, 221, 212)",
      windowBackgroundPattern:
        "https://static.dolphinchat.ai/plugin/wa/img/whatsapp.png",
      TitleName: "DolphinChat",
      whatsappNumber: "911010101010",
    };
  }
}

export function objectFromFormData(formData) {
  return {
    widgetId: formData.widgetID,
    align: formData.align,
    bubbleBackgroundColor: formData.bubbleBackgroundColor,
    bubbleIconColor: formData.bubbleIconColor,
    buttonColor: formData.buttonColor,
    buttonText: formData.buttonText,
    headerBackgroundColor: formData.headerBackgroundColor,
    hide_dolphinchat_logo: formData.hideDolphinchatLogo,
    nameCaptionReplyTime: formData.nameCaptionReplyTime,
    parent_url: formData.parentUrl,
    pictureCustom: formData.pictureCustom,
    preFilledMessage: formData.preFilledMessage,
    welcomeMessage: formData.welcomeMessage,
    windowBackgroundColor: formData.windowBackgroundColor,
    windowBackgroundPattern: formData.windowBackgroundPattern,
    phone: formData.whatsappNumber,
    name: formData.TitleName,
  };
}

export const validationSchema = Yup.object().shape({
  nameCaptionReplyTime: Yup.string()
    .max(35, "It should not be greater than 35 letters")
    .required("Title is required!"),

  welcomeMessage: Yup.string()
    .max(70, "Should not be more then 70 letters")
    .required("Welcome Message is required"),

  buttonText: Yup.string()
    .max(20, "Should not be greater than 20 letters")
    .required("Button text is required."),

  preFilledMessage: Yup.string().max(
    500,
    "Should not be greater than 30 characters"
  ),
  TitleName: Yup.string()
    .max(20, "Should not be greater than 20 characters")
    .required("Title is required"),
  whatsappNumber: Yup.string().min(6).required("It is required"),
  parentUrl: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct domain name"
    )
    .required("Domain Name is required"),
});
