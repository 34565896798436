import { Fragment, useState, useEffect } from "react";
import {
  Paper as MuiPaper,
  Typography,
  LinearProgress,
  Grid,
} from "@mui/material";
import styled from "styled-components";

import { useComponent } from "../../../hooks";

const Paper = styled(MuiPaper)`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function TemplateCurrentMonth({ startDate, botID }) {
  const { axios } = useComponent();

  const [loading, setLoading] = useState(true);
  const [templateInsights, setTemplateInsights] = useState({});

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/templates/${botID}`,
      disableError: true,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplateInsights(response.data);
        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Grid container spacing={0} style={{ padding: "0 10px" }}>
            <Grid item xs>
              <Typography
                variant="h5"
                sx={{ fontSize: { lg: 17, md: 22, sm: 20, xs: 15 } }}
              >
                {" "}
                Template Messages Insights (Current Month)
              </Typography>
            </Grid>
          </Grid>

          <Container>
            <Grid container spacing={8}>
              <Grid item xs={6} sm={6}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {templateInsights.allowed}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Total Messages Allowed
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={6} sm={6}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {templateInsights.left}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Total Messages Left
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={6} sm={4}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {templateInsights.sent}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Total Messages Sent
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={6} sm={4}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {templateInsights.failed}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Total Messages Failed
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid item xs={12} sm={3}>
                <ContainerItem>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
                  >
                    {templateInsights.delivered}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                  >
                    Total Messages Delivered
                  </Typography>
                </ContainerItem>
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Paper>
  );
}
