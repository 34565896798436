import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Info } from "@mui/icons-material";

import { useAxios } from "../../../hooks";
import { Autocomplete } from "../../../components";
import AddBodyVariable from "../../template/template details component/add sample components/AddBodyVariableSample";
import AddSampleMedia from "../../template/template details component/add sample components/AddSampleMedia";
import AddButtonVariable from "../../template/template details component/add sample components/AddButtonVariable";

export default function TemplateDetailsWithVariable({
  templates,
  formikProps,
}) {
  const axios = useAxios();
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const { activeBot } = useSelector((state) => state.memberDetails);

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        phoneNumber: "+" + country.dialCode + "",
      }));
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        phoneNumber: value,
      }));
    }
  };

  const handleChangeTemplate = (value, type) => {
    if (type === "selectOption") {
      axios({
        url: "/wa/template/" + activeBot.ID + "?template_id=" + value._id,
      })
        .then((response) => {
          if (response.status) {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              templateFooter: response.data.template_footer || {},
              templateBody: response.data.template_body || {},
              templateHeader: response.data.template_header || {},
              templateButtons: response.data.template_button || {},
            }));
          }
        })
        .catch((err) => console.error(err));

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    } else {
      value = {
        _id: "",
        language: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={1}>
        <Typography variant="caption1">Template details</Typography>
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          name="templateID"
          label="Template"
          defaults={{
            primaryKey: "_id",
            displayLabel: "name",
          }}
          options={templates}
          onChange={(_, value, type) => handleChangeTemplate(value, type)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneInput
          value={formikProps.values.phoneNumber}
          onChange={(value, country, e) =>
            handleChangePhoneNumber(value, country, e)
          }
          inputProps={{
            name: "mobileNumber",
          }}
          onBlur={formikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: "currentColor",
            borderColor: !validPhoneNumber ? "#4076d2" : "#f44336",
            padding: "17.5px 14px 18.5px 58px",
            cursor: "default",
          }}
        />
        {validPhoneNumber && (
          <span className="validationError">Number is Required</span>
        )}
      </Grid>

      <AddSampleMedia formikProps={formikProps} />

      <AddBodyVariable formikProps={formikProps} />

      <AddButtonVariable formikProps={formikProps} />

      <Grid item xs={12} mt={1}>
        <div
          style={{
            display: "flex",
            backgroundColor: "rgb(203 203 203 / 34%)",
            padding: "10px",
          }}
        >
          <Info fontSize="small" color="primary" />
          &nbsp;
          <Typography variant="body1">
            The variables in the template will get replaced by the sample values
            you'll provide.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
