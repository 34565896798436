import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { ComponentHeader, Paper } from "../../../../components";

const DetailsCard = styled.div`
  padding: 20px;
  width: 300px;
  height: 300px;
  overflow: scroll;
  border-radius: 10px;
  margin-right: 20px;
  background: #e1ebee;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const TermTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

export default function Preview({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"SMS Preview"}
          subtitle="This is how your sms look like."
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <DetailsCard>
          {formikProps.values.flowId && (
            <TermTypography variant="h5">
              Hello Ashwin, just a friendly reminder to let you know that for
              Property ID: P20900662007, the property tax of ₹ 646610.00 is yet
              to be paid and the due date is Sunday, 30th of April. You can pay
              now at https://rzp.io/i/6WVbsd19YC to avoid getting on the
              defaulter's list. By Dolphinchat(https://dolphinchat.ai/)
            </TermTypography>
          )}
        </DetailsCard>
      </Grid>
    </Paper>
  );
}
