import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import pages from "../../../../constants/pages";
import { useAxios, useComponent } from "../../../../hooks";
import { PageHeader, Loader } from "../../../../components";
import Table from "../../email components/list components/Table";

export default function EmailList({ pageID }) {
  const axios = useAxios();
  const { loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [broadcasts, setBroadcasts] = useState([]);
  const [cancelBroadcast, setCancelBroadcast] = useState("");

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/broadcast_email/get_broadcast_list/?agent_id=" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setBroadcasts(response.data);
        loader.apiComplete(1);
      } else {
        loader.apiComplete(1);
      }
    });
  }, [cancelBroadcast, axios, activeBot, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Email Broadcast"
        addIcon={[
          "Create Email Broadcast",
          pages.sailBroadcastEmailDetails.route,
          true,
        ]}
      />

      <Table broadcasts={broadcasts} setCancelBroadcast={setCancelBroadcast} />
    </Loader>
  );
}
