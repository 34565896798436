import { Fragment } from "react";
import {
  Grid,
  Tooltip,
  Typography,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import { DeleteOutlined, Add } from "@mui/icons-material";

import { ComponentHeader, Paper, TextField } from "../../../components";
import FormDisplay from "../../utterance/utterance details components/FormDisplay";

export default function FaqFormDisplay({
  formikProps,
  pagePermissions,
  editState,
  setEditState,
}) {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12}>
              <ComponentHeader title="FAQs" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Topic</Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField name="" label="" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Questions</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Enter the variations</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                name="Questions"
                label="Variations"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Tooltip title="Delete ">
                        <IconButton style={{ color: "red", padding: "0px" }}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs />
            <Grid item>
              <Tooltip title="Add more ">
                <Button color="primary" variant="outlined">
                  <Add />
                </Button>
              </Tooltip>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <FormDisplay
        formikProps={formikProps}
        pagePermissions={pagePermissions}
        editState={editState}
        setEditState={setEditState}
      />
    </Fragment>
  );
}
