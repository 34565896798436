import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import { startLoader, apiComplete } from "../../redux/slices/loaderSlice";
import PageHeader from "../../components/PageHeader";
import {
  customFieldsDetailsObject,
  objectFromResponse,
} from "./custom field details component/customFieldsDetailsObject";
import Form from "./custom field details component/Form";
import { Loader } from "../../components";
import pages from "../../constants/pages";

export default function CustomFieldDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();

  const { pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const fieldID = params.fieldID || "";

  const [controlTypes, setControlTypes] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(customFieldsDetailsObject);

  useEffect(() => {
    dispatch(startLoader(2));
    axios({
      url: "/custom_fields/get_controls",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        dispatch(apiComplete(1));
        setControlTypes(response.data);
      }
    });

    if (!!fieldID) {
      setEditState(false);
      axios({
        url:
          "/custom_fields/get_field_details?field_id=" +
          fieldID +
          "&agent_id=" +
          activeBot.ID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          dispatch(apiComplete(2));
          setFormData(objectFromResponse(response.data));
        }
      });
    } else {
      dispatch(apiComplete(2));
    }
  }, [axios, dispatch, fieldID, activeBot]);

  return (
    <Loader>
      <PageHeader
        pageName="Custom Field Details"
        breadcrumbs={[
          {
            name: "Custom Fields",
            location: pages.customFieldList.route,
          },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        controlTypes={controlTypes}
        setControlTypes={setControlTypes}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
