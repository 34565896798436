import { Grid, Typography } from "@mui/material";
import React from "react";

import { Paper } from "../../../components";
import TemplateDetailsWithVariable from "./TemplateDetailsWithVariable";

export default function MessageSentForm({ formikProps, templates }) {
  return (
    <Paper style={{ marginBottom: "0" }}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{ color: "#808080b3" }}
        >
          Send message to the user via whatsapp or email. Fill the template
          details and select the option through which you want to send the
          message.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TemplateDetailsWithVariable
          formikProps={formikProps}
          templates={templates}
        />
      </Grid>
    </Paper>
  );
}
