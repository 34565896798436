import { Fragment, useState } from "react";
import { Message } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import { convertDate } from "../../../utils";
import { DataTable } from "../../../components";
import ViewConversation from "../view conversation components/ViewConversation";
import FilterSection from "../filter list components/FilterSection";

export default function Table({
  page,
  labels,
  setPage,
  setUsers,
  userData,
  activeBot,
  memberList,
  searchQuery,
  setSearchQuery,
}) {
  const [viewConversation, setViewConversation] = useState(false);

  const [senderID, setSenderID] = useState();

  const options = {
    filter: false,
    download: false,
    sortOrder: {
      direction: "desc",
    },

    customToolbar: () => (
      <FilterSection
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        memberList={memberList}
        activeBot={activeBot}
        labels={labels}
        page={page}
        setUsers={setUsers}
      />
    ),
    onChangePage: (currentPage) => {
      if (currentPage >= 1 && page.number < currentPage) {
        setPage((prev) => {
          return { ...prev, number: 0 + currentPage };
        });
      }
    },
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "assigned_to",
      label: "Assigned To",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "latest_message_timestamp",
      label: "Latest Message Timestamp",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "sender_id",
      label: "View Conversation",
      options: {
        customBodyRender: (value) => {
          return (
            <Button
              onClick={() => {
                setSenderID(value);
                setViewConversation(true);
              }}
              variant="outlined"
              startIcon={<Message />}
            >
              View Conversation
            </Button>
          );
        },
      },
    },
  ];

  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={userData} />

      <Grid>
        <ViewConversation
          viewConversation={viewConversation}
          setViewConversation={setViewConversation}
          senderID={senderID}
        />
      </Grid>
    </Fragment>
  );
}
