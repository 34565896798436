import { InputAdornment } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Event } from "@mui/icons-material";
import { get, set } from "lodash";

import TextField from "./TextField";

export default function DatePicker({
  name,
  label,
  disabled,
  formikProps,
  ...props
}) {
  return (
    <MobileDatePicker
      name={name}
      label={label}
      inputFormat="dd/MM/yyyy"
      disabled={disabled}
      value={get(formikProps.values, name, "") * 1000 || ""}
      onChange={(value) =>
        formikProps.setValues((prevValue) =>
          set(prevValue, name, new Date(value).getTime() / 1000 || "")
        )
      }
      minDate={props.minDate}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={Boolean(
            get(formikProps.touched, name) && get(formikProps.errors, name)
          )}
          helperText={
            get(formikProps.touched, name) && get(formikProps.errors, name)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Event />
              </InputAdornment>
            ),
          }}
          formikProps={formikProps}
        />
      )}
    />
  );
}
