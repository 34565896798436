import { Fragment } from "react";
import { Grid } from "@mui/material";

import { convertDate } from "../utils";
import TextField from "./TextField";

export default function CreatedAndUpdatedInfo({ formikProps }) {
  return (
    <Fragment>
      {formikProps.values.createdBy && (
        <Grid item xs={6}>
          <TextField
            label="Created By"
            name="createdBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>
      )}

      {formikProps.values.createdAt && (
        <Grid item xs={6}>
          <TextField
            label="Created At"
            name="createdAt"
            value={convertDate(formikProps.values.createdAt)}
            disabled
            formikProps={formikProps}
          />
        </Grid>
      )}

      {formikProps.values.updatedBy && (
        <Grid item xs={6}>
          <TextField
            label="Updated By"
            name="updatedBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>
      )}

      {formikProps.values.updatedAt && (
        <Grid item xs={6}>
          <TextField
            label="Updated At"
            name="updatedAt"
            value={convertDate(formikProps.values.updatedAt)}
            disabled
            formikProps={formikProps}
          />
        </Grid>
      )}
    </Fragment>
  );
}
