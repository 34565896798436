import React, { useState } from "react";
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";

import { TextField } from "../../../components";
import { useAlert, useAxios } from "../../../hooks";
import EntityDetails from "./Popover";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "500px" },
}));

export default function AddExample({
  intents,
  setState,
  setExpanded,
  activeBot,
  dialogState,
  setDialogState,
}) {
  const classes = useStyles();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const axios = useAxios({ baseURL: "botBuilder" });

  const [text, setText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState({
    entityName: "",
    entityValue: "",
    sentence: "",
    intentName: "",
  });

  async function selection(e, item, sentence, intent) {
    function getSelection() {
      let text = window.getSelection().toString();
      return text.trim();
    }

    var selectedText = getSelection();

    if (!!selectedText) {
      var start = sentence.indexOf(selectedText);
      var end = start + selectedText.length;
      setText(selectedText);

      setData({
        entity: "",
        value: selectedText,
        sentence: sentence,
        intentName: intent,
        start: start,
        end: end,
      });
      setAnchorEl(e.currentTarget);
    }
  }

  const handleClose = () => {
    setDialogState({
      state: false,
      data: {
        intentName: "",
        examples: [],
      },
    });
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={dialogState.state}
        onClose={handleClose}
      >
        <DialogTitle
          style={{ paddingBottom: "10px", borderBottom: "1px solid #cbcbcb" }}
        >
          Add Example
        </DialogTitle>
        <Formik
          initialValues={dialogState.data}
          enableReinitialize
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            let example = formData.examples;
            const regex = new RegExp(/\*/g);
            formData.example.text.replaceAll(regex, "");
            example.push(formData.example);
            let data = {
              intent_name: formData.intentName,
              examples: example,
            };
            axios({
              url: "/add-intent-examples/" + activeBot.ID,
              method: "POST",
              data: data,
            }).then((response) => {
              setSubmitting(false);
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: "Example added successfully.",
                  })
                );

                setState((prevVal) => ({
                  ...prevVal,
                  addExample: true,
                }));
                handleClose();
              } else {
                handleClose();
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent style={{ marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={12}>
                    <TextField
                      formikProps={dialogFormikProps}
                      label="Example"
                      multiline
                      rows={4}
                      name="example.text"
                      onMouseUp={(e) =>
                        selection(
                          e,
                          dialogFormikProps.values,
                          dialogFormikProps.values.example.text,
                          dialogFormikProps.values.intentName
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                    >
                      Add Example
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>

              <EntityDetails
                text={text}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                intents={intents}
                data={data}
                component="example"
                formikProps={dialogFormikProps}
                setData={setData}
                activeBot={activeBot}
                setState={setState}
              />
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
