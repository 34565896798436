import { Grid } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function RegisteredAdderssForm({ editState, formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Rgistered  Address"
          subtitle="You can view and edit your details here!"
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="Rgistered Address"
          name="registeredAddress"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="City"
          name="registeredCity"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="State"
          name="registeredState"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          label="Country"
          name="registeredCountry"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
