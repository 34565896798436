import { Fragment } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { validationSchema, objectFromFormData } from "./memberDetailsObject";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";

export default function Form({
  botID,
  editState,
  setEditState,
  formData,
  setFormData,
  permissionGroups,
  pagePermissions,
  userActivity,
  memberID,
  setUserActivity,
  page,
  setPage,
  loaderState,
}) {
  const { axios, alert } = useComponent();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let method = "POST";
        let URL = "/member";

        if (!!formData.memberID) {
          method = "PUT";
          URL = URL + "/" + formData.memberID;
        }

        axios({
          url: `${URL}?agent_id=${botID}`,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);

            alert.success(response.message.displayMessage);
            setEditState(false);

            if (!!formData.memberID) {
              setFormData(formData);
            } else {
              navigate(pages.memberDetails.route + response.data.member_id);
            }
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            loaderState={loaderState}
            editState={editState}
            userActivity={userActivity}
            formikProps={formikProps}
            permissionGroups={permissionGroups}
            pagePermissions={pagePermissions}
            memberID={memberID}
            setUserActivity={setUserActivity}
            page={page}
            setPage={setPage}
          />

          <ButtonFooter
            ID={formikProps.values.memberID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              () => handleMemberDelete(formikProps.values.memberID),
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );

  function handleMemberDelete(memberID) {
    axios({
      url: `/member/${memberID}?bot_id=${botID}`,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);

        navigate(pages.memberList.route);
      }
    });
  }
}
