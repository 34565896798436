import { Grid, InputAdornment, Tooltip, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";

import { ComponentHeader, Paper, TextField } from "../../../../components";

export default function WindowSettings({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Window Settings"}
          subtitle={"Customize the chat window welcome message."}
        />
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
        >
          <TextField
            label="Welcome Message"
            name="welcomeMessage"
            formikProps={formikProps}
            disabled={!editState}
            required
            style={{
              marginRight: "0px",
            }}
            inputProps={{
              maxLength: 70,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formikProps.values.welcomeMessage.length}/70
                  <Tooltip title="This will be the predefined message in the chat window.">
                    <IconButton style={{ color: "#cbcbcb" }}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              formikProps.setValues((prevVal) => ({
                ...prevVal,
                welcomeMessage: e.target.value,
              }));
            }}
            case="none"
          />
        </div>
      </Grid>
    </Paper>
  );
}
