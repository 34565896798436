import { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import { Paper, ComponentHeader } from "../../../components";
import PagePermissionsTable from "./page permissions display components/PagePermissionsTable";

export default function PagePermissionsDisplay({
  editState,
  botPermissions,
  formikProps,
  pagePermissions,
}) {
  const [expanded, setExpanded] = useState(-1);

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Bot Permissions"
          subtitle="Bot wise permissions for the members of this group"
        />
      </Grid>

      {botPermissions.length > 0 && (
        <Grid item xs={12}>
          {botPermissions.map((bot, index) => {
            const botPermissionsValue =
              formikProps.values.botPermissions.find(
                (botPermissions) => botPermissions.ID === bot.ID
              ) || [];

            return (
              <Accordion
                key={bot.ID}
                expanded={expanded === index}
                onChange={(_, isExpanded) =>
                  setExpanded(isExpanded ? index : -1)
                }
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                  <Typography variant="subtitle2">{bot.name}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <PagePermissionsTable
                    editState={editState}
                    bot={bot}
                    botPermissionsValue={botPermissionsValue}
                    setValues={formikProps.setValues}
                    pagePermissions={pagePermissions}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
}
