import React from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FileCopy, GetApp, Cancel, Sync, Summarize } from "@mui/icons-material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";

import Stats from "./Stats";
import { useAxios } from "../../../../hooks";
import scheduledDate from "./scheduledDateFormat";
import convertDate from "../../../../utils/convertDate";
import { alert } from "../../../../redux/slices/alertSlice";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "14px";
  font-weight: "500";
`;

const ColoredTypography = styled(FontSizeTypography)`
  color: "#9e9e9e !important";
`;

export default function BroadcastReport({
  broadcastReport,
  setBroadcastReport,
  loadReport,
  activeBot,
}) {
  const dispatch = useDispatch();
  const axios = useAxios();
  const classes = useStyles();

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(
      alert({
        type: "success",
        message: "Text copied to clipboard successfully!",
      })
    );
  };

  const downloadUploadedFile = () => {
    window.location.href = broadcastReport.report.broadcast_url;
  };

  const downloadFile = () => {
    axios({
      url:
        "/broadcast_sms/download_email_report/?agent_id=" +
        activeBot.ID +
        "&message_id=" +
        broadcastReport.report.message_id,
      method: "GET",
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={broadcastReport.state}
      onClose={handleClose}
      fullWidth
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">
            Broadcast Report
            <Tooltip title="Refresh Report">
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                style={{ padding: "0" }}
                onClick={() => loadReport(broadcastReport.report._id)}
              >
                <Sync />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          {!!broadcastReport.report.message_id && (
            <Stats broadcastReport={broadcastReport} />
          )}

          <Grid item xs={12} />

          {/* <Grid item xs={3}>
            <FontSizeTypography>Broadcast ID</FontSizeTypography>
          </Grid>

          <Grid item xs={2}>
            <Typography>
              {broadcastReport.report?._id?.slice(0, 15) +
                (broadcastReport.report?._id?.length > 15 ? "..." : "")}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport.report._id)}
            >
              <Tooltip title="Copy broadcast ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Template ID</FontSizeTypography>
          </Grid>

          <Grid item xs={2}>
            <ColoredTypography>
              {broadcastReport.report?.template_id?.slice(0, 15) +
                (broadcastReport.report?.template_id?.length > 15 ? "..." : "")}
            </ColoredTypography>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport.report.template_id)}
            >
              <Tooltip title="Copy template ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid> */}

          <Grid item xs={3}>
            <FontSizeTypography>Broadcast Name</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport?.report?.broadcast_name}
            </ColoredTypography>
          </Grid>
          <Grid item xs={3}>
            <FontSizeTypography>Template Name</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport.report?.template_name}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created At</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {convertDate(broadcastReport.report?.created_at)}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Scheduled At</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {scheduledDate(broadcastReport.report?.schedule_date)}
              &nbsp;|&nbsp;
              {broadcastReport.report?.scheduled_time}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Status</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport.report?.status}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created By</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport.report?.created_by}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Updated By</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {broadcastReport.report?.updated_by}
            </ColoredTypography>
          </Grid>

          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        {!!broadcastReport.report.message_id && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadFile}
              startIcon={<Summarize />}
            >
              Get Report
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setBroadcastReport({
      state: false,
      report: {},
      link: "",
    });
  }
}
