import { Grid, MenuItem } from "@mui/material";

import {
  ComponentHeader,
  Paper,
  TextField,
  CreatedAndUpdatedInfo,
} from "../../../components";
import MemberActivityDisplay from "./MemberActivityDisplay";

export default function FormDisplay({
  loaderState,
  editState,
  formikProps,
  userActivity,
  permissionGroups,
  pagePermissions,
  memberID,
  page,
  setPage,
  setUserActivity,
}) {
  return (
    <Grid alignItems="stretch" container spacing={2}>
      <Grid
        item
        xs={Boolean(memberID) ? 8 : 12}
        style={{ display: memberID && "flex" }}
      >
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Member Details"
              subtitle={"Details of the members."}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="firstName"
              label="First Name"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="lastName"
              label="Last Name"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="email"
              label="Email"
              disabled={!!formikProps.values.memberID}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              name="gender"
              label="Gender"
              disabled={!!formikProps.values.memberID}
              formikProps={formikProps}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              name="group"
              label="Member Group"
              disabled={!(editState && pagePermissions.update)}
              formikProps={formikProps}
            >
              {permissionGroups.map((permissionGroup) => (
                <MenuItem key={permissionGroup.ID} value={permissionGroup.ID}>
                  {permissionGroup.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <CreatedAndUpdatedInfo formikProps={formikProps} />
        </Paper>
      </Grid>

      {memberID && (
        <Grid item xs={4}>
          <MemberActivityDisplay
            loaderState={loaderState}
            userActivity={userActivity}
            memberID={memberID}
            setUserActivity={setUserActivity}
            page={page}
            setPage={setPage}
          />
        </Grid>
      )}
    </Grid>
  );
}
