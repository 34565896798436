import { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import styled from "styled-components";

import { ComponentHeader, Paper } from "../../../../components";
import AddWhatsAppNumberDialog from "./AddWhatsAppNumberDialog";

const TableCell = styled(MuiTableCell)`
  text-align: center;
`;

export default function WhatsAppNumbersForm({ formikProps }) {
  const [dialogState, setDialogState] = useState({
    open: false,
    initialValues: initialDialogValues,
  });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Add WhatsApp Number"
          subtitle="Add WhatsApp numbers you wish to register. You can add multiple
          numbers with different details."
          buttons={[
            <IconButton color="primary" onClick={() => handleDialogOpen()}>
              <Add />
            </IconButton>,
          ]}
        />
      </Grid>

      <AddWhatsAppNumberDialog
        dialogState={dialogState}
        handleSubmit={handleSubmit}
        handleDialogClose={handleDialogClose}
      />

      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>Display Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!!formikProps.values.whatsappNumberDetails &&
              (formikProps.values.whatsappNumberDetails.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="body2" color="red" align="center">
                      {formikProps.errors.whatsappNumberDetails}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                formikProps.values.whatsappNumberDetails.map(
                  (whatsappNumber) => (
                    <TableRow key={whatsappNumber.phoneNumber}>
                      <TableCell>{whatsappNumber.phoneNumber}</TableCell>
                      <TableCell>{whatsappNumber.displayName}</TableCell>
                      <TableCell>{whatsappNumber.categoryType}</TableCell>
                      <TableCell>
                        <Grid container justifyContent="center" spacing={2}>
                          <IconButton
                            color="primary"
                            onClick={() => handleDialogOpen(whatsappNumber)}
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            color="error"
                            onClick={() =>
                              handleDelete(whatsappNumber.phoneNumber)
                            }
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                )
              ))}
          </TableBody>
        </Table>
      </Grid>
    </Paper>
  );

  function handleDialogOpen(initialValues = initialDialogValues) {
    setDialogState({
      open: true,
      initialValues: initialValues,
    });
  }

  function handleDialogClose() {
    setDialogState({
      open: false,
      initialValues: initialDialogValues,
    });
  }

  function handleSubmit(formData) {
    const whatsappNumberDetails = formikProps.values.whatsappNumberDetails;
    const newWhatsappNumberDetails = [];
    let elementFound = false;

    for (let whatsappNumber of whatsappNumberDetails) {
      if (whatsappNumber.phoneNumber === formData.phoneNumber) {
        whatsappNumber = formData;

        elementFound = true;
      }

      newWhatsappNumberDetails.push(whatsappNumber);
    }

    if (elementFound === false) {
      newWhatsappNumberDetails.push(formData);
    }

    formikProps.setFieldValue(
      "whatsappNumberDetails",
      newWhatsappNumberDetails
    );
  }

  function handleDelete(phoneNumber) {
    formikProps.setValues(({ whatsappNumberDetails }) => ({
      whatsappNumberDetails: whatsappNumberDetails.filter(
        (whatsappNumber) => whatsappNumber.phoneNumber !== phoneNumber
      ),
    }));
  }
}

const initialDialogValues = {
  phoneNumber: "",
  displayName: "",
  displayPicture: "",
  statusText: "",
  descriptionText: "",
  addressText: "",
  categoryType: "",
  websiteURL: "",
};
