import { Grid } from "@mui/material";

import { TextField } from "../../../../components";

export default function TextDisplay({
  formikProps,
  editState,
  pagePermissions,
}) {
  return (
    <Grid item xs={12}>
      <TextField
        required
        multiline
        rows={3}
        name="payload.text"
        label="Text"
        disabled={!(editState && pagePermissions.update)}
        formikProps={formikProps}
      />
    </Grid>
  );
}
