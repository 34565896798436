import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import WebhooksTable from "./webhooks components/WebhooksTable";
import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";
import pages from "../../constants/pages";

export default function Webhooks({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [webhooks, setWebhooks] = useState([]);

  useEffect(() => {
    loader.start();

    // axios({ url: `/webhook/${activeBot.ID}` }).then((response) => {
    // if (response.status) {
    loader.apiComplete();

    // setWebhooks(response.data);
    // }
    // });
  }, [activeBot.ID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Webhooks"
        addIcon={[
          "Add New Webhook",
          pages.webhookCreate.route,
          pagePermissions.create,
        ]}
      />

      <WebhooksTable webhooks={webhooks} />
    </Loader>
  );
}
