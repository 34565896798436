import { titleCase } from "change-case-all";
import Highlighter from "react-highlight-words";
import { ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";

import EntityDetails from "./Popover";
import AddExample from "./AddExample";

export default function TrainBotTable({
  examples,
  activeBot,
  dialogState,
  setDialogState,
  intents,
  setState,
  intentName,
  setIntentName,
}) {
  const [text, setText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState({
    entityName: "",
    entityValue: "",
    sentence: "",
    intentName: "",
  });

  const [expanded, setExpanded] = React.useState(intentName);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setIntentName(panel);
  };

  function handleClick(intent) {
    handleChange(intent.intent_name);
    setDialogState({
      state: true,
      data: {
        intentName: intent.intent_name,
        examples: intent.examples,
        example: {
          text: "",
          entities: [],
        },
      },
    });
  }

  async function selection(e, item, sentence, intent) {
    function getSelection() {
      let text = window.getSelection().toString();
      return text.trim();
    }

    var selectedText = getSelection();

    if (!!selectedText) {
      var start = sentence.indexOf(selectedText);
      var end = start + selectedText.length;
      setText(selectedText);

      let entityName = item.entities.filter(
        (entity) => entity.word === selectedText.trim()
      );

      setData({
        entity: entityName.length > 0 ? entityName[0].name : "",
        value: entityName.length > 0 ? entityName[0].value : selectedText,
        sentence: sentence,
        intentName: intent,
        start: start,
        end: end,
      });
      setAnchorEl(e.currentTarget);
    }
  }

  function getSearchWords(entities) {
    var entity = [];
    if (!!entities) {
      for (let entity1 of entities) {
        entity.push(entity1.word);
      }
    }

    return entity;
  }

  return (
    <React.Fragment>
      {examples.map((intent, index) => (
        <div style={{ marginTop: "10px" }} key={index}>
          <Accordion
            expanded={expanded === intent.intent_name}
            onChange={handleChange(intent.intent_name)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h4" color="primary">
                {titleCase(intent.intent_name)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {intent.examples.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} style={{ cursor: "pointer" }}>
                      <Typography
                        onMouseUp={(e) =>
                          selection(e, item, item.text, intent.intent_name)
                        }
                        variant="h6"
                      >
                        {index + 1} - &nbsp;
                        <Highlighter
                          highlightStyle={{
                            fontWeight: "bold",
                            backgroundColor: "#3bb78f",
                            backgroundImage:
                              "linear-gradient(315deg, #3bb78f 0%, #0bab64 74%)",
                            color: "#ffffff",
                            padding: "4px",
                            borderRadius: "5px",
                          }}
                          highlightClassName="YourHighlightClass"
                          searchWords={getSearchWords(item.entities)}
                          autoEscape={true}
                          textToHighlight={item.text}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12} p="4px" textAlign="end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleClick(intent)}
                  >
                    Add Example
                  </Button>
                </Grid>
              </Grid>

              <EntityDetails
                text={text}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                intents={intents}
                data={data}
                component="table"
                setData={setData}
                activeBot={activeBot}
                setState={setState}
                handleChange={handleChange}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <AddExample
        dialogState={dialogState}
        activeBot={activeBot}
        setDialogState={setDialogState}
        intents={intents}
        setExpanded={setExpanded}
        setState={setState}
        selection={selection}
      />
    </React.Fragment>
  );
}
