import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  validationSchema,
  objectFromFormData,
} from "./memberRegistrationObject";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";

export default function Form({ formData, accessToken }) {
  const { axios, alert } = useComponent({ customAuthToken: accessToken });
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/member",
          method: "PUT",
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          setSubmitting(false);

          if (response.status) {
            alert.success(response.message.displayMessage);

            navigate(pages.signIn.route);
          }
        });
      }}
    >
      {(formikProps) => <FormDisplay formikProps={formikProps} />}
    </Formik>
  );
}
