import styled from "styled-components";
import { Grid, Typography, Paper as MuiPaper, Divider } from "@mui/material";

const Paper = styled(MuiPaper)`
  height: 150px;
  display: flex;
  border-radius: 0;
  flex-direction: column;
  padding: 15px 0;
`;

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;

export default function TotalMessage({ insightData }) {
  return (
    <Paper>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} style={{ textAlign: "center", alignSelf: "center" }}>
          <ColoredTypography variant="h3">
            {insightData.total}
          </ColoredTypography>
          <Typography variant="h6">Total Messages</Typography>
        </Grid>

        <Grid item xs={3} style={{ textAlign: "center" }}>
          <ColoredTypography variant="h6">{insightData.user}</ColoredTypography>
          <Typography variant="caption">User Messages</Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" orientation="vertical" />
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <ColoredTypography variant="h6">
            {insightData.member}
          </ColoredTypography>
          <Typography variant="caption">Agent Messages</Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" orientation="vertical" />
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <ColoredTypography variant="h6">{insightData.bot}</ColoredTypography>
          <Typography variant="caption">Bot Messages</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
