import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";

import pages from "../../../constants/pages";
import { Paper } from "../../../components";
import { useAxios, useComponent } from "../../../hooks";
import DeleteDialog from "../template list component/DeleteDailogue";

export default function CreateButton({ formikProps, editState, setEditState }) {
  const axios = useAxios();
  const { alert } = useComponent();
  const navigation = useNavigate();

  const [dialogState, setDialogState] = useState({
    state: false,
    id: formikProps.values.ID,
  });

  const handleDelete = () => {
    axios({
      url: "/wa/template/delete/" + dialogState.id,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        navigation(pages.templateList.route);
      }
    });
  };

  return (
    <Paper>
      {Boolean(formikProps.values.ID) && (
        <Fragment>
          <Grid item>
            <Button variant="outlined" onClick={() => navigation(-1)}>
              Back
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              style={{ background: "#d32f2f", color: "#fff" }}
              onClick={() =>
                setDialogState({
                  state: true,
                  id: formikProps.values.ID,
                })
              }
            >
              <DeleteOutlined fontSize="small" /> &nbsp; Delete Template
            </Button>
          </Grid>
        </Fragment>
      )}
      <Grid item xs />

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </Paper>
  );
}
