import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

import { Paper } from "../../../../components";

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
`;

export default function Insights({ formikProps, editState, setEditState }) {
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper style={{ backgroundColor: "rgb(0,122,255,0.03)" }}>
          <Grid item xs={12}>
            <ContainerItem>
              <Typography
                variant="h3"
                color="primary"
                sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
              >
                {formikProps?.values?.regex?.length || "0"}
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
              >
                Total Regex
              </Typography>
            </ContainerItem>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper style={{ backgroundColor: "rgb(0,122,255,0.03)" }}>
          <Grid item xs={12}>
            <ContainerItem>
              <Typography
                variant="h3"
                color="primary"
                sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
              >
                {formikProps?.values?.synonyms?.length || "0"}
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
              >
                Total Synonyms
              </Typography>
            </ContainerItem>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper style={{ backgroundColor: "rgb(0,122,255,0.03)" }}>
          <Grid item xs={12}>
            <ContainerItem>
              <Typography
                variant="h3"
                color="primary"
                sx={{ fontSize: { lg: 20, md: 23, sm: 23, xs: 20 } }}
              >
                {formikProps?.values?.lookups?.length || "0"}
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
              >
                Total Lookups
              </Typography>
            </ContainerItem>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
