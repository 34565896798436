import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";

import { useComponent } from "../../hooks";
import Form from "./faq details components/Form";
import { PageHeader, Loader } from "../../components";
import { formDetailObject } from "./faq details components/formDetailObject";

export default function FaqDetails({ pageID }) {
  const { faqID } = useParams();
  const [editState, setEditState] = useState(!faqID);
  const [formData, setFormData] = useState(formDetailObject);
  const { loader, pagePermissions } = useComponent({ pageID });

  return (
    <Fragment>
      <PageHeader
        pageName="Faq Detail"
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
      />
    </Fragment>
  );
}
