import { Fragment } from "react";
import styled from "styled-components";
import { GrCircleInformation } from "react-icons/gr";
import {
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Paper as MuiPaper,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const Paper = styled(MuiPaper)`
  height: 200px;
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

export default function CustomerFeedback() {
  return (
    <Paper>
      <Fragment>
        <Typography
          variant="h5"
          sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
        >
          Average Customer Feedback
          <Tooltip
            placement="top-start"
            title="Information provided by customers about their experience with the chatbot."
          >
            <IconButton>
              <GrCircleInformation size={18} />
            </IconButton>
          </Tooltip>
        </Typography>
        <StarIcon
          style={{
            color: "yellow",
            verticalAlign: "text-top",
            fontSize: "60px",
            marginTop: "10px",
          }}
        ></StarIcon>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "500",
                }}
                sx={{ fontSize: { lg: 20, md: 20, sm: 20, xs: 20 } }}
              >
                4.7/5{" "}
              </Typography>

              <Typography
                variant="h6"
                style={{
                  fontWeight: "400",
                }}
                sx={{ fontSize: { lg: 14, md: 14, sm: 12, xs: 12 } }}
              >
                based on 120 feedbacks
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </Paper>
  );
}
