import React from "react";
import { titleCase } from "change-case-all";
import { AddCircleOutlined, DeleteOutlined } from "@mui/icons-material";
import { Grid, MenuItem, IconButton, Tooltip } from "@mui/material";

import { TextField } from "../../../components";

export default function FilterDataForm({
  memberList,
  dialogFormikProps,
  filterColumns,
}) {
  const handleRemoveFilters = (index) => {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.filters?.splice(index, 1),
    }));
  };

  function handleChange(e, index) {
    var value = e.target.value;

    var column = filterColumns.find((item) => item.name === value);

    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      filters: setFilters(prevVal.filters),
    }));

    function setFilters(filters) {
      const columns = [...filters];
      columns[index] = {
        key: value,
        dataType: column.dataType,
        relation: columns[index].relation,
        value: columns[index].value,
        operator: columns[index].operator,
        ...(column.dataType === "list" && { options: column.options }),
      };

      return columns;
    }
  }
  return (
    <React.Fragment>
      {dialogFormikProps?.values?.filters.map((_, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <Grid item xs={11}>
              <TextField
                select
                name={"filters[" + index + "].relation"}
                label="Select Relation"
                formikProps={dialogFormikProps}
              >
                <MenuItem value="and">And</MenuItem>
                <MenuItem value="or">Or</MenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={4}>
            <TextField
              select
              name={"filters[" + index + "].key"}
              label="Select Fields"
              formikProps={dialogFormikProps}
              onChange={(e) => handleChange(e, index)}
            >
              {filterColumns.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  {titleCase(item.label)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {dialogFormikProps?.values?.filters[index].key === "created_by" ||
          dialogFormikProps?.values?.filters[index].key === "updated_by" ? (
            <Grid item xs={4}>
              <TextField
                select
                name={"filters[" + index + "].value"}
                label="Value"
                formikProps={dialogFormikProps}
              >
                {memberList.map((item) => (
                  <MenuItem value={item.id}>{item.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <React.Fragment>
              {dialogFormikProps?.values?.filters[index].dataType === "list" ? (
                <Grid item xs={4}>
                  <TextField
                    select
                    name={"filters[" + index + "].value"}
                    label="Value"
                    formikProps={dialogFormikProps}
                  >
                    {dialogFormikProps?.values?.filters[index].options.map(
                      (option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
              ) : (
                <React.Fragment>
                  {dialogFormikProps?.values?.filters[index].dataType ===
                  "boolean" ? (
                    <Grid item xs={4}>
                      <TextField
                        name={"filters[" + index + "].value"}
                        label="Value"
                        select
                        formikProps={dialogFormikProps}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <TextField
                        name={"filters[" + index + "].value"}
                        label="Value"
                        formikProps={dialogFormikProps}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {dialogFormikProps?.values?.filters[index].dataType === "string" && (
            <Grid item xs={3}>
              <TextField
                select
                name={"filters[" + index + "].operator"}
                label="Operator"
                formikProps={dialogFormikProps}
              >
                <MenuItem value="eq">Equal To</MenuItem>
                <MenuItem value="contains">Contains</MenuItem>
              </TextField>
            </Grid>
          )}

          {dialogFormikProps?.values?.filters[index].dataType === "list" && (
            <Grid item xs={3}>
              <TextField
                select
                name={"filters[" + index + "].operator"}
                label="Operator"
                formikProps={dialogFormikProps}
              >
                <MenuItem value="eq">Equal To</MenuItem>
                <MenuItem value="contains">Contains</MenuItem>
              </TextField>
            </Grid>
          )}

          {dialogFormikProps?.values?.filters[index].dataType === "date" && (
            <Grid item xs={3}>
              <TextField
                select
                name={"filters[" + index + "].operator"}
                label="Operator"
                formikProps={dialogFormikProps}
              >
                <MenuItem value="gt">Greater Than</MenuItem>
                <MenuItem value="lt">Less Than</MenuItem>
                <MenuItem value="eq">Equal To</MenuItem>
              </TextField>
            </Grid>
          )}

          {dialogFormikProps?.values?.filters[index].dataType === "boolean" && (
            <Grid item xs={3}>
              <TextField
                select
                name={"filters[" + index + "].operator"}
                label="Operator"
                formikProps={dialogFormikProps}
              >
                <MenuItem value="eq">Equal To</MenuItem>
              </TextField>
            </Grid>
          )}

          {index === 0 && dialogFormikProps?.values?.filters.length < 4 && (
            <Grid style={{ alignSelf: "center", textAlign: "end" }} item xs={1}>
              <Tooltip title="Add Filter">
                <IconButton onClick={addFilters} color="primary">
                  <AddCircleOutlined style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {index !== 0 && (
            <Grid style={{ alignSelf: "center", textAlign: "end" }} item xs={1}>
              <Tooltip title="Delete Filter">
                <IconButton onClick={() => handleRemoveFilters(index)}>
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#ff0000" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );

  function addFilters() {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      filters: setFilters(prevVal.filters),
    }));

    function setFilters(filters) {
      filters.push({
        key: "",
        value: "",
        operator: "",
        relation: "",
      });
      return filters;
    }
  }
}
