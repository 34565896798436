import * as Yup from "yup";

export const memberDetailsObject = {
  memberID: "",
  firstName: "",
  lastName: "",
  gender: "",
  group: "",
  email: "",
  activeStatus: false,
  createAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: "",
};

export function objectFromFormData(formData) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: formData.email,
    group_id: formData.group,
    gender: formData.gender,
    is_active: formData.activeStatus,
  };
}

export function objectFromResponseData(responseData) {
  return {
    memberID: responseData._id,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    gender: responseData.gender,
    group: responseData.group_id,
    email: responseData.email,
    activeStatus: responseData.is_active,
    createAt: responseData.created_at,
    createdBy: responseData.created_by,
    updatedAt: responseData.updated_at,
    updatedBy: responseData.updated_by,
  };
}
export const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  gender: Yup.string().required(),
  // memberGroup: Yup.string().required(),
});
