import { Fragment } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import pages from "../../../constants/pages";
import { useComponent } from "../../../hooks";
import { TextField } from "../../../components";

export default function AddPermissionGroupDialog({
  dialogState,
  handleDialogClose,
}) {
  const { axios, alert } = useComponent();
  const navigate = useNavigate();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={dialogState}
      onClose={handleDialogClose}
    >
      <DialogTitle>Add New Group</DialogTitle>

      <Formik
        initialValues={{ groupName: "" }}
        validationSchema={Yup.object({
          groupName: Yup.string().required("Please enter a Group Name"),
        })}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/permission/create/group",
            method: "POST",
            data: {
              name: formData.groupName,
              is_active: true,
            },
            disableRedirect: true,
          }).then((response) => {
            setSubmitting(false);

            if (response.status) {
              alert.success(response.message.displayMessage);
              handleDialogClose();
              navigate(
                pages.permissionsGroupDetails.route +
                  response.data.member_group_id
              );
            } else {
              alert.error(response.message.displayMessage);
            }
          });
        }}
      >
        {(formikProps) => (
          <Fragment>
            <DialogContent dividers style={{ padding: "20px 24px" }}>
              <TextField
                name="groupName"
                label="Group Name"
                formikProps={formikProps}
              />
            </DialogContent>

            <DialogActions style={{ padding: "15px 24px" }}>
              <Button
                variant="contained"
                disabled={!formikProps.isValid}
                onClick={formikProps.handleSubmit}
              >
                Submit
              </Button>

              <Button variant="outlined" onClick={handleDialogClose}>
                Cancel
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Dialog>
  );
}
