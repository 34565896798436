import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MembersTable from "./members components/MembersTable";
import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";
import pages from "../../constants/pages";

export default function Members({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [members, setMembers] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: `/member/list?bot_id=${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        setMembers(response.data);

        loader.apiComplete();
      }
    });
  }, [activeBot.ID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Members"
        addIcon={[
          "Add New Member",
          pages.memberCreate.route,
          pagePermissions.create,
        ]}
      />

      <MembersTable members={members} />
    </Loader>
  );
}
