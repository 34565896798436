import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";

import DataTable from "../../../../components/DataTable";
import { useAxios, useComponent } from "../../../../hooks";
import customFieldDate from "../../../../utils/customFieldDate";
import pages from "../../../../constants/pages";
import { Add } from "@mui/icons-material";

export default function UserListTable({
  column,
  allUsers,
  formikProps,
  handleClose,
  setAddUsers,
  activeBot,
  setUserList,
  setAllUsers,
}) {
  const axios = useAxios();
  const navigation = useNavigate();
  const { alert } = useComponent();

  function addLeadsToGroup(rows) {
    const leads = {
      sender_ids: [],
      is_active: true,
    };
    rows.data.map((item) =>
      leads.sender_ids.push(allUsers[item.dataIndex].sender_id)
    );

    axios({
      url: "/broadcast/create/user-group/" + formikProps.values.ID,
      method: "POST",
      data: leads,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setAddUsers(true);

        alert.success("Leads added successfully!");
        handleClose();
      }
    });
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];
  for (let [index, field] of column.entries()) {
    var iterator = true;

    if (index > 2) {
      iterator = false;
    }
    if (field.control_id !== "61e13a0bf2c60729b06385f3") {
      if (
        field.control_id === "61dd2dfa1fb38be82e077ed8" &&
        Boolean(field.multi_Select)
      ) {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value
                ? value.length > 1
                  ? value[0].label + ", " + value[1].label
                  : value[0].label
                : "Not Available";
            },
          },
        });
      } else if (field.control_id === "61dd2dfa1fb38be82e077ed9") {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value ? customFieldDate(value) : "Not Available";
            },
          },
        });
      } else if (
        field.control_id === "61dd2dfa1fb38be82e077ed8" &&
        !Boolean(field.multi_Select)
      ) {
        columns.push({
          name: field.name,
          label: field.label,
          options: {
            display: iterator,
            customBodyRender: (value) => {
              return value ? value[0].label : "Not Available";
            },
          },
        });
      }
    } else if (field.control_id === "61dd2dfa1fb38be82e077ed7") {
      columns.push({
        name: field.name,
        label: field.label,
        options: {
          display: iterator,
          customBodyRender: (value) => {
            return value ? value : "Not Available";
          },
        },
      });
    } else {
      columns.push({
        name: field.name,
        label: field.label,
        options: {
          display: iterator,
          customBodyRender: (value) => {
            return value ? (value === true ? "Yes" : "No") : "Not Available";
          },
        },
      });
    }
  }
  function handleCellClick(_, cellMetaData) {
    navigation(pages.leadDetails.route + allUsers[cellMetaData.dataIndex]._id);
  }

  const options = {
    filter: false,
    selectableRows: "multiple",
    selectToolbarPlacement: "above",
    responsive: "standard",
    onCellClick: handleCellClick,
    rowsPerPage: 24,
    download: false,
    print: false,
    pagination: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <React.Fragment>
        <Tooltip title="Add leads to group.">
          <IconButton
            onClick={() => addLeadsToGroup(selectedRows)}
            disableRipple
            color="primary"
          >
            <Add />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    searchAlwaysOpen: true,

    onSearchChange(searchText) {
      if (searchText) {
        axios({
          url:
            "/leads/get_leads?agent_id=" +
            activeBot.ID +
            "&skip=0&limit=254553&text=" +
            searchText,
          method: "POST",
          data: {},
        }).then((response) => {
          if (response.status) {
            setAllUsers(response.data.paginatedResults);
          }
        });
      } else {
        axios({
          url:
            "/leads/get_leads?agent_id=" +
            activeBot.ID +
            "&skip=0&limit=254553",
          method: "POST",
          data: {},
        }).then((response) => {
          if (response.status) {
            setAllUsers(response.data.paginatedResults);
          }
        });
      }
    },

    onChangePage(currentPage) {
      var start;
      if (currentPage === 0) {
        start = 0;
      } else {
        start = currentPage;
      }
      axios({
        url:
          "/leads/get_leads?agent_id=" +
          activeBot.ID +
          "&skip=" +
          start +
          "&limit=25",
        method: "POST",
        data: {},
      }).then((response) => {
        if (response.status) {
          setAllUsers(response.data.paginatedResults);
        }
      });
    },
  };

  return <DataTable columns={columns} options={options} data={allUsers} />;
}
