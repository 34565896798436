import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Form from "./broadcast details components/Form";
import { useAxios, useComponent } from "../../hooks";
import { PageHeader, Loader } from "../../components";
import {
  broadcastDetailsObject,
  objectFromResponse,
} from "./broadcast details components/broadcastDetailsObject";
import pages from "../../constants/pages";
import { templateDataObject } from "./broadcast details components/templateDataObject";

export default function BroadcastDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();
  const { loader } = useComponent({ pageID });

  const broadcastID = params.broadcastID || "";
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [fields, setFields] = useState([]);

  const [template, setTemplate] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [scheduledTime, setScheduledTime] = useState([]);
  const [previewData, setPreviewData] = useState(templateDataObject);
  const [formData, setFormData] = useState(broadcastDetailsObject);

  useEffect(() => {
    loader.start(6);
    if (Boolean(broadcastID)) {
      axios({
        url:
          "/broadcast/get/campaign/" + activeBot.ID + "?sbcid=" + broadcastID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponse(response.data));
          loader.apiComplete();

          axios({
            url:
              "/broadcast/time?schedule_date=" + response.data.scheduled_date,
            method: "GET",
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setScheduledTime(response.data);
              loader.apiComplete();
            }
          });

          axios({
            url:
              "/wa/template/variable/" +
              activeBot.ID +
              "/" +
              response.data.template_id,
            method: "GET",
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setTemplateData(response.data);
              loader.apiComplete();
            }
          });
        }
      });
    } else {
      loader.apiComplete();
      loader.apiComplete();
      loader.apiComplete();
    }

    axios({
      url: "/custom_fields/get_fields_for_agent?agent_id=" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      setFields(response.data);
      loader.apiComplete();
    });

    axios({
      url: "/wa/template/" + activeBot.ID + "?is_active=1&verified_status=3",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setTemplate(response.data.temp_obj);
        loader.apiComplete();
      }
    });

    axios({
      url: "/broadcast/get/group/" + activeBot.ID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setGroupList(response.data);
        loader.apiComplete();
      }
    });
  }, [broadcastID, axios, activeBot, dispatch, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Broadcast Details"
        breadcrumbs={[
          {
            name: "Broadcast",
            location: pages.broadcastList.route,
          },
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        template={template}
        groupList={groupList}
        scheduledTime={scheduledTime}
        templateData={templateData}
        setTemplateData={setTemplateData}
        setScheduledTime={setScheduledTime}
        activeBot={activeBot}
        previewData={previewData}
        setPreviewData={setPreviewData}
        fields={fields}
      />
    </Loader>
  );
}
