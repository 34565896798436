import { Fragment, useState, useEffect } from "react";
import {
  Paper as MuiPaper,
  Typography,
  LinearProgress,
  Grid,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import styled from "styled-components";
import { Event } from "@mui/icons-material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useComponent } from "../../../hooks";

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function TemplateMessagesPerMonth({ startDate, botID }) {
  const { axios } = useComponent();

  const [value, setValue] = useState([
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 30)),
  ]);
  const [loading, setLoading] = useState(true);
  const [templateInsights, setTemplateInsights] = useState({});

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/templates/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        start_date: new Date().getTime() / 1000,
        end_date:
          new Date(new Date().setDate(new Date().getDate() + 30)).getTime() /
          1000,
      },
    }).then((response) => {
      if (response.status) {
        setTemplateInsights(response.data);
        setLoading(false);
      }
    });
  }, [axios, botID]);

  function handleTemplateMessageChange(dates) {
    if (dates.length > 1) {
      axios({
        url: `/insights/templates/${botID}`,
        disableError: true,
        disableRedirect: true,
        params: {
          start_date: dates[0].getTime() / 1000,
          end_date: dates[1].getTime() / 1000,
        },
      }).then((response) => {
        if (response.status) {
          setTemplateInsights(response.data);
          setLoading(false);
        }
      });
    }
  }

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Grid container spacing={0} style={{ padding: "0 10px" }}>
            <Grid item xs>
              <Typography
                variant="h5"
                sx={{ fontSize: { lg: 17, md: 22, sm: 20, xs: 15 } }}
              >
                Template Message (Selected Month)
              </Typography>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={value}
                  okText={"OK"}
                  cancelText={"Cancel"}
                  onChange={(newValue) => {
                    setValue(newValue);
                    handleTemplateMessageChange(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <Fragment>
                      <TextField
                        {...startProps}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Event />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField
                        {...endProps}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Event />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Container>
            <ContainerItem>
              <Typography variant="h3" color="primary">
                {templateInsights.sent}
              </Typography>

              <Typography variant="h6">Total Messages Sent</Typography>
            </ContainerItem>

            <ContainerItem>
              <Typography variant="h3" color="primary">
                {templateInsights.failed}
              </Typography>

              <Typography variant="h6">Total Messages Failed</Typography>
            </ContainerItem>

            <ContainerItem>
              <Typography variant="h3" color="primary">
                {templateInsights.delivered}
              </Typography>

              <Typography variant="h6">Total Messages Delivered</Typography>
            </ContainerItem>
          </Container>
        </Fragment>
      )}
    </Paper>
  );
}
