import { Fragment } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { TextField } from "../../../../components";

export default function QuickReplyDisplay({
  formikProps,
  editState,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField
          rows={3}
          required
          multiline
          name={`payload.text`}
          label="Text"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      {formikProps.values.payload.buttons.map((_, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">
              Quick Reply Button{index + 1}
            </Typography>

            <IconButton
              color="error"
              size="small"
              onClick={() => deleteIndex(index)}
            >
              <Delete />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              name={`payload.buttons[${index}].title`}
              label="Title"
              disabled={!(editState && pagePermissions.update)}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              name={`payload.buttons[${index}].payload`}
              label="Payload"
              disabled={!(editState && pagePermissions.update)}
              formikProps={formikProps}
            />
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );

  function deleteIndex(deleteIndex) {
    formikProps.setFieldValue(
      "payload",
      formikProps.values.payload.filter((_, index) => index !== deleteIndex)
    );
  }
}
