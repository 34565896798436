import { Sync } from "@mui/icons-material";
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

import { convertDate } from "../../../utils";
import { useAxios, useComponent } from "../../../hooks";
import { ComponentHeader, Paper } from "../../../components";
import { useCallback } from "react";

const Wrapper = styled(Grid)`
  overflow: scroll;
  height: calc(100vh - 330px);
`;

const EmptyWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function MemberActivityDisplay({
  setUserActivity,
  userActivity,
  memberID,
  page,
  setPage,
  loaderState,
}) {
  const axios = useAxios();
  const { alert } = useComponent();

  const nextPage = useCallback(() => {
    if (!loaderState && page.number + page.size + 1 < userActivity.totalCount) {
      setPage((prevValue) => ({
        ...prevValue,
        number: prevValue.number + page.size + 1,
      }));
    }
  }, [page.number, page.size, userActivity.totalCount, loaderState, setPage]);

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Member Activity"
          subtitle={"Activity of the member."}
          buttons={[<RefreshActivity />]}
        />
      </Grid>
      {userActivity.length === 0 && (
        <Grid item xs={12}>
          <EmptyWrapper>
            <Typography variant="h5">No activity yet!</Typography>
          </EmptyWrapper>
        </Grid>
      )}

      <Wrapper item xs={12}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          onYReachEnd={nextPage}
        >
          {userActivity.activities.map((item, index) => (
            <Timeline key={index}>
              <TimelineItem>
                <TimelineOppositeContent>
                  {convertDate(item.timestamp)}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <Typography
                    variant="subtitle2"
                    style={{ color: "rgb(38,77,145)", fontSize: "15px" }}
                  >
                    {item.module.replace(/_/g, " ")}
                  </Typography>
                  <Typography variant="body2">{item.operation}</Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ))}
          {userActivity.activities.length === 0 && (
            <Typography
              variant="subtitle2"
              style={{
                color: "rgb(38,77,145)",
                fontSize: "18px",
                textAlign: "center",
                marginTop: "120px",
              }}
            >
              No user activity for this member yet..
            </Typography>
          )}

          {loaderState && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </PerfectScrollbar>
      </Wrapper>
    </Paper>
  );

  // function nextPage() {
  //   if (page.number + page.size + 1 < userActivity.totalCount) {
  //     setPage((prevValue) => ({
  //       ...prevValue,
  //       number: prevValue.number + page.size + 1,
  //     }));
  //   }
  // }

  function RefreshActivity() {
    return (
      <Button
        variant="outlined"
        startIcon={<Sync />}
        onClick={() => {
          axios({
            url: "/member/member-activity/" + memberID,
          }).then((response) => {
            if (response.status) {
              alert.success("Member activity Refreshed!");
              setUserActivity(response.data);
            }
          });
        }}
      >
        Refresh
      </Button>
    );
  }
}
