import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import pages from "../../constants/pages";
import {
  memberDetailsObject,
  objectFromResponseData,
} from "./member details components/memberDetailsObject";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./member details components/Form";

export default function MemberDetails({ pageID }) {
  const { memberID } = useParams();
  const { axios, loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);
  const [loaderState, setLoaderState] = useState(true);
  const [page, setPage] = useState({
    number: 0,
    size: 25,
  });
  const [userActivity, setUserActivity] = useState({
    activities: [],
    totalCount: 0,
  });
  const [editState, setEditState] = useState(!memberID);
  const [formData, setFormData] = useState(memberDetailsObject);
  const [permissionGroups, setPermissionsGroups] = useState([]);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/permission/members/groups",
    }).then((response) => {
      if (response.status) {
        setPermissionsGroups(
          response.data.map((member) => ({
            ID: member._id,
            name: member.name,
          }))
        );

        loader.apiComplete();
      }
    });

    if (!!memberID) {
      axios({
        url: `/member/${memberID}?bot_id=${activeBot.ID}`,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));

          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete(1);
    }
  }, [activeBot.ID, axios, loader, memberID]);

  useEffect(() => {
    setLoaderState(true);

    if (!!memberID) {
      axios({
        url:
          "/member/member-activity/" +
          memberID +
          "?skip=" +
          page.number +
          "&limit=" +
          page.size,
      }).then((response) => {
        if (response.status) {
          setUserActivity((prevVal) => ({
            ...prevVal,
            activities: activity(prevVal.activities),
            totalCount: response.data.total_count,
          }));

          function activity(act) {
            let acts = [...act];
            for (let activity of response.data.activities) {
              acts.push({
                timestamp: activity.timestamp,
                operation: activity.operation,
                module: activity.module,
              });
            }

            return acts;
          }
          setLoaderState(false);
        }
        setLoaderState(false);
      });
    }
  }, [axios, memberID, page.number, page.size]);

  return (
    <Loader>
      <PageHeader
        pageName="Member Details"
        breadcrumbs={[{ location: pages.memberList.route, name: "Members" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        loaderState={loaderState}
        botID={activeBot.ID}
        userActivity={userActivity}
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        memberID={memberID}
        permissionGroups={permissionGroups}
        pagePermissions={pagePermissions}
        setUserActivity={setUserActivity}
        page={page}
        setPage={setPage}
      />
    </Loader>
  );
}
