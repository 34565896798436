import { Grid, MenuItem } from "@mui/material";

import {
  ComponentHeader,
  Paper,
  TextField,
  CreatedAndUpdatedInfo,
  ColorPicker,
} from "../../../components";

export default function FormDisplay({
  editState,
  formikProps,
  pagePermissions,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader title="Label Details" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Label Title"
          name="title"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Active Status"
          name="activeStatus"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Label Description"
          name="description"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <ColorPicker
          label="Label Color"
          name="color"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <CreatedAndUpdatedInfo formikProps={formikProps} />
    </Paper>
  );
}
