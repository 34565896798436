import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { PageHeader, Paper } from "./../../components";
import Form from "./livechat config details component/Form";
import {
  liveChatConfigObject,
  objectFromResponse,
} from "./livechat config details component/LiveChatConfigObject";
import { useAxios, useComponent } from "./../../hooks";

export default function LiveChatConfigDetails({ pageID }) {
  const axios = useAxios();
  const { loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState(liveChatConfigObject);
  const [editState, setEditState] = useState(true);

  useEffect(() => {
    loader.start();
    axios({
      url: "/livechat/config/" + activeBot.ID,
      method: "GET",
    }).then((respone) => {
      if (respone.status) {
        setFormData(objectFromResponse(respone.data));
        setEditState(false);
        loader.apiComplete();
      }
    });
  }, []);

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12} display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => setEditState((prevValue) => !prevValue)}
          >
            Edit
          </Button>
        </Grid>
      </Paper>
      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        activeBot={activeBot}
      />
    </Fragment>
  );
}
