import React from "react";
import { makeStyles } from "@mui/styles";
import { Dialog as MUIDialog, Grid, Typography, Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function RedirectDialog({ popUp, redirectURL, setPopUp }) {
  const classes = useStyles();

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <Grid item container p={5}>
          <Grid item xs={9}>
            <Typography variant="h5">
              {" "}
              Start WhatsApp Onboarding Journey By Click Button
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              href={redirectURL}
              // target="_blank"
            >
              Go To Onboarding Page
            </Button>
          </Grid>
        </Grid>
      </MUIDialog>
    </React.Fragment>
  );
}
