import React from "react";
import { Grid, Switch, FormControlLabel, Tooltip } from "@mui/material";

import { Paper, ComponentHeader, TextField } from "../../../components";

export default function OptionalFields({ formikProps, editState }) {
  const handleChange = (event) => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Optional Fields"
          subtitle="These are the optional features by default they all will be false. You
          can enable them on the component."
        />
      </Grid>

      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Tooltip title="Enable this to add this field as mandatory.">
              <Switch
                checked={
                  formikProps.values.mandatory && formikProps.values.editable
                }
                disabled={!editState && formikProps.values.editable}
                onChange={handleChange}
                name="mandatory"
              />
            </Tooltip>
          }
          label="Mandatory"
        />
      </Grid>

      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Tooltip title="Enable this to add this field as non-editable.">
              <Switch
                checked={formikProps.values.editable}
                onChange={handleChange}
                disabled={!editState}
                name="editable"
              />
            </Tooltip>
          }
          label="Editable"
        />
      </Grid>
      <Grid item xs={6} />

      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              formikProps={formikProps}
              name="createdAt"
              label="Created At"
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              formikProps={formikProps}
              name="updatedAt"
              label="Updated At"
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              formikProps={formikProps}
              name="createdBy"
              label="Created By"
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              formikProps={formikProps}
              name="updatedBy"
              label="Updated By"
              disabled
            />
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );
}
