import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Avatar,
  InputAdornment,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { ColorLens, Info, CloudUpload } from "@mui/icons-material";

import "../UI Components/whatsapp.css";
import CropImage from "../crop image components/CropImage";
import {
  Paper,
  TextField,
  TextFieldWithIcon,
  ComponentHeader,
} from "../../../../components";

export default function Header({ formikProps, editState, image, setImage }) {
  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        left: "40%",
      },
    },
  };

  const [openModal, setOpenModal] = useState(false);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(false);

  const handleChangePhoto = (photo) => {
    const file = photo.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setImage((prev) => ({
          ...prev,
          imageResult: reader.result,
          imageTarget: photo.target.files[0],
        }));
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }

    setOpenModal(true);
  };

  const handleChangeAvatar = (e) => {
    var src = e.target.currentSrc;
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      pictureCustom: src,
    }));
  };

  let ClickBackGroundLens = (handler) => {
    let backgroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!backgroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return backgroundNode;
  };

  let backgroundNode = ClickBackGroundLens(() => {
    setHeaderBackgroundColor(false);
  });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Header Settings"}
          subtitle={
            "Customize bot header by adding custom colors, business avatar,caption etc."
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontSize: "0.9rem" }} variant="h5">
          Business Display
        </Typography>
        <Typography
          style={{
            color: "#808080",
          }}
          variant="body2"
        >
          Image that represents your business or chatbot use-case.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Avatar
            style={{
              margin: "10px",
              height: "55px",
              width: "55px",
              cursor: "pointer",
            }}
            onClick={handleChangeAvatar}
            src={formikProps.values?.pictureCustom}
          ></Avatar>
          <input
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            disabled={!editState}
            onChange={(event) => handleChangePhoto(event)}
          />
          <label style={{ marginTop: "10px" }} htmlFor="raised-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ margin: "10px" }}
              disabled={!editState}
            >
              <CloudUpload />
              &nbsp; Upload
            </Button>
          </label>
        </div>

        {image.imageTarget !== "" && (
          <CropImage
            openModal={openModal}
            setOpenModal={setOpenModal}
            image={image}
            formikProps={formikProps}
            setImage={setImage}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Title"
          case="none"
          name="TitleName"
          required
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values?.TitleName.length}/20
                <Tooltip title="The main title of your bot. This could be the name of your business or any virtual avatar.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Caption(Optional)"
          name="nameCaptionReplyTime"
          formikProps={formikProps}
          disabled={!editState}
          style={{
            paddingRight: "0px",
          }}
          inputProps={{
            maxLength: 35,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values?.nameCaptionReplyTime.length}/35
                <Tooltip title="With caption you can provide extra information about your chatbot or use-case">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              nameCaptionReplyTime: e.target.value,
            }));
          }}
          case="none"
        />
      </Grid>

      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={backgroundNode}
        >
          {headerBackgroundColor && (
            <SketchPicker
              styles={pickerStyle}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  headerBackgroundColor: hex,
                }));
              }}
              color={formikProps.values?.headerBackgroundColor}
            />
          )}

          <TextFieldWithIcon
            label="Background Colour"
            case="none"
            name="headerBackgroundColor"
            formikProps={formikProps}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values?.headerBackgroundColor,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setHeaderBackgroundColor(!headerBackgroundColor)
                    }
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}
