import React from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  Dialog,
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Cancel, Summarize } from "@mui/icons-material";

import { Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "18px" !important;
  font-weight: "700" !important;
`;

export default function RequestDetails({
  requestDetails,
  requestDetailsDailog,
  setRequestDetailsDailog,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={requestDetailsDailog}
      onClose={() => setRequestDetailsDailog(false)}
      fullWidth
    >
      <Grid container spacing={0} style={{ borderBottom: "1px solid #cbcbcb" }}>
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h4">Request Logs Details</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={() => setRequestDetailsDailog(false)}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} />

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Request ID
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{requestDetails[0]?._id}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0px 12px" }}
              //   onClick={() => copyText(requestDetails.data.request_id)}
            ></IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Request Name
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{requestDetails[0]?.report_name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Created At
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography textAlign={"start"}>
              {convertDate(requestDetails[0]?.created_at)}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Created By
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{requestDetails[0]?.requested_by}</Typography>
          </Grid>

          {/* <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              API Name
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{}</Typography>
          </Grid> */}

          {/* <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              API Status
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            status
            <Typography>{}</Typography>
          </Grid> */}

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              From
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {convertDate(requestDetails[0]?.report_start_timestamp)}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              To
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              {convertDate(requestDetails[0]?.report_end_timestamp)}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Selected Members
            </FontSizeTypography>
          </Grid>
          <Grid item xs={4}>
            {requestDetails[0]?.member_chat_report?.map((items, index) => {
              if (index > 3) {
                return;
              }
              return (
                <Chip
                  key={index}
                  label={items.first_name + " " + items.last_name}
                  color="blue"
                  background="white"
                  sx={{ mr: "3px", border: "1px solid blue" }}
                />
              );
            })}
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Labels
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{}</Typography>
            {requestDetails[0]?.labels_selected?.map((items, index) => {
              if (index > 3) {
                return;
              }
              return (
                <Chip
                  key={index}
                  background={items.color}
                  label={items.title}
                  sx={{ mr: "3px" }}
                />
              );
            })}
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Status
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            {" "}
            {requestDetails[0]?.status == "finished" ? (
              <Chip type="success" label="Generated" />
            ) : (
              <Chip type="error" label="Not Generated" />
            )}
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        {requestDetails[0]?.status == "finished" && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Summarize />}
              href={requestDetails[0]?.chat_report_url}
            >
              Download Request Log Report
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
