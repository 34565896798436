import React from "react";
import { ButtonFooter } from "../../../../components";
import Bubble from "./Bubble";
import Footer from "./Footer";
import Header from "./Header";

export default function FormDisplay({
  formikProps,
  editState,
  setEditState,
  image,
  setImage,
}) {
  return (
    <React.Fragment>
      <Bubble
        formikProps={formikProps}
        editState={editState}
        image={image}
        setImage={setImage}
      />

      <Header formikProps={formikProps} editState={editState} />

      <Footer formikProps={formikProps} editState={editState} />

      <ButtonFooter
        ID={formikProps.values.agentID}
        editState={editState}
        setEditState={setEditState}
        formikProps={formikProps}
      />
    </React.Fragment>
  );
}
