import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import { useComponent } from "../../hooks";
import { Loader } from "../../components";
import {
  registrationFormObject,
  objectFromResponseData,
} from "./whatsapp business registration details components/registrationFormObject";
import RegistrationForm from "./whatsapp business registration details components/RegistrationForm";

export default function WhatsAppBusinessRegistrationDetails() {
  const { axios, loader } = useComponent({ disableAuthToken: true });
  const { registrationID } = useParams();

  const [formData, setFormData] = useState(registrationFormObject);
  const [stepperState, setStepperState] = useState(0);

  useEffect(() => {
    loader.start();

    if (!!registrationID) {
      axios({
        url: "/business/whatsapp_registration",
        params: {
          registration_id: registrationID,
        },
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));
          setStepperState(response.data.stage);

          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [axios, loader, registrationID]);

  return (
    <Loader>
      <Container maxWidth="md" style={{ height: "100%" }}>
        <Helmet title="Business Registration" />

        <RegistrationForm
          registrationID={registrationID}
          stepperState={stepperState}
          setStepperState={setStepperState}
          formData={formData}
          setFormData={setFormData}
        />
      </Container>
    </Loader>
  );
}
