import { Fragment } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

import { ComponentHeader, Paper } from "../../../../../components";

const EmailSubject = styled.div`
  border-radius: 2px;
  box-shadow: rgb(0 0 0) 0px 0px 1px;
  padding: 15px;
  margin-bottom: 5px;
`;

const DetailsCard = styled.div`
  border-radius: 2px;
  height: 50vh;
  width: 100%;
  box-shadow: rgb(0 0 0) 0px 0px 1px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const EmailContent = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;

  img,
  video {
    max-width: 100%;
    height: auto;
  }
`;

export default function EmailPreview({ formikProps, templates }) {
  var templateBodyMessage =
    formikProps?.values?.templateBody.text ||
    (templates?.length > 0 ? templates[0].template_body : "No Body");

  var templateSubject =
    formikProps?.values?.emailSubject ||
    (templates?.length > 0 ? templates[0].subject : "No Subject");

  function textToHtmlFormat(string) {
    const htmlFormat = [
      { symbol: "<b>", tag: "b" },
      { symbol: "<i>", tag: "em" },
      { symbol: "<s>", tag: "del" },
      { symbol: "`", tag: "code" },
      { symbol: "\n", tag: "br" },
    ];

    htmlFormat.forEach(({ symbol, tag }) => {
      if (!string) return;

      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
      if (symbol === "\n") {
        string = string.replace(/\n/g, `<${tag}>`);
      } else {
        const match = string.match(regex);
        if (!match) return;

        match.forEach((m) => {
          let formatted = m;
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(
              symbol,
              `<${i > 0 ? "/" : ""}${tag}>`
            );
          }

          string = string.replace(
            /<img(.*?)>/g,
            '<img$1 style="max-width:100%;height:auto;">'
          );
          string = string.replace(
            /<video(.*?)>/g,
            '<video$1 style="max-width:100%;height:auto;" controls>'
          );

          string = string.replace(m, formatted);
        });
      }
    });

    return string;
  }

  return (
    <Grid item xs={12}>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={"Email Preview"}
            subtitle="This is how your email look like."
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <EmailSubject>
            <Typography variant="h6">{templateSubject}</Typography>
          </EmailSubject>

          <DetailsCard>
            <EmailContent>
              {Boolean(templateBodyMessage) &&
                parse(textToHtmlFormat(templateBodyMessage))}
            </EmailContent>
          </DetailsCard>
        </Grid>
      </Paper>
    </Grid>
  );
}
