import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import UtterancesTable from "./utterances components/UtterancesTable";
import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";
import pages from "../../constants/pages";

export default function Utterances({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [utterances, setUtterances] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: `/utterance/${activeBot.serviceID}?bot_id=${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();

        setUtterances(response.data);
      }
    });
  }, [activeBot.ID, activeBot.serviceID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Utterances"
        addIcon={[
          "Add New Utterance",
          pages.utteranceCreate.route,
          pagePermissions.create,
        ]}
      />

      <UtterancesTable utterances={utterances} />
    </Loader>
  );
}
