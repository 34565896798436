import convertDateToBackendFormat from "../../other components/convertDateToBackendFormat";

export const emailBroadcastDetailsObject = {
  ID: "",
  senderName: "",
  senderEmail: "",
  broadcastFile: "",
  subject: "",
  broadcastName: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
  templateID: "",
};

export function objectFromFormData(formData) {
  if (formData.whenToBroadcast === "later") {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time: formData.broadcastTime,
      template_name: formData.templateID,
      sender_name: formData.senderName,
      sender_email_id: formData.senderEmail,
      subject: formData.subject,
      file_url: formData.broadcastFile,
    };
  } else {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      template_name: formData.templateID,
      sender_name: formData.senderName,
      sender_email_id: formData.senderEmail,
      subject: formData.subject,
      file_url: formData.broadcastFile,
    };
  }
}
