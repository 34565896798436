import { Fragment, useState } from "react";
import { Popover, IconButton, InputAdornment } from "@mui/material";
import { Circle, Colorize } from "@mui/icons-material";
import { ChromePicker } from "react-color";
import { get, set } from "lodash";

import TextField from "./TextField";

export default function ColorPicker({ formikProps, ...props }) {
  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <Fragment>
      <TextField
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Circle
                style={{
                  fill: get(formikProps.values, props.name) || "none",
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={showPicker}
                variant="contained"
                disabled={props.disabled}
              >
                <Colorize />
              </IconButton>
            </InputAdornment>
          ),
        }}
        formikProps={formikProps}
        {...props}
      />

      <Popover
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ChromePicker
          disableAlpha
          color={get(formikProps.values, props.name, "")}
          onChange={handleColorChange}
        />
      </Popover>
    </Fragment>
  );

  function showPicker({ currentTarget }) {
    setAnchorElement(currentTarget);
  }

  function handleColorChange(color) {
    formikProps.setValues((prevValue) => set(prevValue, props.name, color.hex));
  }
}
