import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, MenuItem, Typography, Button } from "@mui/material";
import { Group } from "@mui/icons-material";

import Instructions from "./Instruction";
import BodyVariable from "./BodyVariable";
import HeaderVariable from "./HeaderVariable";
import ButtonVariable from "./ButtonVariable";
import { useAxios } from "../../../../hooks";
import pages from "../../../../constants/pages";
import { templateDataResponse } from "../templateDataObject";
import { TextField, Autocomplete } from "../../../../components";

export default function GenerateCSVForm({
  dialogFormikProps,
  groupList,
  templateData,
  template,
  setTemplateData,
  formikProps,
  activeBot,
  fields,
  setPreviewData,
}) {
  const axios = useAxios({ disableRedirect: true });
  const navigate = useNavigate();
  const handleChangeTemplate = (value, type) => {
    if (type === "selectOption") {
      axios({
        url: "/wa/template/variable/" + activeBot.ID + "/" + value._id,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setTemplateData(response.data);
        }
      });

      setPreviewData(templateDataResponse(value));

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    } else {
      value = {
        _id: "",
        language: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    }
  };

  const handleGroup = (groupList, type) => {
    if (type === "selectOption") {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        group_id: groupList.group_id,
        group: groupList.group_name,
      }));
    } else {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        group_id: "",
        group: "",
      }));
    }
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs mt={6}>
        <Typography variant="h6" color="primary">
          Instructions and steps
        </Typography>
      </Grid>

      <Grid item mt={6}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Group />}
          onClick={() => navigate(pages.groupList.route)}
        >
          Manage Groups
        </Button>
      </Grid>

      <Instructions />

      <Grid item xs={6}>
        <Autocomplete
          name="templateID"
          label="Select Template"
          defaults={{
            primaryKey: "_id",
            displayLabel: "name",
          }}
          options={template}
          onChange={(_, value, type) => handleChangeTemplate(value, type)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Filter"
          name="filter_type"
          disabled={!Boolean(formikProps.values.templateID)}
          formikProps={dialogFormikProps}
        >
          <MenuItem value={"all"}>All Users</MenuItem>
          <MenuItem value={"active"}>Active Users</MenuItem>
          <MenuItem value={"group"}>Group</MenuItem>
        </TextField>
      </Grid>

      {dialogFormikProps.values.filter_type === "group" && (
        <Grid item xs={6}>
          <Autocomplete
            name="group_id"
            label="Select Group"
            defaults={{
              primaryKey: "group_id",
              displayLabel: "group_name",
            }}
            options={groupList}
            onChange={(_, value, type) => handleGroup(value, type)}
            disabled={!Boolean(formikProps.values.templateID)}
            formikProps={dialogFormikProps}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <BodyVariable
          dialogFormikProps={dialogFormikProps}
          templateData={templateData}
          formikProps={formikProps}
          fields={fields}
        />
      </Grid>

      <Grid item xs={12}>
        <HeaderVariable
          dialogFormikProps={dialogFormikProps}
          templateData={templateData}
          formikProps={formikProps}
          fields={fields}
        />
      </Grid>

      <Grid item xs={12}>
        <ButtonVariable
          dialogFormikProps={dialogFormikProps}
          templateData={templateData}
          formikProps={formikProps}
          fields={fields}
        />
      </Grid>
    </Grid>
  );
}
