import { Grid } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../../components";

export default function BusinessDetailsFormDisplay({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Business Details"
          subtitle="Enter business details here. These details will be used to create an
          DolphinChat account."
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Legal Business Name"
          name="businessName"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Business Email"
          name="businessEmail"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          multiline
          rows={5}
          label="Business Address"
          name="businessRegisteredAddr"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          label="GST Details"
          name="businessGSTDetails"
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
