import React from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { useComponent } from "../../../../hooks";
import { alert } from "../../../../redux/slices/alertSlice";

import MoveFieldForm from "./MovieFieldForm";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function MoveFieldDialog({
  moveField,
  setMoveField,
  formikProps,
}) {
  const classes = useStyles();

  const { axios } = useComponent();
  const dispatch = useDispatch();

  const bots = useSelector((state) => state.memberDetails.bots);

  const handleClose = () => {
    setMoveField(false);
  };

  var dynamicField = {
    agent_id: "",
    field_id: formikProps.values.ID,
  };

  if (!Boolean(formikProps.values.ID)) {
    return "";
  }

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={moveField}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Copy Field</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={dynamicField}
          enableReinitialize
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "/custom_fields/copy_data_to_another_agent",
              method: "POST",
              data: formData,
            }).then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                setSubmitting(false);
                handleClose();
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  height: "200px",
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Select a bot to copy this custom field in it. If you had
                      set the sequence in this field ,it will not be applied for
                      the selected bot you need to set the sequence of this
                      field in that bot.
                    </Typography>
                  </Grid>

                  <Grid style={{ marginTop: "10px" }} item xs={12}>
                    <MoveFieldForm
                      bots={bots}
                      dialogFormikProps={dialogFormikProps}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item xs style={{ textAlign: "end" }}>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Copy Field
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
const validationSchema = Yup.object().shape({
  agent_id: Yup.string().required("This field is required."),
});
