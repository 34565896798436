import { useRef } from "react";
import { convertDate } from "../../../../../utils";

export const templateDetailsObject = {
  ID: "",
  agentID: "",
  templateName: "",
  emailSubject: "",
  templateBody: {
    example: [],
  },
  createdAt: "",
  emailAttachment: 0,
  emailSubjectVariableCount: 0,
};

export function objectFromResponse(responseData) {
  let bodyExamples = [];

  if (responseData?.template_body?.example?.length > 0) {
    for (let example of responseData.template_body?.example) {
      bodyExamples.push({
        example: example,
      });
    }
  }

  return {
    ID: responseData._id || "",
    templateName: responseData.template_name || "",
    emailSubject: responseData.subject || "",
    templateBody:
      {
        text: responseData?.template_body?.replaceAll("<br />", "\n"),
        count_of_variable: responseData?.body_variable_count,
        example: bodyExamples,
      } || {},
    emailSubjectVariableCount: responseData.subject_variable_count,
    createdAt: convertDate(responseData.created_at) || "",
    emailAttachment: responseData.attachments_count,
    emailSubjectVariableCount: responseData.subject_variable_count || 0,
  };
}

export function objectFromFormData(formData, html) {
  return {
    template_name: formData.templateName,
    template_body: html?.code,
    subject: formData.emailSubject,
    subject_variable_count: formData.emailSubjectVariableCount,
    body_variable_count: html.variableCount,
    attachments_count: formData.emailAttachment,
    html_json: html.json,
  };
}
