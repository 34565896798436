import styled from "styled-components";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { Fragment, useState, useEffect, useRef } from "react";
import { Divider, Paper, Switch, Typography } from "@mui/material";

import { useComponent } from "../../../../hooks";
import { changeActiveChat } from "../../helperFunctions";
import ListSection from "./user list components/ListSection";
import { messageSocket } from "../../../../constants/serverPath";
import FilterSection from "./user list components/FilterSection";
import { convertQueryParams, formatUser } from "../../helperFunctions";

const Wrapper = styled(Paper)`
  height: 100%;
  width: 300px;
  min-width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 65px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function UserList({
  userList,
  setUserList,
  userState,
  setUserState,
  chatState,
  setChatState,
  searchQuery,
  setSearchQuery,
  senderID,
  setSenderID,
}) {
  const timeoutRef = useRef(null);
  const userSenderID = useRef(userState.ID);

  const { axios } = useComponent();

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [labels, setLabels] = useState([]);
  const [addUser, setAddUser] = useState("");

  const [noUsers, setNoUsers] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [loaderState, setLoaderState] = useState(true);

  const [page, setPage] = useState({
    number: 0,
    size: 25,
  });

  useEffect(() => (userSenderID.current = userState.ID), [userState.ID]);

  useEffect(() => {
    setUserList([]);

    setPage((prevValue) => ({
      ...prevValue,
      number: 0,
    }));
  }, [
    setUserList,
    chatState.activeChat,
    searchQuery.search,
    searchQuery.name,
    searchQuery.email,
    searchQuery.phoneNumber,
    searchQuery.conversation,
    searchQuery.assignedTo,
    searchQuery.label,
    searchQuery.unReadConversation,
    searchQuery.assign,
  ]);

  useEffect(() => {
    const socket = io(messageSocket.address);

    socket.on("message", (data) => {
      const message = JSON.parse(data);
      if (message.agent_id === activeBot.ID) {
        setUserList((userList) => {
          const newMessageUser = userList.find(
            (user) => user.ID === message.sender_id
          );
          if (!!newMessageUser) {
            return [
              {
                ...newMessageUser,
                ...(message.sender_id !== userSenderID && {
                  newMessage: true,
                }),
                latestMessage: message.payload.text || "",
                latestMessageTime: message.timestamp,
              },
              ...userList.filter((user) => user.ID !== message.sender_id),
            ];
          } else {
            return userList;
          }
        });
      }
    });
  }, [userSenderID, activeBot.ID, setUserList]);

  useEffect(() => {
    setLoaderState(true);

    clearTimeout(timeoutRef.current);

    axios({
      url: "/leads/members_list",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setMemberList(response.data);
      }
    });

    axios({
      url: "/label/" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setLabels(response.data);
      }
    });

    timeoutRef.current = setTimeout(
      () =>
        axios({
          url: `/bot-user/${activeBot.ID}`,
          params: {
            action_type: convertQueryParams(chatState.activeChat),
            ...(chatState.activeChat === "livechats" && {
              is_livechats: true,
            }),
            page: page.number,
            limit: page.size,
            ...(chatState.activeChat == "requests" && {
              is_assigned: false,
              chat_ended: false,
            }),
            ...(!!searchQuery.search && { search: searchQuery.search }),
            ...(!!searchQuery.name && { search: searchQuery.name }),
            ...(!!searchQuery.email && { search: searchQuery.email }),
            ...(!!searchQuery.phoneNumber && {
              search: searchQuery.phoneNumber,
            }),
            ...(!!searchQuery.conversation && {
              conversation: searchQuery.conversation,
            }),
            ...(!!searchQuery.assignedTo && {
              assigned_to: searchQuery.assignedTo,
            }),
            ...(!!searchQuery.label && { label_id: searchQuery.label }),
            ...(!!searchQuery.unReadConversation && {
              latest_user_message_read: searchQuery.unReadConversation,
            }),
            ...(!!searchQuery.assign && {
              is_assigned: searchQuery.assign,
            }),
          },
        }).then((response) => {
          if (response.status) {
            const newUsers = [];
            for (let user of response.data) {
              newUsers.push(formatUser(user));
            }

            if (response.data.length === 0) {
              setUserList(newUsers);
              setNoUsers(
                searchQuery.search +
                  searchQuery.name +
                  searchQuery.email +
                  searchQuery.phoneNumber +
                  searchQuery.conversation +
                  searchQuery.assignedTo +
                  searchQuery.label +
                  searchQuery.unReadConversation +
                  searchQuery.assign
              );
            } else if (
              response.data.length > 0 &&
              (searchQuery.assignedTo !== "" ||
                searchQuery.search !== "" ||
                searchQuery.email !== "" ||
                searchQuery.phoneNumber !== "" ||
                searchQuery.conversation !== "" ||
                searchQuery.name !== "" ||
                searchQuery.assign !== "" ||
                searchQuery.unReadConversation !== "")
            ) {
              setNoUsers("");
              setUserList((prevUsers) => [...prevUsers, ...newUsers]);
            } else {
              setNoUsers("");
              setUserList((prevUsers) => [...prevUsers, ...newUsers]);
            }

            setLoaderState(false);
          }
        }),
      250
    );
  }, [
    axios,
    setUserList,
    activeBot.ID,
    chatState.activeChat,
    page.number,
    page.size,
    searchQuery.search,
    searchQuery.name,
    searchQuery.email,
    searchQuery.phoneNumber,
    searchQuery.conversation,
    searchQuery.assignedTo,
    searchQuery.label,
    searchQuery.unReadConversation,
    searchQuery.assign,
    addUser,
  ]);
  return (
    <Wrapper>
      <FilterSection
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        memberList={memberList}
        labels={labels}
        setSenderID={setSenderID}
        senderID={senderID}
      />

      {chatState.activeChat !== "requests" && (
        <Fragment>
          <Divider />

          <Container>
            <Typography variant="subtitle2">My chats</Typography>

            <Switch
              checked={chatState.activeChat === "myChats"}
              onChange={toggleMyChatsFilter}
            />

            <Typography variant="subtitle2">Live chats</Typography>

            <Switch
              checked={chatState.activeChat === "livechats"}
              onChange={toggleLiveChatsFilter}
            />
          </Container>
        </Fragment>
      )}

      <Divider />

      <ListSection
        loaderState={loaderState}
        page={page}
        setPage={setPage}
        userList={userList}
        setUserList={setUserList}
        chatState={chatState}
        userState={userState}
        setUserState={setUserState}
        setAddUser={setAddUser}
        activeBot={activeBot}
        noUsers={noUsers}
        senderID={senderID}
      />
    </Wrapper>
  );

  function toggleMyChatsFilter({ target }) {
    const { checked } = target;

    if (checked) {
      setSearchQuery(() => ({
        search: "",
        name: "",
        email: "",
        phoneNumber: "",
        label: "",
        assignedTo: "",
        conversation: "",
      }));

      senderID === "all" &&
        changeActiveChat(setUserState, "myChats", setChatState, senderID);
    } else {
      setSearchQuery(() => ({
        search: "",
        name: "",
        email: "",
        phoneNumber: "",
        label: "",
        assignedTo: "",
        conversation: "",
      }));
    }

    senderID === "all" &&
      changeActiveChat(
        setUserState,
        checked ? "myChats" : "allChats",
        setChatState,
        senderID
      );
  }

  function toggleLiveChatsFilter({ target }) {
    const { checked } = target;

    if (checked) {
      setSearchQuery(() => ({
        search: "",
        name: "",
        email: "",
        phoneNumber: "",
        label: "",
        assignedTo: "",
        conversation: "",
      }));

      senderID === "all" &&
        changeActiveChat(setUserState, "livechats", setChatState, senderID);
    } else {
      setSearchQuery(() => ({
        search: "",
        name: "",
        email: "",
        phoneNumber: "",
        label: "",
        assignedTo: "",
        conversation: "",
      }));
    }

    senderID === "all" &&
      changeActiveChat(
        setUserState,
        checked ? "livechats" : "allChats",
        setChatState,
        senderID
      );
  }
}
