import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Form from "./Form";
import { useAxios, useComponent } from "../../../../hooks";
import { Loader, PageHeader } from "../../../../components";
import { intentObject, dataFromResponse } from "./intentDetailsObject";

export default function IntentDetails({ pageID }) {
  const params = useParams();
  const axios = useAxios({ baseURL: "botBuilder" });

  const [example, setExamples] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(intentObject);

  const { pagePermissions, loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);
  const intentID = params.intentID || "";

  useEffect(() => {
    if (!!formData.ID) {
      setEditState(false);
    }
  }, [formData.ID]);

  useEffect(() => {
    loader.start(1);

    if (!!intentID) {
      axios({
        url: "/get-intent/" + activeBot.ID + "?intent_name=" + intentID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setFormData(dataFromResponse(response.data));
          loader.stop();
        }
      });
      axios({
        url:
          "/get-intents-examples/" + activeBot.ID + "?intent_name=" + intentID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setExamples(response.data);
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [axios, loader, activeBot.ID, intentID]);

  return (
    <Loader>
      <PageHeader
        pageName="Intent Details"
        editIcon={[editState, setEditState, pagePermissions.update]}
      />
      <Form
        formData={formData}
        setFormData={setFormData}
        setEditState={setEditState}
        editState={editState}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
