import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

import DataTable from "../../../../components/DataTable";

export default function RegexTable({ editState, formikProps }) {
  const options = {
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    filter: false,
    download: false,
    viewColumns: false,
    search: false,
    print: false,
  };

  function handleDelete(index) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      regex: setRegex(prevVal.regex),
    }));

    function setRegex(regex) {
      var re = [...regex];
      return re.filter((r) => r.regex !== re[index.rowIndex].regex, 1);
    }
  }

  const columns = [
    {
      name: "regex",
      label: "Regex",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (_, index) => (
          <React.Fragment>
            <IconButton
              disabled={!editState}
              style={{ color: !editState ? "rgba(0,0,0,0.26)" : "#ff0000" }}
              onClick={() => handleDelete(index)}
              size="small"
            >
              <Tooltip title="Delete Regex">
                <DeleteOutline />
              </Tooltip>
            </IconButton>
          </React.Fragment>
        ),
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        options={options}
        data={formikProps.values.regex}
      />
    </React.Fragment>
  );
}
