import { DataTable } from "../../../components";

export default function BotsTable({ bots }) {
  return <DataTable columns={columns} data={bots} options={options} />;
}

const options = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
};

const columns = [
  {
    name: "agent_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "channel_id",
    label: "Channel",
    options: {
      customBodyRender: (value) => {
        switch (value) {
          case "f5145f46752c41e48a7cf51bd958b921":
            return "Web";

          case "606fdbaaf169cd7446773b7e":
            return "Whatsapp";

          case "32dc9508b1c249148a5af9e77ae0466d":
            return "Facebook";

          case "c28615e8d26d443cb9610b14d86d77a1":
            return "Instagram";

          default:
            return "";
        }
      },
    },
  },
];
