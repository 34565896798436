import { Grid, Typography, Paper as MuiPaper } from "@mui/material";
import styled from "styled-components";

const Paper = styled(MuiPaper)`
  height: 150px;
  display: flex;
  border-radius: 0;
  flex-direction: column;
  padding: 15px 0 15px 10px;
`;

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;

export default function StatsOfUser({ insightData }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Grid
            container
            spacing={5}
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              <ColoredTypography variant="h2">
                {insightData.total_livechat_requests}
              </ColoredTypography>
              <Typography variant="h5">Live Chat Requested</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
