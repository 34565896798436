import React, { useState } from "react";
import { TiAttachment } from "react-icons/ti";
import styled from "styled-components/macro";

import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Paper as MUIPaper,
} from "@mui/material";
import { NotesOutlined, DeleteOutline, Visibility } from "@mui/icons-material";

import AddComments from "./AddComments";
import DeleteDialog from "./DeleteDailogue";
import { useAxios, useComponent } from "../../../../hooks";
import { convertDate } from "../../../../utils";
import { ComponentHeader, Paper } from "../../../../components";

const NotesPaper = styled(MUIPaper)`
  display: flex;
  width: 300px;
  border-color: #b8daff;
  padding: 10px;
  border-radius: 10px;
`;

const TypographyCreatedAt = styled(Typography)`
  font-size: 12px;
  color: #cbcbcb;
`;

export default function Comments({
  formData,
  comments,
  setAddComments,
  setDeleteComment,
  pagePermissions,
}) {
  const axios = useAxios();
  const { alert } = useComponent();

  const [popUp, setPopUp] = useState({
    state: false,
    data: {
      text: "",
      attachment: {
        name: "",
        url: "",
      },
    },
  });
  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  function downloadAttachment(url) {
    window.location.href = url;
  }

  function handleDelete() {
    axios({
      url: "/leads/comments",
      method: "DELETE",
      data: {
        lead_id: formData.ID,
        comment_id: dialogState.id,
      },
    }).then((response) => {
      if (response.status) {
        alert.success(response.message?.displayMessage);
        setDeleteComment(dialogState.id);
      }
    });
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={"Comments"}
            subtitle={"You can use these comments for your leads."}
            buttons={[
              pagePermissions.create && formData.ID && <CreateComments />,
            ]}
          />
        </Grid>

        <Grid item>
          <Grid container spacing={2} alignItems="stretch">
            {comments.comments?.map((item, index) => (
              <Grid item display="flex" key={index}>
                <NotesPaper elevation={2}>
                  <Grid container spacing={2}>
                    <Grid
                      style={{ height: "35px", position: "sticky", top: "0" }}
                      item
                      xs
                      alignSelf={"center"}
                    >
                      <TypographyCreatedAt>
                        {convertDate(item.updated_at)}
                      </TypographyCreatedAt>
                    </Grid>

                    {pagePermissions.delete && (
                      <Grid
                        item
                        alignItems="end"
                        style={{ height: "35px", position: "sticky", top: "0" }}
                        alignSelf={"center"}
                      >
                        <Tooltip title="Delete Comment">
                          <IconButton
                            disableRipple
                            style={{ padding: "0px", color: "#d11a2a" }}
                            onClick={() =>
                              setDialogState({
                                state: true,
                                id: item._id,
                              })
                            }
                          >
                            <DeleteOutline fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}

                    <Grid
                      item
                      alignItems="end"
                      style={{ height: "35px", position: "sticky", top: "0" }}
                      alignSelf={"center"}
                    >
                      <Tooltip title="View Comment">
                        <IconButton
                          color="primary"
                          disableRipple
                          style={{ padding: "0px" }}
                          onClick={() =>
                            setPopUp({
                              state: true,
                              data: {
                                comment_id: item._id,
                                text: item.text,
                                attachment: {
                                  name: item.attachment.name,
                                  url: item.attachment.url,
                                },
                              },
                            })
                          }
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} style={{ height: "calc(100% - 65px)" }}>
                      <Typography
                        style={{ color: "rgba(0,0,0,0.54)" }}
                        variant="subtitle2"
                      >
                        {item.text?.slice(0, 150) +
                          (item.text?.length > 150 ? "..." : "")}
                      </Typography>
                      {!!item.attachment.url && (
                        <Tooltip title="Download Attachment">
                          <IconButton
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            style={{
                              padding: "0",
                              color: "rgb(38,77,145)",
                              marginTop: "10px",
                            }}
                            onClick={() =>
                              downloadAttachment(item.attachment.url)
                            }
                          >
                            <TiAttachment />{" "}
                            <Typography
                              style={{ color: "rgb(38,77,145)" }}
                              variant="subtitle2"
                            >
                              {item.attachment.name?.slice(0, 15) +
                                (item.attachment.name?.length > 15
                                  ? "..."
                                  : "")}
                            </Typography>
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ height: "30px", paddingTop: "10px" }}
                    >
                      <TypographyCreatedAt>
                        Created By {item.created_by}
                      </TypographyCreatedAt>
                    </Grid>
                  </Grid>
                </NotesPaper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>

      <AddComments
        popUp={popUp}
        setPopUp={setPopUp}
        setAddComments={setAddComments}
        formData={formData}
        pagePermissions={pagePermissions}
      />

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </React.Fragment>
  );

  function CreateComments() {
    return (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<NotesOutlined />}
        onClick={() =>
          setPopUp({
            state: true,
            data: {
              lead_id: formData.ID,
              text: "",
              attachment: {
                url: "",
                name: "",
              },
            },
          })
        }
      >
        Add Comments
      </Button>
    );
  }
}
