import React, { useState, useEffect } from "react";
import { Tab, Grid } from "@mui/material";
import {
  TabContext as MUITabContext,
  TabList,
  TabPanel as MUITabPanel,
} from "@mui/lab";
import styled from "styled-components/macro";

import Form from "./Form";
import { useAxios } from "../../../hooks";
import { Paper } from "../../../components";
import Comments from "../lead detail components/comments components/Comments";
import Document from "./documents components/Documents";
import Label from "./label components/Label";

const TabPanel = styled(MUITabPanel)`
  padding: 12px 0;
`;

const TabContext = styled(MUITabContext)`
  padding: 12px 0;
`;

export default function TabsList({
  formData,
  fields,
  setEditState,
  editState,
  setFormData,
  templates,
  pagePermissions,
  integrationDetails,
  userInfo,
  labels,
  conversation,
  activeBot,
  setChangedLeadProperty,
  leadProperties,
}) {
  const axios = useAxios();
  const [tab, setTab] = useState("leadDetails");

  const [comments, setComments] = useState([]);
  const [addComments, setAddComments] = useState("");
  const [deleteComment, setDeleteComment] = useState("");

  const [documents, setDocuments] = useState([]);
  const [addDocument, setAddDocument] = useState("");
  const [deleteDocument, setDeleteDocument] = useState("");

  const [assignLabel, setAssignLabel] = useState([]);
  const [assignUnassignLabel, setAssignUnassignLabel] = useState("");

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (formData.ID) {
      axios({
        url: "/leads/comments?lead_id=" + formData.ID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setComments(response.data);
        }
      });
    }
  }, [addComments, deleteComment, axios, formData.ID]);

  useEffect(() => {
    if (formData.ID) {
      axios({
        url: "/label/get/assigned-label?lead_id=" + formData.ID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setAssignLabel(response.data);
        }
      });
    }
  }, [axios, formData.ID, assignUnassignLabel]);

  useEffect(() => {
    if (!!formData.ID) {
      axios({
        url: "/leads/documents?lead_id=" + formData.ID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDocuments(response.data);
        }
      });
    }
  }, [formData.ID, axios, addDocument, deleteDocument]);

  return (
    <TabContext value={tab}>
      <Paper style={{ padding: "8px" }}>
        <Grid item xs={12}>
          <TabList onChange={handleChange}>
            <Tab label="Lead Details" value="leadDetails" />
            <Tab label="Comments" value="comments" disabled={!formData.ID} />
            <Tab label="Documents" value="documents" disabled={!formData.ID} />
            <Tab label="Labels" value="labels" disabled={!formData.ID} />
          </TabList>
        </Grid>
      </Paper>

      <TabPanel value="leadDetails">
        <Form
          userInfo={userInfo}
          fields={fields}
          formData={formData}
          activeBot={activeBot}
          editState={editState}
          templates={templates}
          setFormData={setFormData}
          setEditState={setEditState}
          pagePermissions={pagePermissions}
          integrationDetails={integrationDetails}
          leadProperties={leadProperties}
          conversation={conversation}
          setChangedLeadProperty={setChangedLeadProperty}
        />
      </TabPanel>

      <TabPanel value="comments">
        <Comments
          comments={comments}
          formData={formData}
          pagePermissions={pagePermissions}
          setAddComments={setAddComments}
          setDeleteComment={setDeleteComment}
        />
      </TabPanel>

      <TabPanel value="documents">
        <Document
          formData={formData}
          pagePermissions={pagePermissions}
          documents={documents}
          setAddDocument={setAddDocument}
          setDeleteDocument={setDeleteDocument}
        />
      </TabPanel>

      <TabPanel value="labels">
        <Label
          labels={labels}
          assignLabel={assignLabel}
          setAssignUnassignLabel={setAssignUnassignLabel}
          formData={formData}
          pagePermissions={pagePermissions}
        />
      </TabPanel>
    </TabContext>
  );
}
