import { useEffect, useState } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { TextAvatar } from "../../../../../../components";
import { useComponent } from "../../../../../../hooks";
import { chatReducerConstants } from "../../../chatReducer";
import { userReducerConstants } from "../../../userReducer";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const InfoContainer = styled.div`
  width: 300px;
  align-self: flex-start;
`;

export default function ConversationsHeaderInfo({
  userState,
  setChatState,
  setUserState,
  setUserList,
  userList,
}) {
  const { axios, alert } = useComponent();
  const { activeBot, groupID } = useSelector((state) => state.memberDetails);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [onlineMembers, setOnlineMembers] = useState([]);

  useEffect(() => {
    axios({
      url: `/livechat/member-list?bot_id=${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        setOnlineMembers(formatOnlineMembers(response.data));
      }
    });
  }, [activeBot.ID, axios, userState.ID]);

  return (
    <Wrapper>
      <TextAvatar
        size={{ height: "40px", width: "40px" }}
        userName={userState.name}
        onClick={() =>
          setChatState({ type: chatReducerConstants.TOGGLE_PROFILE_SECTION })
        }
      />

      <InfoContainer>
        <Typography variant="h6">{userState.name}</Typography>

        <Typography
          variant="caption"
          onClick={(event) => setMenuAnchor(event.currentTarget)}
          style={{ cursor: "pointer" }}
        >
          Assign to {userState.assignedTo} ▼
        </Typography>

        <Menu
          sx={{ marginTop: "3px" }}
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {onlineMembers.length > 0 ? (
            onlineMembers.map((member) => (
              <MenuItem
                key={member.ID}
                onClick={() => assignToMember(member.ID, member.name)}
              >
                {member.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No one is online</MenuItem>
          )}
        </Menu>
      </InfoContainer>
    </Wrapper>
  );

  function assignToMember(memberID, memberName) {
    axios({
      url: `/livechat/assigned-member?bot_id=${activeBot.ID}`,
      data: {
        member_id: memberID,
        sender_id: userState.ID,
      },
      method: "PATCH",
    }).then((response) => {
      if (response.status) {
        alert.success("User has been assigned");

        var index = userList.findIndex((user) => user.ID === userState.ID);

        let users = [...userList];
        users[index].assignedTo = memberName;
        users[index].chatActive = true;

        setUserList(users);

        setUserState({
          type: userReducerConstants.SET_USER,
          payload: {
            ID: userState.ID,
            name: userState.name,
            phone: userState.phone,
            email: userState.email,
            assignedTo: memberName,
            chatActive: true,
          },
        });
      }
    });
  }
}

function formatOnlineMembers(memberList) {
  const newMemberList = [];

  for (let member of memberList) {
    newMemberList.push({
      ID: member._id,
      name: `${member.first_name} ${member.last_name}`,
    });
  }

  return newMemberList;
}
