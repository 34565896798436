import React from "react";
import {
  Dialog,
  Grid,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";

import UserDetailsForm from "./UserDetailsForm";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function AddUserPopUp({
  activeBot,
  addUserPopUp,
  setAddUserPopUp,
  allUsers,
  columns,
  setAddUsers,
  formikProps,
  setUserList,
  setAllUsers,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={addUserPopUp.state}
      onClose={handleClose}
      fullWidth
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">Add leads in group</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
            disableRipple
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="caption1"
              style={{ color: "rgb(128, 128, 128)" }}
            >
              You can add the leads in the group by selecting the leads.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <UserDetailsForm
              column={columns}
              allUsers={allUsers}
              formikProps={formikProps}
              handleClose={handleClose}
              setUserList={setUserList}
              setAddUsers={setAddUsers}
              setAllUsers={setAllUsers}
              activeBot={activeBot}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  function handleClose() {
    setAddUserPopUp({
      state: false,
      groupUserId: "",
    });
  }
}
