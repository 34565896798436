import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import FormRest from "./rest bot components/style bot components/Form";
import {
  objectFromResponseData,
  restBotStyleObject,
} from "./rest bot components/style bot components/restBotStyle";
import FormWhatsapp from "./whatsapp bot components/style bot components/Form";
import {
  configureDetailFromResponseData,
  whatsappDetailObject,
} from "./whatsapp bot components/style bot components/whatsappStyleBotObject";

export default function StyleBot({ pageID }) {
  const axios = useAxios();
  const { loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(
    activeBot.channel === "web" ? restBotStyleObject : whatsappDetailObject
  );
  const [image, setImage] = useState({
    originalImage: "",
    imageResult: "",
    imageTarget: "",
  });

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/bot/widget/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        if (activeBot.channel === "web") {
          setFormData(objectFromResponseData(response.data));
          setImage((prevVal) => ({
            ...prevVal,
            originalImage: response.data.widget?.bubble?.avatar,
          }));
        } else if (activeBot.channel === "whatsapp") {
          setFormData(configureDetailFromResponseData(response.data));
          setImage((prevVal) => ({
            ...prevVal,
            originalImage: response.data.widget?.pictureCustom,
          }));
        }

        loader.stop();
      }
    });
  }, [activeBot.ID, axios, loader, activeBot.channel]);

  return (
    <Loader>
      <PageHeader
        pageName={"Style Bot"}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      {activeBot.channel === "web" ? (
        <FormRest
          formData={formData}
          pagePermissions={pagePermissions}
          editState={editState}
          setEditState={setEditState}
          image={image}
          setImage={setImage}
          setFormData={setFormData}
        />
      ) : (
        <FormWhatsapp
          formData={formData}
          pagePermissions={pagePermissions}
          editState={editState}
          setEditState={setEditState}
          image={image}
          setImage={setImage}
          setFormData={setFormData}
        />
      )}
    </Loader>
  );
}
