import React from "react";

import MainForm from "./MainForm";
import OptionalFields from "./OptionalField";
import DateControl from "../custom field control types/DateControl";
import DropDownControl from "../custom field control types/DropDownControl";
import TextFieldControl from "../custom field control types/TextFieldControl";

export default function FormDisplay({
  formikProps,
  controlTypes,
  setControlTypes,
  editState,
  activeBot,
}) {
  return (
    <React.Fragment>
      <MainForm
        formikProps={formikProps}
        controlTypes={controlTypes}
        setControlTypes={setControlTypes}
        editState={editState}
      />

      {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed7" && (
        <TextFieldControl formikProps={formikProps} editState={editState} />
      )}

      {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed9" && (
        <DateControl formikProps={formikProps} editState={editState} />
      )}

      {formikProps.values.controlID === "61dd2dfa1fb38be82e077ed8" && (
        <DropDownControl formikProps={formikProps} editState={editState} />
      )}

      <OptionalFields formikProps={formikProps} editState={editState} />
    </React.Fragment>
  );
}
