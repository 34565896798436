import { useEffect } from "react";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import { MailOutline } from "@mui/icons-material";

import { TextAvatar } from "../../../../../../components";
import { userReducerConstants } from "../../../userReducer";
import UserPillInfo from "./user pill components/UserPillInfo";

const Wrapper = styled.div`
  min-height: 80px;
  width: 100%;
  border-radius: 4px;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  border: ${({ theme }) => {
    return theme.name === "DARK" ? "1px solid #e8e8e838" : "1px solid #e8e8e8";
  }};
  &: hover {
    background: #e3f2fd1a;
  }
  background: ${({ activeUser, theme }) => {
    return activeUser && theme.palette.background.default;
  }};
`;

export default function UserPill({
  user,
  activeUser,
  setUserState,
  setUserList,
  senderID,
}) {
  useEffect(() => {
    if (senderID !== "all") {
      loadUser();
    }
  }, [senderID]);

  return (
    <Wrapper activeUser={activeUser} onClick={loadUser}>
      <TextAvatar
        userName={user.name}
        size={{ height: "55px", width: "55px" }}
      />

      <UserPillInfo user={user} />

      {user.newMessage && (
        <Avatar
          style={{
            background: "#0277bd",
            height: "20px",
            width: "20px",
            float: "right",
          }}
          display="inline"
        >
          <MailOutline sx={{ height: "14px", width: "14px" }} />
        </Avatar>
      )}
    </Wrapper>
  );

  function loadUser() {
    setUserList((prevValue) => {
      return prevValue.map((clickedUser) => {
        if (clickedUser.ID === user.ID) {
          return {
            ...clickedUser,
            //    newMessage: false,
          };
        }

        return clickedUser;
      });
    });

    setUserState({
      type: userReducerConstants.SET_USER,
      payload: {
        ID: user.ID,
        name: user.name,
        phone: user.phone,
        email: user.email,
        assignedTo: user.assignedTo,
        chatActive: user.chatActive,
      },
    });
  }
}
