import React from "react";
// import AWS from "aws-sdk";
import { Grid } from "@mui/material";

import { FileUploadNew, TextField } from "../../../../components";
import uploadToS3 from "../../../../utils/uploadToS3";

export default function AddCommentForm({
  dialogFormikProps,
  folderPath,
  bucket = {},
}) {
  const handleUploadFile = (CSV) => {
    const file = CSV.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      // Commenting this part as Minio is being used in dev instead of AWS S3
      // var bucketName = "dc-chat-media";
      // var bucketRegion = "ap-south-1";
      // var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

      // AWS.config.update({
      //   region: bucketRegion,
      //   credentials: new AWS.CognitoIdentityCredentials({
      //     IdentityPoolId: identityPoolID,
      //   }),
      // });

      // var s3 = new AWS.S3({
      //   apiVersion: "2006-03-01",
      //   params: {
      //     Bucket: bucketName,
      //   },
      // });

      // var filePath = "sample_data_files/" + file.name;
      // var upload = s3.upload({
      //   Key: filePath,
      //   ACL: "public-read",
      //   Body: file,
      // });

      // var promise = upload.promise();

      const uploadBucket = {
        name: "dc-chat-media",
        region: "ap-south-1",
        identityPoolID: "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103",
        ...bucket,
      };

      uploadToS3({
        bucket: uploadBucket,
        filePath: "sample_data_files/" + file.name,
        file,
        bucketRegion: "ap-south-1",
      }).then(function (data) {
        dialogFormikProps.setValues((prevVal) => ({
          ...prevVal,
          attachment: {
            url:
              "https://" +
              uploadBucket.name +
              ".s3.ap-south-1.amazonaws.com/" +
              folderPath +
              file.name,
            name: file.name,
          },
        }));
      });
    };
    reader.onerror = function (error) {
      console.error("Error: ", error);
    };
  };

  return (
    <React.Fragment>
      <Grid style={{ marginTop: "10px" }} item xs={12}>
        <TextField
          name="text"
          label="Text"
          multiline
          rows={5}
          disabled={!!dialogFormikProps.values?.comment_id}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid mt={"10px"} item xs={12}>
        <FileUploadNew
          name="attachment.name"
          label="Attachment"
          folder={"comments/" + new Date().getTime() + "_"}
          maxSize={25}
          onChange={handleUploadFile}
          allowedExtension={
            "jpg,.jpeg,.png,.gif,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,video/mp4,video/x-m4v"
          }
          disabled={!!dialogFormikProps.values?.comment_id}
          formikProps={dialogFormikProps}
        />
      </Grid>
    </React.Fragment>
  );
}
