/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

//Main Module Components
import Home from "../../pages/home/Home";
import Profile from "../../pages/profile/Profile";
import Settings from "../../pages/profile/Settings";
import BusinessProfile from "../../pages/business profile/BusinessProfile";

//Members Module
import Members from "../../pages/member/Members";
import MemberDetails from "../../pages/member/MemberDetails";

//Insights Module Components

//Bot Module
import Bots from "../../pages/bot/Bots";
import BotDetails from "../../pages/bot/BotDetails";

//Template Module Components
import TemplateList from "../../pages/template/TemplateList";
import TemplateDetails from "../../pages/template/TemplateDetails";

//Custom Field Module Components
import CustomFieldDetails from "../../pages/custom fields/CustomFieldsDetails";
import CustomFieldList from "../../pages/custom fields/CustomFieldsList";

//User Module Components
import LeadList from "../../pages/leads/LeadListPage";
import LeadDetails from "../../pages/leads/LeadDetailPage";

//Broadcast Module Components
import BroadcastList from "../../pages/broadcast/BroadcastList";
import BroadcastDetails from "../../pages/broadcast/BroadcastDetails";

//Group Module Components
import GroupList from "../../pages/group/GroupList";
import GroupDetails from "../../pages/group/GroupDetails";

//Label Module
import Labels from "../../pages/label/Labels";
import LabelDetails from "../../pages/label/LabelDetails";

// //Utterance Module
import Utterances from "../../pages/utterance/Utterances";
import UtteranceDetails from "../../pages/utterance/UtteranceDetails";

//Webhook Module
import Webhooks from "../../pages/webhook/Webhooks";
import WebhookDetails from "../../pages/webhook/WebhookDetails";

// //Webhook Module
// import Integrations from "../../pages/integration/Integrations";
// import IntegrationDetails from "../../pages/integration/IntegrationDetails";

//Chats Module
import Chats from "../../pages/chats/Chats";

//Feedback Module
import Feedback from "../../pages/feedback component/Feedback";

//Permissions Group Module
import PMCBroadcast from "../../pages/pmc/email components/Email";
import StyleBot from "../../pages/style your bot components/StyleBot";
import PermissionsGroups from "../../pages/permission groups/PermissionsGroups";
import PermissionsGroupDetails from "../../pages/permission groups/PermissionsGroupDetails";
import IntentList from "../../pages/bot builder components/intent component/list components/IntentList";
import EmailList from "../../pages/sail broadcast components/email components/list components/EmailList";
import EntityList from "../../pages/bot builder components/entity components/list components/EntityList";
import IntentDetails from "../../pages/bot builder components/intent component/form components/IntentDetails";
import EntityDetails from "../../pages/bot builder components/entity components/form components/EntityDetails";
import BotTrainingPhrases from "../../pages/bot builder components/training phrases components/BotTrainingPhrases";
import EmailBroadcastDetails from "../../pages/sail broadcast components/email components/form components/EmailBroadcastDetails";
import WhatsappNumberDetailsPage from "../../pages/whatsapp number/WhatsappNumberDetailsPage";
import SMSList from "../../pages/sail broadcast components/message components/list components/SMSList";
import MessageBroadcastDetails from "../../pages/sail broadcast components/message components/form components/MessageBroadcastDetails";
import SlotList from "../../pages/bot builder components/slot components/slot list components/Slot";
import SlotDetails from "../../pages/bot builder components/slot components/slot details components/SlotDetails";
import FaqList from "../../pages/faq/FaqList";
import FaqDetails from "../../pages/faq/FaqDetails";
import ChatGpt from "../../pages/chat gpt component/ChatGpt";
import CannedMessagesList from "../../pages/canned messages components/CannedMessagesList";
import CannedMessagesDetails from "../../pages/canned messages components/CannedMessageDetails";
import ChatReportList from "./../../pages/report components/ReportList";
import LiveChatConfigDetails from "../../pages/livechat config components/LiveChatConfigDetails";
import LiveChatInsight from "../../pages/live chat insight/LiveChatInsightList";
import ChatTranscripts from "../../pages/chat transcripts/ChatTranscripts";
import LivechatRequest from "../../pages/live chat request/LivechatRequestListPage";
import ResetPassword from "../../pages/reset password/ResetPassword";
import WhatsappOnboarding from "../../pages/whatsapp onboarding component/WhatsappOnboarding";

import EmailTemplateList from "./../../pages/pmc/email components/email template components/EmailTemplateList";
import EmailBroadcastList from "./../../pages/pmc/email components/email broadcast components/EmailBroadcastList";
import EmailTemplateDetails from "./../../pages/pmc/email components/email template components/EmailTemplateDetails";
import Greviance from "../../pages/greviance components/Greviance";
import GrievanceDetails from "../../pages/greviance components/GrievanceDetails";
import PushNotification from "../../pages/push notification components/PushNotification";
import PushNotificationBroadcastList from "../../pages/push notification components/push notification broadcast components/PushNotificationBroadcastList";
import PushNotificationTemplateDetails from "../../pages/push notification components/push notification template components/PushNotificationTemplateDetails";
import PushNotificationTemplateList from "../../pages/push notification components/push notification template components/PushNotificationTemplateList";
import FCMCred from "./../../pages/FcmCred/FCMCred";

const dashboardLayoutRoutes = {
  //Main Module Routes
  homeRoute: {
    ID: pages.home.ID,
    path: pages.home.route,
    element: Home,
    index: true,
    disableAuth: true,
  },
  profileRoute: {
    ID: pages.profile.ID,
    path: pages.profile.route,
    element: Profile,
    disableAuth: true,
  },
  businessProfileRoute: {
    ID: pages.businessProfile.ID,
    path: pages.businessProfile.route,
    element: BusinessProfile,
    disableAuth: true,
  },
  resetPasswordRoute: {
    ID: pages.resetPassword.ID,
    path: pages.resetPassword.route,
    element: ResetPassword,
    disableAuth: true,
  },
  settingsRoute: {
    ID: pages.settings.ID,
    path: pages.settings.route,
    element: Settings,
    disableAuth: true,
  },

  //Faq Module Route
  faqsListRoute: {
    ID: pages.faqsList.ID,
    path: pages.faqsList.route,
    element: FaqList,
  },
  faqsDetailsRoute: {
    ID: pages.faqsDetail.ID,
    path: pages.faqsDetail.route,
    element: FaqDetails,
  },

  //Member Module Route
  memberListRoute: {
    ID: pages.memberList.ID,
    path: pages.memberList.route,
    element: Members,
  },
  memberCreateRoute: {
    ID: pages.memberCreate.ID,
    path: pages.memberCreate.route,
    element: MemberDetails,
  },
  memberDetailsRoute: {
    ID: pages.memberDetails.ID,
    path: pages.memberDetails.route + ":memberID",
    element: MemberDetails,
  },

  //Insights Route
  // insightsRoute: {
  //   ID: pages.insights.ID,
  //   path: pages.insights.route,
  //   element: Insights,
  // },

  //Bot Module Routes
  botListRoute: {
    ID: pages.botList.ID,
    path: pages.botList.route,
    element: Bots,
  },
  botDetailsRoute: {
    ID: pages.botDetails.ID,
    path: pages.botDetails.route + ":botID",
    element: BotDetails,
  },

  //Template Module Routes
  templateListRoute: {
    ID: pages.templateList.ID,
    path: pages.templateList.route,
    element: TemplateList,
  },
  templateDetailsRoute: {
    ID: pages.templateDetails.ID,
    path: pages.templateDetails.route + ":templateID",
    element: TemplateDetails,
  },
  templateCreateRoute: {
    ID: pages.templateCreate.ID,
    path: pages.templateCreate.route,
    element: TemplateDetails,
  },
  templateCloneRoute: {
    ID: pages.templateClone.ID,
    path: pages.templateClone.route + ":cloneID",
    element: TemplateDetails,
  },

  //Custom Field Module Routes
  customFieldList: {
    ID: pages.customFieldList.ID,
    path: pages.customFieldList.route,
    element: CustomFieldList,
  },
  customFieldCreate: {
    ID: pages.customFieldCreate.ID,
    path: pages.customFieldCreate.route,
    element: CustomFieldDetails,
  },
  customFieldDetailRoute: {
    ID: pages.customFieldDetails.ID,
    path: pages.customFieldDetails.route + ":fieldID",
    element: CustomFieldDetails,
  },

  chatGptRoute: {
    ID: pages.chatGptDetail.ID,
    path: pages.chatGptDetail.route,
    element: ChatGpt,
  },

  //Lead Module
  leadListRoute: {
    ID: pages.leadList.ID,
    path: pages.leadList.route,
    element: LeadList,
  },
  leadCreateRoute: {
    ID: pages.leadCreate.ID,
    path: pages.leadCreate.route,
    element: LeadDetails,
  },
  leadDetailsRoute: {
    ID: pages.leadDetails.ID,
    path: pages.leadDetails.route + ":leadID",
    element: LeadDetails,
  },

  //Broadcast Module

  broadcastListRoute: {
    ID: pages.broadcastList.ID,
    path: pages.broadcastList.route,
    element: BroadcastList,
  },
  broadcastCreateRoute: {
    ID: pages.broadcastCreate.ID,
    path: pages.broadcastCreate.route,
    element: BroadcastDetails,
  },
  broadcastDetailsRoute: {
    ID: pages.broadcastDetails.ID,
    path: pages.broadcastDetails.route + ":broadcastID",
    element: BroadcastDetails,
  },
  // Feedback Module
  feedbackDetailsRoute: {
    ID: pages.feedbackDetail.ID,
    path: pages.feedbackDetail.route,
    element: Feedback,
  },

  //whatsapp Module
  whatsappDetailsRoute: {
    ID: pages.whatsappNumberDetail.ID,
    path: pages.whatsappNumberDetail.route,
    element: WhatsappNumberDetailsPage,
  },

  //Group Module
  groupListRoute: {
    ID: pages.groupList.ID,
    path: pages.groupList.route,
    element: GroupList,
  },
  groupDetailsRoute: {
    ID: pages.groupDetails.ID,
    path: pages.groupDetails.route + ":groupID",
    element: GroupDetails,
  },

  //Labels Module
  labelListRoute: {
    ID: pages.labelList.ID,
    path: pages.labelList.route,
    element: Labels,
  },
  labelCreateRoute: {
    ID: pages.labelCreate.ID,
    path: pages.labelCreate.route,
    element: LabelDetails,
  },
  labelDetailsRoute: {
    ID: pages.labelDetails.ID,
    path: pages.labelDetails.route + ":labelID",
    element: LabelDetails,
  },

  //Utterance Module
  utteranceListRoute: {
    ID: pages.utteranceList.ID,
    path: pages.utteranceList.route,
    element: Utterances,
  },
  utteranceCreateRoute: {
    ID: pages.utteranceCreate.ID,
    path: pages.utteranceCreate.route,
    element: UtteranceDetails,
  },
  utteranceDetailsRoute: {
    ID: pages.utteranceDetails.ID,
    path: pages.utteranceDetails.route + ":utteranceID",
    element: UtteranceDetails,
  },

  //Webhook Module
  webhookListRoute: {
    ID: pages.webhookList.ID,
    path: pages.webhookList.route,
    element: Webhooks,
    disableAuth: true,
  },
  webhookCreateRoute: {
    ID: pages.webhookCreate.ID,
    path: pages.webhookCreate.route,
    element: WebhookDetails,
    disableAuth: true,
  },
  webhookDetailsRoute: {
    ID: pages.webhookDetails.ID,
    path: pages.webhookDetails.route + ":utteranceID",
    element: WebhookDetails,
    disableAuth: true,
  },

  //Webhook Module
  // integrationListRoute: {
  //   ID: pages.integrationList.ID,
  //   path: pages.integrationList.route,
  //   element: Integrations,
  //   disableAuth: true,
  // },
  // integrationCreateRoute: {
  //   ID: pages.integrationCreate.ID,
  //   path: pages.integrationCreate.route,
  //   element: IntegrationDetails,
  //   disableAuth: true,
  // },
  // integrationDetailsRoute: {
  //   ID: pages.integrationDetails.ID,
  //   path: pages.integrationDetails.route + ":integrationID",
  //   element: IntegrationDetails,
  //   disableAuth: true,
  // },

  //Chats Module
  chatsRoute: {
    ID: pages.chats.ID,
    path: pages.chats.route + ":leadID",
    element: Chats,
  },

  allChatsRoute: {
    ID: pages.chats.ID,
    path: pages.chats.route,
    element: Chats,
  },

  //Permission Groups Module
  permissionsGroupListRoute: {
    ID: pages.permissionsGroupList.ID,
    path: pages.permissionsGroupList.route,
    element: PermissionsGroups,
  },
  permissionsGroupDetailsRoute: {
    ID: pages.permissionsGroupDetails.ID,
    path: pages.permissionsGroupDetails.route + ":permissionsGroupID",
    element: PermissionsGroupDetails,
  },

  //bot builder module

  botPhrasesRoute: {
    ID: pages.botPhrases.ID,
    path: pages.botPhrases.route,
    element: PMCBroadcast,
  },
  botEntityListRoute: {
    ID: pages.botEntityList.ID,
    path: pages.botEntityList.route,
    element: EntityList,
  },
  botEntityDetailsRoute: {
    ID: pages.botEntityDetails.ID,
    path: pages.botEntityDetails.route + ":entityID",
    element: EntityDetails,
  },
  botEntityCreateRoute: {
    ID: pages.botEntityCreate.ID,
    path: pages.botEntityCreate.route,
    element: EntityDetails,
  },

  botIntentListRoute: {
    ID: pages.botIntentList.ID,
    path: pages.botIntentList.route,
    element: IntentList,
  },
  botIntentDetailsRoute: {
    ID: pages.botIntentDEtails.ID,
    path: pages.botIntentDEtails.route + ":intentID",
    element: IntentDetails,
  },
  botIntentCreateRoute: {
    ID: pages.botIntentDEtails.ID,
    path: pages.botIntentCreate.route,
    element: IntentDetails,
  },

  botExampleRoute: {
    ID: pages.botExample.ID,
    path: pages.botExample.route,
    element: BotTrainingPhrases,
  },

  slotListRoute: {
    ID: pages.slotList.ID,
    path: pages.slotList.route,
    element: SlotList,
  },

  slotCreateRoute: {
    ID: pages.slotCreate.ID,
    path: pages.slotCreate.route,
    element: SlotDetails,
  },

  slotDetailsRoute: {
    ID: pages.slotCreate.ID,
    path: pages.slotDetails.route + ":slotName",
    element: SlotDetails,
  },

  //style your rest bot

  styleBotRoute: {
    ID: pages.styleBot.ID,
    path: pages.styleBot.route,
    element: StyleBot,
  },

  //pmc module routes

  broadcastPmcRoute: {
    ID: pages.pmcRoute.ID,
    path: pages.pmcRoute.route,
    element: PMCBroadcast,
  },

  //broadcast module routes

  broadcastSailEmailListRoute: {
    ID: pages.sailBroadcastEmailList.ID,
    path: pages.sailBroadcastEmailList.route,
    element: EmailList,
  },

  broadcastSailEmailDetailRoute: {
    ID: pages.sailBroadcastEmailDetails.ID,
    path: pages.sailBroadcastEmailDetails.route,
    element: EmailBroadcastDetails,
  },

  broadcastSailSMSListRoute: {
    ID: pages.sailBroadcastSMSList.ID,
    path: pages.sailBroadcastSMSList.route,
    element: SMSList,
  },

  broadcastSailSMSDetailRoute: {
    ID: pages.sailBroadcastSMSDetails.ID,
    path: pages.sailBroadcastSMSDetails.route,
    element: MessageBroadcastDetails,
  },
  // canned message
  cannedMessagesListRoute: {
    ID: pages.cannedMessagesList.ID,
    path: pages.cannedMessagesList.route,
    element: CannedMessagesList,
  },
  cannedMessagesCreatelRoute: {
    ID: pages.cannedMessagesCreate.ID,
    path: pages.cannedMessagesCreate.route,
    element: CannedMessagesDetails,
  },
  cannedMessagesDetailRoute: {
    ID: pages.cannedMessagesCreate.ID,
    path: pages.cannedMessagesCreate.route + ":canned_message_id",
    element: CannedMessagesDetails,
  },
  // live chat report and config
  liveChatReportRoute: {
    ID: pages.liveChatReport.ID,
    path: pages.liveChatReport.route,
    element: ChatReportList,
  },
  liveChatConfigRoute: {
    ID: pages.liveChatConfig.ID,
    path: pages.liveChatConfig.route,
    element: LiveChatConfigDetails,
  },
  liveChatInsightRoute: {
    ID: pages.liveChatInsight.ID,
    path: pages.liveChatInsight.route,
    element: LiveChatInsight,
  },
  chatsTranscriptsRoute: {
    ID: pages.chatsTranscripts.ID,
    path: pages.chatsTranscripts.route,
    element: ChatTranscripts,
  },
  liveChatRequestRoute: {
    ID: pages.liveChatRequest.ID,
    path: pages.liveChatRequest.route,
    element: LivechatRequest,
  },
  whatsappOnboardingRoute: {
    ID: pages.whatsappOnboarding.ID,
    path: pages.whatsappOnboarding.route,
    element: WhatsappOnboarding,
  },

  emailTemplateCreateRoute: {
    ID: pages.emailTemplateCreate.ID,
    path: pages.emailTemplateCreate.route,
    element: EmailTemplateDetails,
  },

  emailTemplateDetailsRoute: {
    ID: pages.emailTemplateDetails.ID,
    path: pages.emailTemplateDetails.route + ":templateID",
    element: EmailTemplateDetails,
  },

  emailTemplateListRoute: {
    ID: pages.emailTemplateList.ID,
    path: pages.emailTemplateList.route,
    element: EmailTemplateList,
  },

  emailBroadcastListRoute: {
    ID: pages.emailBroadcastList.ID,
    path: pages.emailBroadcastList.route,
    element: EmailBroadcastList,
  },

  // Push Notification Create Broadcast Route
  pushNotificationDetailsRoute: {
    ID: pages.pushNotificationDetails.ID,
    path: pages.pushNotificationDetails.route,
    element: PushNotification,
  },

  // Push Notification Broadcast List Route
  pushNotificationBroadcastListRoute: {
    ID: pages.pushNotificationBroadcastList.ID,
    path: pages.pushNotificationBroadcastList.route,
    element: PushNotificationBroadcastList,
  },

  // Push Notification Create Template Route
  pushNotificationTemplateCreateRoute: {
    ID: pages.pushNotificationTemplateCreate.ID,
    path: pages.pushNotificationTemplateCreate.route,
    element: PushNotificationTemplateDetails,
  },

  // Push Notification Details Template Route
  pushNotificationTemplateDetailsRoute: {
    ID: pages.pushNotificationTemplateDetails.ID,
    path: pages.pushNotificationTemplateDetails.route + ":templateID",
    element: PushNotificationTemplateDetails,
  },

  // Push Notification Template List Route
  pushNotificationTemplateListRoute: {
    ID: pages.pushNotificationTemplateList.ID,
    path: pages.pushNotificationTemplateList.route,
    element: PushNotificationTemplateList,
  },

  //greviance

  grevianceListRoute: {
    ID: pages.grevianceList.ID,
    path: pages.grevianceList.route,
    element: Greviance,
  },

  grevianceDetailsRoute: {
    ID: pages.grevianceDetails.ID,
    path: pages.grevianceDetails.route + ":ticketID",
    element: GrievanceDetails,
  },

  //FCM

  FCMRoute: {
    ID: pages.FCM.ID,
    path: pages.FCM.route,
    element: FCMCred,
  },
};

export default dashboardLayoutRoutes;
