import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { Cancel, Edit, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { useAxios } from "../../../../hooks";
import pages from "../../../../constants/pages";
import BroadcastReport from "./BroadcastReport";
import scheduledDate from "./scheduledDateFormat";
import CancelDialog from "./CancelBroadcastDialogue";
import convertDate from "../../../../utils/convertDate";
import { DataTable, Chip } from "../../../../components";
import { alert } from "../../../../redux/slices/alertSlice";

export default function Table({ broadcasts, setCancelBroadcast }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });
  const [broadcastReport, setBroadcastReport] = useState({
    state: false,
    report: {},
    link: "",
  });

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Broadcast ID",
      options: {
        display: false,
        filter: true,
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 15) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },

    {
      name: "broadcast_name",
      label: "Broadcast Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "schedule_date",
      label: "Scheduled At",
      options: {
        customBodyRender: (value) => {
          return value ? scheduledDate(value) : "Not Available";
        },
      },
    },

    {
      name: "schedule_time",
      label: "Scheduled Time",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (rowIndex, index) => (
          <div style={{ display: "flex" }}>
            <div style={{ width: "35px" }}>
              {index.rowData[6] === "d35ceb22fa0e4c2b964e253062bf3460" && (
                <IconButton
                  onClick={() =>
                    navigate(pages.broadcastDetails.route + index.rowData[0])
                  }
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Edit Broadcast">
                    <Edit />
                  </Tooltip>
                </IconButton>
              )}
            </div>

            <div style={{ width: "35px" }}>
              {index.rowData[5] === "pending" && (
                <IconButton
                  style={{
                    color: "#ff0000",
                  }}
                  onClick={() =>
                    setDialogState({
                      state: true,
                      id: index.rowData[0],
                    })
                  }
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Cancel Broadcast">
                    <Cancel />
                  </Tooltip>
                </IconButton>
              )}
            </div>

            <div style={{ width: "35px" }}>
              <Tooltip title="View Report">
                <IconButton
                  onClick={() => loadReport(index.rowData[0])}
                  color="primary"
                  size="small"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ),
      },
    },
  ];

  const loadReport = (id) => {
    axios({
      url:
        "/broadcast_sms/get_broadcast_list/?agent_id=" +
        activeBot.ID +
        "&broadcast_id=" +
        id,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        setBroadcastReport({
          state: true,
          report: res.data,
        });
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  const cancelBroadcast = () => {
    var data = dialogState.id;
    axios({
      url: `/broadcast_sms/cancel_broadcast?agent_id=${activeBot.ID}&broadcast_id=${data}`,
      method: "POST",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        setCancelBroadcast(res.data.sbcid);
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={broadcasts} />

      <BroadcastReport
        broadcastReport={broadcastReport}
        setBroadcastReport={setBroadcastReport}
        activeBot={activeBot}
        loadReport={loadReport}
      />

      <CancelDialog
        dialogState={dialogState.state}
        setDialogState={setDialogState}
        handleAgree={cancelBroadcast}
      />
    </React.Fragment>
  );
}
