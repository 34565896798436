import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import { Loader } from "../../components";
import pages from "../../constants/pages";
import PageHeader from "../../components/PageHeader";
import Table from "./template list component/Table";
import Stats from "./template list component/Stats";
import { startLoader, apiComplete } from "../../redux/slices/loaderSlice";

export default function TemplateList({ pageID }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { pagePermissions } = useComponent({ pageID });

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [templates, setTemplates] = useState([]);
  const [templateDelete, setTemplateDelete] = useState("");

  useEffect(() => {
    dispatch(startLoader());

    axios({
      url: "/wa/template/" + activeBot.ID,
    }).then((response) => {
      if (response.status) {
        setTemplates(response.data);
        dispatch(apiComplete());
      }
    });
  }, [axios, templateDelete, dispatch, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Templates"
        addIcon={[
          "Create Template",
          pages.templateCreate.route,
          pagePermissions.create,
        ]}
      />

      <Stats list={templates} />

      <Table
        templates={templates}
        setTemplates={setTemplates}
        agentID={activeBot.ID}
        setTemplateDelete={setTemplateDelete}
      />
    </Loader>
  );
}
