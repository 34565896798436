import { Fragment } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { validationSchema, objectFromFormData } from "./utteranceDetailsObject";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";

export default function Form({
  botID,
  botServiceID,
  editState,
  setEditState,
  formData,
  pagePermissions,
}) {
  const { axios, alert } = useComponent();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let method = "POST";
        let URL = `/utterance/${botServiceID}?bot_id=${botID}`;

        if (!!formData.utteranceID) {
          method = "PUT";
          URL = URL + "?utterance_id=" + formData.utteranceID;
        }

        axios({
          url: URL,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          setSubmitting(false);
          setEditState(false);

          if (response.status) {
            alert.success(response.message.displayMessage);
            navigate(pages.utteranceList.route);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
          />

          <ButtonFooter
            ID={formikProps.values.utteranceID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              () => handleUtteranceDelete(formikProps.values.utteranceID),
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );

  function handleUtteranceDelete(utteranceID) {
    axios({
      url: `/utterance/${botServiceID}/${utteranceID}?bot_id=${botID}`,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);

        navigate(pages.utteranceList.route);
      }
    });
  }
}
