import { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Link,
  Grid,
  Button,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { TextField, FileUpload } from "../../../../components";

export default function AddWhatsAppNumberDialog({
  dialogState,
  handleSubmit,
  handleDialogClose,
}) {
  return (
    <Dialog fullWidth maxWidth="md" open={dialogState.open}>
      <DialogTitle>Add WhatsApp Phone Number</DialogTitle>

      <Formik
        initialValues={dialogState.initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData) => {
          handleSubmit(formData);

          handleDialogClose();
        }}
      >
        {(formikProps) => (
          <Fragment>
            <DialogContent dividers>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    required
                    disabled={!!dialogState.initialValues.phoneNumber}
                    label="Phone Number"
                    name="phoneNumber"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Phone number that needs to be registered for WhatsApp
                    Business API.{" "}
                    <Link
                      href="https://developers.facebook.com/docs/whatsapp/overview/phone-number/"
                      target="_blank"
                    >
                      Click here
                    </Link>{" "}
                    for more details
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    label="Display Name"
                    name="displayName"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Assign a display name to your phone number.{" "}
                    <Link
                      href="https://developers.facebook.com/docs/whatsapp/overview/display-name"
                      target="_blank"
                    >
                      Click here
                    </Link>{" "}
                    for more details.
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    required
                    label="Category"
                    name="categoryType"
                    formikProps={formikProps}
                  >
                    <MenuItem value="Automotive">Automotive</MenuItem>
                    <MenuItem value="Beauty, Spa and Salon">
                      Beauty, Spa and Salon
                    </MenuItem>
                    <MenuItem value="Clothing and Apparel">
                      Clothing and Apparel
                    </MenuItem>
                    <MenuItem value="Education">Education</MenuItem>
                    <MenuItem value="Entertainment">Entertainment</MenuItem>
                    <MenuItem value="Event Planning and Service">
                      Event Planning and Service
                    </MenuItem>
                    <MenuItem value="Finance and Banking">
                      Finance and Banking
                    </MenuItem>
                    <MenuItem value="Food and Grocery">
                      Food and Grocery
                    </MenuItem>
                    <MenuItem value="Public Service">Public Service</MenuItem>
                    <MenuItem value="Hotel and Lodging">
                      Hotel and Lodging
                    </MenuItem>
                    <MenuItem value="Medical and Health">
                      Medical and Health
                    </MenuItem>
                    <MenuItem value="Non-profit">Non-profit</MenuItem>
                    <MenuItem value="Professional Services">
                      Professional Services
                    </MenuItem>
                    <MenuItem value="Shopping and Retail">
                      Shopping and Retail
                    </MenuItem>
                    <MenuItem value="Travel and Transportation">
                      Travel and Transportation
                    </MenuItem>
                    <MenuItem value="Restaurant">Restaurant</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>

                  <Typography variant="caption" color="gray">
                    Category of your business.{" "}
                    <Link
                      href="https://developers.facebook.com/docs/whatsapp/api/settings/business-profile/#parameters"
                      target="_blank"
                    >
                      Click here
                    </Link>{" "}
                    for more details
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <FileUpload
                    required
                    maxSize="5"
                    folderPath={
                      "whatsapp/registration/profile_pictures/" +
                      new Date().getTime() +
                      "_"
                    }
                    allowedExtension=".jpg, .jpeg"
                    label="Profile Picture"
                    name="displayPicture"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Max file size in 5 MBs
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Status Message"
                    name="statusText"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Business status displayed to the user when they view the
                    WhatsApp profile.
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Website URL"
                    name="websiteURL"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Business URL displayed to the user when they view the
                    WhatsApp profile.
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    multiline
                    rows={4}
                    label="Description Message"
                    name="descriptionText"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Business description displayed to the user when they view
                    the WhatsApp profile.
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    multiline
                    rows={4}
                    label="Address"
                    name="addressText"
                    formikProps={formikProps}
                  />

                  <Typography variant="caption" color="gray">
                    Business Address displayed to the user when they view the
                    WhatsApp profile.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Single Person of Contact</Typography>

                  <Typography variant="subtitle2" color="gray">
                    Enter the details of the person who'll be contacted for
                    phone number verification.
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    required
                    rows={4}
                    label="Name"
                    name="SPOC.name"
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    required
                    rows={4}
                    label="Phone Number"
                    name="SPOC.number"
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    required
                    rows={4}
                    label="Email"
                    name="SPOC.email"
                    formikProps={formikProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Grid container spacing={4}>
                <Grid item xs />

                <Grid item>
                  <Button onClick={handleDialogClose}>Cancel</Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    disabled={!formikProps.isValid}
                    onClick={formikProps.handleSubmit}
                  >
                    {!!dialogState.initialValues.phoneNumber ? "Edit" : "Add"}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Dialog>
  );
}

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required("Please enter a phone number")
    .matches(/^[1-9][0-9]{9}$/, "Please enter a valid phone number"),
  displayName: Yup.string()
    .required("Please enter a display name")
    .min(3, "Minimum 3 characters required."),
  displayPicture: Yup.string().required("Please upload a display picture"),
  statusText: Yup.string(),
  descriptionText: Yup.string().max(
    256,
    "Description cannot be more than 256 characters"
  ),
  addressText: Yup.string().max(
    256,
    "Address cannot be more than 256 characters"
  ),
  categoryType: Yup.string().required("Please select a category"),
  websiteURL: Yup.string().url("Please enter a valid URL"),
  SPOC: Yup.object({
    name: Yup.string().required("Please enter a name for verification"),
    number: Yup.string()
      .required("Please enter a number for verification")
      .matches(/^[1-9][0-9]{9}$/, "Please enter a valid phone number"),
    email: Yup.string()
      .required("Please enter a email for verification")
      .email("Please enter a valid email address"),
  }),
});
