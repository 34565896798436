import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import Table from "./Table";
import pages from "../../../../constants/pages";
import { useAxios, useComponent } from "../../../../hooks";
import { Loader, PageHeader } from "../../../../components";

export default function EntityList({ pageID }) {
  const axios = useAxios({ baseURL: "botBuilder" });
  const { loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [entities, setEntities] = useState([]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/get-entity/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setEntities(response.data);
        loader.stop();
      }
    });
  }, [axios, loader, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName={"Entity List"}
        addIcon={[
          "Add Entity",
          pages.botEntityCreate.route,
          pagePermissions.create,
        ]}
      />
      <Table entities={entities} />
    </Loader>
  );
}
