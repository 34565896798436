import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import pages from "../../constants/pages";
import {
  botDetailsObject,
  formatBotDetailsResponse,
  formatLiveChatDetailsResponse,
  memberConfigResponse,
} from "./bot details components/botDetailsObject";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./bot details components/Form";

export default function BotDetails({ pageID }) {
  const { botID } = useParams();
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(botDetailsObject);

  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/bot/detail/" + botID,
    }).then((response) => {
      if (response.status) {
        setFormData((prevValue) => ({
          ...prevValue,
          ...formatBotDetailsResponse(response.data),
        }));

        loader.apiComplete();
      }
    });

    axios({
      url: "/member?bot_id=" + botID,
    }).then((response) => {
      if (response.status) {
        setMemberList(response.data);
      }
    });

    axios({
      url: "/livechat/member-config?bot_id=" + botID,
    }).then((response) => {
      if (response.status) {
        setFormData((prevVal) => ({
          ...prevVal,
          ...memberConfigResponse(response.data),
        }));
      }
    });

    axios({
      url: `/livechat/config/${botID}`,
    }).then((response) => {
      if (response.status) {
        setFormData((prevValue) => ({
          ...prevValue,
          ...formatLiveChatDetailsResponse(response.data),
        }));

        loader.apiComplete();
      }
    });
  }, [loader, axios, botID]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Details"
        breadcrumbs={[{ location: pages.botList.route, name: "Bots" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
        memberList={memberList}
        botID={botID}
      />
    </Loader>
  );
}
