import { useNavigate } from "react-router-dom";

import { DataTable } from "../../../components";
import convertDate from "../../../utils/convertDate";
import pages from "../../../constants/pages";

export default function Table({ grievance }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(pages.grevianceDetails.route + grievance[dataIndex].ticket_id, {
        state: {
          data: grievance[dataIndex],
        },
      }),
  };
  return <DataTable columns={columns} data={grievance} options={options} />;
}

const columns = [
  {
    name: "ticket_id",
    label: "Ticket ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "message",
    label: "Message",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "subject",
    label: "Subject",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "created_by",
    label: "Created By",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
