import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import LabelsTable from "./labels components/LabelsTable";
import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";
import pages from "../../constants/pages";

export default function Labels({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader, pagePermissions } = useComponent({ pageID });

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: `/label/${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();

        setLabels(response.data);
      }
    });
  }, [activeBot.ID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Labels"
        addIcon={[
          "Add New Label",
          pages.labelCreate.route,
          pagePermissions.create,
        ]}
      />

      <LabelsTable labels={labels} />
    </Loader>
  );
}
