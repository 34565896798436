import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAxios, useComponent } from "../../../../hooks";
import { Loader, PageHeader } from "../../../../components";
import { slotObject, dataFromResponse } from "./slotDetailsObject";
import Form from "./Form";

export default function SlotDetails({ pageID }) {
  const params = useParams();
  const axios = useAxios({ baseURL: "botBuilder" });

  const [intents, setIntents] = useState([]);
  const [entities, setEntities] = useState([]);

  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(slotObject);

  const { pagePermissions, loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);
  const slotName = params.slotName || "";

  useEffect(() => {
    if (!!formData.ID) {
      setEditState(false);
    }
  }, [formData.ID]);

  useEffect(() => {
    loader.start(3);
    axios({
      url: "/get-intent/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setIntents(response.data);
        loader.apiComplete();
      }
    });

    axios({
      url: "/get-entity/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setEntities(response.data);
        loader.apiComplete();
      }
    });

    if (!!slotName) {
      axios({
        url: "/get-slots/" + activeBot.ID,
        method: "POST",
        data: {
          slot_name: [slotName],
        },
      }).then((response) => {
        if (response.status) {
          setFormData(dataFromResponse(response.data));
          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [axios, loader, activeBot.ID, slotName]);

  return (
    <Loader>
      <PageHeader
        pageName="Intent Details"
        editIcon={[editState, setEditState, pagePermissions.update]}
      />
      <Form
        intents={intents}
        entities={entities}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
