import React, { useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { DataTable } from "../../../../components";
import OptionPopUp from "./OptionPopUp";
import DeleteDialog from "../../custom field details component/DeleteDailogue";

export default function OptionList({ list, formikProps, editState }) {
  for (let option of formikProps.values?.options) {
    if (option.is_active) {
      list.push(option);
    }
  }

  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  const [addOptionPopUp, setAddOptionPopUp] = useState({
    state: false,
    data: {},
  });

  function handleCellClick(cellData, cellMetaData) {
    if (cellMetaData.colIndex !== 2) {
      setAddOptionPopUp({
        state: true,
        data: {
          id: list[cellMetaData.dataIndex].id,
          label: list[cellMetaData.dataIndex].label,
          is_active: true,
        },
      });
    }
  }

  const options = {
    onCellClick: handleCellClick,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    download: false,
    print: false,
    pagination: true,
    search: true,
    filter: false,
  };

  const columns = [
    {
      name: "id",
      label: "Option ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "label",
      label: "Option Label",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        customBodyRender: (rowIndex, index) => (
          <IconButton
            disabled={!editState}
            style={{
              color: editState ? "#ff0000" : "#00000042",
              padding: "0px",
            }}
            onClick={() =>
              setDialogState({
                state: true,
                id: index.rowIndex,
              })
            }
          >
            <DeleteOutline />
          </IconButton>
        ),
      },
    },
  ];

  function deleteOptions() {
    let newOptions = [...formikProps.values.options];
    newOptions.splice(dialogState.id, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      options: newOptions,
    }));
  }

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={list} />
      <OptionPopUp
        setAddOptionPopUp={setAddOptionPopUp}
        addOptionPopUp={addOptionPopUp}
        formikProps={formikProps}
      />
      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={deleteOptions}
        title={"Delete Option"}
        description={
          "Are you sure you want to delete this option. If so please select delete."
        }
      />
    </React.Fragment>
  );
}
