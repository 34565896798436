import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import Table from "./Table";
import pages from "../../../../constants/pages";
import { useAxios, useComponent } from "../../../../hooks";
import { Loader, PageHeader } from "../../../../components";

export default function SlotList({ pageID }) {
  const axios = useAxios({ baseURL: "botBuilder" });
  const { loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [slots, setSlots] = useState([]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/get-slots/" + activeBot.ID,
      method: "POST",
      data: {
        slot_name: [],
      },
    }).then((response) => {
      if (response.status) {
        setSlots(response.data);
        loader.stop();
      }
    });
  }, [axios, loader, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName={"Slot List"}
        addIcon={["Add Slot", pages.slotCreate.route, pagePermissions.create]}
      />
      <Table slots={slots} />
    </Loader>
  );
}
