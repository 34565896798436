import {
  Typography as MuiTypography,
  Avatar,
  Button,
  Divider,
} from "@mui/material";
import styled from "styled-components";
import { Article } from "@mui/icons-material";
import SmsFailedSharpIcon from "@mui/icons-material/SmsFailedSharp";
import parse from "html-react-parser";

import { convertDate } from "../../../../../../utils";
import React from "react";

const Wrapper = styled.div`
  max-width: 70%;
`;

const BodyWrapper = styled.div`
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  > button {
    width: 33%;
    flex-grow: 1;
  }
`;

const Typography = styled(MuiTypography)`
  text-align: justify;
  word-break: break-all;
`;

const Image = styled.img`
  height: 160px;
  max-width: 320px;
  cursor: pointer;
`;

const Video = styled.video`
  height: 160px;
  max-width: 320px;
`;

const Audio = styled.audio`
  height: 50px;
  width: 300px;
  ::-webkit-media-controls-panel {
    background: white;
  }
`;

const Document = styled.div`
  background: #fff;
  height: 50px;
  width: 300px;
  border-radius: 4px;
  margin-bottom: 4px;
  padding-inline: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export default function MessageBody({ message, userState }) {
  function textToHtmlFormat(string) {
    const htmlFormat = [
      {
        symbol: "*",
        tag: "b",
      },
      {
        symbol: "_",
        tag: "em",
      },
      {
        symbol: "~",
        tag: "del",
      },
      {
        symbol: "`",
        tag: "code",
      },
      {
        symbol: "\n",
        tag: "br",
      },
    ];

    htmlFormat.forEach(({ symbol, tag }) => {
      if (!string) return;

      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
      const match = string.match(regex);
      if (!match) return;

      match.forEach((m) => {
        let formatted = m;
        for (let i = 0; i < 2; i++) {
          formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
        }
        string = string.replace(m, formatted);
      });
    });
    return string;
  }

  return message.type === "custom" ? (
    <Wrapper>
      <div style={{ border: "2px solid green" }}>
        <BodyWrapper style={{ background: "#f1f0f0", color: "#000" }}>
          {!!message.payload.header.imageURL && (
            <Image
              onClick={() => openMediaInNewTab(message.payload.header.imageURL)}
              src={message.payload.header.imageURL}
            />
          )}

          {!!message.payload.header.videoURL && (
            <Video controls>
              <source src={message.payload.header.videoURL} />
            </Video>
          )}

          {!!message.payload.header.documentURL && (
            <Document
              onClick={() =>
                openMediaInNewTab(message.payload.header.documentURL)
              }
            >
              <Avatar>
                <Article />
              </Avatar>

              <Typography variant="subtitle2" color="#000">
                {message.payload.header.documentName || "Unknown Document"}
              </Typography>
            </Document>
          )}

          {!!message.payload.header.text && (
            <Typography variant="subtitle2" color="#000">
              {message.payload.header.text}
            </Typography>
          )}

          {!!message.payload.body && (
            <Typography variant="body1" mt={2}>
              {parse(textToHtmlFormat(message.payload.body))}
            </Typography>
          )}

          {!!message.payload.footer && (
            <React.Fragment>
              <Divider />
              <Typography id="whatsapp-demo-message-footer">
                {message.payload.footer}
              </Typography>
            </React.Fragment>
          )}
        </BodyWrapper>

        {!!message.payload.buttons && message.payload.buttons.length > 0 && (
          <ButtonWrapper>
            {message.payload.buttons.map((button, index) => (
              <Button key={index} variant="outlined">
                {button.text}
              </Button>
            ))}
          </ButtonWrapper>
        )}
      </div>

      <Typography
        variant="caption"
        style={{ float: message.actor === "user" ? "left" : "right" }}
      >
        {convertDate(message.timeStamp)}
      </Typography>
    </Wrapper>
  ) : (
    <Wrapper>
      <BodyWrapper
        style={{
          background: message.actor === "user" ? "#f1f0f0" : "#397be2",
          color: message.actor === "user" ? "#000" : "#fff",
          maxWidth: message.actor === "user" ? "800px" : "",
        }}
      >
        {!!message.body.text && (
          <Typography variant="body1">{message.body.text}</Typography>
        )}

        {!!message.body.buttonLabel && (
          <Button variant="outlined">{message.body.buttonLabel}</Button>
        )}

        {Object.keys(message.body).length === 0 && message.actor === "user" ? (
          <p>
            <SmsFailedSharpIcon sx={{ fontSize: 25 }} /> User shared unsupported
            media type like location, contact... <br /> We do not support these
            as of now...
          </p>
        ) : (
          ""
        )}

        {!!message.body.imageURL && (
          <Image
            onClick={() => openMediaInNewTab(message.body.imageURL)}
            src={message.body.imageURL}
          />
        )}

        {!!message.body.videoURL && (
          <Video controls>
            <source src={message.body.videoURL} />
          </Video>
        )}

        {!!message.body.documentURL && (
          <Document onClick={() => openMediaInNewTab(message.body.documentURL)}>
            <Avatar>
              <Article />
            </Avatar>

            <Typography variant="subtitle2" color="#000">
              {message.body.documentName || "Unknown Document"}
            </Typography>
          </Document>
        )}

        {!!message.body.audioURL && (
          <Audio controls>
            <source src={message.body.audioURL} />
          </Audio>
        )}

        {!!message.body.header && (
          <Typography variant="subtitle2">{message.body.header}</Typography>
        )}

        {!!message.body.footer && (
          <Typography variant="body1">{message.body.footer}</Typography>
        )}

        {!!message.body.caption && (
          <Typography variant="body1">{message.body.caption}</Typography>
        )}
      </BodyWrapper>

      {!!message.body.buttons && message.body.buttons.length > 0 && (
        <ButtonWrapper>
          {message.body.buttons.map((button, index) => (
            <Button key={index} variant="outlined">
              {button.title}
            </Button>
          ))}
        </ButtonWrapper>
      )}

      <Typography
        variant="caption"
        style={{ float: message.actor === "user" ? "left" : "right" }}
      >
        {convertDate(message.timeStamp)}
      </Typography>
    </Wrapper>
  );

  function openMediaInNewTab(url) {
    return window.open(url, "_blank");
  }
}
