import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";

import EmailTemplate from "./EmailTemplate";
import { useAxios, useComponent } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function EmailDialog({
  popUp,
  setPopUp,
  integrationDetails,
  userInfo,
  formikProps,
}) {
  const axios = useAxios({
    baseURL: "component",
  });
  const classes = useStyles();
  const { alert } = useComponent();

  const [cc, setCc] = useState([]);
  const [mails, setMails] = useState([]);

  const data = {
    integration_id: integrationDetails?.integration_id,
    service: integrationDetails?.service,
    to: [formikProps?.values?.email],
    cc: [],
    subject: "",
    html_content: "",
    attachments: [],
  };

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Send Mail</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={data}
          enableReinitialize
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "/email/mail_v2",
              method: "POST",
              data: formData,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                alert.success("Message sent successfully!");
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Send email to user
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <EmailTemplate
                      formikProps={dialogFormikProps}
                      mails={mails}
                      setMails={setMails}
                      cc={cc}
                      setCc={setCc}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item xs></Grid>

                  <Grid item xs style={{ textAlign: "end" }}>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Send Mail
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}

const validationSchema = Yup.object().shape({
  subject: Yup.string().required("Give subject to your mail."),
  html_content: Yup.string().required(
    "You need to provide some message.It can't be blank."
  ),
});
