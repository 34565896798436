import React, { useState } from "react";
import { Grid, MenuItem, Button } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import {
  Autocomplete,
  ComponentHeader,
  FileUpload,
  Paper,
  TextField,
} from "../../../components";
import Instructions from "./Instructions";
import { useAxios } from "../../../hooks";
import PublicURLDialog from "./public url components/PublicURLDialog";
import { templateDataResponse } from "../broadcast details components/templateDataObject";

export default function FormDisplay({
  formikProps,
  editState,
  template,
  scheduledTime,
  templateData,
  setTemplateData,
  setScheduledTime,
  activeBot,
  groupList,
  setPreviewData,
  fields,
}) {
  const axios = useAxios();
  const [popUp, setPopUp] = useState(false);

  const handleChange = (value) => {
    let date = new Date(value);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let time = day + "-" + month + "-" + year;

    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: value,
    }));

    axios({
      url: "/broadcast/time?schedule_date=" + time,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };

  const handleChangeTemplate = (value, type) => {
    if (type === "selectOption") {
      axios({
        url: "/wa/template/variable/" + activeBot.ID + "/" + value._id,
        method: "GET",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setTemplateData(response.data);
        }
      });

      setPreviewData(templateDataResponse(value));

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    } else {
      value = {
        _id: "",
        language: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateID: value._id,
      }));
    }
  };

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Broadcast Details"
            subtitle={"Details for creating the broadcast."}
            buttons={[<GetPublicURL />]}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Broadcast Name"
            name="broadcastName"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            required
            label="When To Broadcast"
            name="whenToBroadcast"
            disabled={!editState}
            formikProps={formikProps}
          >
            <MenuItem value={"now"}>Now</MenuItem>
            <MenuItem value={"later"}>Later</MenuItem>
          </TextField>
        </Grid>

        {Boolean(formikProps.values.whenToBroadcast) &&
          formikProps.values.whenToBroadcast !== "now" && (
            <React.Fragment>
              <Grid item xs={6}>
                <DesktopDatePicker
                  name="broadcastDate"
                  label="Select Date"
                  inputFormat="MM-dd-yyyy"
                  onChange={handleChange}
                  formikProps={formikProps}
                  value={formikProps.values.broadcastDate}
                  minDate={new Date()}
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() + 30))
                  }
                  disabled={
                    !editState || formikProps.values.whenToBroadcast === "now"
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="broadcastDate"
                      label="Select Date"
                      formikProps={formikProps}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Select Time"
                  name="broadcastTime"
                  disabled={
                    !editState || formikProps.values.whenToBroadcast === "now"
                  }
                  formikProps={formikProps}
                >
                  {scheduledTime.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </React.Fragment>
          )}

        <Grid item xs={6}>
          <Autocomplete
            name="templateID"
            label="Select Template"
            defaults={{
              primaryKey: "_id",
              displayLabel: "name",
            }}
            options={template}
            onChange={(_, value, type) => handleChangeTemplate(value, type)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <FileUpload
            label="Upload File"
            name="uploadCSVFile"
            formikProps={formikProps}
            allowedExtension={".xlsx"}
            maxSize={16}
            folderPath={"broadcast/" + activeBot.ID + "/"}
          />
        </Grid>

        <Instructions
          formikProps={formikProps}
          templateData={templateData}
          setTemplateData={setTemplateData}
          groupList={groupList}
          template={template}
          activeBot={activeBot}
          fields={fields}
          setPreviewData={setPreviewData}
        />
      </Paper>

      <PublicURLDialog popUp={popUp} setPopUp={setPopUp} />
    </React.Fragment>
  );

  function GetPublicURL() {
    return (
      <Button variant="outlined" color="primary" onClick={() => setPopUp(true)}>
        Get Public URL
      </Button>
    );
  }
}
