import { useState } from "react";
import { Grid, Button } from "@mui/material";

import { useAxios, useComponent } from "../../../../hooks";
import { Chip, ComponentHeader, Paper } from "../../../../components";
import LabelDialog from "./LabelDialog";

export default function Label({
  labels,
  formData,
  assignLabel,
  setAssignUnassignLabel,
  pagePermissions,
}) {
  const axios = useAxios();
  const { alert } = useComponent();
  const [labelDialog, setLabelDialog] = useState(false);

  function handleDelete(id) {
    // setAssignUnassignLabel();
    if (pagePermissions.delete) {
      axios({
        url: "/label/assign/label/" + id + "?lead_id=" + formData.ID,
        disableRedirect: true,
        method: "PATCH",
        data: {
          action: "unassign",
        },
      }).then((response) => {
        if (response.status) {
          alert.success(response.message.displayMessage);
          setAssignUnassignLabel();
        }
      });
    } else {
      alert.warning("You don't have permission to delete the label!");
    }
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Labels"}
          subtitle="Add labels for the leads."
          buttons={[
            formData.ID && pagePermissions.create && (
              <Button variant="outlined" onClick={() => setLabelDialog(true)}>
                Assign Label
              </Button>
            ),
          ]}
        />
      </Grid>
      {assignLabel.map((item) => (
        <Grid item key={item._id}>
          <Chip
            label={item.title}
            style={{ backgroundColor: item.color, color: "#fff" }}
            onDelete={() => handleDelete(item._id)}
          />
        </Grid>
      ))}

      <LabelDialog
        data={formData}
        labelDialog={labelDialog}
        setLabelDialog={setLabelDialog}
        labels={labels}
        pagePermissions={pagePermissions}
        assignLabel={assignLabel}
        setAssignUnassignLabel={setAssignUnassignLabel}
      />
    </Paper>
  );
}
