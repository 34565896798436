import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import { Checkbox, IconButton, Tooltip } from "@mui/material";

import { DataTable } from "../../components";
import { convertDate } from "../../utils";
import { useAxios } from "../../hooks";
import RequestDetails from "./view chat transcripts request details/RequestDetails";
import CustomFilter from "./CustomFilter";

export default function Table({ listData, setListData }) {
  const axios = useAxios();

  const { ID, activeBot } = useSelector((state) => state.memberDetails);

  const [requestDetails, setRequestDetails] = useState([]);

  const [requestDetailsDailog, setRequestDetailsDailog] = useState(false);

  function requestChatTranscriptsReport(index) {
    axios({
      url: `/insights/transcription_list/${activeBot.ID}?request_id=${index}`,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setRequestDetails(response.data);
      }
    });
    setRequestDetailsDailog(true);
  }

  const columns = [
    {
      name: "_id",
      label: "Request ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "report_name",
      label: "Request Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "requested_by",
      label: "Created by",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created at",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "updated_at",
      label: "Updated at",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (rowIndex, index) => (
          <div>
            <div style={{ width: "35px" }}>
              <IconButton
                color="primary"
                size="small"
                onClick={() => requestChatTranscriptsReport(index.rowData[0])}
              >
                <Tooltip title="View Request Detail">
                  <Visibility />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        ),
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },

    customToolbar: () => <CustomFilter setListData={setListData} />,
  };
  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={listData} />

      <RequestDetails
        requestDetails={requestDetails}
        requestDetailsDailog={requestDetailsDailog}
        setRequestDetailsDailog={setRequestDetailsDailog}
      ></RequestDetails>
    </Fragment>
  );
}
