import { Fragment } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { validationSchema, objectFromFormData } from "./labelDetailsObject";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";
import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";

export default function Form({
  botID,
  editState,
  setEditState,
  formData,
  setFormData,
  pagePermissions,
}) {
  const { axios, alert } = useComponent();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let method = "POST";
        let URL = "/label/" + botID;

        if (!!formData.labelID) {
          method = "PUT";
          URL = URL + "/" + formData.labelID;
        }

        axios({
          url: URL,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            alert.success(response.message.displayMessage);
            setEditState(false);

            if (!!formData.labelID) {
              setFormData(formData);
            } else {
              navigate(pages.labelDetails.route + response.data._id);
            }
            setSubmitting(false);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            pagePermissions={pagePermissions}
          />

          <ButtonFooter
            ID={formikProps.values.labelID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[
              pagePermissions.delete,
              () => handleLabelDelete(formikProps.values.labelID),
            ]}
          />
        </Fragment>
      )}
    </Formik>
  );

  function handleLabelDelete(labelID) {
    axios({
      url: `/label/${botID}/${labelID}`,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);

        navigate(pages.labelList.route);
      }
    });
  }
}
