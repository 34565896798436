import { DataTable } from "../../../components";

export default function FaqTable({ faqList }) {
  return <DataTable columns={columns} data={faqList} />;
}

const columns = [
  {
    name: "topic",
    label: "Topic",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "no_of_question",
    label: "No of Questions",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
];
