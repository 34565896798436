import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from "styled-components";

import { chatReducerConstants } from "../chatReducer";
import { AvatarButton } from "../../../../components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function ProfileHeader({ setChatState }) {
  return (
    <Wrapper>
      <Typography variant="h5">Profile</Typography>

      <AvatarButton
        style={{ color: "black" }}
        onClick={() => {
          setChatState({ type: chatReducerConstants.HIDE_PROFILE_SECTION });
        }}
      >
        <Close fontSize="small" />
      </AvatarButton>
    </Wrapper>
  );
}
