import { Fragment, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { AiFillSetting } from "react-icons/ai";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";

import { PageHeader, Paper } from "../../components";
import BusinessForm from "./whatsapp profile style components/BusinessForm";

export default function WhatsappNumberDetailsPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <PageHeader pageName="WhatsApp Number Details" />
      <Paper>
        <Grid item xs={12}>
          <Typography variant="h3">Bot Details</Typography>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item sx={10} style={{ display: "flex" }}>
          <Paper style={{ marginTop: "10px", height: "fit-content" }}>
            <Tabs
              style={{ width: "150px" }}
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              <Tab
                iconPosition="start"
                icon={<CgProfile size={18} />}
                style={{ fontSize: "13px" }}
                label="Profile"
              />

              <Tab
                iconPosition="start"
                icon={<AiFillSetting size={18} />}
                style={{ fontSize: "13px" }}
                label="Settings"
              />
            </Tabs>
          </Paper>

          <TabPanel value={value} index={0}>
            <Grid item xs={12}>
              <BusinessForm />
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Paper>
              <Grid item xs={12}>
                <Typography variant="h4">Settings</Typography>
              </Grid>
            </Paper>
          </TabPanel>
        </Grid>
      </Grid>
    </Fragment>
  );
}
