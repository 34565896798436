import * as Yup from "yup";

export const registrationFormObject = [
  {
    firstName: "",
    lastName: "",
    contactEmail: "",
    phoneNumber: "",
    numberVerified: false,
  },
  {
    businessName: "",
    businessRegisteredAddr: "",
    businessEmail: "",
    businessGSTDetails: "",
  },
  {
    whatsappNumberDetails: [],
  },
  {
    fbBusinessVerificationID: "",
    karixTnCFromURL: "",
    wabaActivationRequestURL: "",
  },
];

export function objectFromResponseData(responseData) {
  return [
    {
      firstName: responseData.contact_details.first_name,
      lastName: responseData.contact_details.last_name || "",
      contactEmail: responseData.contact_details.contact_email,
      phoneNumber: responseData.contact_details.phone_number,
      numberVerified: responseData.contact_details.number_verified,
    },
    {
      businessName: responseData.business_details.business_name,
      businessRegisteredAddr: responseData.business_details.business_address,
      businessEmail: responseData.business_details.business_email,
      businessGSTDetails: responseData.business_details.gst,
    },
    {
      whatsappNumberDetails: responseData.whatsapp_details.map(
        (whatsappNumber) => ({
          phoneNumber: whatsappNumber.whatsapp_phone_number,
          displayName: whatsappNumber.display_name,
          displayPicture: whatsappNumber.display_picture || "",
          statusText: whatsappNumber.status || "",
          descriptionText: whatsappNumber.description || "",
          addressText: whatsappNumber.address || "",
          categoryType: whatsappNumber.category,
          websiteURL: whatsappNumber.website_url || "",
          approvalStatus: whatsappNumber.approval_status || "",
          approvalMessage: whatsappNumber.approval_message || "",
          SPOC: {
            name: whatsappNumber.spoc.name,
            email: whatsappNumber.spoc.email,
            number: whatsappNumber.spoc.number,
          },
        })
      ),
    },
    {
      fbBusinessVerificationID:
        responseData.fb_verfication_and_integration.fb_business_id,
      karixTnCFromURL:
        responseData.fb_verfication_and_integration.karix_tnc_form_url,
      wabaActivationRequestURL:
        responseData.fb_verfication_and_integration.waba_activation_form_url,
    },
    {
      registrationStatus: responseData.registration_status,
      statusMessage: responseData.status_message,
    },
  ];
}

export function objectFromFormData(formData, stepperState) {
  switch (stepperState) {
    case 0: {
      return {
        first_name: formData.firstName,
        last_name: formData.lastName,
        contact_email: formData.contactEmail,
        phone_number: formData.phoneNumber,
        number_verified: formData.numberVerified,
      };
    }

    case 1: {
      return {
        business_name: formData.businessName,
        business_address: formData.businessRegisteredAddr,
        business_email: formData.businessEmail,
        gst: formData.businessGSTDetails,
      };
    }

    case 2: {
      return {
        whatsapp_details: formData.whatsappNumberDetails.map(
          (whatsappNumber) => ({
            whatsapp_phone_number: whatsappNumber.phoneNumber,
            display_name: whatsappNumber.displayName,
            display_picture: whatsappNumber.displayPicture,
            status: whatsappNumber.statusText,
            description: whatsappNumber.descriptionText,
            address: whatsappNumber.addressText,
            category: whatsappNumber.categoryType,
            website_url: whatsappNumber.websiteURL,
            spoc: {
              name: whatsappNumber.SPOC.name,
              email: whatsappNumber.SPOC.email,
              number: whatsappNumber.SPOC.number,
            },
          })
        ),
      };
    }

    case 3: {
      return {
        fb_business_id: formData.fbBusinessVerificationID,
        waba_activation_form_url: formData.karixTnCFromURL,
        karix_tnc_form_url: formData.wabaActivationRequestURL,
      };
    }

    default:
      return {};
  }
}

export const validationSchema = [
  Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string(),
    contactEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Email Address is required"),
    phoneNumber: Yup.string()
      .matches(/^[1-9][0-9]{9}$/, "Please enter a valid phone number")
      .required("Phone Number is required"),
    numberVerified: Yup.bool().oneOf([true], "Please verify number"),
  }),

  Yup.object({
    businessName: Yup.string().required("Business Name is required"),
    businessRegisteredAddr: Yup.string().required(
      "Business Address is required"
    ),
    businessEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Email address is required"),
    businessGSTDetails: Yup.string()
      .required("Business GST details are required")
      .length(15, "Please enter a valid GST Number"),
  }),

  Yup.object({
    whatsappNumberDetails: Yup.array().min(1, "Please add at least one number"),
  }),

  Yup.object({
    fbBusinessVerificationID: Yup.string().required(
      "Please enter the facebook business verification ID"
    ),
    karixTnCFromURL: Yup.string().required(
      "Please upload the Karix Terms and Conditions document"
    ),
  }),
];
