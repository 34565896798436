import * as Yup from "yup";

export const labelDetailsObject = {
  labelID: "",
  title: "",
  description: "",
  color: "",
  activeStatus: true,
  createdAt: "",
  updatedAt: "",
  createdBy: "",
  updatedBy: "",
};

export function objectFromResponseData(responseData) {
  return {
    labelID: responseData._id,
    title: responseData.title,
    description: responseData.description,
    color: responseData.color,
    activeStatus: responseData.is_active,
    createdAt: responseData.created_at,
    updatedAt: responseData.updated_at,
    createdBy: responseData.created_by,
    updatedBy: responseData.updated_by,
  };
}

export function objectFromFormData(formData) {
  return {
    title: formData.title,
    description: formData.description,
    color: formData.color,
    is_active: formData.activeStatus,
  };
}

export const validationSchema = Yup.object({
  title: Yup.string()
    .required("Please enter a title")
    .max(20, "Label cannot be more than 20 characters"),
  description: Yup.string()
    .required("Please provide description")
    .max(200, "Description cannot be more than 200 characters"),
  color: Yup.string().required("Please select a color"),
  activeStatus: Yup.string().required("Please set an active status"),
});
