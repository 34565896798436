import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import Tooltip from "@mui/material/Tooltip";

import { useComponent } from "../../../../../hooks";
import Message from "./conversations window component/Message";
import { formatMessage, templateMessageFormat } from "../../../helperFunctions";
import { messageSocket } from "../../../../../constants/serverPath";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ConversationsWindow({
  setUserList,
  userState,
  setLatestUserMessageRead,
  setLatestUserMessageTimeStamp,
}) {
  const { axios } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const scrollRef = useRef(null);
  const userSenderID = useRef(userState.ID);

  const [messages, setMessages] = useState([]);
  const [loaderState, setLoaderState] = useState(true);

  useEffect(() => {
    setMessages([]);
    userSenderID.current = userState.ID;
  }, [userState.ID]);

  useEffect(() => {
    const socket = io(messageSocket.address);

    socket.on("message", (data) => {
      const message = JSON.parse(data);

      if (message.agent_id === activeBot.ID) {
        if (message.sender_id === userSenderID.current) {
          if (
            message.payload?.text !== "/human_handoff" &&
            message.payload?.text !== "/restart" &&
            message.payload?.action !== "/human_handoff"
          ) {
            setMessages((prevValue) => [...prevValue, formatMessage(message)]);
          }
          scrollToBottom();
        }

        setUserList((userList) => {
          const newMessageUser = userList.find(
            (user) => user.ID === message.sender_id
          );

          if (!!newMessageUser) {
            return [
              {
                ...newMessageUser,
                ...(message.sender_id !== userSenderID.current && {
                  newMessage: true,
                }),
                latestMessage: message.payload.text || "",
                latestMessageTime: message.timestamp,
              },
              ...userList.filter((user) => user.ID !== message.sender_id),
            ];
          } else {
            return userList;
          }
        });
      }
    });
  }, [activeBot.ID, setUserList]);

  useEffect(() => {
    setLoaderState(true);

    axios({
      url: `/conversation/${userState.ID}`,
      params: {
        bot_id: activeBot.ID,
      },
    }).then((response) => {
      if (response.status) {
        const messages = [];

        for (let message of response.data.events) {
          if (message.type === "custom") {
            messages.push(templateMessageFormat(message));
          } else if (
            message.payload?.text !== "/human_handoff" &&
            message.payload?.text !== "/restart" &&
            message.payload?.action !== "/human_handoff"
          ) {
            messages.push(formatMessage(message));
          }
        }
        setLatestUserMessageRead(response.data.latest_user_message_read);

        setMessages(messages);

        scrollToBottom();

        setLoaderState(false);
      }
    });
  }, [activeBot.ID, axios, userState.ID]);

  for (let i = messages.length - 20; i < messages.length; i++) {
    if (messages[i]?.actor === "user") {
      setLatestUserMessageTimeStamp(messages[i]?.timeStamp);
    }
  }

  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true, minScrollbarLength: 20 }}
      containerRef={(ref) => (scrollRef.current = ref)}
      style={{ paddingRight: "10px" }}
    >
      <Wrapper>
        {loaderState && (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}

        {messages.map((message) => (
          <Message key={message.ID} message={message} userState={userState} />
        ))}
      </Wrapper>
      <Tooltip title="Scroll to Bottom">
        <button
          style={{
            position: "fixed",
            bottom: "150px",
            right: "40px",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            color: "#366fcf",
          }}
          onClick={scrollToBottom}
        >
          <KeyboardDoubleArrowDownRoundedIcon fontSize="medium" />
        </button>
      </Tooltip>
    </PerfectScrollbar>
  );

  function scrollToBottom() {
    if (!!scrollRef.current) {
      scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER;
    }
  }
}
