import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom/dist";

import {
  getFacebookLoginStatus,
  initFacebookSdk,
} from "../../utils/FacebookSDK";
import { useAlert } from "../../hooks";
import useAxios from "./../../hooks/useAxios";

export default function OnboardingPage() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const axios = useAxios({
    customAuthToken: sessionStorage.getItem("authToken"),
  });

  const name = searchParams.get("name");
  const logo = searchParams.get("logo");
  const phone = searchParams.get("phone");
  const referenceId = searchParams.get("reference_id");
  const businessId = searchParams.get("businessId");

  useEffect(() => {
    initFacebookSdk().then(() => {
      getFacebookLoginStatus().then((response) => {
        if (response == null) {
          console.log("No login status for the person");
        } else {
          console.log(response, "response");
        }
      });
    });

    const sessionInfoListener = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        console.log(data, "data");
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            axios({
              url: "/embedded_signup/agent-info",
              method: "POST",
              data: {
                waba_id: waba_id,
                phone_number_id: phone_number_id,
                phone_number: phone,
                redirection_url: window.location.href,
                logo: logo,
                status: "FINISH",
                business_id: businessId,
              },
            }).then((resp) => {
              if (resp.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: resp.message.displayMessage,
                  })
                );
              }
            });
            console.log("Phone Number ID:", phone_number_id, logo, name);

            console.log("WABA ID:", waba_id);
          } else {
            const { current_step } = data.data;

            dispatch(alert({ type: "error", message: current_step }));

            console.log("Current Step:", current_step);
          }
        }
      } catch (error) {
        console.log("Non JSON Response", event.data);
      }
    };

    window.addEventListener("message", sessionInfoListener);
  }, []);

  // Facebook Login with JavaScript SDK
  function launchWhatsAppSignup() {
    // Launch Facebook login
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "426905303286095", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2, //  Receive Session Logging Info
        },
      }
    );
  }
  return (
    <Fragment>
      <Helmet>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js"
        ></script>
      </Helmet>

      <Button
        variant="outlined"
        style={{
          position: "relative",
          top: "42.5vh",
          left: "42.5vw",
          fontSize: "20px",
        }}
        onClick={launchWhatsAppSignup}
      >
        Login with Facebook
      </Button>
    </Fragment>
  );
}
