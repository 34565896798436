import React from "react";
import PhoneDesktopView from "./PhoneDesktopView";
import "./restBot.css";

export default function BotInterface({
  formikProps,
  phoneView,
  setPhoneView,
  showBubble,
  setShowBubble,
}) {
  return (
    <React.Fragment>
      {phoneView ? (
        <div className="smartphone">
          <PhoneDesktopView
            formikProps={formikProps}
            showBubble={showBubble}
            setShowBubble={setShowBubble}
            phoneView={phoneView}
            setPhoneView={setPhoneView}
          />
        </div>
      ) : (
        <PhoneDesktopView
          phoneView={phoneView}
          setPhoneView={setPhoneView}
          formikProps={formikProps}
          showBubble={showBubble}
          setShowBubble={setShowBubble}
        />
      )}
    </React.Fragment>
  );
}
