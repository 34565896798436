import { Fragment, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { TextField } from "../../components";

export default function Form({ formikProps }) {
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h3" align={"center"}>
          Reset Password
        </Typography>
      </Grid>

      <Grid item container>
        <TextField
          required
          type={showPassword.old ? "text" : "password"}
          name="oldPassword"
          label="Old Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword({ old: !showPassword.old })}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>

      <Grid item container>
        <TextField
          required
          type={showPassword.new ? "text" : "password"}
          name="newPassword"
          label="New Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword({ new: !showPassword.new })}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>

      <Grid item container>
        <TextField
          required
          type={showPassword.confirm ? "text" : "password"}
          name="confirmPassword"
          label="Confirm Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() =>
                    setShowPassword({
                      confirm: !showPassword.confirm,
                    })
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item container justifyContent={"center"}>
        {formikProps.values.oldPassword &&
          formikProps.values.newPassword &&
          formikProps.values.confirmPassword && (
            <Button
              fullWidth
              sx={{ padding: "10px" }}
              variant="contained"
              onClick={() => formikProps.handleSubmit()}
            >
              Reset Password
            </Button>
          )}
      </Grid>
    </Fragment>
  );
}
