import React, { useState } from "react";
import { Grid, Button } from "@mui/material";

import LookupTable from "./LookUpTable";
import LookupDialog from "./LookUpDialog";
import { Paper, ComponentHeader } from "../../../../../components";

export default function LookUp({ formikProps, editState }) {
  const [popUp, setPopUp] = useState(false);

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            subtitle={"Add lookups to the intent."}
            buttons={[<AddLookup />]}
          />
        </Grid>

        <Grid item xs={12}>
          <LookupTable
            data={formikProps.values.regex}
            formikProps={formikProps}
          />
        </Grid>
      </Paper>
      <LookupDialog
        formikProps={formikProps}
        popUp={popUp}
        setPopUp={setPopUp}
      />
    </React.Fragment>
  );

  function AddLookup() {
    return (
      <Button
        disabled={!editState}
        variant="outlined"
        onClick={() => setPopUp(true)}
      >
        Add Lookup
      </Button>
    );
  }
}
