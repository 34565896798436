import React from "react";

import { Autocomplete } from "../../../../components";

export default function MoveFieldForm({ bots, dialogFormikProps }) {
  const handleBotChange = (value, type) => {
    if (type === "selectOption") {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        agent_id: value.ID,
      }));
    } else {
      value = {
        ID: "",
        name: "",
      };
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        agent_id: value.ID,
      }));
    }
  };

  return (
    <Autocomplete
      name="agent_id"
      label="Select Bot"
      defaults={{
        primaryKey: "ID",
        displayLabel: "name",
      }}
      options={bots}
      onChange={(_, value, type) => handleBotChange(value, type)}
      formikProps={dialogFormikProps}
    />
  );
}
