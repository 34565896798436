import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import pages from "../../constants/pages";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Table from "./canned list components/Table";

export default function CannedMessagesList({ pageID }) {
  const axios = useAxios();
  const navigate = useNavigate();

  const { loader } = useComponent({ pageID });
  const [cannedMsg, setCannedMsg] = useState([]);
  const { activeBot, businessID } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start();
    axios({
      url: "/canned-message/" + businessID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setCannedMsg(response.data);
        loader.apiComplete();
      }
    });
  }, [axios, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Canned Response"
        buttons={[
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              navigate(pages.cannedMessagesCreate.route);
            }}
          >
            Create Canned Response
          </Button>,
        ]}
      />
      <Table cannedMsg={cannedMsg} />
    </Loader>
  );
}
