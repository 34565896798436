import { titleCase } from "change-case-all";
import * as Yup from "yup";
import convertDate from "../../../utils/convertDate";

export const customFieldsDetailsObject = {
  ID: "",
  controlID: "",
  description: "Please provide description for this field",
  copyable: true,
  editable: true,
  isActive: true,
  label: "Sample label",
  mandatory: false,
  maxLength: "502",
  multiline: false,
  name: "text",
  rows: 0,
  regex: "[a-z]",
  errorMessage: "Please fill correct value",
  tooltip: "Please enter the text",
  createdAt: "",
  updatedAt: "",
  createdBy: "",
  updatedBy: "",
};

export function controlText(formikProps, response) {
  formikProps.setValues(() => ({
    controlID: response.data._id,
    copyable: response.data.copyable,
    isActive: response.data.is_active,
    label: response.data.label,
    length: response.data.length,
    maxLength: response.data.max_length,
    multiline: response.data.multiline,
    name: response.data.name,
    editable: true,
    mandatory: true,
    regex: "[a-z]",
    errorMessage: response.error_message,
  }));
}

export function dateTime(formikProps, response) {
  formikProps.setValues(() => ({
    controlID: response.data._id,
    editable: true,
    mandatory: true,
    isActive: response.data.is_active,
    label: response.data.label,
    name: response.data.name,
    end_date: response.data.end_date,
    start_date: response.data.start_date,
    disabled_days: [],
    daysDisabled: [],
  }));
}

export function dropDown(formikProps, response) {
  formikProps.setValues(() => ({
    controlID: response.data._id,
    editable: true,
    mandatory: true,
    isActive: response.data.is_active,
    label: response.data.label,
    multi_Select: response.data.multi_Select,
    options: [],
    name: response.data.name,
    sortingOrder: response.data.sorting_order.id,
  }));
}

export function switchControl(formikProps, response) {
  formikProps.setValues(() => ({
    controlID: response.data._id,
    editable: true,
    mandatory: true,
    name: response.data.name,
    isActive: response.data.is_active,
    defaultSelection: false,
  }));
}

export function objectFromResponse(response) {
  var data = {
    ID: response._id,
    controlID: response.control_id,
    editable: response.editable,
    description: response.description,
    isActive: response.is_active,
    label: response.label,
    mandatory: response.mandatory || false,
    name: response.name,
    tooltip: response.tooltip,
    createdBy: response.created_by,
    updatedBy: response.updated_by,
    createdAt: convertDate(response.created_at),
    updatedAt: convertDate(response.updated_at),
  };
  if (response.control_id === "61dd2dfa1fb38be82e077ed7") {
    data = {
      ...data,
      regex: response.regex,
      multiline: response.multiline,
      copyable: response.copyable,
      rows: response.rows,
      errorMessage: response.error_message,
    };
  }
  if (response.control_id === "61dd2dfa1fb38be82e077ed9") {
    data = {
      ...data,
      end_date: response.end_date,
      start_date: response.start_date,
      disabled_days: response.disabled_days,
      daysDisabled: response.disabled_days?.map((days) => days.id),
    };
  }
  if (response.control_id === "61dd2dfa1fb38be82e077ed8") {
    data = {
      ...data,
      multi_Select: response.multi_Select,
      options: response.options,
      sortingOrder: response.sorting_order?.id,
    };
  }
  if (response.control_id === "61e13a0bf2c60729b06385f3") {
    data = {
      ...data,
      defaultSelection: response.default_selection,
    };
  }

  return data;
}

export function objectFromFormData(formData, agentID, fieldID) {
  var data = {
    ...(Boolean(formData.ID) && {
      field_id: formData.ID,
    }),

    default: true,
    is_deleted: false,
    control_id: formData.controlID,
    agent_id: agentID,
    label: titleCase(formData.label),
    mandatory: formData.mandatory,
    name: formData.label.replace(/\s+/g, "_").toLowerCase(),
    tooltip: formData.tooltip,
    editable: formData.editable,
    is_active: formData.isActive,
    description: formData.description,
    sequence: 0,
  };

  if (formData.controlID === "61dd2dfa1fb38be82e077ed7") {
    data = {
      ...data,
      regex: formData.regex,
      multiline: formData.multiline,
      copyable: formData.copyable,
      rows: formData.rows,
      error_message: formData.errorMessage,
    };
  }

  if (formData.controlID === "61dd2dfa1fb38be82e077ed9") {
    data = {
      ...data,
      end_date: formData.end_date,
      start_date: formData.start_date,
      disabled_days: formData.disabled_days,
    };
  }

  if (formData.controlID === "61dd2dfa1fb38be82e077ed8") {
    if (formData.sortingOrder === "1") {
      formData.sortingOrder = {
        id: "1",
        label: "asc",
      };
    }

    if (formData.sortingOrder === "2") {
      formData.sortingOrder = {
        id: "2",
        label: "dsc",
      };
    }

    if (formData.sortingOrder === "3") {
      formData.sortingOrder = {
        id: "3",
        label: "none",
      };
    }

    data = {
      ...data,
      multi_Select: formData.multi_Select,
      options: formData.options,
      sorting_order: formData.sortingOrder,
    };
  }
  if (formData.controlID === "61e13a0bf2c60729b06385f3") {
    data = {
      ...data,
      default_selection: false,
    };
  }
  return data;
}

export const validationSchema = Yup.object().shape({
  controlID: Yup.string().required("Please select component type."),
  label: Yup.string().required("Please enter label for your component."),
  isActive: Yup.string().required("Please select active status."),
  description: Yup.string().required("Fill  the description."),

  regex: Yup.string().when("controlID", {
    is: (ID) => ID === "61dd2dfa1fb38be82e077ed7",
    then: Yup.string().required("Give a regex to validate you field."),
  }),

  errorMessage: Yup.string().when("controlID", {
    is: (ID) => ID === "61dd2dfa1fb38be82e077ed7",
    then: Yup.string().required(
      "Give error message to display if the field doesn't satisfy regex."
    ),
  }),

  sortingOrder: Yup.string().when("controlID", {
    is: (ID) => ID === "61dd2dfa1fb38be82e077ed8",
    then: Yup.string().required("Select sorting order,If not the select none."),
  }),

  start_date: Yup.number().when("controlID", {
    is: (ID) => ID === "61dd2dfa1fb38be82e077ed9",
    then: Yup.number()
      .positive("please give positive number")
      .min(0, "Give value greater than or equal to 0")
      .required("Enter start date."),
  }),

  end_date: Yup.number().when("controlID", {
    is: (ID) => ID === "61dd2dfa1fb38be82e077ed9",
    then: Yup.number()
      .positive("please give positive number")
      .min(0, "Give value greater than or equal to 0")
      .required("Enter start date."),
  }),
});
