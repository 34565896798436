import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import pages from "../../constants/pages";
import {
  permissionsGroupDetailsObject,
  objectFromResponseData,
} from "./permissions group details components/permissionsGroupDetailsObjects";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./permissions group details components/Form";

export default function PermissionsGroupDetails({ pageID }) {
  const { axios, loader, pagePermissions } = useComponent({ pageID });
  const { bots } = useSelector((state) => state.memberDetails);
  const { permissionsGroupID } = useParams();

  const [editState, setEditState] = useState(!permissionsGroupID);
  const [formData, setFormData] = useState(permissionsGroupDetailsObject);

  useEffect(() => {
    loader.start();

    axios({
      url: `/permission/get/group?member_group_id=${permissionsGroupID}`,
    }).then((response) => {
      if (response.status) {
        setFormData(objectFromResponseData(response.data));

        loader.apiComplete();
      }
    });
  }, [loader, axios, permissionsGroupID]);

  return (
    <Loader>
      <PageHeader
        pageName="Member Group Details"
        breadcrumbs={[
          { location: pages.permissionsGroupList.route, name: "Member Groups" },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        botPermissions={bots}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
