import {
  Table,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import { sortBy } from "lodash";

export default function PagePermissionsTable({
  editState,
  bot,
  botPermissionsValue,
  setValues,
  pagePermissions,
}) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Page Name</TableCell>
          <TableCell align="center">Read Permissions</TableCell>
          <TableCell align="center">Create Permissions</TableCell>
          <TableCell align="center">Update Permissions</TableCell>
          <TableCell align="center">Delete Permissions</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {bot.pagePermissions.map((page) => {
          const pagePermissionValue =
            botPermissionsValue?.pagePermissions?.find(
              (pageValue) => pageValue.ID === page.ID
            ) || {
              read: false,
              create: false,
              update: false,
              delete: false,
            };

          return (
            <TableRow key={page.ID}>
              <TableCell>{page.name}</TableCell>
              {["read", "create", "update", "delete"].map((permission) => (
                <TableCell align="center" key={permission}>
                  <Checkbox
                    name={`${page.ID}.${permission}`}
                    checked={pagePermissionValue[permission]}
                    onChange={onPermissionChange}
                    disabled={
                      !(
                        editState &&
                        pagePermissions.update &&
                        page[permission] &&
                        (permission === "read"
                          ? true
                          : pagePermissionValue.read)
                      )
                    }
                  />
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  function onPermissionChange({ target }) {
    const { name, checked } = target;
    const [pageID, operation] = name.split(".");

    setValues((prevValue) => {
      const prevBotPermissionValue = prevValue.botPermissions.find(
        (botPermission) => botPermission.ID === bot.ID
      ) || {
        ID: bot.ID,
        pagePermissions: [],
      };

      const prevPagePermissionValue =
        prevBotPermissionValue.pagePermissions.find(
          (pagePermission) => pagePermission.ID === pageID
        ) || {
          ID: pageID,
          read: true,
          create: false,
          update: false,
          delete: false,
        };

      const newPagePermissions = prevBotPermissionValue.pagePermissions.filter(
        (pagePermission) => pagePermission.ID !== pageID
      );

      if (!(operation === "read" && !checked)) {
        newPagePermissions.push({
          ...prevPagePermissionValue,
          [operation]: checked,
        });
      } else {
        newPagePermissions.push({
          ...prevPagePermissionValue,
          read: false,
          create: false,
          update: false,
          delete: false,
        });
      }

      const newBotPermission = prevValue.botPermissions.filter(
        (botPermission) => botPermission.ID !== bot.ID
      );

      newBotPermission.push({
        ...prevBotPermissionValue,
        pagePermissions: sortBy(newPagePermissions, ["ID"]),
      });

      return {
        ...prevValue,
        botPermissions: sortBy(newBotPermission, ["ID"]),
      };
    });
  }
}
