import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TextField, InputAdornment, IconButton, Tooltip } from "@mui/material";
import { Send, AttachFile, StickyNote2, WhatsApp } from "@mui/icons-material";

import { AvatarButton } from "../../../../../components";
import { useComponent, useAxios } from "../../../../../hooks";
import AttachmentMenu from "./conversations footer components/AttachmentMenu";
import AttachmentDialog from "./conversations footer components/AttachmentDialog";
import TemplateMessages from "./conversations footer components/dialog content components/TemplateMessages";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export default function ConversationsFooter({
  userState,
  latestUserMessageTimeStamp,
}) {
  const axiosTemplate = useAxios();
  const { axios } = useComponent({
    baseURL: "messagingV2",
  });

  const { activeBot, firstName, lastName } = useSelector(
    (state) => state.memberDetails
  );

  const [text, setText] = useState("");
  const [templates, setTemplates] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [templatePopUp, setTemplatePopUp] = useState(false);
  const [dialog, setDialog] = useState({
    state: false,
    content: "menu",
  });

  useEffect(() => {
    if (activeBot.channel === "whatsapp") {
      axiosTemplate({
        url: "/wa/template/" + activeBot.ID + "?verified_status=3",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setTemplates(response.data.temp_obj);
        }
      });
    }
  }, [axiosTemplate, activeBot]);

  return (
    <Wrapper>
      <TextField
        value={text}
        fullWidth
        placeholder={"Send Message"}
        onKeyDown={handleKeyDown}
        onChange={handleTextChange}
        inputProps={{ maxLength: 4096 }}
        InputProps={{
          endAdornment: text && (
            <InputAdornment position="end">
              <IconButton color="primary" onClick={handleButtonClick}>
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={
          (new Date().getTime() - 3600 * 1000 * 24) / 1000 -
            latestUserMessageTimeStamp >=
          0
        }
      />

      {!Boolean(
        (new Date().getTime() - 3600 * 1000 * 24) / 1000 -
          latestUserMessageTimeStamp >=
          0
      ) && (
        <Tooltip title="Attachments">
          <AvatarButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
            <AttachFile color="primary" fontSize="small" />
          </AvatarButton>
        </Tooltip>
      )}

      {!Boolean(
        (new Date().getTime() - 3600 * 1000 * 24) / 1000 -
          latestUserMessageTimeStamp >=
          0
      ) && (
        <Tooltip title="Canned Messages">
          <AvatarButton onClick={() => handleDialogOpen("cannedMessages")}>
            <StickyNote2 color="primary" fontSize="small" />
          </AvatarButton>
        </Tooltip>
      )}

      {activeBot.channel === "whatsapp" && (
        <Tooltip title="Template Messages">
          <AvatarButton onClick={() => setTemplatePopUp(true)}>
            <WhatsApp color="primary" fontSize="small" />
          </AvatarButton>
        </Tooltip>
      )}

      <AttachmentMenu
        menuAnchor={menuAnchor}
        setMenuAnchor={setMenuAnchor}
        handleDialogOpen={handleDialogOpen}
      />

      <AttachmentDialog
        dialog={dialog}
        setDialog={setDialog}
        sendMessage={sendMessage}
        setText={setText}
      />

      <TemplateMessages
        templatePopUp={templatePopUp}
        setTemplatePopUp={setTemplatePopUp}
        templates={templates}
        activeBot={activeBot}
        userInfo={userState}
      />
    </Wrapper>
  );

  function handleKeyDown({ code }) {
    if (code === "Enter" && text !== "") {
      sendMessage({
        type: "text",
        text: text,
      }).then((response) => {
        if (response.status) {
          setText("");
        }
      });
    }
  }

  function handleTextChange({ target }) {
    const { value } = target;

    setText(value);
  }

  function handleButtonClick() {
    if (text !== "") {
      sendMessage({
        type: "text",
        text: text,
      }).then((response) => {
        if (response.status) {
          setText("");
        }
      });
    }
  }

  function handleDialogOpen(contentType) {
    setDialog({
      state: true,
      content: contentType,
    });
  }

  function sendMessage(messageData) {
    return axios({
      url: "/middleware/bot/reply",
      method: "POST",
      params: {
        bot_id: activeBot.ID,
      },
      data: {
        sender_id: userState.ID,
        message: formatMessageData(messageData),
        actor: "agent",
        metadata: {},
      },
    });
  }
}

function formatMessageData(messageData) {
  switch (messageData.type) {
    case "text":
      return {
        type: "text",
        payload: {
          text: messageData.text,
        },
      };

    case "image":
    case "video":
    case "audio":
    case "document":
      return {
        type: messageData.type,
        payload: {
          caption: messageData.caption,
          url: messageData.URL,
          name: messageData.name,
        },
      };

    default:
      return {};
  }
}
