import { useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

import { useAxios, useComponent } from "../../../hooks";
import { Paper, ComponentHeader, Loader } from "../../../components";
import TrainBotTable from "./TrainBotTable";

export default function BotTrainingPhrases({ pageID }) {
  const { loader } = useComponent({ pageID });
  const axios = useAxios({ baseURL: "botBuilder" });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [state, setState] = useState({
    addEntity: false,
    addExample: false,
  });
  const [dialogState, setDialogState] = useState({
    state: false,
    data: {
      intentName: "",
      examples: [],
      example: {
        text: "",
        entities: [],
      },
    },
  });
  const [intentName, setIntentName] = useState("");

  const [examples, setExamples] = useState([]);
  const [intents, setIntents] = useState([]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/get-entity/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setIntents(response.data);
        loader.apiComplete();
      }
    });
  }, [loader, axios, activeBot.ID]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/get-intents-examples/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setExamples(response.data);
        setState({
          addEntity: false,
          addExample: false,
        });
        loader.stop();
      }
    });
  }, [loader, axios, activeBot.ID, state.addEntity, state.addExample]);

  return (
    <Loader>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Examples"
            subtitle={"Add the examples and tag entity in intents."}
            buttons={[<ImportFile />, <ExportFile />]}
          />
        </Grid>
        <Grid item xs={12}>
          <TrainBotTable
            examples={examples}
            activeBot={activeBot}
            dialogState={dialogState}
            setDialogState={setDialogState}
            intents={intents}
            setState={setState}
            intentName={intentName}
            setIntentName={setIntentName}
          />
        </Grid>
      </Paper>
    </Loader>
  );

  function ImportFile() {
    return <Button variant="outlined">Import File</Button>;
  }

  function ExportFile() {
    return <Button variant="outlined">Export File</Button>;
  }
}
