import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { Info } from "@mui/icons-material";

import { alert } from "../../../redux/slices/alertSlice";
import GenerateCSVPopUp from "./generate CSV components/GenerateCSV";

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
    backgroundColor: "rgb(203 203 203 / 34%)",
    padding: "10px",
  },
  downloadFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
  },
  generateFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
    margin: "0 10px",
  },
}));

export default function Instructions({
  setPreviewData,
  formikProps,
  templateData,
  template,
  setTemplateData,
  groupList,
  activeBot,
  fields,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [CSVPopUp, setCSVPopUp] = useState(false);

  const dummyXSLX = () => {
    var date = Math.round(new Date().getTime() / 1000).toString();
    if (!Boolean(formikProps.values.templateID)) {
      dispatch(
        alert({
          type: "warning",
          message: "Select a template first.",
        })
      );
    } else {
      var excel_data = [["mobile_number"]];
      var body_variable = 0;
      if (templateData.template_data?.template_body?.count_of_variable > 0) {
        body_variable =
          templateData.template_data?.template_body?.count_of_variable;
        for (
          var i = 0;
          i < templateData.template_data?.template_body?.count_of_variable;
          i++
        ) {
          excel_data[0][i + 1] = "body_variable" + (i + 1);
        }
      }
      if (templateData.template_data?.template_header?.count_of_variable > 0) {
        for (
          var j = 0;
          j < templateData.template_data?.template_header?.count_of_variable;
          j++
        ) {
          excel_data[0][body_variable + 1] = "header_variable" + (j + 1);
        }
      }

      if (templateData.template_data?.template_button?.count_of_variable > 0) {
        for (
          var button = 0;
          button <
          templateData.template_data?.template_button?.count_of_variable;
          button++
        ) {
          excel_data[0][body_variable + 2] = "button_variable" + (button + 1);
        }
      }

      var excel_work_book = XLSX.utils.book_new();
      excel_work_book.Props = {
        Title: "Broadcast_Data",
        CreatedDate: new Date(),
      };
      excel_work_book.SheetNames.push("Broadcast Sheet");
      var excel_work_sheet = XLSX.utils.aoa_to_sheet(excel_data);
      excel_work_book.Sheets["Broadcast Sheet"] = excel_work_sheet;
      var work_book = XLSX.write(excel_work_book, {
        bookType: "xlsx",
        type: "binary",
      });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }
      FileSaver.saveAs(
        new Blob([s2ab(work_book)], { type: "application/octet-stream" }),
        `${date}_broadcast_data.xlsx`
      );
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} mt={1}>
        <div className={classes.divStyle}>
          <Info
            fontSize="small"
            color="primary"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1">
            You need to select a template to download blank file or to create a
            file.
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} mt={1}>
        <div className={classes.divStyle}>
          <Info
            fontSize="small"
            color="primary"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1">
            If the file uploaded does not follow the template, numbers will not
            get added.
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} mt={1}>
        <div className={classes.divStyle}>
          <Info
            fontSize="small"
            color="primary"
            style={{ marginRight: "10px" }}
          />

          <Typography variant="body1">
            <Typography
              variant="button"
              id="template_download_link"
              className={classes.downloadFile}
              onClick={() => dummyXSLX()}
              style={{ margin: "0 10px 0 0" }}
            >
              Generate Blank File
            </Typography>
            to upload data in a proper format. The number should start with
            country code and without plus(+). If the template contains any
            multimedia variable then please provide public URL for it.
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} mt={1}>
        <div className={classes.divStyle}>
          <Info
            fontSize="small"
            color="primary"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1">
            You can create and download File by giving some specific mapped
            variables if any ,You can also give custom values according to your
            need.
            <Typography
              variant="button"
              className={classes.generateFile}
              onClick={() => setCSVPopUp(true)}
            >
              Generate File
            </Typography>
            using user data e.g. mobile number, email, name etc.
          </Typography>
        </div>
      </Grid>

      <GenerateCSVPopUp
        CSVPopUp={CSVPopUp}
        setCSVPopUp={setCSVPopUp}
        template={template}
        formikProps={formikProps}
        templateData={templateData}
        setTemplateData={setTemplateData}
        groupList={groupList}
        activeBot={activeBot}
        fields={fields}
        setPreviewData={setPreviewData}
      />
    </React.Fragment>
  );
}
