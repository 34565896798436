import { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { Info, ColorLens } from "@mui/icons-material";
import { Grid, Tooltip, InputAdornment, IconButton } from "@mui/material";

import {
  Paper,
  TextField,
  TextFieldWithIcon,
  ComponentHeader,
} from "../../../../components";

export default function Footer({ formikProps, editState }) {
  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        left: "40%",
      },
    },
  };
  const [buttonColor, setButtonColor] = useState(false);

  let ClickBackGroundLens = (handler) => {
    let backgroundNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!backgroundNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return backgroundNode;
  };

  let backgroundNode = ClickBackGroundLens(() => {
    setButtonColor(false);
  });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Footer Settings"}
          subtitle={" Manage the button text and button colour in the footer."}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Button Text"
          name="buttonText"
          formikProps={formikProps}
          disabled={!editState}
          case="none"
          required
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values.buttonText.length}/20
                <Tooltip title="This is the button text in the chat window which will initiate the chat.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              buttonText: e.target.value,
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            position: "relative",
          }}
          ref={backgroundNode}
        >
          {buttonColor && (
            <SketchPicker
              styles={pickerStyle}
              onChange={({ hex }) => {
                formikProps.setValues((prevVal) => ({
                  ...prevVal,
                  buttonColor: hex,
                }));
              }}
              color={formikProps.values.buttonColor}
            />
          )}
          <TextFieldWithIcon
            label="Button Colour"
            case="none"
            name="headerBackgroundColor"
            formikProps={formikProps}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      background: formikProps.values.buttonColor,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={() => setButtonColor(!buttonColor)}
                    disabled={!editState}
                  >
                    <ColorLens />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}
