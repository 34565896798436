import { Fragment } from "react";
import { Grid, MenuItem, IconButton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import {
  ComponentHeader,
  Paper,
  TextField,
  CreatedAndUpdatedInfo,
} from "../../../components";

export default function FormDisplay({
  editState,
  formikProps,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title="Webhook Details" />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="name"
            label="Webhook Name"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="activeStatus"
            label="Webhook Status"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="method"
            label="Method"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          >
            <MenuItem value="get">GET</MenuItem>
            <MenuItem value="post">POST</MenuItem>
            <MenuItem value="put">PUT</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="webhookURL"
            label="Webhook URL"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="event.name"
            label="Event Name"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          >
            <MenuItem>Event Name 1</MenuItem>
            <MenuItem>Event Name 2</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            select
            name="event.topic"
            label="Event Topic"
            disabled={!(editState && pagePermissions.update)}
            formikProps={formikProps}
          >
            <MenuItem>Event Topic 1</MenuItem>
            <MenuItem>Event Topic 2</MenuItem>
          </TextField>
        </Grid>

        <CreatedAndUpdatedInfo formikProps={formikProps} />
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Webhook URL Headers"
            buttons={[<AddHeaderObject setValues={formikProps.setValues} />]}
          />
        </Grid>

        {formikProps.values.header.map((_, index) => (
          <Fragment>
            <Grid item xs={12} alignSelf="center">
              <Typography>Header Object {index + 1}</Typography>
            </Grid>

            <Grid item xs={3}>
              <TextField
                required
                name={`header[${index}].key`}
                label="Header Key"
                disabled={!(editState && pagePermissions.update)}
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs>
              <TextField
                required
                name={`header[${index}].value`}
                label="Header Value"
                disabled={!(editState && pagePermissions.update)}
                formikProps={formikProps}
              />
            </Grid>

            <Grid item alignSelf="center">
              <IconButton
                color="error"
                onClick={() => deleteHeaderObject(index)}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Fragment>
        ))}
      </Paper>
    </Fragment>
  );

  function deleteHeaderObject(deleteIndex) {
    formikProps.setValues((prevValue) => ({
      ...prevValue,
      header: prevValue.header.filter((_, index) => deleteIndex !== index),
    }));
  }
}

function AddHeaderObject({ setValues }) {
  return (
    <IconButton color="primary" onClick={addHeaderObject}>
      <Add />
    </IconButton>
  );

  function addHeaderObject() {
    setValues((prevValue) => ({
      ...prevValue,
      header: [...prevValue.header, { key: "", value: "" }],
    }));
  }
}
