import { Fragment } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  Typography as MuiTypography,
} from "@mui/material";
import styled from "styled-components";

import { ComponentHeader, Paper, TextField } from "../../../components";
import MemberConfigTable from "./MemberBotConfig";

const Typography = styled(MuiTypography)`
  color: #808080b3;
`;

export default function FormDisplay({
  editState,
  formikProps,
  memberList,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Bot Details"
            subtitle="Basic details of the bot"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Bot Name"
            name="botName"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Plan Name"
            name="planName"
            formikProps={formikProps}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Channel"
            name="channelName"
            formikProps={formikProps}
            disabled
          />
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Live Chat Configuration"
            subtitle="You can configure how default settings for the chat bot here"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Live Chat automatic end time"
            name="chatAutoEndTime"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Minutes</InputAdornment>
              ),
            }}
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            In how many minutes of inactivity, will the chat end?
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            label="Auto Assignment"
            name="autoAssignment"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          >
            <MenuItem value={true}>Enabled</MenuItem>
            <MenuItem value={false}>Disabled</MenuItem>
          </TextField>

          <Typography variant="subtitle2">
            Should new chat requests be auto assigned to agents?
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Assigned to Agent Message"
            name="assignedToMessage"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            Message to send when user is assigned to an agent
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Transfer Message"
            name="transferMessage"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            Message to send when user is transferred from one agent to another
            agent
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Agent Unavailable Message"
            name="agentUnavailableMessage"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            Message to display when no agent is online or available
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Chat End Message"
            name="endChatMessage"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            Message to display when the chat ends
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            label="Agent Lost Connection Message"
            name="agentLostConnectionMessage"
            formikProps={formikProps}
            disabled={!(editState && pagePermissions.update)}
          />

          <Typography variant="subtitle2">
            Message to display when agent quits a chat abruptly
          </Typography>
        </Grid>
      </Paper>

      <MemberConfigTable
        editState={editState}
        formikProps={formikProps}
        pagePermissions={pagePermissions}
        memberList={memberList}
      />
    </Fragment>
  );
}
