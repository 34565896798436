import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import pages from "../../constants/pages";
import {
  labelDetailsObject,
  objectFromResponseData,
} from "./label details components/labelDetailsObject";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./label details components/Form";

export default function LabelDetails({ pageID }) {
  const { axios, loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { labelID } = useParams();

  const [editState, setEditState] = useState(!labelID);
  const [formData, setFormData] = useState(labelDetailsObject);

  useEffect(() => {
    loader.start();

    if (!!labelID) {
      axios({
        url: "/label/" + activeBot.ID + "?label_id=" + labelID,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));

          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [loader, axios, labelID, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Label Details"
        breadcrumbs={[{ location: pages.labelList.route, name: "Labels" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        botID={activeBot.ID}
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
