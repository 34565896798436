import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, Fragment } from "react";

import pages from "../../../../constants/pages";
import { PageHeader } from "../../../../components";
import Form from "./template details component/Form";
import { useAxios, useComponent } from "../../../../hooks";
import {
  objectFromResponse,
  templateDetailsObject,
} from "./template details component/emailTemplateDetailsObject";
import { apiComplete, startLoader } from "../../../../redux/slices/loaderSlice";

export default function EmailTemplateDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();
  const { pagePermissions } = useComponent({ pageID });
  const agentID = useSelector((state) => state.memberDetails.activeBot.ID);
  const [html, setHtml] = useState({ code: "", json: {}, variableCount: 0 });

  const templateID = params.templateID || "";

  const [botDetails, setBotDetails] = useState({});
  const [formData, setFormData] = useState(templateDetailsObject);

  useEffect(() => {
    dispatch(startLoader(1));
    if (Boolean(templateID)) {
      var url =
        "/broadcast_email/template?agent_id=" +
        agentID +
        "&template_id=" +
        templateID;
      axios({
        method: "GET",
        url: url,
      }).then((response) => {
        if (response.status === true) {
          setHtml({
            json: response.data.html_json,
            code: response.data.template_body,
            variableCount: response.data.body_variable_count,
          });
          setFormData(objectFromResponse(response.data));

          dispatch(apiComplete());
        }
      });
    } else {
      dispatch(apiComplete());
    }
  }, [templateID, agentID, dispatch]);

  return (
    <Fragment>
      <PageHeader
        pageName="Email Template Details"
        breadcrumbs={[
          {
            name: "Templates",
            location: pages.templateList.route,
          },
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        agentID={agentID}
        botDetails={botDetails}
        pagePermissions={pagePermissions}
        html={html}
        setHtml={setHtml}
      />
    </Fragment>
  );
}
