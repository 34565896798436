import {
  Box,
  Divider,
  Typography,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import { ClearAll, Launch } from "@mui/icons-material";
import styled from "styled-components";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import notificationRoute from "./pagesRoute";

const Wrapper = styled(Box)`
  width: 400px;
  max-height: 70vh;
`;

const MenuHeader = styled(Box)`
  padding: 0px 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyWrapper = styled.div`
  display: flex;
  text-align: center;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  max-width: 500px;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled(Box)`
  padding: 8px;

  ${(props) => {
    return {
      background:
        props.unread && (props.theme.name === "DARK" ? "#2B3E57" : "#F8F8FF"),
    };
  }}
`;

export default function NotificationMenu({
  notifications,
  handleClose,
  page,
  setPage,
}) {
  const navigate = useNavigate();
  const [clearNotification, setClearNotifications] = useState("");

  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
      onYReachEnd={nextPage}
    >
      <Wrapper>
        <MenuHeader>
          <Typography variant="h5">Notifications</Typography>

          <Tooltip title="Clear all notifications">
            <IconButton color="secondary" onClick={removeNotifications}>
              <ClearAll />
              {clearNotification}
            </IconButton>
          </Tooltip>
        </MenuHeader>

        <Divider />

        {notifications.length === 0 && (
          <EmptyWrapper>
            <Typography Variant="h5">No notifications!</Typography>
          </EmptyWrapper>
        )}

        {notifications.map((notification) => (
          <Fragment key={notification.ID}>
            <MenuItem unread={!notification.readStatus}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography
                    variant={notification.readStatus ? "body1" : "subtitle2"}
                  >
                    {notification.displayContent}
                  </Typography>

                  <Typography variant="caption">
                    {notification.timeStamp}
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    onClick={() =>
                      navigate(
                        notificationRoute[notification.pageID] +
                          notification.payload.page.ID
                      )
                    }
                  >
                    <Launch />
                  </IconButton>
                </Grid>
              </Grid>
            </MenuItem>

            <Divider />
          </Fragment>
        ))}
      </Wrapper>
    </PerfectScrollbar>
  );

  function nextPage() {
    if ((page.number + 1) * page.size <= notifications.length) {
      setPage((prevValue) => ({
        ...prevValue,
        number: prevValue.number + 1,
      }));
    }
  }
  function removeNotifications() {
    notifications.length = 0;
    setClearNotifications();
  }
}
