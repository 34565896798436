import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { TextField } from "../../../components";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";
import pages from "../../../constants/pages";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function CreateGroupPopUp({
  createGroupModal,
  setCreateGroupModal,
  setNewGroup,
  activeBot,
}) {
  const groupDetails = {
    group_name: "",
  };

  const axios = useAxios();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={createGroupModal}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Create Group</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={groupDetails}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/broadcast/create/group/" + activeBot.ID,
            method: "POST",
            data: {
              group_name: formData.group_name,
              is_active: true,
            },
          }).then((response) => {
            if (response.status) {
              setSubmitting(false);

              dispatch(
                alert({
                  type: "success",
                  message: response.message.displayMessage,
                })
              );
              setNewGroup(response.data.group_id);
              navigate(pages.groupDetails.route + response.data.group_id);
            } else {
              setSubmitting(false);
            }
            handleClose();
          });
        }}
      >
        {(dialogFormikProps) => (
          <React.Fragment>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ marginTop: "6px" }}
                    required
                    variant="outlined"
                    name="group_name"
                    label="Group Name"
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    value="save"
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty
                    }
                  >
                    Create Group
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setCreateGroupModal(false);
  }
}

const validationSchema = Yup.object().shape({
  group_name: Yup.string().max(50).required("Group Name is required."),
});
