import { useLocation } from "react-router-dom";

import Form from "./grievance details components/Form";
import { Fragment } from "react";
import { PageHeader } from "../../components";

export default function GrievanceDetails() {
  const { state } = useLocation();

  console.log(state);

  return (
    <Fragment>
      <PageHeader pageName={"Grievance Details"} />

      <Form formData={state.data} />
    </Fragment>
  );
}
