import React, { useState, useEffect } from "react";
import { Popover, Typography, Grid, MenuItem, Button } from "@mui/material";

import { useAxios, useComponent } from "../../../hooks";
import { TextField } from "../../../components";

export default function EntityDetails({
  data,
  text,
  intents,
  setState,
  anchorEl,
  component,
  activeBot,
  setAnchorEl,
  formikProps,
  handleChange,
}) {
  const open = Boolean(anchorEl);
  const { alert } = useComponent();
  const axios = useAxios({ baseURL: "botBuilder" });

  const [entities, setEntities] = useState({
    entityName: data.entity,
    entityValue: data.value,
  });

  useEffect(() => {
    if (data.entity !== "" || data.value !== "") {
      setEntities({
        entityName: data.entity,
        entityValue: data.value,
      });
    }
  }, [data.entity, data.value]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleEntity() {
    if (component === "table") {
      let values = {
        intent_name: data.intentName,
        example: data.sentence,
        entity: {
          word: text,
          name: entities.entityName,
          type: "ert",
          start: data.start,
          end: data.end,
          value: entities.entityValue,
        },
      };
      axios({
        url: "/entity-to-example/" + activeBot.ID,
        method: "POST",
        data: values,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setState((prevVal) => ({
            ...prevVal,
            addEntity: true,
          }));
          handleChange(data.intentName);
        }
      });
    } else {
      formikProps.setValues((prevValue) => ({
        ...prevValue,
        example: setEntity(prevValue.example),
      }));

      function setEntity(entity) {
        entity.entities.push({
          word: text,
          name: entities.entityName,
          type: "ert",
          start: data.start,
          end: data.end,
          value: entities.entityValue,
        });
        let textSen = entity.text.split("");

        textSen.splice(data.start, 0, "*");
        textSen.splice(data.end + 1, 0, "*");

        entity.text = textSen.join("");

        return entity;
      }
      alert.success("Entity added successfully.");
      handleClose();
    }
  }

  function handleEntityName(e) {
    setEntities((val) => ({
      ...val,
      entityName: e.target.value,
    }));
  }

  function handleEntityValue(e) {
    setEntities((val) => ({
      ...val,
      entityValue: e.target.value,
    }));
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: "250px" },
      }}
    >
      <Grid container spacing={4} p="20px">
        <Grid item xs={12}>
          <Typography sx={{ p: 2 }} variant="h4">
            Selected Word : {text}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleEntityName}
            label="Tag Entity"
            value={entities.entityName}
            select
          >
            {intents.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.entity_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleEntityValue}
            label="Value"
            value={entities.entityValue}
          />
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleEntity} color="primary" variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}
