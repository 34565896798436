import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { Autocomplete, DatePicker } from "../../../components";

import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useAxios } from "../../../hooks";
import { DateRange } from "react-date-range";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function GenerateReportDialog({
  generateReportDialog,
  setGenerateReportDialog,
  members,
  loader,
  generateList,
}) {
  const classes = useStyles();
  const axios = useAxios();
  const [formData, setFormData] = useState({
    memberId: [],
    startDate: "",
    endDate: "",
    dateRange: [],
  });

  function handleClose() {
    setGenerateReportDialog(false);
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const validationSchema = Yup.object().shape({
    // memberId: Yup.array().min(1),
    startDate: Yup.number().required("Start Date is required."),
    endDate: Yup.number().required("End Date is required."),
  });

  members = members?.filter((items) => {
    return items.is_active === true;
  });

  return (
    <Dialog
      open={generateReportDialog}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Generate Chat Report</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            let memberId = formData.memberId.map((items) => {
              return items._id;
            });

            axios({
              url: "/insights/request",
              method: "POST",
              data: {
                member_ids: memberId,
                start_time: formData.startDate,
                end_time: formData.endDate,
              },
            }).then((response) => {
              if (response.status) {
                generateList();
                handleClose();
              }
            });
          }}
        >
          {(formikProps) => {
            function handleChange(item) {
              setState([item.selection]);
              const timestamp1 = new Date(item.selection.startDate).getTime();
              const timestamp2 = new Date(item.selection.endDate).getTime();

              formikProps.setFieldValue("startDate", timestamp1 / 1000);
              formikProps.setFieldValue(
                "endDate",
                (timestamp2 + 3600 * 1000 * 24) / 1000
              );
            }

            return (
              <React.Fragment>
                <Grid container justifyContent={"space-evenly"}>
                  <Grid item sx={{ pl: "25px", pt: "25px" }}>
                    <Grid item mb={2}>
                      <Typography variant="caption1">
                        Select Start Date And End Date
                      </Typography>
                    </Grid>

                    <Grid>
                      <DateRange
                        ranges={state}
                        onChange={(items) => handleChange(items)}
                      ></DateRange>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ pl: "25px", pt: "25px" }} xs={6}>
                    <Grid item>
                      <Typography variant="caption1">
                        Select Member Id
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        multiple={true}
                        name="memberId"
                        label="Select Member"
                        defaults={{
                          primaryKey: "first_name",
                          displayLabel: "first_name",
                        }}
                        required={false}
                        options={members}
                        formikProps={formikProps}
                        sx={{ mt: "10px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <DialogActions style={{ padding: "12px 25px 24px" }}>
                  <Grid
                    container
                    spacing={3}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        onClick={formikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!formikProps.dirty || !formikProps.isValid}
                      >
                        Generate Report
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </React.Fragment>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
