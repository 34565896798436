import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { Paper } from "../../../../../../../components";
import { useComponent, useAxios } from "../../../../../../../hooks";
import MessageSentForm from "../../../../../../leads/message send components/MessageSentForm";
import { templateValue } from "../../../../../../leads/message send components/templateValue";
import WhatsappPreview from "../../../../../../template/whatsapp interface/WhatsappPreview";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1200px" },
  iconButton: {
    float: "right",
    padding: "16px 24px",
  },
  dialogContent: {
    padding: "5px 8px 5px",
    background: "#cbcbcb38",
  },
}));

export default function TemplateMessages({
  templatePopUp,
  setTemplatePopUp,
  templates,
  activeBot,
  userInfo,
}) {
  const classes = useStyles();
  const axios = useAxios({ baseURL: "messagingV2" });

  const { alert } = useComponent();

  templateValue.phoneNumber = userInfo?.phone;

  const handleClose = () => {
    setTemplatePopUp(false);
  };

  return (
    <MUIDialog
      classes={{ paper: classes.paper }}
      open={templatePopUp}
      onClose={handleClose}
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">Send Message</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <Formik
        initialValues={templateValue}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          var data = {
            to: formData.phoneNumber,
            type_of_message: "template",
            template_id: formData.templateID,
            payload: {
              body: [
                {
                  type: "header",
                  parameters: [],
                },
                {
                  type: "body",
                  parameters: [],
                },
                {
                  type: "footer",
                  parameters: [],
                },
                {
                  type: "button",
                  index: "0",
                  sub_type: "",
                  parameters: [],
                },
              ],
            },
          };

          if (formData.templateHeader.count_of_variable > 0) {
            data.payload.body[0].parameters.push({
              type: formData.templateHeader.type,
              [formData.templateHeader.type]: {
                link: formData.templateHeader.example[0].example,
              },
            });
          } //@mohitbhatt

          if (formData.templateBody.count_of_variable > 0) {
            for (let i = 0; i < formData.templateBody.count_of_variable; i++) {
              data.payload.body[1].parameters.push({
                type: "text",
                text: formData.templateBody.example[i].example,
              });
            }
          } //mohitbhatt

          if (formData.templateButtons.count_of_variable > 0) {
            for (let button of formData.templateButtons.buttons) {
              data.payload.body[3].sub_type = button.button_type;
              // data.payload.body[3].index = button.button_type;

              data.payload.body[3].parameters.push({
                type: "text",
                text:
                  formData.templateButtons.type === "call_to_action"
                    ? button.example
                    : button.example[0],
              });
            }
          }

          axios({
            url: "/whatsapp/" + activeBot.ID,
            method: "POST",
            data: data,
          }).then((response) => {
            if (response.status) {
              setSubmitting(false);
              alert.success(response?.message?.displayMessage);
              handleClose();
            } else {
              setSubmitting(false);
            }
          });
        }}
      >
        {(dialogFormikProps) => (
          <React.Fragment>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={8} display="flex">
                  <MessageSentForm
                    formikProps={dialogFormikProps}
                    templates={templates}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Paper style={{ marginBottom: "0" }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ color: "#808080b3" }}
                      >
                        The message for user will look like this.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <WhatsappPreview formikProps={dialogFormikProps.values} />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs></Grid>

                <Grid item xs style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty
                    }
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </MUIDialog>
  );
}

const validationSchema = Yup.object().shape({
  templateID: Yup.string().required("This field is required."),
  phoneNumber: Yup.string().required("Provide phone number"),
});
