import * as Yup from "yup";

export const whatsappStyleObject = {
  whatsappNumberID: "",
  profilePicture: "",
  profileAddress: "",
  profileDescription: "",
  businessProfileEmail: "",
  businessProfileVrtical: "",
  businessWebsite: "",
  businessProfileDescription: "",
};

export function objectFromBusinessProfileDetails(responseData) {
  let webs = [];
  for (let website of responseData.business_profile_response.websites) {
    webs.push({
      websiteName: website,
    });
  }
  return {
    whatsappNumberID: responseData.id,
    profilePicture: responseData.profile_photo_response.link,
    profileAddress: responseData.business_profile_response.address,
    profileDescription: responseData.profile_description_response.text,
    businessProfileEmail: responseData.business_profile_response.email,
    businessProfileVrtical: responseData.business_profile_response.vertical,
    businessWebsite: webs,
    businessProfileDescription:
      responseData.business_profile_response.description,
    businessVerticalId: responseData.business_profile_response.vertical,
  };
}

export function objectFromBusinessProfile(businessProfile) {
  let webs = [];
  for (let website of businessProfile.businessWebsite) {
    webs.push(website.websiteName);
  }
  return {
    profile_address: businessProfile.profileAddress,
    profile_description: businessProfile.profileDescription,
    business_profile_email: businessProfile.businessProfileEmail,
    business_profile_vertical: businessProfile.businessVerticalId,
    business_website: webs,
  };
}

export const validationSchema = Yup.object().shape({
  whatsappNumberID: Yup.string().required("Whatsapp Number Id is required"),
  profilePicture: Yup.string().required("Profile picture is required"),
  profileAddress: Yup.string().max(256).required("Profile address is required"),
  profileDescription: Yup.string()
    .max(256)
    .required("Profile Description is required"),
  businessProfileDescription: Yup.string()
    .max(256)
    .required("Business Profile Description is required"),
  businessProfileEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  businessVerticalId: Yup.string().required("Business Number Id is required"),

  businessWebsite: Yup.array(
    Yup.object().shape({
      websiteName: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("Please enter website"),
    })
  ),
});
