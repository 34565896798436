import React, { useState } from "react";
import { Grid } from "@mui/material";

import Profile from "./form display component/Profile";
import ProfilePreview from "./form display component/ProfilePreview";

export default function FormDisplay({ formikProps }) {
  const [image, setImage] = useState({});
  const [website, setWebsite] = useState([""]);
  const [value, setValues] = useState([
    {
      description: "",
      address: "",
      email_address: "",
    },
  ]);
  const [category, setCategory] = useState([
    {
      id: "Automotive",
      label: "Automotive",
    },
    {
      id: "Beauty Spa and Salon",
      label: "Beauty Spa and Salon",
    },
    {
      id: "Clothing and Apparel",
      label: "Clothing and Apparel",
    },
    {
      id: "Education",
      label: "Education",
    },
    {
      id: "Entertainment",
      label: "Entertainment",
    },
    {
      id: "Event Planning and Service",
      label: "Event Planning and Service",
    },
    {
      id: "Finance and Banking",
      label: "Finance and Banking",
    },
    {
      id: "Food and Grocery",
      label: "Food and Grocery",
    },
    {
      id: "Public Service",
      label: "Public Service",
    },
    {
      id: "Hotel and Lodging",
      label: "Hotel and Lodging",
    },
    {
      id: "Medical and Health",
      label: "Medical and Health",
    },
    {
      id: "Non-profit",
      label: "Non-profit",
    },
    {
      id: "Professional Services",
      label: "Professional Services",
    },
    {
      id: "Shopping and Retail",
      label: "Shopping and Retail",
    },
    {
      id: "Travel and Transportation",
      label: "Travel and Transportation",
    },
    {
      id: "Restaurant",
      label: "Restaurant",
    },
    {
      id: "Other",
      label: "Other",
    },
  ]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8} sm={12}>
          <Profile
            formikProps={formikProps}
            image={image}
            value={value}
            category={category}
            setCategory={setCategory}
            setValues={setValues}
            setImage={setImage}
            website={website}
            setWebsite={setWebsite}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4} sm={12} style={{ top: "64px" }}>
          <ProfilePreview value={value} website={website} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
