import React, { useState } from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const FieldItem = styled(Grid)`
  margin: 10px;
  background-color: #fff;
  padding: 10px;
  color: #376fd0;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #376fd0;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

function CustomFields({ field, index }) {
  return (
    <Grid container spacing={4}>
      <Draggable draggableId={field._id} index={index}>
        {(provided) => (
          <FieldItem
            item
            xs={12}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Typography variant="h6">{field.label}</Typography>
            <Typography variant="subtitle2" color="secondary">
              {field.description}
            </Typography>
          </FieldItem>
        )}
      </Draggable>
    </Grid>
  );
}

const CustomFieldList = React.memo(function CustomFieldList({ customField }) {
  return customField.map((field, index) => (
    <CustomFields field={field} index={index} key={field._id} />
  ));
});

export default function SequenceFieldForm({ dialogFormikProps }) {
  const [state, setState] = useState({
    fields: dialogFormikProps.values?.sequence_fields,
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const fields = reorder(
      state.fields,
      result.source.index,
      result.destination.index
    );
    setState({ fields });

    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      sequence_fields: fields,
    }));
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <CustomFieldList customField={state.fields} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
