import { titleCase } from "change-case-all";

import {
  ButtonDisplay,
  ContactDisplay,
  LocationDisplay,
  MultimediaDisplay,
  QuickReplyDisplay,
  TextDisplay,
} from "./utterance payload display components";

export default function UtterancePayload({
  editState,
  formikProps,
  pagePermissions,
}) {
  switch (formikProps.values.type) {
    case "text":
      return (
        <TextDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
        />
      );

    case "quick_replies":
      return (
        <QuickReplyDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
        />
      );

    case "image":
    case "video":
    case "audio":
    case "document":
      return (
        <MultimediaDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
          type={titleCase(formikProps.values.type)}
        />
      );

    case "button":
      return (
        <ButtonDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
        />
      );

    case "location":
      return (
        <LocationDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
        />
      );

    case "contact":
      return (
        <ContactDisplay
          formikProps={formikProps}
          editState={editState}
          pagePermissions={pagePermissions}
        />
      );

    default:
      return "";
  }
}
