import React from "react";

import { Grid, Typography, Paper as MuiPaper } from "@mui/material";

import styled from "styled-components";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Stats({ insightData }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Paper style={{ color: "#37b24d", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {insightData?.online_members_livechat}
          </Typography>
          <Typography variant="subtitle1">Total Online Agents</Typography>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper style={{ color: "#e61d1d", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {insightData?.offline_members_livechat}
          </Typography>
          <Typography variant="subtitle1">Total Offline Agents</Typography>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper style={{ color: "#0066ff", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {insightData?.all_members_livechat.count}
          </Typography>
          <Typography variant="subtitle1">Total Livechat Count</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} />
    </Grid>
  );
}
