import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable, Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function MembersTable({ members }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(pages.memberDetails.route + members[dataIndex]._id),
  };

  return <DataTable columns={columns} data={members} options={options} />;
}

const columns = [
  {
    name: "first_name",
    label: "First Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "last_name",
    label: "Last Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "gender",
    label: "Gender",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "group_name",
    label: "Permissions Group",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Account Status",
    options: {
      customBodyRender: (value) => {
        return (
          <Chip
            type={value ? "success" : "error"}
            label={value ? "Active" : "Inactive"}
          />
        );
      },
    },
  },
  {
    name: "created_at",
    label: "Joining Date",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
