import React from "react";
import { Grid } from "@mui/material";

import { TextField } from "../../../../../components";

export default function CreateLookup({ formikProps }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name="lookup" label="Lookup" formikProps={formikProps} />
      </Grid>
    </Grid>
  );
}
