import React from "react";
import { Grid, Typography } from "@mui/material";

import { TextField } from "../../../../components";

export default function AddBodyVariable({ formikProps }) {
  return (
    <React.Fragment>
      {formikProps.values.templateBody.count_of_variable !== 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Body Variable</Typography>
        </Grid>
      )}
      {Array(formikProps.values?.templateBody?.count_of_variable)
        .fill()
        .map((_, index) => (
          <Grid key={index} item mt={1} xs={4}>
            <TextField
              formikProps={formikProps}
              label={"Body Variable Value " + (index + 1)}
              disabled={Boolean(formikProps.values.ID)}
              name={"templateBody.example[" + index + "].example"}
            />
          </Grid>
        ))}
    </React.Fragment>
  );
}
