import { get } from "lodash";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";

import { useAxios, useComponent } from "../../hooks";
import { FileUpload, PageHeader, Paper } from "../../components";

export default function FCMCred() {
  const axios = useAxios();

  const { alert } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    fileName: "",
    fileJSON: {},
  });

  function handleFileChange({ target }, formikProps) {
    const file = get(target, "files[0]", "");

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        try {
          const jsonData = JSON.parse(e.target.result);
          formikProps.setFieldValue("fileJSON", jsonData);

          formikProps.setFieldValue("fileName", file.name);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      reader.readAsText(file);
    }
  }

  return (
    <Fragment>
      <PageHeader pageName="FCM" />

      <Paper>
        <Grid item container justifyContent={"space-between"}>
          <Formik
            initialValues={formData}
            onSubmit={(formData, { setSubmitting }) => {
              setSubmitting(true);
              axios({
                url: "/push_notification/fcm?agent_id=" + activeBot.ID,
                method: "POST",
                disableRedirect: true,
                data: { ...formData.fileJSON },
              }).then((response) => {
                if (response.status) {
                  alert.success(response.message.displayMessage);
                }
                setSubmitting(false);
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  <Grid item xs={6}>
                    <FileUpload
                      label="Upload File JSON"
                      name="fileName"
                      formikProps={formikProps}
                      onChange={(e) => handleFileChange(e, formikProps)}
                      allowedExtension={".xlsx,.json"}
                      maxSize={16}
                      folderPath={"broadcast/" + "/"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      onClick={formikProps.handleSubmit}
                      disabled={!formikProps.dirty}
                      loading={formikProps.isSubmitting}
                      sx={{ height: "100%" }}
                      color="primary"
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </Grid>
      </Paper>
    </Fragment>
  );
}
