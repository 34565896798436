import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { CircularProgress, Button, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { createTheme, ThemeProvider } from "@mui/material/styles"; //@mohbhatt
import UserPill from "./list section components/UserPill";

import { formatUser } from "../../../helperFunctions";
import { useComponent } from "../../../../../hooks";
import { userReducerConstants } from "../../userReducer";
import AddNewUserDialog from "../../profile components/AddNewUserDialog";
import { userSocket } from "../../../../../constants/serverPath";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

// creating ripple effect on add user button @mohitbhatt
const theme = createTheme({
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});

export default function ListSection({
  loaderState,
  page,
  setPage,
  userList,
  setUserList,
  chatState,
  userState,
  setUserState,
  noUsers,
  setAddUser,
  activeBot,
  senderID,
}) {
  const { alert } = useComponent();

  const { ID } = useSelector((state) => state.memberDetails);

  const memberID = useRef(ID);
  const chatStatus = useRef(chatState.activeChat);

  const [addNewUserDialog, setAddNewUserDialog] = useState(false);

  useEffect(() => {
    const socket = io(userSocket.address);

    socket.on("user", (data) => {
      const user = JSON.parse(data);

      if (user.event === "new_user" && user.agent_id === activeBot.ID) {
        if (user.assigned_to === memberID.current) {
          if (
            chatStatus.current === "allChats" ||
            chatStatus.current === "myChats" ||
            chatState.current === "livechats"
          ) {
            setUserList((prevValue) => {
              var status = prevValue.some((lead) => lead.ID === user.sender_id);
              if (!status) {
                alert.success("New User has been assigned to you!");
                return [formatUser(user), ...prevValue];
              } else {
                alert.success("New User has been assigned to you!");
                return [...prevValue];
              }
            });
          }

          if (chatStatus.current === "requests") {
            setUserList((prevValue) =>
              prevValue.filter((listUser) => listUser.ID === user.sender_id)
            );
          }
        } else {
          if (chatStatus.current === "allChats") {
            setUserList((prevValue) => {
              var status = prevValue.some((lead) => lead.ID === user.sender_id);
              if (!status) {
                return [formatUser(user), ...prevValue];
              } else {
                return [...prevValue];
              }
            });
          }

          if (chatStatus.current === "requests") {
            if (user.assigned_to === "") {
              alert.success("New User has requested live chat!");
              setUserList((prevValue) => [formatUser(user), ...prevValue]);
            } else {
              alert.success("New User has requested live chat!");
              setUserList((prevValue) =>
                prevValue.filter((listUser) => listUser.ID === user.sender_id)
              );
            }
          }
        }
      }

      if (user.event === "end_chat") {
        setUserList((userList) => {
          return userList.filter((items) => user.sender_id !== items.ID);
        });
      }

      if (userState.ID === user.sender_id) {
        setUserState({ type: userReducerConstants.RESET_USER });
      }
    });
  }, [
    ID,
    activeBot.ID,
    alert,
    chatState.activeChat,
    setUserList,
    setUserState,
    userState.ID,
    userState.assignedTo,
    userState.chatActive,
  ]);
  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
      onYReachEnd={nextPage}
    >
      <Wrapper>
        {userList.map((user, index) => (
          <UserPill
            key={index}
            setUserList={setUserList}
            user={user}
            userState={userState}
            activeUser={userState.ID === user.ID}
            setUserState={setUserState}
            senderID={senderID}
          />
        ))}

        {noUsers !== "" && (
          <React.Fragment>
            <WrapperDiv>
              <Typography variant="h5">No user Found</Typography>
            </WrapperDiv>
          </React.Fragment>
        )}
        {activeBot.channel === "whatsapp" && (
          <ThemeProvider theme={theme}>
            <Button
              style={{
                position: "fixed",
                bottom: "85px",
                padding: "5px 102px",
                zIndex: "2",
              }} //@mohitbhatt "Add user button"
              variant="contained"
              color="primary"
              onClick={addNewLead}
            >
              Add User
            </Button>
          </ThemeProvider> //@mohitbhatt
        )}

        {loaderState && (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </Wrapper>

      <AddNewUserDialog
        activeBot={activeBot}
        setAddNewUserDialog={setAddNewUserDialog}
        addNewUserDialog={addNewUserDialog}
        setAddUser={setAddUser}
      />
    </PerfectScrollbar>
  );

  function nextPage() {
    if ((page.number + 1) * page.size <= userList.length) {
      setPage((prevValue) => ({
        ...prevValue,
        number: prevValue.number + 1,
      }));
    }
  }

  function addNewLead() {
    setAddNewUserDialog(true);
  }
}
