import { useState } from "react";
import { Grid, InputAdornment } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function FormDisplay({ editState, formikProps }) {
  const [num, setNum] = useState();
  const limitChar = 16;

  const handleChange = (e) => {
    if (e.target.value.toString().length <= limitChar) {
      setNum(e.target.value);
    }
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Profile Details"
          subtitle="You can view and edit your details here!"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Business Name"
          name="businessName"
          //   disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Business GST No."
          name="gstNumber"
          //   disabled={!editState}
          formikProps={formikProps}
          inputProps={{
            maxLength: 15,
          }}
          onChange={(e) => handleChange(e)}
          value={num}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{num?.length}/15</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Contact Number"
          name="contactNumber"
          //   disabled={!editState}
          formikProps={formikProps}
        ></TextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Business Email Address"
          name="businessEmailAddress"
          //   disabled
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
