import styled from "styled-components";
import { Pie } from "react-chartjs-2";
import { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  Paper as MuiPaper,
} from "@mui/material";
import { Chart as ChartJs, Tooltip, ArcElement, Legend } from "chart.js";

import { useComponent } from "../../../hooks";
import startDateCalculator from "./startDateCalculator";

ChartJs.register(Tooltip, ArcElement, Legend);

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
    },
  },
};

export default function ChatsDisplay({ startDate, botID }) {
  const { axios } = useComponent();
  const [chats, setChats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/chats/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        end_date: new Date().getTime() / 1000,
        ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
      },
    }).then((response) => {
      if (response.status) {
        setChats({
          labels: response.data.labels,
          datasets: response.data.dataset,
          totalLiveChats: response.data.total_requests,
        });

        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  return (
    <Paper>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Container>
            <Grid container spacing={2}>
              <Grid
                item
                xs={7}
                sm={6}
                md={4}
                lg={4}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignSelf: "center",
                  marginTop: "60px",
                }}
              >
                <ContainerItem>
                  <Typography
                    variant="h2"
                    color="primary"
                    sx={{
                      marginTop: "auto",

                      fontSize: { lg: 25, md: 23, sm: 23, xs: 20 },
                    }}
                  >
                    {chats.totalLiveChats}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
                  >
                    Total LiveChats
                  </Typography>
                </ContainerItem>
              </Grid>

              <Grid
                item
                xs={7}
                sm={6}
                md={8}
                lg={8}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignSelf: "center",
                }}
              >
                <Pie
                  height="240vh"
                  width="240vw"
                  data={chats}
                  options={options}
                />
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Paper>
  );
}
