import React, { useState } from "react";
import { IconButton, Tooltip, Grid } from "@mui/material";
import { PhoneAndroid, DesktopMac } from "@mui/icons-material";

import "./whatsapp.css";
import MobileViewBot from "./MobileViewBot";
import { ComponentHeader, Paper } from "../../../../components";

export default function WhatsappStyleBot({ formikProps }) {
  const [phoneView, setPhoneView] = useState(false);
  const [showBubble, setShowBubble] = useState(true);

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={"Bot & Bubble Preview"}
            subtitle={
              "To view the header and footer changes please click on the bubble."
            }
            buttons={[<View />]}
          />
        </Grid>
      </Paper>

      <MobileViewBot
        phoneView={phoneView}
        setPhoneView={setPhoneView}
        formikProps={formikProps}
        showBubble={showBubble}
        setShowBubble={setShowBubble}
      />
    </React.Fragment>
  );

  function View() {
    return (
      <React.Fragment>
        <Tooltip title="Phone View">
          <IconButton onClick={() => setPhoneView(true)}>
            <PhoneAndroid style={{ color: phoneView && "#0091f3" }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Desktop View">
          <IconButton onClick={() => setPhoneView(false)}>
            <DesktopMac style={{ color: !phoneView && "#0091f3" }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}
