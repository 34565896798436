import React from "react";
import {
  Grid,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
} from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

const daysNames = [
  {
    id: 0,
    label: "Sunday",
  },
  {
    id: 1,
    label: "Monday",
  },
  {
    id: 2,
    label: "Tuesday",
  },
  {
    id: 3,
    label: "Wednesday",
  },
  {
    id: 4,
    label: "Thursday",
  },
  {
    id: 5,
    label: "Friday",
  },
  {
    id: 6,
    label: "Saturday",
  },
];

export default function DateControl({ formikProps, editState }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      daysDisabled: daysDisable(prevVal.daysDisabled),
      disabled_days: daysToBeDisabled(value),
    }));

    function daysDisable(days) {
      days = typeof value === "string" ? value.split(",") : value;
      return days;
    }

    function daysToBeDisabled(days) {
      var arr = [];
      days.map((item) => arr.push(daysNames[item]));
      return arr;
    }
  };

  function renderingValue(value) {
    var arr = [];
    value.map((item) => arr.push(daysNames.find((x) => x.id === item).label));
    return arr;
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Date Components Features"
            subtitle="These are the features which can be useful and important to your
          component"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            name="start_date"
            type="number"
            label="Start Date"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            name="end_date"
            label="End Date"
            type="number"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            select
            name="daysDisabled"
            label="Disable Days"
            disabled={!editState}
            SelectProps={{
              renderValue: (selected) => renderingValue(selected).join(","),
              multiple: true,
              value: formikProps.values.daysDisabled,
              onChange: handleChange,
            }}
          >
            {daysNames.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <Checkbox
                  checked={
                    formikProps.values.daysDisabled.indexOf(item.id) > -1
                  }
                />
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <Typography style={{ fontSize: "13px", color: "#808080" }}>
            Provide the value in numbers if one month ago should be the start
            date then it means 30 days
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography style={{ fontSize: "13px", color: "#808080" }}>
            Provide the value in numbers if one after today's date should be 30
            days
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography style={{ fontSize: "13px", color: "#808080" }}>
            The days which you want to disable in the calendar.
          </Typography>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
