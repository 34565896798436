import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, IconButton, Grid } from "@mui/material";

import ChromeIcon from "../../../assets/colored icons/ChromeIcon";
import InstagramIcon from "../../../assets/colored icons/InstagramIcon";
import WhatsappIcon from "../../../assets/colored icons/WhatsappIcon";
import FacebookIcon from "../../../assets/colored icons/FacebookIcon";
import { setActiveBot } from "../../../redux/slices/memberSlice";
import { DataTable } from "../../../components";
import pages from "../../../constants/pages";

export default function SelectBotButton() {
  const { activeBot, bots } = useSelector((state) => state.memberDetails);
  const [dialogState, setDialogState] = useState(false);

  return (
    <Fragment>
      <Button
        variant="outlined"
        startIcon={<BotIcon botChannel={activeBot.channel} />}
        onClick={() => setDialogState(true)}
      >
        {activeBot.name}
      </Button>

      <SelectBotDialog
        botList={bots}
        dialogState={dialogState}
        setDialogState={setDialogState}
      />
    </Fragment>
  );
}

function SelectBotDialog({ botList, dialogState, setDialogState }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    responsive: "standard",
    download: false,
    search: "disabled",
    searchOpen: true,
    print: false,
    viewColumns: false,
    filter: false,
    onCellClick: (_, { colIndex, dataIndex }) => {
      if (colIndex !== 2) {
        dispatch(setActiveBot(botList[dataIndex]));
        setDialogState(false);
        navigate(pages.home.route);
      }
    },
  };

  const columns = [
    {
      name: "ID",
      label: "Bot ID",
    },
    {
      name: "name",
      label: "Bot Name",
    },
    {
      name: "whatsapp",
      label: "Whatsapp Number",
    },
    {
      name: "channel",
      label: "Channel",
      options: {
        setCellProps: () => ({
          style: {
            textTransform: "capitalize",
          },
        }),
      },
    },
    {
      name: "URL",
      label: "URL",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <IconButton
            onClick={() => window.open(botList[dataIndex].URL, "_blank")}
          >
            <BotIcon botChannel={botList[dataIndex].channel} />
          </IconButton>
        ),
        setCellProps: () => ({
          style: {
            textTransform: "capitalize",
          },
        }),
      },
    },
  ];

  return (
    <Dialog open={dialogState} fullWidth onClose={handleClose} maxWidth="md">
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DataTable columns={columns} options={options} data={botList} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  function handleClose() {
    setDialogState(false);
  }
}

function BotIcon({ botChannel }) {
  switch (botChannel) {
    case "web":
      return <ChromeIcon />;

    case "whatsapp":
      return <WhatsappIcon />;

    case "facebook":
      return <FacebookIcon />;

    case "instagram":
      return <InstagramIcon />;

    default:
      return "";
  }
}
