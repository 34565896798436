import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

import { useAxios, useComponent } from "../../hooks";
import { Loader } from "../../components";
import PageHeader from "../../components/PageHeader";
import Table from "./custom field list components/Table";
import pages from "../../constants/pages";
import SetSequenceField from "./custom field list components/SetSequenceDialog";

export default function CustomFieldList({ pageID }) {
  const axios = useAxios();

  const { pagePermissions, loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [customFields, setCustomFields] = useState([]);
  const [sequence, setSequence] = useState([]);
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    loader.start();
    axios({
      url: "/custom_fields/get_fields_for_agent?agent_id=" + activeBot.ID,
    }).then((response) => {
      if (response.status) {
        loader.stop();
        setCustomFields(response.data);
      }
    });

    axios({
      url: "/custom_fields/sequence?agent_id=" + activeBot.ID,
    }).then((response) => {
      if (response.status) {
        loader.stop();
        setSequence(response.data);
      }
    });
  }, [axios, loader, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Custom Fields"
        addIcon={[
          "Create Custom Field",
          pages.customFieldCreate.route,
          pagePermissions.create,
        ]}
        buttons={[
          pagePermissions.create && customFields.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPopUp(true)}
            >
              Custom Field Sequence
            </Button>
          ),
        ]}
      />

      <Table customFields={customFields} agentID={activeBot.ID} />

      <SetSequenceField
        popUp={popUp}
        setPopUp={setPopUp}
        sequence={sequence}
        customFields={customFields}
        pagePermissions={pagePermissions}
        activeBot={activeBot}
      />
    </Loader>
  );
}
