import { useState, useEffect } from "react";
import styled from "styled-components";
import { Paper, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

import { useComponent } from "../../../hooks";
import { convertDate } from "../../../utils";
import ProfileHeader from "./profile components/ProfileHeader";
import AboutSection from "./profile components/AboutSection";
import LabelsSection from "./profile components/LabelsSection";
import CommentsSection from "./profile components/CommentsSection";
import LeadScoreProfile from "./profile components/LeadScoreProfile";

const Wrapper = styled(Paper)`
  width: 350px;
  min-width: 350px;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function Profile({
  setUserList,
  setChatState,
  userState,
  userList,
}) {
  let pageID = "a7dcf2b995d645c1b657fdac59f77c45";
  const { axios, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [fields, setFields] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [leadProperties, setLeadProperties] = useState({});

  const [comments, setComments] = useState([]);
  const [commentDialog, setCommentDialog] = useState({
    state: false,
    data: {
      comment_id: "",
      text: "",
      attachment: {
        name: "",
        url: "",
      },
    },
  });

  const [leadScore, setLeadScore] = useState();

  useEffect(() => {
    axios({
      url:
        "/custom_fields/get_fields_for_agent?agent_id=" +
        activeBot.ID +
        "&is_active=1",
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        var fields = response.data;
        setFields(fields);
        axios({
          url: "/leads/get_leads_by_sender_id",
          disableRedirect: true,
          params: {
            agent_id: activeBot.ID,
            sender_id: userState.ID,
          },
        }).then((userResponse) => {
          if (userResponse.status) {
            var userData = userResponse.data;
            setUserDetails({
              ID: userData.lead_details._id,
              name: userData.lead_details.name,
              email: userData.lead_details.email,
              phone: userData.lead_details.phone,
            });

            setLeadProperties({
              createdAt: convertDate(userData.lead_details.created_at),
              updatedAt: convertDate(userData.lead_details.updated_at),
              createdBy: userData.lead_details.created_by,
              updatedBy: userData.lead_details.updated_by,
            });

            setLeadScore(userData.lead_details.lead_score);

            for (let field of fields) {
              if (field.control_id === "61dd2dfa1fb38be82e077ed8") {
                setUserDetails((prevVal) => ({
                  ...prevVal,
                  [field.name]: setValue(userData.lead_details[field.name]),
                }));

                function setValue(options) {
                  if (Boolean(field.multi_Select)) {
                    var arr = [];

                    if (Boolean(options)) {
                      var dropDownOption = options.map((option) =>
                        field.options.find((item) => item.id === option.id)
                      );
                      for (let option of dropDownOption) {
                        arr.push(option.id);
                      }
                    }
                    return arr;
                  } else {
                    var value = "";

                    if (Boolean(options)) {
                      var dropDownSingle = options.map((option) =>
                        field.options.find((item) => item.id === option.id)
                      );
                      for (let option of dropDownSingle) {
                        value = option.id;
                      }
                    }
                    return value;
                  }
                }
              } else if (field.control_id === "61dd2dfa1fb38be82e077ed9") {
                setUserDetails((prevVal) => ({
                  ...prevVal,
                  [field.name]: userData.lead_details[field.name],
                }));
              } else if (field.control_id === "61e13a0bf2c60729b06385f3") {
                setUserDetails((prevVal) => ({
                  ...prevVal,
                  [field.name]: userData.lead_details[field.name],
                }));
              } else {
                setUserDetails((prevVal) => ({
                  ...prevVal,
                  [field.name]: userData.lead_details[field.name],
                }));
              }
            }
          }
        });
      }
    });
  }, [activeBot.ID, axios, userState.ID, dialog]);

  useEffect(() => {
    axios({
      url: "/leads/comments?sender_id=" + userState.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setComments(response.data.comments);
      }
    });
  }, [axios, userState.ID, commentDialog.state]);

  return (
    <Wrapper>
      <ProfileHeader setChatState={setChatState} />

      <Divider />

      <PerfectScrollbar>
        <Container>
          <AboutSection
            userState={userState}
            userDetails={userDetails}
            setDialog={setDialog}
            dialog={dialog}
            fields={fields}
            activeBot={activeBot}
            leadProperties={leadProperties}
            pagePermissions={pagePermissions}
          />

          <Divider />

          <LeadScoreProfile
            leadScore={leadScore}
            setLeadScore={setLeadScore}
            userDetails={userDetails}
            pagePermissions={pagePermissions}
          />

          <Divider />

          <LabelsSection
            pagePermissions={pagePermissions}
            setUserList={setUserList}
            userState={userState}
          />

          <Divider />

          <CommentsSection
            userState={userState}
            commentDialog={commentDialog}
            setCommentDialog={setCommentDialog}
            comments={comments}
          />

          <Divider />
        </Container>
      </PerfectScrollbar>
    </Wrapper>
  );
}
