import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

export const messageSocket = {
  address: `https://${URL_PREFIX}consumer.dolphinchat.ai`,
  port: {
    dev: "3020",
    uat: "3030",
    main: "3040",
  },
};

export const userSocket = {
  address: `https://${URL_PREFIX}consumer.dolphinchat.ai`,
  port: {
    dev: "3021",
    uat: "3031",
    main: "3041",
  },
};

export const notificationSocket = {
  address: `https://${URL_PREFIX}consumer.dolphinchat.ai`,
  port: {
    dev: "3022",
    uat: "3032",
    main: "3042",
  },
};
