import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Table from "./livechat request list components/Table";

export default function LivechatRequest({ pageID }) {
  const axios = useAxios();

  const dispatch = useDispatch();

  const { loader } = useComponent({ pageID });

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [users, setUsers] = useState([]);

  const [labels, setLabels] = useState([]);

  const [memberList, setMemberList] = useState([]);

  const [page, setPage] = useState({
    number: 0,
    size: 25,
  });

  const [searchQuery, setSearchQuery] = useState({
    name: "",
    email: "",
    label: "",
    assign: "",
    search: "",
    endDate: "",
    startDate: "",
    assignedTo: "",
    phoneNumber: "",
    conversation: "",
    unReadConversation: "",
  });

  useEffect(() => {
    axios({
      url: `/livechat_request_list/${activeBot.ID}`,
      params: {
        page: page.number,
        limit: page.size,
        ...(!!searchQuery.search && { search: searchQuery.search }),
        ...(!!searchQuery.name && { name: searchQuery.name }),
        ...(!!searchQuery.email && { email: searchQuery.email }),
        ...(!!searchQuery.phoneNumber && {
          phone: searchQuery.phoneNumber,
        }),
        ...(!!searchQuery.conversation && {
          conversation: searchQuery.conversation,
        }),
        ...(!!searchQuery.assignedTo && {
          assigned_to: searchQuery.assignedTo,
        }),
        ...(!!searchQuery.label && { label_id: searchQuery.label }),
        ...(!!searchQuery.unReadConversation && {
          latest_user_message_read: searchQuery.unReadConversation,
        }),
        ...(!!searchQuery.assign && {
          is_assigned: searchQuery.assign,
        }),
        ...(!!searchQuery.startDate && {
          start_time: searchQuery.startDate,
        }),
        ...(!!searchQuery.endDate && {
          end_time: searchQuery.endDate,
        }),
      },
    }).then((response) => {
      if (response.status) {
        if (page.number === 0) {
          setUsers(response.data);
        } else {
          setUsers((prev) => [...prev, ...response.data]);
        }
        loader.apiComplete();
      }
    });
  }, [
    loader,
    axios,
    page.size,
    page.number,
    activeBot.ID,
    searchQuery.name,
    searchQuery.label,
    searchQuery.email,
    searchQuery.assign,
    searchQuery.search,
    searchQuery.endDate,
    searchQuery.startDate,
    searchQuery.assignedTo,
    searchQuery.phoneNumber,
    searchQuery.conversation,
    searchQuery.unReadConversation,
  ]);

  useEffect(() => {
    loader.start();

    // axios({
    //   url: "/livechat_requests/" + activeBot.ID,
    //   method: "GET",
    // }).then((response) => {
    //   if (response.status) {
    //     setUsers(response.data);

    //     loader.apiComplete();
    //   }
    // });

    axios({
      url: "/leads/members_list",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setMemberList(response.data);
      }
    });

    axios({
      url: "/label/" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setLabels(response.data);
      }
    });
  }, [activeBot.ID, dispatch, axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Livechat Request List" />

      <Table
        userData={users}
        labels={labels}
        memberList={memberList}
        activeBot={activeBot}
        searchQuery={searchQuery}
        page={page}
        setPage={setPage}
        setSearchQuery={setSearchQuery}
        setUsers={setUsers}
      />
    </Loader>
  );
}
