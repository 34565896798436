import { useNavigate } from "react-router-dom";

import { Chip, DataTable } from "../../../components";
import convertDate from "../../../utils/convertDate";
import pages from "../../../constants/pages";

export default function Table({ groups }) {
  const navigate = useNavigate();
  const options = {
    onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  function handleRowClick(_, rowMetaData) {
    navigate(pages.groupDetails.route + groups[rowMetaData.dataIndex].group_id);
  }

  return <DataTable columns={columns} options={options} data={groups} />;
}

const columns = [
  {
    name: "group_name",
    label: "Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Group Status",
    options: {
      customBodyRender: (value) => {
        return value === true ? (
          <Chip label="Active" type="success" />
        ) : (
          <Chip label="Inactive" type="error" />
        );
      },
    },
  },

  {
    name: "total_users",
    label: "Total Members",
    options: {
      customBodyRender: (value) => {
        return value ? value : 0;
      },
    },
  },
  {
    name: "updated_at",
    label: "Updated At",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
