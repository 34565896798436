import { titleCase } from "change-case-all";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function UtteranceTable({ utterances }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(pages.utteranceDetails.route + utterances[dataIndex]._id),
  };

  return <DataTable columns={columns} data={utterances} options={options} />;
}

const columns = [
  {
    name: "name",
    label: "Utterance Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "type",
    label: "Utterance Type",
    options: {
      customBodyRender: (value) => {
        return value ? titleCase(value.replace("_", " ")) : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
