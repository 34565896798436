import React, { useState } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  Switch,
  FormControlLabel,
} from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";
import RegexDialog from "../custom field details component/regex/RegexDialog";

export default function TextFieldControl({ formikProps, editState }) {
  const [popUp, setPopUp] = useState(false);
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Text Field Features"
          subtitle="These are the features which can be useful and important to your
          component"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          required
          formikProps={formikProps}
          name="regex"
          label="Field Regex"
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          required
          formikProps={formikProps}
          name="errorMessage"
          label="Error Message"
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Tooltip title="Enable this to make the field as text area.">
              <Switch
                checked={formikProps.values.multiline}
                name="multiline"
                onChange={formikProps.handleChange}
                disabled={!editState}
              />
            </Tooltip>
          }
          label="Multiline"
        />
      </Grid>

      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Tooltip title="Enable this to make the copy icon on text field so that you can copy the value by clicking on it.">
              <Switch
                checked={formikProps.values.copyable}
                name="copyable"
                onChange={formikProps.handleChange}
                disabled={!editState}
              />
            </Tooltip>
          }
          label="Copy Icon"
        />
      </Grid>

      <Grid item xs={2}>
        {Boolean(formikProps.values.multiline) && (
          <TextField
            type="number"
            formikProps={formikProps}
            name="rows"
            label="Rows in Text Field"
            disabled={!editState}
          />
        )}
      </Grid>

      {!!editState && (
        <Grid item xs={6}>
          <Typography style={{ fontSize: "13px", color: "#808080" }}>
            Provide a regex for your field as a validation .To build a customize
            regex{" "}
            <Typography
              style={{ cursor: "pointer" }}
              variant="button"
              color="primary"
              onClick={() => setPopUp(true)}
            >
              Click Here!
            </Typography>
          </Typography>
        </Grid>
      )}

      <RegexDialog
        popUp={popUp}
        setPopUp={setPopUp}
        formikProps={formikProps}
      />
    </Paper>
  );
}
