import React, { Fragment } from "react";
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "600px" },
}));

export default function SampleFileDialog({
  templates,
  sampleFileDialog,
  setSampleFileDialog,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setSampleFileDialog(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={sampleFileDialog}
        onClose={handleClose}
      >
        <DialogTitle style={{ paddingBottom: "4px" }}>
          Download Sample File
        </DialogTitle>

        <Formik initialValues={{ templateID: "" }}>
          {(formikProps) => {
            return (
              <Fragment>
                <Grid container item xs={12}>
                  <DialogContent>
                    <TextField
                      select
                      label="Template Name"
                      name="templateID"
                      required
                      sx={{ width: "100%" }}
                      formikProps={formikProps}
                    >
                      {templates?.map((items, index) => {
                        return (
                          <MenuItem value={items._id}>
                            {items.template_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </DialogContent>
                </Grid>

                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    variant="contained"
                  >
                    Download
                  </Button>

                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="primary"
                    autoFocus
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Fragment>
            );
          }}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
