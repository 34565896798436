import { Fragment, useState } from "react";
import { Paper, Divider, Typography } from "@mui/material";
import styled from "styled-components";

import ConversationHeader from "./conversation components/ConversationsHeader";
import ConversationsWindow from "./conversation components/ConversationsWindow";
import ConversationsFooter from "./conversation components/ConversationsFooter";

const Wrapper = styled(Paper)`
  height: 100%;
  width: calc(100% - 300px);
  min-width: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ClickDetails = styled(Typography)`
  padding: 40px 370px;
  font-size: 25px;
  font-weight: 600;
  background-image: linear-gradient(to left, #1e2f97, #b393d3);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  white-space: nowrap;
`;

export default function Conversations({
  setUserList,
  userState,
  setUserState,
  setChatState,
  userList,
  setChatEnd,
}) {
  const [latestUserMessageRead, setLatestUserMessageRead] = useState(true);
  const [latestUserMessageTimeStamp, setLatestUserMessageTimeStamp] =
    useState();

  return (
    <Wrapper>
      {!!userState.ID ? (
        <Fragment>
          <ConversationHeader
            userState={userState}
            setUserState={setUserState}
            setChatState={setChatState}
            userList={userList}
            setUserList={setUserList}
            setChatEnd={setChatEnd}
            latestUserMessageRead={latestUserMessageRead}
            setLatestUserMessageRead={setLatestUserMessageRead}
          />

          <Divider />

          <ConversationsWindow
            setUserList={setUserList}
            userState={userState}
            setLatestUserMessageRead={setLatestUserMessageRead}
            setLatestUserMessageTimeStamp={setLatestUserMessageTimeStamp}
          />

          <Divider />

          <ConversationsFooter
            userState={userState}
            setChatState={setChatState}
            latestUserMessageTimeStamp={latestUserMessageTimeStamp}
          />
        </Fragment>
      ) : (
        <ClickDetails>Click on the chats to see more details.</ClickDetails>
      )}
    </Wrapper>
  );
}
