import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useComponent } from "../../hooks";
import { PageHeader, Loader } from "../../components";
import Table from "./greviance list components/Table";

export default function Greviance({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader } = useComponent({ pageID });

  const [grievance, setGrievance] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: `/leads/grievance?agent_id=${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        setGrievance(response.data);

        loader.apiComplete();
      }
    });
  }, [activeBot.ID, axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Grievance" />

      <Table grievance={grievance} />
    </Loader>
  );
}
