import { Paper, IconButton, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import SelectBotButton from "./navbar components/SelectBotButton";
import NotificationsButton from "./navbar components/NotificationsButton";
import DropDownButton from "./navbar components/DropDownButton";

const Wrapper = styled(Paper)`
  height: 64px;
  border-radius: 0px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
  padding-inline: 10px;
  gap: 6px;
`;

export default function Navbar({ sidebarState, toggleSideBar }) {
  const navigate = useNavigate();

  return (
    <Wrapper elevation={4}>
      <GridContainer container>
        <Grid item>
          <IconButton onClick={toggleSideBar} color="primary">
            {sidebarState.static ? <RiMenuFoldFill /> : <RiMenuUnfoldFill />}
          </IconButton>
        </Grid>

        <Grid item>
          <Typography
            variant="h3"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            DolphinChat
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <SelectBotButton />
        </Grid>

        <Grid item>
          <NotificationsButton />
        </Grid>

        <Grid item>
          <DropDownButton />
        </Grid>
      </GridContainer>
    </Wrapper>
  );
}
