import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import pages from "../../constants/pages";
import { Loader, PageHeader } from "../../components";
import Form from "./canned message detail components/Form";
import {
  cannedMessageObject,
  objectFromResponse,
} from "./canned message detail components/cannedMessageObject";
import { useAxios, useComponent } from "../../hooks";

export default function CannedMessagesDetails({ pageID }) {
  const [formData, setFormData] = useState(cannedMessageObject);
  const [editState, setEditState] = useState(true);

  const axios = useAxios();
  const params = useParams();

  const { loader, pagePermissions } = useComponent({ pageID });
  const CannedMessageId = params.canned_message_id || "";
  const { activeBot, businessID } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (Boolean(CannedMessageId)) {
      setEditState(false);
    }
  }, [CannedMessageId]);

  useEffect(() => {
    if (CannedMessageId) {
      loader.start();
      axios({
        url:
          "canned-message/" +
          businessID +
          "?canned_message_id=" +
          CannedMessageId,
        method: "GET",
      }).then((responses) => {
        if (responses.status) {
          setFormData(objectFromResponse(responses.data, true));
          loader.apiComplete();
        }
      });
    }
    loader.stop();
  }, [axios, activeBot.ID]);
  return (
    <Loader>
      <PageHeader
        pageName="Canned Response Details"
        breadcrumbs={[
          {
            name: "Canned Response",
            location: pages.cannedMessagesList.route,
          },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />
      <Form
        setFormData={setFormData}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        activeBot={activeBot}
        CannedMessageId={CannedMessageId}
        activeBotID={activeBot.ID}
      />
    </Loader>
  );
}
