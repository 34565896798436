import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import { useComponent } from "../../hooks";
import Message from "../chats/chats components/main window components/conversation components/conversations window component/Message";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function TestPage({ userID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios } = useComponent();

  const scrollRef = useRef(null);

  const [loaderState, setLoaderState] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setLoaderState(true);

    axios({
      url: `/conversation/${userID}`,
      params: {
        bot_id: activeBot.ID,
      },
    }).then((response) => {
      if (response.status) {
        const messages = [];

        for (let message of response.data.events) {
          messages.push(formatMessage(message));
        }

        setMessages(messages);

        scrollToBottom();

        setLoaderState(false);
      }
    });
  }, [activeBot.ID, axios, userID]);

  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true, minScrollbarLength: 20 }}
      containerRef={(ref) => (scrollRef.current = ref)}
    >
      <Wrapper>
        {loaderState && (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}

        {messages.map((message) => (
          <Message key={message.ID} message={message} />
        ))}
      </Wrapper>
    </PerfectScrollbar>
  );

  function scrollToBottom() {
    scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER;
  }
}

export function formatMessage(message) {
  return {
    ID: message.message_id,
    actor: message.actor,
    body: sanitizeBody(message.payload, message.type),
    timeStamp: message.timestamp,
  };

  function sanitizeBody(payload, type) {
    switch (type) {
      case "text":
        return {
          text: payload.text,
        };

      case "image":
        return {
          imageURL: payload.url,
          footer: payload.caption,
        };

      case "video":
        return {
          videoURL: payload.url,
          footer: payload.caption,
        };

      case "audio":
        return {
          audioURL: payload.url,
        };

      case "document":
        return {
          documentName: payload.name,
          documentURL: payload.url,
        };

      case "quick_replies":
        return {
          text: payload.text,
          buttons: payload.buttons,
        };

      case "carousel":
        return {
          text: "Bot sent a carousel",
        };

      default:
        return {};
    }
  }
}
