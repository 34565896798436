import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useEffect, useState } from "react";

import Table from "./Table";
import { useAxios } from "../../hooks";
import OnboardingDialog from "./OnboardingDialog";
import { PageHeader, Paper } from "../../components";

export default function WhatsappOnboarding() {
  const axios = useAxios();
  const { businessID } = useSelector((state) => state.memberDetails);

  const [dataTable, setDataTable] = useState([]);
  const [onboardDialog, setOnboardDialog] = useState(false);

  useEffect(() => {
    axios({
      url: "/embedded_signup/phone-numbers/" + businessID,
      disableRedirect: true,
    }).then((resp) => {
      if (resp.status) {
        setDataTable(resp.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <PageHeader
        pageName={"WhatsApp Onboarding"}
        buttons={[
          <Button variant="contained" onClick={() => setOnboardDialog(true)}>
            Onboard New Number <AddIcon />
          </Button>,
        ]}
      />

      <Paper>
        <Grid item xs={12}>
          <Table dataTable={dataTable} />
        </Grid>
      </Paper>

      <OnboardingDialog
        onboardDialog={onboardDialog}
        setOnboardDialog={setOnboardDialog}
      />
    </Fragment>
  );
}
