import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { Cancel, Edit, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import BroadcastReport from "./BroadcastReport";
import convertDate from "../../../utils/convertDate";
import scheduledDate from "./scheduledDateFormat";
import { DataTable, Chip } from "../../../components";
import { useAxios } from "../../../hooks";
import CancelDialog from "./CancelBroadcastDialogue";
import pages from "../../../constants/pages";
import { alert } from "../../../redux/slices/alertSlice";

export default function Table({ broadcasts, setCancelBroadcast }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  const [broadcastReport, setBroadcastReport] = useState({
    state: false,
    report: {},
    link: "",
  });

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Broadcast ID",
      options: {
        display: false,
        filter: true,
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 15) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },

    {
      name: "broadcast_name",
      label: "Broadcast Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "template_name",
      label: "Template Name",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 30) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },

    {
      name: "scheduled_date",
      label: "Scheduled Date",
      options: {
        customBodyRender: (value) => {
          return value ? scheduledDate(value) : "Not Available";
        },
      },
    },

    {
      name: "scheduled_time_data",
      label: "Scheduled Time",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "status_id",
      label: "Broadcast Status",
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            if (value === "a7e117f613ef47338df34c60f3a75d76") {
              return <Chip type={"success"} label={"Success"} />;
            } else if (
              value === "d35ceb22fa0e4c2b964e253062bf3460" ||
              value === "86844d49cfb147ff92b0cae976ec43ec"
            ) {
              return (
                <Chip
                  label={
                    value === "d35ceb22fa0e4c2b964e253062bf3460"
                      ? "Draft"
                      : "Pending"
                  }
                  type="warning"
                />
              );
            } else if (
              value === "0d84075ce0ba4504b09df1f959ef610a" ||
              value === "23a547fb34eb496a8dd44c09d44b49c0"
            ) {
              return (
                <Chip
                  label={
                    value === "0d84075ce0ba4504b09df1f959ef610a"
                      ? "Failed"
                      : "Cancelled"
                  }
                  type={"error"}
                />
              );
            } else if (value === "a76d72f97f554476ab921c14b64fa788") {
              return <Chip label={"Processing"} type="info" />;
            } else {
              return value;
            }
          } else {
            return "Not Available";
          }
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (rowIndex, index) => (
          <div style={{ display: "flex" }}>
            <div style={{ width: "35px" }}>
              {index.rowData[6] === "d35ceb22fa0e4c2b964e253062bf3460" && (
                <IconButton
                  onClick={() =>
                    navigate(pages.broadcastDetails.route + index.rowData[0])
                  }
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Edit Broadcast">
                    <Edit />
                  </Tooltip>
                </IconButton>
              )}
            </div>

            <div style={{ width: "35px" }}>
              {index.rowData[6] !== "23a547fb34eb496a8dd44c09d44b49c0" &&
                index.rowData[6] !== "a76d72f97f554476ab921c14b64fa788" &&
                index.rowData[6] !== "a7e117f613ef47338df34c60f3a75d76" &&
                index.rowData[6] !== "d35ceb22fa0e4c2b964e253062bf3460" &&
                index.rowData[6] !== "0d84075ce0ba4504b09df1f959ef610a" && (
                  <IconButton
                    style={{
                      color: "#ff0000",
                    }}
                    onClick={() =>
                      setDialogState({
                        state: true,
                        id: index.rowData[0],
                      })
                    }
                    color="primary"
                    size="small"
                  >
                    <Tooltip title="Cancel Broadcast">
                      <Cancel />
                    </Tooltip>
                  </IconButton>
                )}
            </div>

            <div style={{ width: "35px" }}>
              <Tooltip title="View Report">
                <IconButton
                  onClick={() => loadReport(index.rowData[0])}
                  color="primary"
                  size="small"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ),
      },
    },
  ];

  const loadReport = (id) => {
    axios({
      url: "/broadcast/get/report/" + id,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        setBroadcastReport({
          state: true,
          report: res.data,
        });
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  const cancelBroadcast = () => {
    var data = dialogState.id;
    axios({
      url: "/broadcast/cancel/campaign",
      method: "PATCH",
      disableRedirect: true,
      data: {
        sbcid: data,
      },
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        setCancelBroadcast(res.data.sbcid);
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={broadcasts} />

      <BroadcastReport
        broadcastReport={broadcastReport}
        setBroadcastReport={setBroadcastReport}
        activeBot={activeBot}
        loadReport={loadReport}
      />

      <CancelDialog
        dialogState={dialogState.state}
        setDialogState={setDialogState}
        handleAgree={cancelBroadcast}
      />
    </React.Fragment>
  );
}
