import { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";

import { Paper } from "../../../../components";
import FailedDisplayNameForm from "./FailedDisplayNameForm";

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`;

export default function RegistrationStatusDisplay({
  formData,
  registrationID,
}) {
  return (
    <Wrapper>
      <Paper style={{ marginBlock: "auto", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center">
            Thank you for registering with us.
          </Typography>

          <Typography variant="subtitle2" align="center">
            Your registration ID is {registrationID}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {registrationStatusText(formData?.[4]?.registrationStatus)}
        </Grid>
      </Paper>
    </Wrapper>
  );

  function registrationStatusText(registrationStatus) {
    switch (registrationStatus) {
      case "form submission":
        return (
          <Typography variant="h5" align="center" color="#376fd0">
            Your form has been submitted. Come back later to check the
            registration status.
          </Typography>
        );

      case "in progress":
        return (
          <Typography variant="h5" align="center" color="#376fd0">
            Your form has been submitted and registration is under progress.
          </Typography>
        );

      case "approved":
        return (
          <Typography variant="h5" align="center" color="#1c872c">
            Your request has been approved!
          </Typography>
        );

      case "failed":
        return (
          <Typography variant="h5" align="center" color="error">
            Your registration has failed. Please contact DolphinChat for more
            information.
          </Typography>
        );

      case "display name error":
        return (
          <Fragment>
            <Typography variant="h5" align="center" color="error">
              Some of the display names have not been approved.
            </Typography>

            <Typography variant="h5" align="center" color="error" gutterBottom>
              Please change them so that the registration can move forward.
            </Typography>

            <FailedDisplayNameForm
              formData={formData}
              registrationID={registrationID}
            />
          </Fragment>
        );

      default:
        return (
          <Typography variant="h5" align="center" color="#376fd0">
            Your form has been submitted and in process of being approved.
          </Typography>
        );
    }
  }
}
