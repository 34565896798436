import { Fragment, useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useComponent } from "../../../../hooks";
import { Chip } from "../../../../components";
import LabelDialog from "../../../leads/lead detail components/label components/LabelDialog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
`;

export default function LabelsSection({
  setUserList,
  userState,
  pagePermissions,
  userList,
}) {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios } = useComponent();

  const [labels, setLabels] = useState([]);
  const [labelDialog, setLabelDialog] = useState(false);
  const [assignLabel, setAssignLabel] = useState([]);
  const [assignUnassignLabel, setAssignUnassignLabel] = useState("");

  useEffect(() => {
    axios({
      url: "/label/" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setLabels(response.data);
      }
    });
  }, [axios, activeBot.ID]);

  useEffect(() => {
    if (userState.ID) {
      axios({
        url: "/label/get/assigned-label?sender_id=" + userState.ID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setAssignLabel(response.data);
        }
      });
    }
  }, [axios, userState.ID]);

  function handleDelete(id) {
    if (pagePermissions.delete) {
      axios({
        url: "/label/assign/label/" + id + "?sender_id=" + userState.ID,
        disableRedirect: true,
        method: "PATCH",
        data: {
          action: "unassign",
        },
      }).then((response) => {
        if (response.status) {
          setAssignUnassignLabel(id);
        }
      });
    } else {
      alert.warning("You don't have permission to delete the label!");
    }
  }
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        Labels
      </Typography>

      {assignLabel.length > 0 && (
        <Fragment>
          <LabelWrapper>
            {assignLabel.map((item, index) => (
              <Chip
                key={index}
                label={item.title}
                style={{ backgroundColor: item.color, color: "#fff" }}
                onDelete={() => handleDelete(item._id)}
              />
            ))}
          </LabelWrapper>
        </Fragment>
      )}

      {assignLabel.length === 0 && (
        <Typography variant="subtitle2">
          No labels assigned till yet!
        </Typography>
      )}

      <Button variant="outlined" onClick={() => setLabelDialog(true)}>
        Assign Labels
      </Button>
      <LabelDialog
        labelDialog={labelDialog}
        labels={labels}
        data={userState}
        component="chats"
        pagePermissions={pagePermissions}
        setLabelDialog={setLabelDialog}
        assignLabel={assignLabel}
        setAssignUnassignLabel={setAssignUnassignLabel}
        setAssignLabel={setAssignLabel}
      />
    </Wrapper>
  );
}
