import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataTable } from "../../../../../components";
import pages from "../../../../../constants/pages";
import { convertDate } from "../../../../../utils";

export default function Table({ templates, setTemplateDelete }) {
  const navigation = useNavigate();

  function handleCellClick(rowData, rowMeta) {
    navigation(pages.emailTemplateDetails.route + rowData[0]);
  }

  const options = {
    onRowClick: handleCellClick,

    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Template Name",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "template_name",
      label: "Template Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "subject",
      label: "Email Template Subject",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={templates} />
    </React.Fragment>
  );
}
