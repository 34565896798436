import React, { useState } from "react";
import { Grid, Button } from "@mui/material";

import RegexTable from "./RegexTable";
import { Paper, ComponentHeader } from "../../../../../components";
import RegexDialog from "../../../../custom fields/custom field details component/regex/RegexDialog";

export default function RegexList({ formikProps, editState, setEditState }) {
  const [popUp, setPopUp] = useState(false);

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            subtitle={"Add Regex to the intent."}
            buttons={[<AddRegex />]}
          />
        </Grid>

        <Grid item xs={12}>
          <RegexTable
            data={formikProps.values.regex}
            formikProps={formikProps}
          />
        </Grid>
      </Paper>

      <RegexDialog
        popUp={popUp}
        setPopUp={setPopUp}
        formikProps={formikProps}
        component={"botBuilder"}
      />
    </React.Fragment>
  );

  function AddRegex() {
    return (
      <Button
        disabled={!editState}
        variant="outlined"
        onClick={() => setPopUp(true)}
      >
        Add Regex
      </Button>
    );
  }
}
