import React from "react";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../../components/DataTable";
import pages from "../../../../constants/pages";
import { convertDate } from "../../../../utils";

export default function Table({ slots }) {
  const navigate = useNavigate();

  function handleClick(_, cellMetaData) {
    navigate(pages.slotDetails.route + slots[cellMetaData.dataIndex].slot_name);
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
    onCellClick: handleClick,
  };

  const columns = [
    {
      name: "slot_name",
      label: "Slot Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "type",
      label: "Slot Type",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value
            ? value === true
              ? "Active"
              : "Inactive"
            : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "created_by",
      label: "Created By",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={slots} />;
}
