import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import pages from "../../constants/pages";
import {
  utteranceDetailsObject,
  objectFromResponseData,
} from "./utterance details components/utteranceDetailsObject";
import { Loader, PageHeader } from "../../components";
import { useComponent } from "../../hooks";
import Form from "./utterance details components/Form";

export default function UtteranceDetails({ pageID }) {
  const { utteranceID } = useParams();
  const { axios, loader, pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [editState, setEditState] = useState(!utteranceID);
  const [formData, setFormData] = useState(utteranceDetailsObject);

  useEffect(() => {
    loader.start();

    if (!!utteranceID) {
      axios({
        url: `/utterance/${activeBot.serviceID}?bot_id=${activeBot.ID}&utterance_id=${utteranceID}`,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));

          loader.apiComplete();
        }
      });

      loader.stop();
    } else {
      loader.stop();
    }
  }, [activeBot.ID, activeBot.serviceID, axios, loader, utteranceID]);

  return (
    <Loader>
      <PageHeader
        pageName="Utterance Details"
        breadcrumbs={[
          { location: pages.utteranceList.route, name: "Utterances" },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        botID={activeBot.ID}
        botServiceID={activeBot.serviceID}
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
