import React from "react";
import { Grid, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";

import { FileUpload, TextField } from "../../../../components";

export default function HeaderVariable({
  dialogFormikProps,
  templateData,
  formikProps,
  fields,
}) {
  var extensions;
  if (templateData.template_data?.template_header?.type === "image") {
    extensions = ".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp,image/*";
  } else if (templateData.template_data?.template_header?.type === "video") {
    extensions = "video/mp4,video/x-m4v,video/*";
  } else {
    extensions = ".xlsx,.xls,.doc,.docx,.ppt, .pptx,.txt,.pdf";
  }

  return (
    <Grid container spacing={4} alignItems="center">
      {templateData.template_data?.template_header?.count_of_variable !== 0 &&
        !!formikProps.values.templateID && (
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <Typography variant="subtitle1">Map Header Variables</Typography>
              <Tooltip title="Values will be used in place of variables in header while sending message to the users.">
                <IconButton disableRipple style={{ padding: "0 0 0 5px" }}>
                  <Info
                    style={{ color: "rgb(203 203 203 / 0.63)" }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        )}

      {templateData.template_data?.template_header?.count_of_variable !== 0 &&
        !!formikProps.values.templateID &&
        templateData.variables?.header?.map((_, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <TextField
                select
                label="Header Variable Name"
                name={"header_variable[" + index + "].variable_name"}
                formikProps={dialogFormikProps}
              >
                {templateData.variables.header.map((item, index) => (
                  <MenuItem
                    value={item}
                    disabled={dialogFormikProps.values?.header_variable.some(
                      (name) => name.variable_name === item
                    )}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={
                dialogFormikProps.values?.header_variable[index]
                  ?.variable_value_type === "custom"
                  ? 4
                  : 3
              }
            >
              <TextField
                select
                label={"Header Variable Type"}
                name={"header_variable[" + index + "].variable_value_type"}
                formikProps={dialogFormikProps}
              >
                <MenuItem
                  value="user_variable"
                  disabled={
                    templateData.template_data?.template_header?.type !== "text"
                  }
                >
                  Custom Fields
                </MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "user_variable" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={3}>
                  <TextField
                    select
                    label={"Custom Fields"}
                    name={"header_variable[" + index + "].variable_value"}
                    formikProps={dialogFormikProps}
                  >
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone_number">Phone Number</MenuItem>
                    {fields.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "custom" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={5}>
                  <TextField
                    label={"Custom Value"}
                    onBlur={dialogFormikProps.handleBlur}
                    name={"header_variable[" + index + "].variable_value"}
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              )}

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "user_variable" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={3}>
                  <TextField
                    label={"Fallback Value"}
                    onBlur={dialogFormikProps.handleBlur}
                    name={
                      "header_variable[" + index + "].variable_default_value"
                    }
                    formikProps={dialogFormikProps}
                    style={{ padding: "0px" }}
                  />
                </Grid>
              )}

            {templateData.template_data?.template_header?.type !== "text" && (
              <Grid item xs={5}>
                <FileUpload
                  label="Upload File"
                  name={"header_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                  allowedExtension={extensions}
                  maxSize={25}
                  folderPath={"variables/" + new Date().getTime() + "_"}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
}
