import { Typography } from "@mui/material";
import styled from "styled-components";
import LeadScore from "./LeadScoreForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default function LeadScoreProfile({
  leadScore,
  setLeadScore,
  userDetails,
  pagePermissions,
}) {
  return (
    <Wrapper>
      <Typography variant="h6">Lead Score</Typography>

      <LeadScore
        leadScore={leadScore}
        setLeadScore={setLeadScore}
        formData={userDetails}
        pagePermissions={pagePermissions}
      />
    </Wrapper>
  );
}
