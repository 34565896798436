import React from "react";
import General from "./General";
import Footer from "./Footer";
import Header from "./Header";
import WindowSettings from "./Window";
import { ButtonFooter } from "../../../../components";

export default function FormDisplay({
  formikProps,
  editState,
  setEditState,
  image,
  setImage,
}) {
  return (
    <React.Fragment>
      <General
        formikProps={formikProps}
        editState={editState}
        image={image}
        setImage={setImage}
      />

      <Header
        formikProps={formikProps}
        editState={editState}
        image={image}
        setImage={setImage}
      />

      <WindowSettings formikProps={formikProps} editState={editState} />

      <Footer formikProps={formikProps} editState={editState} />

      <ButtonFooter
        ID={formikProps.values.agentID}
        editState={editState}
        setEditState={setEditState}
        formikProps={formikProps}
      />
    </React.Fragment>
  );
}
