import styled from "styled-components";
import { Typography, Button } from "@mui/material";

import { TextAvatar } from "../../../../components";
import CustomFieldDialog from "./CustomFieldsDialog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export default function AboutSection({
  userState,
  userDetails,
  setDialog,
  dialog,
  fields,
  activeBot,
  leadProperties,
  pagePermissions,
}) {
  return (
    <Wrapper>
      <Typography variant="h6">About</Typography>

      <ProfileContainer>
        <TextAvatar
          userName={userState.name}
          size={{ height: "70px", width: "70px", fontSize: "20px" }}
        />
      </ProfileContainer>

      {!!userState.name && (
        <ProfileContainer>
          <Typography variant="h6">{userState.name}</Typography>
        </ProfileContainer>
      )}

      {!!userState.phone && (
        <ProfileContainer>
          <Typography
            variant="subtitle1"
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            +{userState.phone}
          </Typography>
        </ProfileContainer>
      )}

      {!!userState.email && (
        <ProfileContainer>
          <Typography
            variant="subtitle1"
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            {userState.email}
          </Typography>
        </ProfileContainer>
      )}

      <Button variant="outlined" onClick={() => setDialog(true)}>
        Show More Details
      </Button>

      <CustomFieldDialog
        dialog={dialog}
        setDialog={setDialog}
        fields={fields}
        activeBot={activeBot}
        values={userDetails}
        userState={userState}
        leadProperties={leadProperties}
        editState={pagePermissions.update}
      />
    </Wrapper>
  );
}
