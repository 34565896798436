import { Formik } from "formik";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import FormDisplay from "./FormDisplay";
import SubmitButton from "./SubmitButtons";
import CreateButton from "./CreateButtons";
import pages from "../../../constants/pages";
import validationSchema from "./validationSchema";
import { useAxios, useComponent } from "../../../hooks";
import AddSample from "./add sample components/AddSample";
import { objectFromFormData } from "./templateDetailsObject";
import WhatsappPreview from "../whatsapp interface/WhatsappPreview";
import TemplateHeader from "./template header component/TemplateHeader";
import TemplateBody from "./template body components/TemplateBody";
import TemplateFooter from "./template footer components/TemplateFooter";
import TemplateButtons from "./template button components/TemplateButtons";
import { ComponentHeader, Paper } from "../../../components";

export default function Form({
  formData,
  templateData,
  agentID,
  language,
  category,
  botDetails,
  pagePermissions,
}) {
  const axios = useAxios();
  const { alert } = useComponent();
  const navigation = useNavigate();

  const [addSample, setAddSample] = useState(false);
  const [editState, setEditState] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData);
        var count_var1 = (formData.templateBody.text.match(/\{{/g) || [])
          .length;

        var count_var2 = (formData.templateBody.text.match(/\}}/g) || [])
          .length;

        if (count_var1 === count_var2) {
          axios({
            url: "/wa/template/create/" + agentID,
            method: "POST",
            disableRedirect: true,
            data: data,
          }).then((response) => {
            if (response.status) {
              alert.success(response.message.displayMessage);

              navigation(
                pages.templateDetails.route + response.data.template_id
              );
            }
            setSubmitting(false);
          });
        } else {
          alert.error("Please match the variable!");
        }
      }}
    >
      {(formikProps) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <FormDisplay
              formikProps={formikProps}
              templateData={templateData}
              agentID={agentID}
              language={language}
              category={category}
              addSample={addSample}
              botDetails={botDetails}
              pagePermissions={pagePermissions}
            />

            <TemplateHeader formikProps={formikProps} addSample={addSample} />

            <TemplateBody formikProps={formikProps} addSample={addSample} />

            <TemplateFooter formikProps={formikProps} addSample={addSample} />

            <TemplateButtons formikProps={formikProps} addSample={addSample} />

            {(formikProps.values.templateBody.count_of_variable > 0 ||
              formikProps.values.templateHeader.count_of_variable > 0 ||
              formikProps.values.templateButtons.count_of_variable > 0 ||
              Boolean(formikProps.values.ID)) && (
              <AddSample formikProps={formikProps} />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper style={{ position: "sticky", top: "0" }}>
              <Grid item xs={12}>
                <ComponentHeader
                  title="Template Preview"
                  subtitle={"The template will look like this on users screen."}
                />
              </Grid>
              <Grid item xs={12}>
                <WhatsappPreview formikProps={formikProps.values} />
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            {!formikProps.values.ID && (
              <SubmitButton
                formikProps={formikProps}
                addSample={addSample}
                setAddSample={setAddSample}
              />
            )}

            {!!formikProps.values.ID && (
              <CreateButton
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
