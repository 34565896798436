import { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import { Search, FilterList } from "@mui/icons-material";
import styled from "styled-components";
import FilterDialog from "./filter section components/FilterDialog";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default function FilterSection({
  searchQuery,
  setSearchQuery,
  memberList,
  labels,
  setSenderID,
  senderID,
}) {
  const [filterDialogState, setFilterDialogState] = useState(false);

  return (
    <Wrapper>
      <TextField
        fullWidth
        size="small"
        name="search"
        value={searchQuery.search}
        onChange={({ target }) => searchQueryChange(target)}
        placeholder="Search"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <Tooltip title="Filter users">
        <IconButton color="primary" onClick={() => setFilterDialogState(true)}>
          <Badge badgeContent={searchQueryCount()} color="error">
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <FilterDialog
        filterDialogState={filterDialogState}
        setFilterDialogState={setFilterDialogState}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        memberList={memberList}
        labels={labels}
      />
    </Wrapper>
  );

  function searchQueryChange({ name, value }) {
    senderID !== "all" && setSenderID("all");

    setSearchQuery((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function searchQueryCount() {
    let count = 0;

    for (let query in searchQuery) {
      if (!!searchQuery[query]) {
        count++;
      }
    }

    return count;
  }
}
