import { Dialog, DialogTitle } from "@mui/material";
import styled from "styled-components";

import CannedMessages from "./dialog content components/CannedMessages";
import TemplateMessages from "./dialog content components/TemplateMessages";
import Multimedia from "./dialog content components/Multimedia";

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 20px 20px;
`;

export default function AttachmentDialog({
  dialog,
  setDialog,
  sendMessage,
  setText,
}) {
  return (
    <Dialog open={dialog.state} onClose={handleDialogClose} fullWidth>
      <DialogTitle>{`${dialog.content} Response`}</DialogTitle>

      <Wrapper>
        <DialogContent
          content={dialog.content}
          handleDialogClose={handleDialogClose}
          sendMessage={sendMessage}
          setText={setText}
        />
      </Wrapper>
    </Dialog>
  );

  function handleDialogClose() {
    setDialog((prevValue) => ({
      ...prevValue,
      state: false,
    }));
  }
}

function DialogContent({ content, handleDialogClose, sendMessage, setText }) {
  switch (content) {
    case "image":
    case "video":
    case "document":
    case "audio":
      return (
        <Multimedia
          contentType={content}
          sendMessage={sendMessage}
          handleDialogClose={handleDialogClose}
        />
      );

    case "cannedMessages":
      return (
        <CannedMessages
          sendMessage={sendMessage}
          handleDialogClose={handleDialogClose}
          setText={setText}
        />
      );

    case "templateMessages":
      return (
        <TemplateMessages
          sendMessage={sendMessage}
          handleDialogClose={handleDialogClose}
        />
      );

    default:
      return "";
  }
}
