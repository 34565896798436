import React, { useState } from "react";
import { Grid, Typography, Paper as MuiPaper, IconButton } from "@mui/material";

import styled from "styled-components";
import { Edit } from "@mui/icons-material";
import LeadSettings from "./LeadSetting";

const Paper = styled(MuiPaper)`
  height: 150px;
  display: flex;
  border-radius: 0;
  flex-direction: column;
  padding: 15px 10px 15px 10px;
`;

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;

export default function LeadInformation({
  pagePermissions,
  leadProperties,
  setChangedLeadProperty,
  formData,
  activeBot,
  memberList,
}) {
  const [popUp, setPopUp] = useState({
    state: false,
    data: {
      source: leadProperties?.source,
      lead_status: leadProperties?.lead_status,
      owner: leadProperties?.owner,
    },
  });

  return (
    <React.Fragment>
      <Paper style={{ border: "1px solid rgb(208, 208, 208, 51%)" }}>
        <Grid container spacing={2}>
          <Grid alignSelf={"center"} item xs={10}>
            <Typography variant="h5">Lead Properties</Typography>
          </Grid>

          <Grid item xs={2} textAlign="end">
            {pagePermissions.update && (
              <IconButton
                disableRipple
                style={{ padding: "0" }}
                onClick={() =>
                  setPopUp({
                    state: true,
                    data: {
                      source: leadProperties?.source,
                      lead_status: leadProperties?.lead_status,
                      owner: leadProperties?.owner,
                    },
                  })
                }
              >
                <Edit />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "center",
                  borderRight: "1px solid #cbcbcb",
                }}
              >
                <ColoredTypography variant="h6">
                  {leadProperties?.owner_name || "-"}
                </ColoredTypography>
                <Typography variant="caption">Owner</Typography>
              </Grid>

              <Grid item xs={6} style={{ textAlign: "center" }}>
                <ColoredTypography variant="h6">
                  {leadProperties?.source || "-"}
                </ColoredTypography>
                <Typography variant="caption">Lead Source</Typography>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  textAlign: "center",
                  borderRight: "1px solid #cbcbcb",
                }}
              >
                <ColoredTypography variant="h6">
                  {leadProperties?.lead_age || "-"}
                </ColoredTypography>
                <Typography variant="caption">Lead Age</Typography>
              </Grid>

              <Grid item xs={6} style={{ textAlign: "center" }}>
                <ColoredTypography variant="h6">
                  {leadProperties?.lead_status || "-"}
                </ColoredTypography>
                <Typography variant="caption">Lead Status</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <LeadSettings
        popUp={popUp}
        setPopUp={setPopUp}
        data={formData}
        activeBot={activeBot}
        memberList={memberList}
        setChangedLeadProperty={setChangedLeadProperty}
      />
    </React.Fragment>
  );
}
