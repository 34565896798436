// import AWS from "aws-sdk";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { BsFileEarmark } from "react-icons/bs";
import { Grid, Tooltip, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { ImageOutlined, Upload } from "@mui/icons-material";

import { useAxios } from "../../../../hooks";
import { uploadToS3 } from "../../../../utils";
import { Paper, ComponentHeader } from "../../../../components";

const Div = styled.div`
  border: 2px solid #808080;
  width: 100px;
  height: 80px;
  align-self: center;
  display: flex;
  justify-content: center;
  color: #808080;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    box-shadow: 1px 1px #808080;
  }
`;

const OuterGrid = styled(Grid)`
  text-align: center;
  align-self: center;
  color: #808080;
`;

const Image = styled(ImageOutlined)`
  font-size: 45px;
  align-self: center;
`;

const Video = styled(MdOutlineOndemandVideo)`
  font-size: 45px;
  align-self: center;
`;

const Document = styled(BsFileEarmark)`
  font-size: 40px;
  align-self: center;
`;

export default function DocumentInterface({
  documents,
  formData,
  setAddDocument,
  setDeleteDocument,
  pagePermissions,
  bucket = {},
  folderPath,
}) {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);

  function handleDownload(item) {
    window.location.href = item;
  }

  const handleUploadFile = (CSV) => {
    const file = CSV.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function () {
        // var bucketName = "dc-chat-media";
        // var bucketRegion = "ap-south-1";
        // var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

        // AWS.config.update({
        //   region: bucketRegion,
        //   credentials: new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: identityPoolID,
        //   }),
        // });

        // var s3 = new AWS.S3({
        //   apiVersion: "2006-03-01",
        //   params: {
        //     Bucket: bucketName,
        //   },
        // });

        // var filePath = "sample_data_files/" + file.name;
        // var upload = s3.upload({
        //   Key: filePath,
        //   ACL: "public-read",
        //   Body: file,
        // });

        // var promise = upload.promise();
        const uploadBucket = {
          name: "dc-chat-media",
          region: "ap-south-1",
          identityPoolID: "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103",
          ...bucket,
        };

        uploadToS3({
          bucket: uploadBucket,
          filePath: "sample_data_files/" + file.name,
          file,
          bucketRegion: "ap-south-1",
        }).then(function (data) {
          setLoading(true);
          var option = {
            lead_id: formData.ID,
            attachment: {
              url:
                "https://" +
                uploadBucket.name +
                ".s3.ap-south-1.amazonaws.com/" +
                folderPath +
                file.name,
              name: file.name,
            },
          };

          axios({
            url: "/leads/documents",
            method: "POST",
            data: option,
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setLoading(false);
              setDeleteDocument(response.data._id);
            }
          });
        });
      };
      reader.onerror = function (error) {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Documents"}
          subtitle="Upload the documents you need for the leads or you want to save them."
          buttons={[
            pagePermissions.create && formData.ID && <UploadDocument />,
          ]}
        />
      </Grid>

      {documents.map((item) => (
        <React.Fragment key={item._id}>
          {(item.attachment.name.split(".").pop() === "jpg" ||
            item.attachment.name.split(".").pop() === "png" ||
            item.attachment.name.split(".").pop() === "jpeg" ||
            item.attachment.name.split(".").pop() === "gif") && (
            <Tooltip title={item.attachment.name}>
              <OuterGrid
                item
                onClick={() => handleDownload(item.attachment.url)}
              >
                <Div>
                  <Image fontSize="medium" />
                </Div>
                <Typography style={{ fontSize: "10px", letterSpacing: "0.5" }}>
                  {item.attachment.name.slice(0, 13) +
                    (item.attachment.name.length > 13 ? "..." : "")}
                </Typography>
              </OuterGrid>
            </Tooltip>
          )}

          {(item.attachment.name.split(".").pop() === "mp4" ||
            item.attachment.name.split(".").pop() === "x-m4v") && (
            <OuterGrid item onClick={() => handleDownload(item.attachment.url)}>
              <Div>
                <Video />
              </Div>
              <Typography style={{ fontSize: "10px", letterSpacing: "0.5px" }}>
                {item.attachment.name.slice(0, 13) +
                  (item.attachment.name.length > 13 ? "..." : "")}
              </Typography>
            </OuterGrid>
          )}

          {(item.attachment.name.split(".").pop() === "xlsx" ||
            item.attachment.name.split(".").pop() === "xls" ||
            item.attachment.name.split(".").pop() === "doc" ||
            item.attachment.name.split(".").pop() === "docx" ||
            item.attachment.name.split(".").pop() === "ppt" ||
            item.attachment.name.split(".").pop() === "pptx" ||
            item.attachment.name.split(".").pop() === "txt" ||
            item.attachment.name.split(".").pop() === "pdf") && (
            <OuterGrid item onClick={() => handleDownload(item.attachment.url)}>
              <Div>
                <Document />
              </Div>
              <Typography style={{ fontSize: "10px", letterSpacing: "0.5" }}>
                {item.attachment.name.slice(0, 13) +
                  (item.attachment.name.length > 13 ? "..." : "")}
              </Typography>
            </OuterGrid>
          )}
        </React.Fragment>
      ))}
    </Paper>
  );

  function UploadDocument() {
    return (
      <label htmlFor="contained-button-file">
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onClick={(e) => {
            e.target.value = null;
          }}
          onChange={(event) => handleUploadFile(event)}
          accept=".jpg,.jpeg,.png,.gif,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,video/mp4,video/x-m4v"
        />

        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          component="span"
          startIcon={<Upload />}
        >
          Upload Document
        </LoadingButton>
      </label>
    );
  }
}
