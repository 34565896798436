import React from "react";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { TextField } from "../../../../components";
import { useAxios, useComponent } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "600px" },
}));

export default function LeadSettings({
  popUp,
  setPopUp,
  data,
  activeBot,
  setChangedLeadProperty,
  memberList,
}) {
  const classes = useStyles();

  const axios = useAxios();
  const { alert } = useComponent();

  const handleClose = () => {
    setPopUp({
      state: false,
    });
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={popUp.state}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Lead Properties</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={popUp.data}
          enableReinitialize
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url:
                "/leads/update_lead?lead_id=" +
                data.ID +
                "&agent_id=" +
                activeBot.ID,
              method: "POST",
              data: formData,
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                setChangedLeadProperty(
                  formData.owner + formData.source + formData.lead_status
                );
                alert.success(response.message.displayMessage);
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  height: "200px",
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      You can change the lead setting here.
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="source"
                      label="Lead Source"
                      formikProps={dialogFormikProps}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="lead_status"
                      label="Lead Status"
                      formikProps={dialogFormikProps}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      name="owner"
                      label="Owner"
                      formikProps={dialogFormikProps}
                    >
                      {memberList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item xs style={{ textAlign: "end" }}>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
