import React from "react";

import { Grid, Typography, Paper as MuiPaper } from "@mui/material";

import styled from "styled-components";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Stats({ broadcastReport }) {
  return (
    <Grid container justifyContent={"space-around"} spacing={3}>
      <Grid item xs={3}>
        <Paper style={{ color: "#00529B", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {broadcastReport.analytics?.initiated || 0}
          </Typography>
          <Typography variant="subtitle1">Total Initiated Mail</Typography>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper style={{ color: "#37b24d", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {broadcastReport.analytics?.sent || 0}
          </Typography>
          <Typography variant="subtitle1">Total Sent Mail</Typography>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper style={{ color: "#f03e3e", backgroundColor: "#8fcfef30" }}>
          <Typography variant="h1">
            {broadcastReport.analytics?.failed || 0}
          </Typography>
          <Typography variant="subtitle1">Total Failed Mail</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} />
    </Grid>
  );
}
