import { Fragment } from "react";
import styled from "styled-components";
import { Paper as MuiPaper, Typography, Grid } from "@mui/material";

const Paper = styled(MuiPaper)`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function WhatsappUsage() {
  return (
    <Paper>
      <Fragment>
        <Typography
          variant="h3"
          sx={{ fontSize: { lg: 17, md: 17, sm: 17, xs: 15 } }}
        >
          Whatsapp Usage
        </Typography>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <ContainerItem>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                >
                  Business Initiated(BI)
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { lg: 18, md: 18, sm: 18, xs: 18 } }}
                >
                  NA
                </Typography>
              </ContainerItem>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <ContainerItem>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 } }}
                >
                  User Initiated(UI)
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { lg: 18, md: 18, sm: 18, xs: 18 } }}
                >
                  NA
                </Typography>
              </ContainerItem>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </Paper>
  );
}
