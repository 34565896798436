import { Grid, MenuItem } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function FormDisplay({ editState, formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Profile Details"
          subtitle="You can view and edit your details here!"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="First Name"
          name="firstName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Last Name"
          name="lastName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          select
          label="Gender"
          name="gender"
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Preferred Time Zone"
          name="preferredTimezone"
          disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Business Name"
          name="businessName"
          disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Email Address"
          name="emailAddress"
          disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Group Name"
          name="groupName"
          disabled
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
