import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PageHeader, Loader } from "../../components";
import { useComponent } from "../../hooks";
import BotsTable from "./bots components/BotsTable";

export default function Bots() {
  const { activeBot } = useSelector((state) => state.memberDetails);
  const { axios, loader } = useComponent();

  const [bots, setBots] = useState([]);

  useEffect(() => {
    loader.start();

    axios({ url: "/bot" })
      .then((response) => {
        if (response.status) {
          loader.apiComplete();

          setBots(response.data);
        }
      })
      .catch((err) => console.error(err));
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Bots" />

      <BotsTable bots={bots} activeBot={activeBot} />
    </Loader>
  );
}
