import { Fragment } from "react";
import { Grid } from "@mui/material";

import { TextField } from "../../../../components";

export default function LocationDisplay({
  formikProps,
  editState,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          required
          name="payload.longitude"
          label="Longitude"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          name="payload.latitude"
          label="Latitude"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.address"
          label="Address"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.image_url"
          label="Image URL"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>
    </Fragment>
  );
}
