import { chatReducerConstants } from "./chats components/chatReducer";
import { userReducerConstants } from "./chats components/userReducer";

export function convertQueryParams(param) {
  switch (param) {
    case "allChats":
      return "all_chat";

    case "myChats":
      return "my_chat";

    case "requests":
      return "queue";

    case "livechats":
      return "all_chat";

    default:
      return "";
  }
}

export function changeActiveChat(
  setUserState,
  chatState,
  setChatState,
  senderID = "all"
) {
  senderID === "all" && setUserState({ type: userReducerConstants.RESET_USER });

  setChatState({
    type:
      chatState === "allChats"
        ? chatReducerConstants.SET_ACTIVE_CHAT_ALL_CHATS
        : chatState === "myChats"
        ? chatReducerConstants.SET_ACTIVE_CHAT_MY_CHATS
        : chatState === "livechats"
        ? chatReducerConstants.SET_ACTIVE_CHAT_LIVE_CHATS
        : chatReducerConstants.SET_ACTIVE_CHAT_REQUESTS,
  });
}

export function formatUser(user) {
  return {
    ID: user.sender_id,
    name: user.name || "Unknown User",
    chatActive: user.is_livechat_request_active,
    assignedTo: user.assigned_to,
    newMessage: !user.latest_user_message_read,
    latestMessage: user.latest_message_payload.text,
    latestMessageTime: user.latest_message_timestamp,
    ...(Boolean(user.phone) && { phone: user.phone }),
    ...(Boolean(user.email) && { email: user.email }),
  };

  function displayLabels(labels) {
    const displayLabels = [];
    let displayTitleLength = 20;

    for (let label of labels) {
      if (label.title.length <= displayTitleLength) {
        displayTitleLength = displayTitleLength - label.title.length;

        displayLabels.push({
          ID: label._id,
          title: label.title,
          color: label.color,
        });
      }
    }

    return displayLabels;
  }
}

export function formatMessage(message) {
  return {
    ID: message.message_id,
    actor: message.actor,
    body: sanitizeBody(message.payload, message.type),
    timeStamp: message.timestamp,
  };

  function sanitizeBody(payload, type) {
    switch (type) {
      case "text":
        return {
          text: payload.text,
        };

      case "image":
        return {
          imageURL: payload.url,
          footer: payload.caption,
        };

      case "video":
        return {
          videoURL: payload.url,
          footer: payload.caption,
        };

      case "audio":
        return {
          audioURL: payload.url,
        };

      case "document":
        return {
          documentName: payload.name,
          documentURL: payload.url,
          footer: payload.caption,
        };

      case "quick_replies":
        return {
          text: payload.text,
          buttons: payload.buttons,
        };

      case "button":
        return {
          buttonLabel: payload.text,
        };

      case "carousel":
        return {
          text: "Bot sent a carousel",
        };

      default:
        return {};
    }
  }
}

export function templateMessageFormat(message) {
  return {
    ID: message.message_id,
    type: "custom",
    actor: message.actor,
    payload: {
      body: message.payload?.body?.text,
      header: sanitizeBody(message.payload?.header),
      buttons: message.payload?.buttons?.buttons,
      ...(Boolean(message.payload?.footer?.type) && {
        footer: message.payload?.footer?.text,
      }),
    },
    timeStamp: message.timestamp,
  };

  function sanitizeBody(payload) {
    switch (payload?.type) {
      case "text":
        return {
          type: "text",
          text: payload.text,
        };

      case "image":
        return {
          type: "image",
          imageURL: payload.url,
        };

      case "video":
        return {
          type: "video",
          videoURL: payload.url,
        };

      case "document":
        return {
          type: "document",
          documentName: payload.filename,
          documentURL: payload.url,
        };

      default:
        return {};
    }
  }
}
