import React, { useState } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useAxios } from "../../../../../hooks";
import { ComponentHeader, Paper, TextField } from "../../../../../components";
import convertDateToBackendFormat from "../convertDateToBackendFormat";
import WhatsappPreview from "../../../../template/whatsapp interface/WhatsappPreview";
import {
  templateDataObject,
  templateDataResponse,
} from "../templateDataObject";

const TermTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  align-self: center;
`;

export default function WhatsappForm({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  groupList,
  template,
  activeBot,
  fields,
}) {
  const axios = useAxios();

  const [templateData, setTemplateData] = useState(templateDataObject);

  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  var date = year + "-" + month_raw + "-" + date_raw;

  const handleChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: e.target.value,
    }));
    var time = convertDateToBackendFormat(e.target.value);

    axios({
      url: "/broadcast/time?schedule_date=" + time,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };

  const templateChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      templateID: e.target.value,
    }));

    axios({
      url: "/wa/template/variable/" + activeBot.ID + "/" + e.target.value,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplateData(templateDataResponse(response.data?.template_data));
      }
    });
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={8} style={{ display: "grid" }}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Broadcast Whatsapp Message"
              subtitle={"Provide the details for creating the broadcast."}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Broadcast Name"
              name="broadcastName"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="Select Group"
              name="groupID"
              disabled={!editState}
              formikProps={formikProps}
            >
              {groupList.map((item) => (
                <MenuItem key={item.group_id} value={item.group_id}>
                  {item.group_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="Select Template"
              name="templateID"
              onChange={templateChange}
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={"66a638d8b6cf4e3bb14da0eb12835874"}>
                Property Tax Notification(Marathi)
              </MenuItem>

              <MenuItem value={"572c8de4aeec444c84fdbd5380c52f31"}>
                Property Tax Defaulter Notification(Marathi)
              </MenuItem>

              <MenuItem value={"b42916b49b9a4d16a5e78f4c8f5dfa97"}>
                Property Tax Defaulter Notification(English)
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="When To Broadcast"
              name="whenToBroadcast"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={"now"}>Now</MenuItem>
              <MenuItem value={"later"}>Later</MenuItem>
            </TextField>
          </Grid>

          {Boolean(formikProps.values.whenToBroadcast) &&
            formikProps.values.whenToBroadcast !== "now" && (
              <React.Fragment>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    name="broadcastDate"
                    label="Select Date"
                    onChange={handleChange}
                    formikProps={formikProps}
                    inputProps={{
                      min: date,
                    }}
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="Select Time"
                    name="broadcastTime"
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    formikProps={formikProps}
                  >
                    {scheduledTime.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.time}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </React.Fragment>
            )}

          <Grid item xs={6}>
            <TextField
              select
              label="Repeat"
              name="repeat"
              required
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value="atleastOnce">Once</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TermTypography variant="h6">Filters</TermTypography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Select Condition"
              name="ctaClicked"
              disabled={!editState}
              formikProps={formikProps}
            >
              {fields.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Select Value"
              name="option"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Whatsapp Message Preview"}
              subtitle="This is how your whatsapp message look like."
            />
          </Grid>
          <Grid item xs={12}>
            <WhatsappPreview formikProps={templateData} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
