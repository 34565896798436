import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";

import Table from "./Table";
import { useComponent } from "../../hooks";
import useAxios from "./../../hooks/useAxios";
import { Loader, PageHeader, Paper } from "../../components";
import RequestChatTranscripts from "./request chat transcripts/RequestChatTranscripts";

export default function ChatTranscripts({ pageId }) {
  const axios = useAxios();

  const { ID, activeBot } = useSelector((state) => state.memberDetails);

  const [memberId, setMemberId] = useState([]);

  const [listData, setListData] = useState([]);

  const { loader } = useComponent({ pageId });

  const [requestTranscripts, setRequestTranscripts] = useState(false);

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: `/member/list?bot_id=${activeBot.ID}`,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setMemberId(response.data);
      }
      loader.apiComplete();
    });
    loader.start();

    axios({
      url: "/insights/transcription_list/agent_id",
      method: "GET",
      data: {
        member_ids: [],
      },
    }).then((response) => {
      if (response.status) {
        setListData(Array.isArray(response?.data) ? response?.data : []);
      }
      loader.apiComplete();
    });

    axios({
      url: `/label/${activeBot.ID}`,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();

        setLabels(response.data);
      }
    });
  }, [activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName={"Chats Transcripts"}
        buttons={[
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRequestTranscripts(true)}
          >
            Request Chats Transcripts
          </Button>,
        ]}
      />
      <RequestChatTranscripts
        listData={listData}
        setListData={setListData}
        memberId={memberId}
        requestTranscripts={requestTranscripts}
        setRequestTranscripts={setRequestTranscripts}
        labels={labels}
      ></RequestChatTranscripts>
      <Table listData={listData} setListData={setListData} />
    </Loader>
  );
}
