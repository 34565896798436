import React from "react";

import MessageForm from "./MessageForm";

export default function FormDisplay({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  groupList,
  fields,
}) {
  return (
    <React.Fragment>
      <MessageForm
        formikProps={formikProps}
        editState={editState}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
        groupList={groupList}
        fields={fields}
      />
    </React.Fragment>
  );
}
