import React from "react";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { Formik } from "formik";

import TextFieldComponent from "./TextFieldComponent";

export default function TextFieldDialog({ formikProps }) {
  var dynamicField = formikProps.values;

  const baseSchema = validationSchema.concat(
    Yup.object().shape({
      [formikProps.values.name]: Yup.string()
        .required(formikProps.values.label + " is required.")
        .matches(formikProps.values.regex, formikProps.values.errorMessage),
    })
  );

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={dynamicField}
        enableReinitialize
        validateOnMount
        validationSchema={baseSchema}
      >
        {(dialogFormikProps) => (
          <TextFieldComponent
            formikProps={dialogFormikProps}
            fieldDetails={dialogFormikProps.values}
            disabled={!dialogFormikProps.values.editable}
          />
        )}
      </Formik>
    </Grid>
  );
}
const validationSchema = Yup.object().shape({});
