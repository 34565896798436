import { Fragment } from "react";
import { Formik } from "formik";
import { Box, Grid, Stepper, Step, StepLabel, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { useComponent } from "../../../hooks";
import { Paper } from "../../../components";
import { validationSchema, objectFromFormData } from "./registrationFormObject";
import PersonalDetailsFormDisplay from "./registration form components/PersonalDetailsFormDisplay";
import BusinessDetailsFormDisplay from "./registration form components/BusinessDetailsFormDisplay";
import WhatsAppNumbersFormDisplay from "./registration form components/WhatsAppNumbersFormDisplay";
import FacebookVerificationAndIntegrationFormDisplay from "./registration form components/FacebookVerificationAndIntegrationFormDisplay";
import RegistrationStatusDisplay from "./registration form components/RegistrationStatusDisplay";

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 20px;
  display: flex;
  gap: 2px;
  flex-direction: column;
`;

const FormWrapper = styled(Box)`
  flex: 1;
  margin: 4px;
  padding: 14px 6px;
  overflow: auto;
`;

export default function RegistrationForm({
  registrationID,
  stepperState,
  setStepperState,
  formData,
  setFormData,
}) {
  const { axios } = useComponent({ disableAuthToken: true });
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Stepper activeStep={stepperState} alternativeLabel>
        {stepperLabels.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {stepperState > 3 ? (
        <RegistrationStatusDisplay
          formData={formData}
          registrationID={registrationID}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formData[stepperState]}
          validationSchema={validationSchema[stepperState]}
          validateOnMount
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "business/whatsapp_registration",
              params: {
                stage: stepperState + 1,
                registration_id: registrationID,
              },
              method: "POST",
              disableRedirect: true,
              data: objectFromFormData(formData, stepperState),
            })
              .then((response) => {
                setSubmitting(false);

                if (response.status) {
                  setFormData((prevValue) => {
                    prevValue[stepperState] = formData;

                    return prevValue;
                  });

                  if (stepperState === 0) {
                    navigate(
                      pages.whatsappBusinessRegistrationDetails.route +
                        response.data.registration_id
                    );
                  } else {
                    goForward();
                  }
                }
              })
              .catch((error) => console.error(error));
          }}
        >
          {(formikProps) => (
            <Fragment>
              <FormWrapper>
                {stepperState === 0 ? (
                  <PersonalDetailsFormDisplay formikProps={formikProps} />
                ) : stepperState === 1 ? (
                  <BusinessDetailsFormDisplay formikProps={formikProps} />
                ) : stepperState === 2 ? (
                  <WhatsAppNumbersFormDisplay formikProps={formikProps} />
                ) : (
                  <FacebookVerificationAndIntegrationFormDisplay
                    formikProps={formikProps}
                  />
                )}
              </FormWrapper>

              <Paper>
                <Grid item>
                  <Button onClick={goBack} disabled={stepperState === 0}>
                    Back
                  </Button>
                </Grid>

                <Grid item xs />

                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={
                      formikProps.dirty ? formikProps.handleSubmit : goForward
                    }
                    disabled={!formikProps.isValid}
                    loading={formikProps.isSubmitting}
                    color="primary"
                  >
                    {stepperState === 3 ? "Submit" : "Next"}
                  </LoadingButton>
                </Grid>
              </Paper>
            </Fragment>
          )}
        </Formik>
      )}
    </Wrapper>
  );

  function goBack() {
    setStepperState((prevValue) => prevValue - 1);
  }

  function goForward() {
    setStepperState((prevValue) => prevValue + 1);
  }
}

const stepperLabels = [
  "Personal Details",
  "Business Details",
  "Add Whatsapp Numbers",
  "Facebook Verification and Integration",
];
