import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Typography, Grid, TextField, MenuItem } from "@mui/material";

import { Paper } from "../../components";
import TopIntents from "./home components/TopIntents";
import LeadsDisplay from "./home components/LeadsDisplay";
import ChatsDisplay from "./home components/ChatsDisplay";
import WhatsappUsage from "./home components/WhatsappUsage";
import ChatBotDisplay from "./home components/ChatBotDisplay";
import MessagesDisplay from "./home components/MessagesDisplay";
import ChatBotAccuracy from "./home components/ChatBotAccuracy";
import ChatBotResponse from "./home components/ChatBotResponse";
import CustomerFeedback from "./home components/CustomerFeedback";
import MessagesDisplayOne from "./home components/MessagesDisplayOne";
import ChatbotAvailablity from "./home components/ChatbotAvailablity";
import WhatsappUtilityUsage from "./home components/WhatsappUtilityUsage";
import TemplateCurrentMonth from "./home components/TemplateCurrentMonth";
import TemplateMessagesPerMonth from "./home components/TemplateMessagesPerMonth";
import ChatBotDisplayOne from "./home components/ChatBotDisplayOne";
import TotalVisitors from "./home components/TotalVisitors";
import LeadsDisplayOne from "./home components/LeadsDisplayOne";
import WhatsappUsageOne from "./home components/WhatsappUsageOne";
import AgentPerformance from "./home components/AgentPerformance";

const ContainerItem = styled(Paper)`
  top: 0;
  z-index: 101;
  position: sticky;
`;

export default function Home() {
  const memberDetails = useSelector((state) => state.memberDetails);
  const [daysFilter, setDaysFilter] = useState(-1);

  return (
    <React.Fragment>
      <ContainerItem>
        <Grid item sm={6} md={6}>
          <Typography variant="h4">
            Welcome, {memberDetails.firstName} {memberDetails.lastName}
          </Typography>
        </Grid>

        <Grid item sm={6} md={6} alignSelf="center">
          <Typography variant="subtitle2" align="right">
            <TextField
              style={{ width: "40%" }}
              select
              label="Filter Data"
              value={daysFilter}
              onChange={({ target }) => setDaysFilter(target.value)}
            >
              <MenuItem value={1}>Last 24 Hrs</MenuItem>
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={-1}>This Year</MenuItem>
            </TextField>
          </Typography>
        </Grid>
      </ContainerItem>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ChatBotAccuracy
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CustomerFeedback />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ChatBotResponse />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ChatbotAvailablity
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        {memberDetails.activeBot.channel === "whatsapp" && (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <WhatsappUsage />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <WhatsappUtilityUsage />
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12} sm={12} md={6}>
          <LeadsDisplayOne />
        </Grid>

        <Grid item xs={12} sm={12} md={6} alignSelf="center">
          <TotalVisitors
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} alignSelf="center">
          <MessagesDisplayOne
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} alignSelf="center">
          <ChatBotDisplayOne
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        {memberDetails.activeBot.channel === "whatsapp" && (
          <Grid item xs={12} sm={12} md={6}>
            <WhatsappUsageOne
              startDate={daysFilter}
              botID={memberDetails.activeBot.ID}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12}>
          <AgentPerformance
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ChatsDisplay
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TopIntents
            startDate={daysFilter}
            botID={memberDetails.activeBot.ID}
          />
        </Grid>

        {/* {memberDetails.activeBot.channel === "whatsapp" && (
          <React.Fragment>
            <Grid item xs={12}>
              <TemplateCurrentMonth botID={memberDetails.activeBot.ID} />
            </Grid>

            <Grid item xs={12}>
              <TemplateMessagesPerMonth botID={memberDetails.activeBot.ID} />
            </Grid>
          </React.Fragment>
        )} */}
      </Grid>
    </React.Fragment>
  );
}
