import styled from "styled-components";

import ShortcutButtons from "./conversations header components/ShortcutButtons";
import ConversationsHeaderInfo from "./conversations header components/ConversationsHeaderInfo";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ConversationHeader({
  userState,
  setChatState,
  setUserState,
  userList,
  setUserList,
  setChatEnd,
  latestUserMessageRead,
  setLatestUserMessageRead,
}) {
  return (
    <Wrapper>
      <ConversationsHeaderInfo
        userState={userState}
        setChatState={setChatState}
        setUserState={setUserState}
        setUserList={setUserList}
        userList={userList}
        setChatEnd={setChatEnd}
      />

      <ShortcutButtons
        userState={userState}
        setUserState={setUserState}
        setChatState={setChatState}
        userList={userList}
        setUserList={setUserList}
        setChatEnd={setChatEnd}
        latestUserMessageRead={latestUserMessageRead}
        setLatestUserMessageRead={setLatestUserMessageRead}
      />
    </Wrapper>
  );
}
