export const initialChatState = {
  chatStatus: "offline",
  activeChat: "livechats",
  profileVisibility: true,
};

export function chatReducer(state, action) {
  switch (action.type) {
    //chat status actions
    case chatReducerConstants.SET_CHAT_STATUS_ONLINE:
      return {
        ...state,
        chatStatus: "online",
      };

    case chatReducerConstants.SET_CHAT_STATUS_OFFLINE:
      return {
        ...state,
        chatStatus: "offline",
      };

    case chatReducerConstants.SET_CHAT_STATUS_BREAK:
      return {
        ...state,
        chatStatus: "break",
      };

    case chatReducerConstants.TOGGLE_CHAT_STATUS:
      return {
        ...state,
        chatStatus: state.chatStatus === "offline" ? "online" : "offline",
      };

    //active chat actions
    case chatReducerConstants.SET_ACTIVE_CHAT_ALL_CHATS:
      return {
        ...state,
        activeChat: "allChats",
      };

    case chatReducerConstants.SET_ACTIVE_CHAT_REQUESTS:
      return {
        ...state,
        activeChat: "requests",
      };

    case chatReducerConstants.SET_ACTIVE_CHAT_MY_CHATS:
      return {
        ...state,
        activeChat: "myChats",
      };

    case chatReducerConstants.SET_ACTIVE_CHAT_LIVE_CHATS:
      return {
        ...state,
        activeChat: "livechats",
      };

    //profile section reducers
    case chatReducerConstants.SHOW_PROFILE_SECTION:
      return {
        ...state,
        profileVisibility: true,
      };

    case chatReducerConstants.HIDE_PROFILE_SECTION:
      return {
        ...state,
        profileVisibility: false,
      };

    case chatReducerConstants.TOGGLE_PROFILE_SECTION:
      return {
        ...state,
        profileVisibility: !state.profileVisibility,
      };

    default:
      return state;
  }
}

export const chatReducerConstants = {
  SET_CHAT_STATUS_OFFLINE: "SET_CHAT_STATUS_OFFLINE",
  SET_CHAT_STATUS_ONLINE: "SET_CHAT_STATUS_ONLINE",
  SET_CHAT_STATUS_BREAK: "SET_CHAT_STATUS_BREAK",
  TOGGLE_CHAT_STATUS: "TOGGLE_CHAT_STATUS",

  SET_ACTIVE_CHAT_ALL_CHATS: "SET_ACTIVE_CHAT_ALL_CHATS",
  SET_ACTIVE_CHAT_REQUESTS: "SET_ACTIVE_CHAT_REQUESTS",
  SET_ACTIVE_CHAT_MY_CHATS: "SET_ACTIVE_CHAT_MY_CHATS",
  SET_ACTIVE_CHAT_LIVE_CHATS: "SET_ACTIVE_CHAT_LIVE_CHATS",

  SHOW_PROFILE_SECTION: "SHOW_PROFILE_SECTION",
  HIDE_PROFILE_SECTION: "HIDE_PROFILE_SECTION",
  TOGGLE_PROFILE_SECTION: "TOGGLE_PROFILE_SECTION",
};
