import convertDateToBackendFormat from "./convertDateToBackendFormat";

export const broadcastDetailsObject = {
  ID: "",
  channel: "",
  groupID: "",
  broadcastName: "",
  templateID: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
  Subject: "",
  mailText: "",
  variables: [],
};

export function objectFromFormData(formData, channel) {
  let variableData = formData.variables.map((item, index) => {
    return item.example;
  });

  if (channel === "email" || channel === "message") {
    return {
      broadcast_name: formData.broadcastName,
      template_id: formData.templateID,
      schedule_type: formData.whenToBroadcast,
      ...(formData.broadcastDate && {
        schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      }),
      ...(formData.broadcastTime && {
        schedule_time: formData.broadcastTime,
      }),
      file_url: formData.uploadCSVFile,
      // sender_name: "VERISMART",
      // sender_email_id: "developer@verismart.ai",
      variables: variableData,
    };
  }

  if (channel === "whatsapp") {
    return {
      name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time_id: formData.broadcastTime,
      group_id: formData.groupID,
      template_id: formData.templateID,
      template_payload: {
        body: [],
      },
    };
  }
}
