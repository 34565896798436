import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable, Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function PermissionsGroupsTable({ permissionsGroups }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(
        pages.permissionsGroupDetails.route +
          permissionsGroups[dataIndex].member_group_id
      ),
  };

  return (
    <DataTable columns={columns} data={permissionsGroups} options={options} />
  );
}

const columns = [
  {
    name: "group_name",
    label: "Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "no_of_member_assigned",
    label: "Total Members",
    options: {
      customBodyRender: (value) => {
        return value ? value : 0;
      },
    },
  },
  {
    name: "is_active",
    label: "Group Status",
    options: {
      customBodyRender: (value) => (
        <Chip
          type={value ? "success" : "error"}
          label={value ? "Active" : "Inactive"}
        />
      ),
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
