import * as Yup from "yup";

import { convertDate } from "../../../utils";

export const cannedMessageObject = {
  ID: "",
  title: "",
  text: "",
  shortcut: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
  file_url: "",
  cannedMsgType: "single",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id,
    title: responseData.title,
    text: responseData.text,
    shortcut: responseData.shortcut
      ? responseData.shortcut.slice(1)
      : responseData.shortcut,
    createdBy: responseData.created_by,
    updatedBy: responseData.updated_by,
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
    cannedMsgType: "single",
  };
}

export function objectFromFormData(formData) {
  return {
    ...(Boolean(formData.title) && { title: formData.title }),
    ...(Boolean(formData.text) && { text: formData.text }),
    ...(Boolean(formData.shortcut) && { shortcut: formData.shortcut }),
    ...(Boolean(formData.file_url) && { file_url: formData.file_url }),
  };
}

export const validationSchema = Yup.object().shape({
  cannedMsgType: Yup.string(),

  file_url: Yup.string().when("cannedMsgType", {
    is: (cannedMsgType) => cannedMsgType === "bulk",
    then: Yup.string().required("File url is required."),
  }),
  title: Yup.string().when({
    is: (cannedMsgType) => cannedMsgType === "single",
    then: Yup.string()
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "Title must contain only alphabetic characters"
      )
      .max(50, "Title must not exceed 50 characters")
      .required("Title is required"),
  }),

  shortcut: Yup.string().when({
    is: (cannedMsgType) => cannedMsgType === "single",
    then: Yup.string()
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "Shortcut must contain only alphabetic characters"
      )
      .max(30, "Shortcut must not exceed 30 characters")
      .required("Shortcut is required"),
  }),

  text: Yup.string().when({
    is: (cannedMsgType) => cannedMsgType === "single",
    then: Yup.string()
      .max(1000, "Title must not exceed 1000 characters")
      .required("Text is required"),
  }),
});
