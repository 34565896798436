import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import pages from "../../../constants/pages";

export default function SignInComponent() {
  const navigate = useNavigate();
  const [registrationID, setRegistrationID] = useState("");

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" align="center" gutterBottom>
          WhatsApp Business API
        </Typography>

        <Typography variant="body2" align="center">
          Enter your registration ID to continue the registration or click on
          the button to start a fresh registration!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          value={registrationID}
          label="Registration ID"
          name="registrationID"
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={() =>
            navigate(
              pages.whatsappBusinessRegistrationDetails.route + registrationID
            )
          }
          disabled={!registrationID}
        >
          Sign In
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          onClick={() =>
            navigate(pages.whatsappBusinessRegistrationCreate.route)
          }
        >
          Sign up for WhatsApp business API
        </Button>
      </Grid>
    </Grid>
  );

  function handleChange({ target }) {
    const { value } = target;

    setRegistrationID(value.trim());
  }
}
