import { Fragment } from "react";
import { Grid } from "@mui/material";

import { TextField, DatePicker } from "../../../../components";

export default function ContactDisplay({
  formikProps,
  editState,
  pagePermissions,
}) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          required
          name="payload.name.first_name"
          label="First Name"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.name.last_name"
          label="Last Name"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <DatePicker
          name="payload.birthday"
          label="Birthday"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          name="payload.phones[0].phone"
          label="Phone Number"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="payload.emails[0].email"
          label="Email"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="payload.addresses[0].street"
          label="Address"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.addresses[0].city"
          label="City"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.addresses[0].state"
          label="State"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.addresses[0].country"
          label="Country"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.addresses[0].zip"
          label="ZIP Code"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.org.company"
          label="Company"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.org.department"
          label="Department"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.org.title"
          label="Title"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="payload.urls[0].url"
          label="URL"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>
    </Fragment>
  );
}
