import React, { Fragment } from "react";

import { PageHeader } from "../../components";
import Form from "./chat gpt details components/Form";

export default function ChatGpt() {
  return (
    <Fragment>
      <PageHeader pageName="ChatGPT Detail" />
      <Form />
    </Fragment>
  );
}
