import { Formik } from "formik";
import FormDisplay from "./FormDisplay";
import { Fragment } from "react";
import { useAxios } from "./../../../hooks";
import { objectFromFormData } from "./LiveChatConfigObject";
import ButtonFooter from "./../../../components/ButtonFooter";

function Form({ formData, setFormData, editState, setEditState, activeBot }) {
  const axios = useAxios();

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/livechat/config/" + activeBot.ID,
          method: "POST",
          data: objectFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);
            setEditState(false);
          }
        });
      }}
    >
      {(formikProps) => {
        return (
          <Fragment>
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />
            <ButtonFooter
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />
          </Fragment>
        );
      }}
    </Formik>
  );
}

export default Form;
