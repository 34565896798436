import { Fragment } from "react";
import { Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { TextField } from "../../../../components";

export default function ButtonDisplay({
  formikProps,
  editState,
  pagePermissions,
}) {
  return (
    <Fragment>
      {formikProps.values.payload.map((payload, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">Button {index + 1}</Typography>

            <IconButton color="error" onClick={() => deleteIndex(index)}>
              <Delete />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              select
              name={`payload[${index}].type`}
              label="Type"
              disabled={!(editState && pagePermissions.update)}
              formikProps={formikProps}
            >
              <MenuItem value="web_url">Web URL</MenuItem>
              <MenuItem value="postback">Post Back</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              name={`payload[${index}].title`}
              label="Title"
              disabled={!(editState && pagePermissions.update)}
              formikProps={formikProps}
            />
          </Grid>

          {payload.type === "postback" && (
            <Grid item xs={6}>
              <TextField
                required
                name={`payload[${index}].payload`}
                label="Payload"
                disabled={!(editState && pagePermissions.update)}
                formikProps={formikProps}
              />
            </Grid>
          )}

          {payload.type === "web_url" && (
            <Fragment>
              <Grid item xs={6}>
                <TextField
                  required
                  name={`payload[${index}].url`}
                  label="URL"
                  disabled={!(editState && pagePermissions.update)}
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  required
                  name={`payload[${index}].extension`}
                  label="Extension"
                  disabled={!(editState && pagePermissions.update)}
                  formikProps={formikProps}
                >
                  <MenuItem value={true}>Open in new Tab</MenuItem>
                  <MenuItem value={false}>Open in same Tab</MenuItem>
                </TextField>
              </Grid>
            </Fragment>
          )}
        </Fragment>
      ))}
    </Fragment>
  );

  function deleteIndex(deleteIndex) {
    formikProps.setFieldValue(
      "payload",
      formikProps.values.payload.filter((_, index) => index !== deleteIndex)
    );
  }
}
