import React from "react";
import { Grid, Typography } from "@mui/material";

import { FileUpload, TextField } from "../../../../components";
import { useSelector } from "react-redux";

export default function AddSampleMedia({ formikProps }) {
  var extensions;
  const { activeBot } = useSelector((state) => state.memberDetails);

  if (formikProps.values.templateHeader?.type === "image") {
    extensions = ".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp,image/*";
  } else if (formikProps.values.templateHeader?.type === "video") {
    extensions = "video/mp4,video/x-m4v,video/*";
  } else {
    extensions = ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf";
  }

  return (
    <React.Fragment>
      {Boolean(formikProps.values.templateHeader?.type) &&
        Boolean(formikProps.values.templateHeader?.count_of_variable !== 0) && (
          <Grid item xs={12}>
            <Typography variant="caption1">Header Variable</Typography>
          </Grid>
        )}

      {formikProps.values.templateHeader.type === "text" &&
        Boolean(formikProps.values.templateHeader.type) &&
        Array(formikProps.values?.templateHeader?.count_of_variable)
          .fill()
          .map((_, index) => (
            <Grid key={index} item mt={1} xs={12}>
              <TextField
                formikProps={formikProps}
                label="Header Variable Value"
                disabled={Boolean(formikProps.values.ID)}
                name={"templateHeader.example[" + index + "].example"}
              />
            </Grid>
          ))}

      {formikProps.values.templateHeader.type !== "text" &&
        Boolean(formikProps.values.templateHeader.type) &&
        Array(formikProps.values?.templateHeader?.count_of_variable)
          .fill()
          .map((_, index) => (
            <Grid key={index} item mt={1} xs={12}>
              <FileUpload
                formikProps={formikProps}
                name={"templateHeader.example[" + index + "].example"}
                label="Header Variable Value"
                allowedExtension={extensions}
                disabled={Boolean(formikProps.values.ID)}
                maxSize={25}
                folderPath={"templates/" + activeBot.ID + "/"}
              />
            </Grid>
          ))}
    </React.Fragment>
  );
}
