import { Paper as MuiPaper, Grid, Typography, Link } from "@mui/material";
import styled from "styled-components/macro";

const Paper = styled(MuiPaper)`
  margin: 2px 10px;
  padding: 15px 10px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
`;

export default function Footer() {
  return (
    <Paper>
      <GridContainer container>
        <Grid item xs>
          <Link href="mailto:support@dolphinchat.ai">Contact Us</Link>
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle2" align="right">
            © DolphinChat 2022
          </Typography>
        </Grid>
      </GridContainer>
    </Paper>
  );
}
