import React from "react";
import { Save } from "@mui/icons-material";
import { Grid, InputAdornment, IconButton, MenuItem } from "@mui/material";

import {
  Paper,
  TextField,
  ComponentHeader,
  CreatedAndUpdatedInfo,
} from "../../../../components";
import Insights from "./Insights";
import RegexList from "./regex components/Regex";
import LookUp from "./lookups components/LookUp";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  return (
    <Grid container spacing={2}>
      {formikProps.values.ID && <Insights formikProps={formikProps} />}

      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Entity Details"}
              subtitle={"Details of the entity."}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Entity Name"
              name="entityName"
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              select
              label="Entity Status"
              name="isActive"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>I
            </TextField>
          </Grid>

          {formikProps.values.ID && (
            <CreatedAndUpdatedInfo formikProps={formikProps} />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <RegexList formikProps={formikProps} editState={editState} />
      </Grid>

      <Grid item xs={12}>
        <LookUp formikProps={formikProps} editState={editState} />
      </Grid>
    </Grid>
  );
}
