import { titleCase } from "change-case-all";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { DataTable, Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";

export default function LabelsTable({ labels }) {
  const navigate = useNavigate();

  const options = {
    onRowClick: (_, { dataIndex }) =>
      navigate(pages.labelDetails.route + labels[dataIndex]._id),
  };

  return <DataTable columns={columns} data={labels} options={options} />;
}

const columns = [
  {
    name: "title",
    label: "Label Title",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "description",
    label: "Label Description",
    options: {
      setCellProps: () => ({
        style: {
          width: "50%",
        },
      }),

      customBodyRender: (value) => {
        return value ? titleCase(value) : "Not Available";
      },
    },
  },
  {
    name: "color",
    label: "Label",
    options: {
      customBodyRender: (value, { rowData }) => {
        return value ? (
          <Chip background={value} label={rowData[0]} />
        ) : (
          "Not Available"
        );
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
