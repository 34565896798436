import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { useOutlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";

import PermissionError from "../../pages/misc/PermissionError";

const Wrapper = styled(Box)`
  flex-grow: 1;
  padding: 10px;
`;

export default function MainContent() {
  const element = useOutlet();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const { pageID, disableAuth } = get(
    element,
    "props.children.props.children.props",
    ""
  );

  if (!disableAuth) {
    const pagePermissions = activeBot.pagePermissions.find(
      (page) => page.ID === pageID
    );
    if (!(pagePermissions && pagePermissions.read)) {
      return (
        <Wrapper>
          <PermissionError />
        </Wrapper>
      );
    }
  }

  return <Wrapper>{element}</Wrapper>;
}
