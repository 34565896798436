import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import {
  objectFromFormData,
  signUpDetailsObject,
  validationSchema,
} from "./signUpDetailsObjects";
import { useAxios, useAlert } from "../../../hooks";

export default function Form() {
  const axios = useAxios({ disableAuthToken: true });
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={signUpDetailsObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData);
        axios({
          url: "/sign-up",
          method: "POST",
          data: data,
          disableRedirect: true,
        })
          .then(async (res) => {
            if (res.status) {
              setSubmitting(false);
              dispatch(
                alert({ type: "success", message: res.message.displayMessage })
              );
            } else {
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => <FormDisplay formikProps={formikProps} />}
    </Formik>
  );
}
