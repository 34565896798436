import React from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { useComponent } from "../../../../hooks";
import { alert } from "../../../../redux/slices/alertSlice";
import FormDisplay from "../../../leads/lead detail components/FormDisplay";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function CustomFieldDialog({
  dialog,
  setDialog,
  fields,
  pagePermissions,
  activeBot,
  values,
  leadProperties,
  userState,
}) {
  const classes = useStyles();

  const { axios } = useComponent();
  const dispatch = useDispatch();

  const bots = useSelector((state) => state.memberDetails.bots);

  let baseSchema = Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().when("phone", {
      is: (type) => type === undefined,
      then: Yup.string().required(
        "Either give email or phone number. Both can not be blank."
      ),
      otherwise: Yup.string().email("Must be a valid email"),
    }),
  });

  for (let field of fields) {
    if (field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string()
            .required(field.label + " is required field.")
            .matches(field.regex, field.error_message),
        })
      );
    } else if (
      field.mandatory &&
      !Boolean(field.regex) &&
      !Boolean(field.multi_Select)
    ) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().required(
            field.label + " is required field."
          ),
        })
      );
    } else if (!field.mandatory && Boolean(field.regex)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.string().matches(field.regex, field.error_message),
        })
      );
    } else if (field.mandatory && Boolean(field.multi_Select)) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.array()
            .min(1, "Choose at least one " + field.label)
            .required(field.label + "is required field."),
        })
      );
    }
  }

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={dialog}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Lead Details</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={baseSchema}
          initialValues={values}
          onSubmit={(formData, { setSubmitting }) => {
            for (let field of fields) {
              if (field.control_id === "61dd2dfa1fb38be82e077ed8") {
                function setValue(options) {
                  var arr = [];

                  if (Boolean(options)) {
                    var dropDownOption = [];

                    if (Boolean(field.multi_Select)) {
                      dropDownOption = options.map((option) =>
                        field.options.find((item) => item.id === option)
                      );

                      for (let option of dropDownOption) {
                        arr.push({
                          id: option.id,
                          label: option.label,
                        });
                      }
                    } else {
                      dropDownOption = field.options.find(
                        (item) => item.id === options
                      );
                      arr.push({
                        id: dropDownOption.id,
                        label: dropDownOption.label,
                      });
                    }
                  }
                  return arr;
                }

                formData = {
                  ...formData,
                  [field.name]: setValue(formData[field.name]),
                };
              }
            }

            axios({
              url:
                "/leads/update_lead?sender_id=" +
                userState.ID +
                "&agent_id=" +
                activeBot.ID,
              method: "POST",
              data: formData,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);

                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                handleClose();
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  padding: "10px",
                  background: "#cbcbcb38",
                }}
              >
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={12}>
                    <FormDisplay
                      fields={fields}
                      formikProps={dialogFormikProps}
                      editState={true}
                      pagePermissions={pagePermissions}
                      activeBot={activeBot}
                      leadProperties={leadProperties}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs></Grid>

                  <Grid item xs style={{ textAlign: "end" }}>
                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        dialogFormikProps.dirty
                          ? dialogFormikProps.isSubmitting ||
                            !dialogFormikProps.isValid
                          : !dialogFormikProps.dirty
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
