import React from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { useAxios, useComponent } from "../../../../hooks";
import AddCommentForm from "../../../leads/lead detail components/comments components/AddCommentForm";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function CommentsDialog({
  userState,
  commentsDialog,
  setCommentDialog,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const { alert } = useComponent();

  const handleClose = () => {
    setCommentDialog(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={commentsDialog.state}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Add Comment</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={commentsDialog.data}
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            var data = {
              ...formData,
            };
            axios({
              url: "/leads/comments?sender_id=" + userState.ID,
              method: "POST",
              data: data,
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                alert.success(response.message?.displayMessage);
              } else {
                setSubmitting(false);
              }
              handleClose();
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  height: "270px",
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Add a comment for you lead so that it can be used later.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AddCommentForm dialogFormikProps={dialogFormikProps} />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "12px 24px 24px" }}>
                {!dialogFormikProps.values?.comment_id && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid item xs />

                    <Grid item xs style={{ textAlign: "end" }}>
                      <Button
                        onClick={dialogFormikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={
                          dialogFormikProps.dirty
                            ? dialogFormikProps.isSubmitting ||
                              !dialogFormikProps.isValid
                            : !dialogFormikProps.dirty
                        }
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}

const validationSchema = Yup.object().shape({
  text: Yup.string().required("This field is required."),
});
