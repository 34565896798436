import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import PageHeader from "../../components/PageHeader";
import {
  templateDetailsObject,
  objectFromResponse,
  objectFromResponseToClone,
} from "./template details component/templateDetailsObject";
import { apiComplete, startLoader } from "../../redux/slices/loaderSlice";
import Form from "./template details component/Form";
import { Loader } from "../../components";
import pages from "../../constants/pages";

export default function TemplateDetails({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();
  const { pagePermissions } = useComponent({ pageID });

  const cloneID = params.cloneID || "";
  const templateID = params.templateID || "";
  const agentID = useSelector((state) => state.memberDetails.activeBot.ID);

  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [botDetails, setBotDetails] = useState({});
  const [formData, setFormData] = useState(templateDetailsObject);

  useEffect(() => {
    dispatch(startLoader(4));
    if (Boolean(templateID)) {
      var url = "/wa/template/" + agentID + "?template_id=" + templateID;
      axios({
        method: "GET",
        url: url,
      }).then((response) => {
        if (response.status === true) {
          if (Boolean(templateID)) {
            setFormData(objectFromResponse(response.data));
          }
          if (Boolean(cloneID)) {
            setFormData(objectFromResponseToClone(response.data));
          }
          dispatch(apiComplete());
        }
      });
    } else {
      dispatch(apiComplete());
    }
    if (Boolean(cloneID)) {
      url = "/wa/template/" + agentID + "?template_id=" + cloneID;
      axios({
        method: "GET",
        url: url,
      }).then((response) => {
        if (response.status === true) {
          if (Boolean(templateID)) {
            setFormData(objectFromResponse(response.data));
          }
          if (Boolean(cloneID)) {
            setFormData(objectFromResponseToClone(response.data));
          }
          dispatch(apiComplete());
        }
      });
    } else {
      dispatch(apiComplete());
    }

    axios({
      method: "GET",
      url: "/bot/detail/" + agentID,
    }).then((response) => {
      if (response.status) {
        setBotDetails(response.data);
        dispatch(apiComplete());
      }
    });

    axios({
      method: "GET",
      url: "/wa/template/category",
    }).then((response) => {
      if (response.status === true) {
        setCategory(response.data);
        dispatch(apiComplete());
      }
    });

    axios({
      method: "GET",
      url: "/wa/template/language",
    }).then((response) => {
      if (response.status === true) {
        setLanguage(response.data);
        dispatch(apiComplete(2));
      }
    });
  }, [templateID, agentID, cloneID, axios, dispatch]);

  return (
    <Loader>
      <PageHeader
        pageName="Template Details"
        breadcrumbs={[
          {
            name: "Templates",
            location: pages.templateList.route,
          },
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        category={category}
        language={language}
        agentID={agentID}
        botDetails={botDetails}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
