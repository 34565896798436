import { DataTable } from "../../../components";
import { AiFillStar } from "react-icons/ai";

import convertDate from "../../../utils/convertDate";
import { Typography, Rating, Tooltip } from "@mui/material";

export default function FeedbackTable({ feedback }) {
  const options = {
    filter: true,
    rowsPerPage: 20,
    download: true,
    print: true,
    viewColumns: false,
    search: true,
    rowsPerPageOptions: [],
  };

  const columns = [
    {
      name: "_id",
      label: "Visitor ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "rating",
      label: "Feedback",
      options: {
        customBodyRender: (value) => {
          const ratingIcon =
            value >= 2 ? (
              <Rating
                name="size-small"
                value={value}
                readOnly
                precision={0.5}
                emptyIcon={
                  <AiFillStar style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            ) : (
              <Rating name="size-small" value={null} />
            );
          return value ? (
            <Typography component="legend">{ratingIcon}</Typography>
          ) : (
            "Not Available"
          );
        },
      },
    },

    {
      name: "comment",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          if (value.length <= 50) {
            return <Typography>{value}</Typography>;
          } else {
            return (
              <div>
                <Tooltip title={value}>
                  <Typography>More ...</Typography>
                </Tooltip>
              </div>
            );
          }
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} data={feedback} options={options} />;
}
