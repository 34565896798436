import convertDateToBackendFormat from "./convertDateToBackendFormat";

export const broadcastDetailsObject = {
  ID: "",
  channel: "",
  groupID: "",
  broadcastName: "",
  templateID: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
};

export function objectFromFormData(formData, channel) {
  if (channel === "email" || channel === "message") {
    return {
      name: formData.broadcastName,
      template_id: formData.templateID,
      schedule_type: formData.whenToBroadcast,
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time_id: formData.broadcastTime,
      group_id: formData.groupID,
    };
  }
  if (channel === "whatsapp") {
    return {
      name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time_id: formData.broadcastTime,
      group_id: formData.groupID,
      template_id: formData.templateID,
      template_payload: {
        body: [],
      },
    };
  }
}
