import React from "react";

import { Popover, Typography } from "@mui/material";
import { FaFile, FaImage, FaVideo } from "react-icons/fa";

export default function Details({
  popUp,
  setPopUp,
  details,
  anchorEl,
  setAnchorEl,
}) {
  const handleClose = () => {
    setPopUp(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: "200px" },
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      <div style={{ padding: "10px" }}>
        <Typography variant="h6">Header</Typography>
        <Typography>
          {popUp.details.template_header?.type === "text" &&
            popUp.details.template_header?.text}
        </Typography>
        {popUp.details.template_header?.type === "image" && (
          <FaImage
            style={{
              color: "#c5c5c5",
              backgroundColor: "#f5f5f6",
              fontSize: "20px",
            }}
          />
        )}
        {popUp.details.template_header?.type === "video" && (
          <FaVideo
            style={{
              color: "#c5c5c5",
              backgroundColor: "#f5f5f6",
              fontSize: "20px",
            }}
          />
        )}
        {popUp.details.template_header?.type === "document" && (
          <FaFile
            style={{
              color: "#c5c5c5",
              backgroundColor: "#f5f5f6",
              fontSize: "20px",
            }}
          />
        )}
      </div>

      <div style={{ padding: "10px" }}>
        <Typography variant="h6">Body</Typography>
        <Typography>
          {popUp.details?.template_body?.text.slice(0, 80) +
            (popUp.details?.template_body?.text.length > 80 ? "..." : "") || ""}
        </Typography>
      </div>
      <div style={{ padding: "10px" }}>
        <Typography variant="h6">Footer</Typography>
        <Typography>{popUp.details?.template_footer?.text || ""}</Typography>
      </div>
    </Popover>
  );
}
