import React from "react";
import { Grid, MenuItem } from "@mui/material";

import { useAxios } from "../../../../hooks";
import {
  ComponentHeader,
  Paper,
  TextField,
  FileUpload,
} from "../../../../components";
import convertDateToBackendFormat from "../../other components/convertDateToBackendFormat";
import Preview from "./Preview";

export default function FormDisplay({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  activeBot,
}) {
  const axios = useAxios();
  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  var date = year + "-" + month_raw + "-" + date_raw;

  const handleChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: e.target.value,
    }));
    var time = convertDateToBackendFormat(e.target.value);

    axios({
      url:
        "/broadcast_sms/time_slot/?agent_id=" + activeBot.ID + "&date=" + time,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={8} style={{ display: "grid" }}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Broadcast SMS"
              subtitle={"Provide the details for creating the broadcast."}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              label="Broadcast Name"
              name="broadcastName"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              label="Sender Name"
              name="senderName"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              label="Flow ID"
              name="flowId"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="When To Broadcast"
              name="whenToBroadcast"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={"now"}>Now</MenuItem>
              <MenuItem value={"later"}>Later</MenuItem>
            </TextField>
          </Grid>

          {Boolean(formikProps.values.whenToBroadcast) &&
            formikProps.values.whenToBroadcast !== "now" && (
              <React.Fragment>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    name="broadcastDate"
                    label="Select Date"
                    onChange={handleChange}
                    formikProps={formikProps}
                    inputProps={{
                      min: date,
                    }}
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="Select Time"
                    name="broadcastTime"
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    formikProps={formikProps}
                  >
                    {scheduledTime.map((item) => (
                      <MenuItem key={item.time} value={item.time}>
                        {item.time}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </React.Fragment>
            )}

          <Grid item xs={6}>
            <FileUpload
              label="Upload File"
              name="broadcastFile"
              formikProps={formikProps}
              allowedExtension={".xlsx"}
              maxSize={16}
              folderPath={"broadcast/sms/" + activeBot.ID + "/"}
            />
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Preview formikProps={formikProps} />
      </Grid>
    </Grid>
  );
}
