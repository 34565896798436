import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import pages from "./../../../constants/pages";
import { useAxios, useComponent } from "./../../../hooks";
import Table from "./push notification broadcast list component/Table";
import { Loader, PageHeader } from "./../../../components";
import { apiComplete, startLoader } from "./../../../redux/slices/loaderSlice";
import EmailPreview from "../push notification template components/template details component/EmailTemplatePreview";

export default function PushNotificationBroadcastList({ pageID }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [broadcasts, setBroadcasts] = useState([]);
  const [broadcastID, setBroadcastID] = useState([]);

  useEffect(() => {
    dispatch(startLoader());

    axios({
      url: "push_notification/broadcast/?agent_id=" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setBroadcasts(response.data instanceof Array ? response.data : []);
      }
      dispatch(apiComplete());
    });
  }, [axios, dispatch, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Broadcast"
        addIcon={[
          "Create Broadcast",
          pages.pushNotificationDetails.route,
          pagePermissions.create,
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table broadcasts={broadcasts} setBroadcastID={setBroadcastID} />
        </Grid>
      </Grid>
    </Loader>
  );
}
