import { Fragment, useState } from "react";
import { Link, Grid, Typography, Button, Switch } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function FormDisplay() {
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  function apiKey() {
    window.open("https://platform.openai.com/account/api-keys", "_blank");
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12}>
              <ComponentHeader title={"ChatGPT"} />
              <Typography variant="subtitle2">
                Dolphinchat supports integration with ChatGPT language model. To
                enable ChatGPT you need an{" "}
                <Typography
                  variant="Link"
                  onClick={apiKey}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <Link href="https://platform.openai.com/account/api-keys">
                    API key
                  </Link>
                </Typography>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sm={12}
              style={{
                display: "flex",
              }}
            >
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize", alignSelf: "center" }}
              >
                Enable ChatGPT
              </Typography>
              <Switch checked={checked} onChange={handleChange} />
            </Grid>

            <Grid item xs={12} md={4} lg={4} sm={12}>
              <TextField name="api key" label="Api Key" />
            </Grid>

            <Grid item xs />
            <Grid item>
              <Button color="primary" variant="contained">
                Submit
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
