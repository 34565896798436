import { Chip } from "../../components";
import { convertDate } from "../../utils";
import DataTable from "./../../components/DataTable";

export default function Table({ dataTable }) {
  const options = {};

  return <DataTable columns={columns} data={dataTable} options={options} />;
}

const columns = [
  {
    name: "name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "phone_number",
    label: "Phone Number",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },

  {
    name: "created_by",
    label: "Created By",
    options: {
      customBodyRender: (value, { rowData }) => {
        return value ? value : "Not Available";
      },
    },
  },

  {
    name: "status",
    label: "status",
    options: {
      customBodyRender: (value, { rowData }) => {
        return value ? value : "Not Available";
      },
    },
  },
];
