import * as Yup from "yup";

export const entityObject = {
  ID: "",
  entityName: "",
  regex: [],
  entityLookups: [],
  entitySynonyms: [],
};

export function dataFromResponse(response) {
  return {
    ID: response._id,
    entityName: response.entity_name,
    regex: response.regex,
    entityLookups: response.lookups,
    entitySynonyms: response.synonyms,
    isActive: response.is_active,
    agentID: response.agent_id,
    createdAt: response.created_at,
    createdBy: response.created_by,
    updatedAt: response.updated_at,
    updatedBy: response.updated_by,
  };
}

export function dataFromFormData(formData) {
  return {
    ...Boolean(
      formData.id && {
        _id: formData.ID,
      }
    ),
    entity_name: formData.entityName,
    regex: formData.regex,
    lookups: formData.entityLookups,
    synonyms: formData.entitySynonyms,
    is_active: formData.isActive,
  };
}

export const validationSchema = Yup.object().shape({
  entityName: Yup.string()
    .required("This field is required.")
    .matches(
      /^[a-zA-Z0-9@_]+$/,
      "This field cannot contain white space and special character"
    ),
  isActive: Yup.string().required("This field is required"),
});
