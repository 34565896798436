import React, { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, Typography, Divider } from "@mui/material";

import { Paper } from "../../../../components";
import Brand from "../../../../assets/NLAlogo.png";

const ImageDiv = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justifycontent: center;
  textalign: center;
  margin: auto;
  alignitem: center;
`;

const SubHeading = styled(Typography)`
  font-size: 11px;
  color: "gray";
`;

export default function ProfilePreview({ image, value, website }) {
  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}></Grid>
        <ImageDiv>
          <img
            style={{
              height: "100px",
              width: "100px",
              marginTop: "6px",
              borderRadius: "6px",
            }}
            src={Brand}
            alt="!"
          />
        </ImageDiv>

        <Grid
          item
          xs={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItem: "center",
          }}
        >
          <Typography variant="h4">Dolphinchat</Typography>
          <Typography variant="h5" style={{ color: "gray" }}>
            Sapio Analytics
          </Typography>

          <SubHeading variant="h6" style={{ marginTop: "6px", color: "gray" }}>
            {value.label}
          </SubHeading>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <SubHeading variant="h6" style={{ color: "gray" }}>
            This is a business account
          </SubHeading>
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <Typography>{value.description}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ color: "green" }}> {value.address} </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ color: "green" }}>
            {value.email_address}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ color: "green" }}>{website}</Typography>
        </Grid>
      </Paper>
    </Fragment>
  );
}
