import React from "react";

import { Grid, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Paper } from "../../../../../components";

export default function SubmitButton({ formikProps, setAddSample, html }) {
  const navigate = useNavigate();
  let key = `<!DOCTYPE html><html><body><div style="background-color:#F5F5F5;color:#262626;font-family:&quot;Helvetica Neue&quot;, &quot;Arial Nova&quot;, &quot;Nimbus Sans&quot;, Arial, sans-serif;font-size:16px;font-weight:400;letter-spacing:0.15008px;line-height:1.5;margin:0;padding:32px 0;min-height:100%;width:100%"><table align="center" width="100%" style="margin:0 auto;max-width:600px;background-color:#FFFFFF" role="presentation" cellSpacing="0" cellPadding="0" border="0"><tbody><tr style="width:100%"><td></td></tr></tbody></table></div></body></html>`;

  return (
    <Paper>
      <Grid item>
        <Button
          onClick={() => {
            navigate(-1);
            formikProps.handleReset();
          }}
          color="primary"
          variant="outlined"
        >
          Back
        </Button>
      </Grid>

      <Grid item xs />
      <Grid item>
        <Button
          disabled={
            formikProps.dirty
              ? formikProps.isSubmitting ||
                !formikProps.isValid ||
                html?.code == key
              : !formikProps.dirty
          }
          onClick={formikProps.handleSubmit}
          color="primary"
          variant="contained"
        >
          Create Template
        </Button>
      </Grid>
    </Paper>
  );
}
