import React, { Fragment, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import {
  Paper as MuiPaper,
  Typography,
  Grid,
  LinearProgress,
} from "@mui/material";
import {
  Title,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  Chart as ChartJS,
  CategoryScale,
} from "chart.js";

import { useComponent } from "../../../hooks";
import startDateCalculator from "./startDateCalculator";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

const Paper = styled(MuiPaper)`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export default function TotalVisitors({ botID, startDate }) {
  const { axios } = useComponent();
  const [loading, setLoading] = useState(true);
  const [totalVisitors, setTotalVisitors] = useState({});

  useEffect(() => {
    setLoading(true);

    axios({
      url: `/insights/visitor_graphs/${botID}`,
      disableError: true,
      disableRedirect: true,
      params: {
        end_date: new Date().getTime() / 1000,
        ...(startDate !== -1 && { start_date: startDateCalculator(startDate) }),
      },
    }).then((response) => {
      if (response.status) {
        setTotalVisitors({
          labels: response.data.labels,
          datasets: response.data.dataset,
        });

        setLoading(false);
      }
    });
  }, [axios, botID, startDate]);

  const config = {
    type: "line",
    data: totalVisitors,
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Chart.js Line Chart",
        },
      },
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: "Days",
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: " Value",
          },
        },
      },
    },
  };

  return (
    <Paper style={{ height: { lg: "390px", sm: "350px" } }}>
      {loading ? (
        <LinearProgress style={{ width: "80%" }} />
      ) : (
        <Fragment>
          <Container>
            <Grid
              container
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid
                item
                xs={8}
                sm={10}
                md={12}
                lg={12}
                xl={11}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <ContainerItem>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: { lg: 17, md: 17, sm: 17, xs: 15 },
                      alignItems: "center",
                    }}
                  >
                    Total Visitors
                  </Typography>

                  <Line
                    height="40vw"
                    width="80vw"
                    data={totalVisitors}
                    options={config}
                  />
                </ContainerItem>
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Paper>
  );
}
