import React from "react";
import { Grid, MenuItem } from "@mui/material";

import Instructions from "./InstructionsRegex";
import { TextField, TextFieldWithCopy } from "../../../../components";

export default function CreateRegex({ formikProps }) {
  return (
    <Grid container spacing={3}>
      {formikProps.values.regex ? (
        <React.Fragment>
          <Grid item xs={8}>
            <TextFieldWithCopy
              name="regex"
              label="Generated Regex"
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={4}>
          <TextField
            select
            name="DataType"
            label="Data Type"
            formikProps={formikProps}
          >
            <MenuItem value="Varchar">String</MenuItem>
            <MenuItem value="Char">Char</MenuItem>
            <MenuItem value="Integer">Integer</MenuItem>
            <MenuItem value="CountryCode">Country Code</MenuItem>
            <MenuItem value="MobileNumber">Mobile Number</MenuItem>
            <MenuItem value="EmailID">Email ID</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </Grid>
      )}

      {(formikProps.values.DataType === "Varchar" ||
        formikProps.values.DataType === "Integer" ||
        formikProps.values.DataType === "Char") && (
        <React.Fragment>
          <Grid item xs={4}>
            <TextField
              type="number"
              name="MinLength"
              label="Min Length"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type="number"
              name="MaxLength"
              label="Max Length"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type="number"
              name="Length"
              label="Length"
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}

      {formikProps.values.DataType === "Integer" && (
        <Grid item xs={4}>
          <TextField
            type="number"
            name="IntegerRange"
            label="Integer Range"
            formikProps={formikProps}
          />
        </Grid>
      )}

      {(formikProps.values.DataType === "Varchar" ||
        formikProps.values.DataType === "char") && (
        <React.Fragment>
          <Grid item xs={4}>
            <TextField
              select
              name="WithSpace"
              label="With Space"
              formikProps={formikProps}
            >
              <MenuItem value={1}>Allowed</MenuItem>
              <MenuItem value={0}>Not Allowed</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField name="Prefix" label="Prefix" formikProps={formikProps} />
          </Grid>

          <Grid item xs={4}>
            <TextField name="Suffix" label="Suffix" formikProps={formikProps} />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="SymbolsAllowed"
              label="Symbols Allowed"
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}

      <Instructions />
    </Grid>
  );
}
