// import AWS from "aws-sdk";
import { useState } from "react";

import styled from "styled-components/macro";
import { ImAttachment } from "react-icons/im";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Chip as MUIChip } from "@mui/material";

import { TextField } from "../../../../components";
import To from "./To";
import Cc from "./Cc";
import { uploadToS3 } from "../../../../utils";

const Chip = styled(MUIChip)`
  border-radius: 30px;
  margin: 2px;
`;

export default function EmailTemplate({
  formikProps,
  mails,
  setMails,
  cc,
  setCc,
  bucket = {},
}) {
  const [loading, setLoading] = useState(false);

  function handleDeleteAttachment(index) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.attachments?.splice(index, 1),
    }));
  }

  function handleClick(url) {
    window.location.href = url;
  }

  function handleChange(event) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      html_content: setText(prevVal.html_content),
      text: setFormattedText(prevVal.text),
    }));

    function setText(html_content) {
      html_content = event.target.value;
      return html_content;
    }

    function setFormattedText(text) {
      text = event.target.value;
      return text;
    }
  }

  const handleUploadFile = (CSV) => {
    setLoading(true);
    const file = CSV.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function () {
        // var bucketName = "dc-chat-media";
        // var bucketRegion = "ap-south-1";
        // var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

        // AWS.config.update({
        //   region: bucketRegion,
        //   credentials: new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: identityPoolID,
        //   }),
        // });

        // var s3 = new AWS.S3({
        //   apiVersion: "2006-03-01",
        //   params: {
        //     Bucket: bucketName,
        //   },
        // });

        // var filePath = "mails_attachment/" + file.name;
        // var upload = s3.upload({
        //   Key: filePath,
        //   ACL: "public-read",
        //   Body: file,
        // });

        // var promise = upload.promise();

        const uploadBucket = {
          name: "dc-chat-media",
          region: "ap-south-1",
          identityPoolID: "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103",
          ...bucket,
        };

        uploadToS3({
          bucket: uploadBucket,
          filePath: "sample_data_files/" + file.name,
          file,
          bucketRegion: "ap-south-1",
        }).then(function (data) {
          setLoading(false);
          formikProps.setValues((prevVal) => ({
            ...prevVal,
            attachments: setAttachmentValue(prevVal.attachments),
          }));

          function setAttachmentValue(attachment) {
            attachment.push({
              url: data.Location,
              name: file.name,
            });
            return attachment;
          }
        });
      };
      reader.onerror = function (error) {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <To formikProps={formikProps} mails={mails} setMails={setMails} />
      </Grid>

      <Grid item xs={12}>
        <Cc formikProps={formikProps} mails={cc} setMails={setCc} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="subject"
          label="Subject"
          required
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs textAlign={"end"} alignSelf="center">
        <label htmlFor="contained-button-file">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={(event) => handleUploadFile(event)}
            disabled={formikProps.values.attachments?.length > 2}
            accept=".jpg,.jpeg,.png,.gif,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,video/mp4,video/x-m4v"
          />
          <LoadingButton
            style={{ justifyContent: "end" }}
            loading={loading}
            loadingPosition="start"
            variant="outlined"
            component="span"
            disabled={formikProps.values.attachments?.length > 2}
            startIcon={<ImAttachment />}
          />
        </label>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Message"
          name="html_content"
          fullWidth
          required
          multiline
          rows={7}
          onChange={handleChange}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        {formikProps.values.attachments?.map((item, index) => (
          <Chip
            key={index}
            label={item.name}
            variant="outlined"
            color="success"
            onClick={() => handleClick(item.url)}
            onDelete={() => handleDeleteAttachment(index)}
          />
        ))}
      </Grid>
    </Grid>
  );
}
