import { useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { CheckCircle, Info, Visibility } from "@mui/icons-material";

import { Paper, TextField } from "../../../components";

const Wrapper = styled.div`
  padding-inline: 10%;
  margin-block: auto;
`;

const CheckList = styled.div`
  display: flex;
  padding: 0 10px;
`;

export default function FormDisplay({ formikProps }) {
  const [visiblePassword, setVisiblePassword] = useState(false);

  var pattern = /[#@$!%*?&]+/;
  var hasNumber = /\d/;
  var hasLowerCase = /[a-z]/;
  var hasUpperCase = /[A-Z]/;

  var number = hasNumber.test(formikProps.values?.password);
  var specialChar = pattern.test(formikProps.values?.password);
  var lowerCase = hasLowerCase.test(formikProps.values?.password);
  var upperCase = hasUpperCase.test(formikProps.values?.password);

  return (
    <Wrapper>
      <Paper>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            DolphinChat
          </Typography>

          <Typography variant="h6" align="center" gutterBottom>
            Welcome! Complete your registration so that you can sign in with us!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="firstName"
            label="First Name"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="lastName"
            label="Last Name"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            required
            name="gender"
            label="Gender"
            formikProps={formikProps}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled
            name="email"
            label="Email Address"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            type={visiblePassword ? "text" : "password"}
            required
            name="password"
            label="Password"
            formikProps={formikProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    <Visibility />
                  </IconButton>

                  <Tooltip title="Password must have at least one small letter, capital letter and special character">
                    <Info
                      color="primary"
                      style={{ cursor: "pointer", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "8px" }}>
          <CheckList>
            {specialChar ? (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "green" }}
              />
            ) : (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "rgb(203 203 203 / 34%)" }}
              />
            )}
            <Typography style={{ fontSize: "13px", color: "#808080" }}>
              The special characters which you can use # @ $ ! % * ? &.
            </Typography>
          </CheckList>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "8px" }}>
          <CheckList>
            {number ? (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "green" }}
              />
            ) : (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "rgb(203 203 203 / 34%)" }}
              />
            )}
            <Typography style={{ fontSize: "13px", color: "#808080" }}>
              There should be atleast one number.
            </Typography>
          </CheckList>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "8px" }}>
          <CheckList>
            {lowerCase ? (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "green" }}
              />
            ) : (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "rgb(203 203 203 / 34%)" }}
              />
            )}
            <Typography style={{ fontSize: "13px", color: "#808080" }}>
              There should be atleast one lower case letter.
            </Typography>
          </CheckList>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "8px" }}>
          <CheckList>
            {upperCase ? (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "green" }}
              />
            ) : (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "rgb(203 203 203 / 34%)" }}
              />
            )}
            <Typography style={{ fontSize: "13px", color: "#808080" }}>
              There should be atleast one upper case letter.
            </Typography>
          </CheckList>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "8px" }}>
          <CheckList>
            {formikProps.values?.password?.length >= 8 ? (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "green" }}
              />
            ) : (
              <CheckCircle
                fontSize="small"
                style={{ marginRight: "10px", color: "rgb(203 203 203 / 34%)" }}
              />
            )}
            <Typography style={{ fontSize: "13px", color: "#808080" }}>
              The password should be atleast of 8 letters.
            </Typography>
          </CheckList>
        </Grid>

        <Grid item xs={12}>
          <TextField
            type="password"
            required
            name="confirmPassword"
            label="Confirm Password"
            formikProps={formikProps}
          />
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs />

        <Grid item>
          <LoadingButton
            variant="contained"
            disabled={!formikProps.isValid}
            loading={formikProps.isSubmitting}
            onClick={formikProps.handleSubmit}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Paper>
    </Wrapper>
  );
}
