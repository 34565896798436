import convertDate from "../../../utils/convertDate";

export const templateDetailsObject = {
  ID: "",
  agentID: "",
  templateName: "",
  templateNamespace: "",
  category: "",
  verifiedStatus: "",
  status: "",
  totalVariable: 0,
  templateLanguage: {
    code_id: "",
    policy: "deterministic",
  },
  languageName: "",
  templateFooter: {
    text: "",
    count_of_variable: 0,
  },
  templateBody: {
    example: [],
  },
  templateHeader: {
    type: null,
    count_of_variable: 0,
    text: "",
    example: [],
  },
  templateButtons: {
    type: null,
    count_of_variable: 0,
    buttons: [],
  },
  createdAt: "",
};

export function objectFromResponse(responseData) {
  let bodyExamples = [];
  let headerExamples = [];
  if (responseData.template_body.example.length > 0) {
    for (let example of responseData.template_body.example) {
      bodyExamples.push({
        example: example,
      });
    }
  }
  if (responseData.template_header.example.length > 0) {
    for (let example of responseData.template_header.example) {
      headerExamples.push({
        example: example,
      });
    }
  }
  return {
    ID: responseData._id || "",
    templateName: responseData.name || "",
    templateNamespace: responseData.namespace || "",
    category: responseData.category_id || "",
    typeOfTemplate: responseData.type_of_template || "",
    verifiedStatus: responseData.verified_status_id || "",
    status: responseData.is_active || false,
    templateLanguage: responseData.language || {},
    templateFooter: responseData.template_footer || {},
    templateBody:
      {
        text: responseData.template_body.text,
        count_of_variable: responseData.template_body.count_of_variable,
        example: bodyExamples,
      } || {},
    templateHeader:
      {
        type: responseData.template_header.type,
        count_of_variable: responseData.template_header.count_of_variable,
        text: responseData.template_header.text,
        example: headerExamples,
      } || {},
    templateButtons: responseData.template_buttons || {},
    createdAt: convertDate(responseData.created_at) || "",
  };
}

export function objectFromResponseToClone(responseData) {
  let bodyExamples = [];
  let headerExamples = [];
  if (responseData.template_body.example.length > 0) {
    for (let example of responseData.template_body.example) {
      bodyExamples.push({
        example: example,
      });
    }
  }

  if (responseData.template_header.example.length > 0) {
    for (let example of responseData.template_header.example) {
      headerExamples.push({
        example: example,
      });
    }
  }
  return {
    ID: "",
    templateName: "",
    templateNamespace: responseData.namespace || "",
    category: responseData.category_id || "",
    typeOfTemplate: responseData.type_of_template || "",
    status: responseData.is_active || false,
    templateLanguage: responseData.language || {},
    templateFooter: responseData.template_footer || {},
    templateBody:
      {
        text: responseData.template_body.text,
        count_of_variable: responseData.template_body.count_of_variable,
        example: bodyExamples,
      } || {},
    templateHeader:
      {
        type: responseData.template_header.type,
        count_of_variable: responseData.template_header.count_of_variable,
        text: responseData.template_header.text,
        example: headerExamples,
      } || {},
    templateButtons: responseData.template_buttons || {},
  };
}

export function objectFromFormData(formData) {
  let bodyExamples = [];
  let headerExamples = [];
  if (formData.templateBody.example?.length > 0) {
    for (let example of formData.templateBody.example) {
      bodyExamples.push(example.example);
    }
  }

  if (formData.templateHeader.example?.length > 0) {
    for (let example of formData.templateHeader.example) {
      headerExamples.push(example.example);
    }
  }

  return {
    category_id: formData.category,
    is_active: formData.status,
    language: formData.templateLanguage,
    name: formData.templateName,
    template_body: {
      text: formData.templateBody.text,
      count_of_variable: formData.templateBody.count_of_variable,
      example: bodyExamples,
    },
    template_button: formData.templateButtons,
    template_footer: formData.templateFooter,
    template_header: {
      text: formData.templateHeader.text,
      count_of_variable: formData.templateHeader.count_of_variable,
      type: formData.templateHeader.type,
      example: headerExamples,
    },
  };
}
