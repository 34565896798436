import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../../hooks";
import validationSchema from "./validationSchema";
import pages from "../../../../constants/pages";
import { ButtonFooter } from "../../../../components";
import { alert } from "../../../../redux/slices/alertSlice";
import { objectFromFormData } from "./broadcastDetailsObject";

export default function Form({
  formData,
  activeBot,
  setFormData,
  groupList,
  scheduledTime,
  setScheduledTime,
  template,
  channel,
  fields,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (Boolean(formData.ID)) {
      setEditState(false);
    }
  }, [formData.ID]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData);
        axios({
          url: "/broadcast_sms/create_broadcast?agent_id=" + activeBot.ID,
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.status) {
            setSubmitting(false);
            setFormData(formData);
            setEditState(false);
            dispatch(
              alert({
                type: "success",
                message: res.message.displayMessage,
              })
            );
            navigate(pages.sailBroadcastSMSList.route);
          }
        });
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            scheduledTime={scheduledTime}
            setScheduledTime={setScheduledTime}
            groupList={groupList}
            activeBot={activeBot}
            template={template}
            fields={fields}
            channel={channel}
          />

          <ButtonFooter
            ID={formikProps.values.ID}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
