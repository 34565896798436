import { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Tooltip,
  Grid,
  TextField,
} from "@mui/material";
import { ForwardToInbox } from "@mui/icons-material";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useComponent } from "../../../../../../../hooks";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const CannedMessagesWrapper = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CannedMessageContainer = styled.div`
  border: 1px solid #f1f0f0;
  border-radius: 4px;
`;

const TitleContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  > h6 {
    flex: 1;
  }
`;

const TextContainer = styled.div`
  padding: 10px;
  background: #f1f0f0;
`;

export default function CannedMessages({ handleDialogClose, setText }) {
  const { axios } = useComponent();
  const { activeBot, businessID } = useSelector((state) => state.memberDetails);

  const [cannedMessages, setCannedMessages] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios({
      url: `/canned-message/${businessID}`,
    }).then((response) => {
      if (response.status) {
        setCannedMessages(response.data);
      }
    });
  }, [activeBot.ID, axios]);

  const handleDebounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  return (
    <Wrapper container spacing={4}>
      {cannedMessages.length === 0 && (
        <Typography variant="subtitle2">
          You need to add new canned messages
        </Typography>
      )}

      <CannedMessagesWrapper>
        <Grid item xs={12} p={3}>
          <TextField
            fullWidth
            label="Search Canned Respone"
            onChange={handleDebounce(function (value) {
              axios({
                url: `/canned-message/${businessID}?shortcut=${value.target.value}`,
              }).then((response) => {
                if (response.status) {
                  setCannedMessages(response.data);
                }
              });
            }, 500)}
          ></TextField>
        </Grid>
        {cannedMessages.map((message) => (
          <CannedMessageContainer key={message._id}>
            <TitleContainer>
              <Typography variant="subtitle2">{message.title}</Typography>

              <Tooltip title="Send this message">
                <IconButton
                  size="small"
                  variant="outlined"
                  onClick={() => sendCannedMessage(message)}
                >
                  <ForwardToInbox />
                </IconButton>
              </Tooltip>
            </TitleContainer>

            <TextContainer>
              <Typography variant="body1">{message.text}</Typography>
            </TextContainer>
          </CannedMessageContainer>
        ))}
      </CannedMessagesWrapper>
    </Wrapper>
  );

  function sendCannedMessage(message) {
    setText(message.text);
    handleDialogClose();
  }
}
