import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  templateName: Yup.string()
    .max(100)
    .matches(
      /^[a-z0-9_]+$/,
      "It sould only contain letters, numbers and underscores"
    )
    .required("Enter template name."),
});

export default validationSchema;
