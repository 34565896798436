import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Switch,
} from "@mui/material";
import styled from "styled-components";
import { Person, CommentsDisabled } from "@mui/icons-material";
import { BiTransfer } from "react-icons/bi";

import { useComponent } from "../../../../../../hooks";
import { AvatarButton } from "../../../../../../components";
import { userReducerConstants } from "../../../userReducer";
import { chatReducerConstants } from "../../../chatReducer";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

export default function ShortcutButtons({
  userState,
  setChatState,
  userList,
  setUserState,
  setUserList,
  setChatEnd,
  latestUserMessageRead,
  setLatestUserMessageRead,
}) {
  const { activeBot, ID, firstName, lastName, groupID } = useSelector(
    (state) => state.memberDetails
  );

  const { axios, alert } = useComponent();
  const [dialogState, setDialogState] = useState(false);

  return (
    <Wrapper>
      {userState.assignedTo === firstName + " " + lastName && (
        <Tooltip title="End Chat">
          <AvatarButton
            style={{ color: "red" }}
            onClick={() => setDialogState(true)}
          >
            <CommentsDisabled style={{ height: "20px", width: "20px" }} />
          </AvatarButton>
        </Tooltip>
      )}

      <Dialog open={dialogState} onClose={handleDialogClose}>
        <DialogContent>
          <Typography variant="h6">
            Are you sure you want to end chat with this user?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="error" onClick={endChat}>
            End Chat
          </Button>

          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Button
        variant={latestUserMessageRead ? "contained" : "outlined"}
        onClick={enableToRead}
        disabled={latestUserMessageRead && true}
      >
        {latestUserMessageRead ? "Read" : "Mark as Read"}
      </Button>

      {userState.assignedTo === "" && (
        <Tooltip title="Assign to me">
          <AvatarButton style={{ color: "#376fd0" }} onClick={assignToMyself}>
            <BiTransfer style={{ height: "20px", width: "20px" }} />
          </AvatarButton>
        </Tooltip>
      )}

      <Tooltip title="View Profile">
        <AvatarButton
          style={{ color: "#376fd0" }}
          onClick={() =>
            setChatState({ type: chatReducerConstants.TOGGLE_PROFILE_SECTION })
          }
        >
          <Person style={{ height: "20px", width: "20px" }} />
        </AvatarButton>
      </Tooltip>
    </Wrapper>
  );

  function handleDialogClose() {
    setDialogState(false);
  }

  function endChat() {
    axios({
      url: "/label/get/assigned-label?sender_id=" + userState.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        if (response.data.length > 0) {
          axios({
            url: `/livechat/end-chat/${userState.ID}?end_by=agent`,
            method: "POST",
          }).then((response) => {
            if (response.status) {
              handleDialogClose();
              alert.success("Chat has been ended.");

              var index = userList.findIndex(
                (user) => user.ID === userState.ID
              );

              let users = [...userList];
              users[index].assignedTo = "";
              users[index].chatActive = false;

              setUserList(users);
              setUserState({
                type: userReducerConstants.SET_USER,
                payload: {
                  ID: userState.ID,
                  name: userState.name,
                  phone: userState.phone,
                  email: userState.email,
                  assignedTo: "",
                  chatActive: false,
                },
              });
            }
          });
        } else {
          alert.error("Please Assign Label To Chat.");
          axios({
            url: `/livechat/end-chat/${userState.ID}?end_by=agent`,
            method: "POST",
          }).then((response) => {
            if (response.status) {
              handleDialogClose();
              alert.success("Chat has been ended.");

              var index = userList.findIndex(
                (user) => user.ID === userState.ID
              );

              let users = [...userList];
              users[index].assignedTo = "";
              users[index].chatActive = false;

              setUserList(users);
              setUserState({
                type: userReducerConstants.SET_USER,
                payload: {
                  ID: userState.ID,
                  name: userState.name,
                  phone: userState.phone,
                  email: userState.email,
                  assignedTo: "",
                  chatActive: false,
                },
              });
            }
          });
        }
      }
    });
  }

  function enableToRead() {
    var index = userList.findIndex((user) => user.ID === userState.ID);

    setUserList((prevValue) =>
      prevValue.map((clickedUser) => {
        if (clickedUser.ID === userList[index].ID) {
          return {
            ...clickedUser,
            newMessage: false,
          };
        }

        return clickedUser;
      })
    );
    axios({
      url: `/update/user-read/${userState.ID}`,
      method: "PUT", //@mohitbhatt update in method
    });
    setLatestUserMessageRead(!latestUserMessageRead);
  }

  function assignToMyself() {
    axios({
      url: `/livechat/assigned-member?bot_id=${activeBot.ID}`,
      data: {
        member_id: ID,
        sender_id: userState.ID,
      },
      method: "PATCH",
    }).then((response) => {
      if (response.status) {
        alert.success("User has been assigned");

        var index = userList.findIndex((user) => user.ID === userState.ID);

        let users = [...userList];
        users[index].assignedTo = firstName + " " + lastName;
        users[index].chatActive = true;

        setUserList(users);
        setUserState({
          type: userReducerConstants.SET_USER,
          payload: {
            ID: userState.ID,
            name: userState.name,
            phone: userState.phone,
            email: userState.email,
            assignedTo: firstName + " " + lastName,
            chatActive: true,
          },
        });
      }
    });
  }
}
