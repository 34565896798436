import { Grid, Typography } from "@mui/material";

import { Autocomplete, TextField } from "./../../../components";

export default function FormOne({ labels, memberId, formikProps }) {
  memberId = memberId.filter((items) => {
    return items.is_active === true;
  });

  return (
    <Grid container p={3} direction={"column"} xs={12}>
      <Grid item mb={3}>
        <Typography variant="h5">Filter by Member ID and Labels.</Typography>
      </Grid>
      <Grid item>
        <TextField
          name={"requestName"}
          label="Request Name"
          formikProps={formikProps}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple={true}
          name="memberId"
          label="Select MemberID"
          defaults={{
            primaryKey: "first_name",
            displayLabel: "first_name",
          }}
          required={false}
          options={memberId}
          formikProps={formikProps}
          sx={{ mt: "10px" }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple={true}
          name="labels"
          label="Select Labels"
          defaults={{
            primaryKey: "title",
            displayLabel: "title",
          }}
          required={false}
          options={labels}
          formikProps={formikProps}
          sx={{ mt: "10px" }}
        />
      </Grid>
    </Grid>
  );
}
