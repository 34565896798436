import * as Yup from "yup";

export const formDetailObject = {
  topic: "",
  question: [{ q1: "" }, { q2: "" }, { q3: "" }],
  utteranceID: "",
  name: "",
  type: "text",
  payload: {},
  activeStatus: true,
  createdAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: "",
};

export function objectFromResponseData(responseData) {
  return {
    utteranceID: responseData._id,
    name: responseData.name,
    type: responseData.type,
    payload: responseData.payload,
    activeStatus: responseData.is_active,
    createdAt: responseData.created_at,
    createdBy: responseData.created_by,
    updatedAt: responseData.updated_at,
    updatedBy: responseData.updated_by,
  };
}
export function objectFormData(formData) {
  return {
    topic: formData.topic,
    question: formData.question,
    utteranceID: formData._id,
    name: formData.name,
    type: formData.type,
    payload: formData.payload,
    is_active: formData.activeStatus,
  };
}

export const validationSchema = Yup.object({
  name: Yup.string().required("Please enter a name for utterance"),
  type: Yup.string().required("Please select an utterance type"),
  activeStatus: Yup.string().required("Please set status for utterance"),
  payload: Yup.object().when("type", (type, schema) => {
    switch (type) {
      case "text":
        return Yup.object({
          text: Yup.string().required("Text payload is required"),
        });

      case "quick_replies":
        return Yup.object({
          text: Yup.string().required("Please enter text for quick replies"),
          buttons: Yup.array()
            .min(1)
            .of(
              Yup.object({
                title: Yup.string().required(
                  "Title for quick reply is required"
                ),
                payload: Yup.string().required(
                  "Payload for quick reply is required"
                ),
              })
            ),
        });

      case "image":
      case "video":
      case "audio":
      case "document":
        return Yup.object({
          url: Yup.string().required("URL is required"),
          name: Yup.string(),
          caption: Yup.string(),
          attachment_id: Yup.string(),
        });

      case "button":
        return Yup.array()
          .min(1)
          .of(
            Yup.object({
              type: Yup.string().required("Type for button is required"),
              title: Yup.string().required("Title for button is required"),
              url: Yup.string().required("URL for button is required"),
              extension: Yup.string().required(
                "Select whether extension is available or not"
              ),
            })
          );

      case "location":
        return Yup.object({
          longitude: Yup.string().required("Longitude is required"),
          latitude: Yup.string().required("Latitude is required"),
          address: Yup.string(),
          image_url: Yup.string(),
        });

      case "contact":
        return Yup.object({
          name: Yup.object({
            first_name: Yup.string().required(
              "First Name for contact is required"
            ),
          }),
          phones: Yup.array().of(
            Yup.object({
              phone: Yup.string().required(
                "Phone Number for contact is required"
              ),
            })
          ),
        });

      default:
        return schema;
    }
  }),
});
