export const initialUserState = {
  ID: "",
  name: "",
  assignedTo: "",
};

export function userReducer(state, action) {
  switch (action.type) {
    case userReducerConstants.SET_USER:
      return {
        ID: action.payload.ID,
        name: action.payload.name,
        phone: action.payload.phone,
        email: action.payload.email,
        assignedTo: action.payload.assignedTo,
        chatActive: action.payload.chatActive,
      };

    case userReducerConstants.SET_ASSIGNED_TO:
      return {
        ...state,
        assignedTo: action.payload.assignedTo,
      };

    case userReducerConstants.SET_LIVECHAT_ACTIVE:
      return {
        ...state,
        chatActive: true,
      };

    case userReducerConstants.SET_LIVECHAT_INACTIVE:
      return {
        ...state,
        chatActive: false,
      };

    case userReducerConstants.TOGGLE_LIVECHAT:
      return {
        ...state,
        chatActive: !state.chatActive,
      };

    case userReducerConstants.RESET_USER:
      return initialUserState;

    default:
      return state;
  }
}

export const userReducerConstants = {
  SET_USER: "SET_USER",
  SET_ASSIGNED_TO: "SET_ASSIGNED_TO",
  SET_LIVECHAT_ACTIVE: "SET_LIVECHAT_ACTIVE",
  SET_LIVECHAT_INACTIVE: "SET_LIVECHAT_INACTIVE",
  TOGGLE_LIVECHAT: "TOGGLE_LIVECHAT",
  RESET_USER: "RESET_USER",
};
