import { Grid, Typography, Link, List, ListItem } from "@mui/material";

import {
  Paper,
  TextField,
  FileUpload,
  ComponentHeader,
} from "../../../../components";

export default function FacebookVerificationAndIntegrationFormDisplay({
  formikProps,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Account Verification"
          subtitle="To finish the account making process you need to complete the
          following steps."
        />
      </Grid>

      <Grid item xs={12}>
        <List>
          <ListItem>
            <Typography variant="subtitle2">
              1. Verify you business with facebook and enter the facebook
              business ID.{" "}
              <Link
                target="_blank"
                href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347"
              >
                Click here
              </Link>{" "}
              to continue to facebook verification page.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="subtitle2">
              2.{" "}
              <Link
                target="_blank"
                href="https://dolphinchat.s3.ap-south-1.amazonaws.com/dashboard/admin/documents/ExhibitB.pdf"
              >
                Download
              </Link>{" "}
              Exhibit B - Client Terms form and upload it back with your
              signature.
            </Typography>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Facebook Business ID"
          name="fbBusinessVerificationID"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <FileUpload
          required
          folderPath={
            "whatsapp/registration/exhibit_B/" + new Date().getTime() + "_"
          }
          allowedExtension=".pdf"
          maxSize="10"
          label="Exhibit B - Client Terms"
          name="karixTnCFromURL"
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
