import { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState, useReducer } from "react";

import Profile from "./chats components/Profile";
import { changeActiveChat } from "./helperFunctions";
import MainWindow from "./chats components/MainWindow";
import ActiveChatBar from "./chats components/ActiveChatBar";
import { chatReducerConstants } from "./chats components/chatReducer";
import { initialChatState, chatReducer } from "./chats components/chatReducer";
import { initialUserState, userReducer } from "./chats components/userReducer";

const Wrapper = styled.div`
  height: calc(100vh - 146px);
  display: flex;
  gap: 5px;
`;

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default function Chats() {
  const { leadID } = useParams();

  const [userList, setUserList] = useState([]);

  const [senderID, setSenderID] = useState(leadID);

  const { activeBot } = useSelector((state) => state.memberDetails);

  const [chatState, setChatState] = useReducer(chatReducer, initialChatState);
  const [userState, setUserState] = useReducer(userReducer, initialUserState);

  const [searchQuery, setSearchQuery] = useState({
    search: "",
    name: "",
    email: "",
    phoneNumber: "",
    label: "",
    assignedTo: "",
    conversation: "",
    unReadConversation: "",
    assign: "",
  });

  useEffect(() => {
    if (!userState.ID) {
      setChatState({ type: chatReducerConstants.HIDE_PROFILE_SECTION });
    }
    if (senderID !== "all") {
      changeActiveChat(setUserState, "allChats", setChatState, senderID);

      setSearchQuery((prevValue) => ({
        ...prevValue,
        search: senderID.replace(`${activeBot.ID}*_*`, ""),
      }));
    }
  }, [userState.ID]);

  return (
    <Wrapper>
      <Container>
        <ActiveChatBar
          setUserState={setUserState}
          chatState={chatState}
          setChatState={setChatState}
          userList={userList}
        />

        <MainWindow
          userList={userList}
          setUserList={setUserList}
          chatState={chatState}
          setChatState={setChatState}
          userState={userState}
          setUserState={setUserState}
          senderID={senderID}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setSenderID={setSenderID}
        />
      </Container>

      {userState.ID && chatState.profileVisibility && (
        <Profile
          setUserList={setUserList}
          setChatState={setChatState}
          userState={userState}
        />
      )}
    </Wrapper>
  );
}
