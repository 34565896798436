import * as Yup from "yup";

export const signUpDetailsObject = {
  email: "",
  firstName: "",
  lastName: "",
  gender: "",
  businessName: "",
  phoneNumber: "",
  otp: "",
  countryCode: "",
  mobileNumber: "",
};

export function objectFromFormData(formData) {
  return {
    email: formData.email,
    first_name: formData.firstName,
    last_name: formData.lastName,
    gender: formData.gender,
    business_name: formData.businessName,
    phone: formData.phoneNumber,
  };
}

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  businessName: Yup.string().required("Business name is required."),
  gender: Yup.string().required("Gender is required"),
  otp: Yup.string().required("OTP is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .matches(
      /^([\w-.]+@(?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!outlook\.com)(?!yopmail\.com)(?!protonmail\.com)(?!zoho\.com)([\w-]+.)+[\w-]{2,4})?$/,
      "You can only use business emails."
    )
    .max(255)
    .required("Email is required"),
});
