import { Grid, MenuItem } from "@mui/material";

import {
  Paper,
  ComponentHeader,
  TextField,
  CreatedAndUpdatedInfo,
} from "../../../components";

export default function FormDisplay({
  editState,
  formikProps,
  pagePermissions,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Group Details"
          subtitle="Basic details for the group"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="groupName"
          label="Group Name"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      {!!formikProps.values.permissionsGroupID && (
        <Grid item xs={3}>
          <TextField
            disabled
            name="memberCount"
            label="Total Members"
            formikProps={formikProps}
          />
        </Grid>
      )}

      <Grid item xs>
        <TextField
          select
          name="activeStatus"
          label="Group Status"
          disabled={!(editState && pagePermissions.update)}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <CreatedAndUpdatedInfo formikProps={formikProps} />
    </Paper>
  );
}
