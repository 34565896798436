import * as Yup from "yup";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import FormOne from "./FormOne";
import { useAlert, useAxios } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "750px" },
}));

export default function RequestChatTranscripts({
  memberId,
  setRequestTranscripts,
  requestTranscripts,
  labels,
}) {
  const [formData, setFormData] = useState({
    requestName: "",
    memberId: [],
    labels: [],
    dateRange: [],
    startTime: "",
    endTime: "",
  });

  const axios = useAxios();

  const classes = useStyles();

  const { ID } = useSelector((state) => state.memberDetails);

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={requestTranscripts}
      onClose={() => setRequestTranscripts(false)}
    >
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pl={10}
      >
        <Grid item>
          <Typography variant="h5">Request Chat Transcripts</Typography>
        </Grid>
        <Grid item>
          <IconButton
            disableRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={() => setRequestTranscripts(false)}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(formData) => {
            let requestData = {
              label_ids: [],
              start_time: formData.startTime / 1000,
              end_time: formData.endTime / 1000,
              report_name: formData.requestName,
              member_ids: [],
            };

            for (let data of formData.labels) {
              requestData.label_ids.push(data._id);
            }
            for (let data of formData.memberId) {
              requestData.member_ids.push(data._id);
            }

            axios({
              url: "/insights/transcription/" + ID,
              method: "POST",
              data: requestData,
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                setRequestTranscripts(false);
                setFormData({
                  requestName: "",
                  memberId: [],
                  labels: [],
                  dateRange: [],
                  startTime: "",
                  endTime: "",
                });
                setState([
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                  },
                ]);
              }
            });
          }}
        >
          {(formikProps) => {
            function handleChange(item) {
              setState([item.selection]);
              const timestamp1 = new Date(item.selection.startDate).getTime();
              const timestamp2 = new Date(item.selection.endDate).getTime();

              formikProps.setFieldValue("startTime", timestamp1);
              formikProps.setFieldValue(
                "endTime",
                timestamp2 + 3600 * 1000 * 24
              );
            }

            return (
              <Fragment>
                <Grid display={"flex"}>
                  <Grid xs={12}>
                    <DateRange
                      ranges={state}
                      minDate={
                        formikProps.values.startTime
                          ? new Date(
                              state[0].startDate.getFullYear(),
                              state[0].startDate.getMonth(),
                              state[0].startDate.getDate()
                            )
                          : new Date(1000, 1, 0)
                      }
                      maxDate={
                        formikProps.values.endTime
                          ? new Date(
                              state[0].startDate.getFullYear(),
                              state[0].startDate.getMonth(),
                              state[0].startDate.getDate() + 7
                            )
                          : new Date(3000, 1, 0)
                      }
                      onChange={(items) => handleChange(items)}
                    ></DateRange>
                  </Grid>

                  <FormOne
                    labels={labels}
                    memberId={memberId}
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"space-between"}
                  style={{ textAlign: "end" }}
                >
                  <Button
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]);
                      setFormData({
                        requestName: "",
                        memberId: [],
                        labels: [],
                        dateRange: [],
                        startTime: "",
                        endTime: "",
                      });
                      setRequestTranscripts(false);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={formikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!formikProps.dirty || !formikProps.isValid}
                  >
                    Generate Request Chat Transcripts
                  </Button>
                </Grid>
              </Fragment>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const validationSchema = Yup.object().shape({
  requestName: Yup.string().required("Request Name is required."),
  // memberId: Yup.array().min(1).required("Member Idis required."),
  // labels: Yup.array().min(1).required("Label is required."),
  startTime: Yup.number().required("Start Date is required."),
  endTime: Yup.number().required("End Date is required."),
});
