import React from "react";

import { Formik } from "formik";

import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { TextField } from "../../../../components";
import { useComponent } from "../../../../hooks";

export default function OptionPopUp({
  addOptionPopUp,
  setAddOptionPopUp,
  formikProps,
}) {
  const { alert } = useComponent();
  return (
    <Dialog open={addOptionPopUp.state} onClose={handleClose} fullWidth>
      <DialogTitle
        style={{
          borderBottom: "1px solid #cbcbcb",
          fontSize: "30px!important",
        }}
      >
        Option Fields
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={addOptionPopUp.data}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          if (addOptionPopUp.mode === "add") {
            var options = [...formikProps.values.options];
            for (let option of options) {
              if (option.id === formData.id) {
                alert.error("Please provide unique option ID.");
                setSubmitting(true);
                return;
              }
            }
            formData = {
              ...formData,
              is_active: true,
            };
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              options: [...prevVal.options, formData],
            }));
            setSubmitting(false);
          } else {
            formikProps.setValues((prevValue) => {
              let newOptions = [];

              for (let options of prevValue.options) {
                if (options.id === formData.id) {
                  newOptions.push(formData);
                } else {
                  newOptions.push(options);
                }
              }

              return {
                ...prevValue,
                options: newOptions,
              };
            });
            setSubmitting(false);
          }
          handleClose(false);
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="id"
                    label="Option ID"
                    disabled={Boolean(addOptionPopUp.data?.id)}
                    formikProps={dialogFormikProps}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="label"
                    label="Option Label"
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    value="save"
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setAddOptionPopUp({
      state: false,
      data: {},
    });
  }
}
