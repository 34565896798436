import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Grid,
  Paper as MUIPaper,
} from "@mui/material";
import styled from "styled-components";
import CommentsDialog from "./CommentsDialog";
import { TiAttachment } from "react-icons/ti";

import { convertDate } from "../../../../utils";
import { TextAvatar } from "../../../../components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CommentWrapper = styled.div`
  min-height: 80px;
  width: 100%;
  border-radius: 4px;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  border: ${({ theme }) => {
    return theme.name === "DARK" ? "1px solid #e8e8e838" : "1px solid #e8e8e8";
  }};
  &: hover {
    background: #e3f2fd1a;
  }
  background: ${({ activeUser, theme }) => {
    return activeUser && theme.palette.background.default;
  }};
`;

const ContextWrapper = styled.div`
  width: 175px;
`;

export default function Comments({
  userState,
  comments,
  commentDialog,
  setCommentDialog,
}) {
  function downloadAttachment(url) {
    window.location.href = url;
  }

  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>

      {comments?.map((item, index) => (
        <CommentWrapper
          key={index}
          onClick={() => {
            setCommentDialog({
              state: true,
              data: {
                comment_id: item._id,
                text: item.text,
                attachment: {
                  name: item.attachment.name,
                  url: item.attachment.url,
                },
              },
            });
          }}
        >
          <TextAvatar
            userName={item.created_by || "Unknown User"}
            size={{ height: "50px", width: "50px" }}
          />

          <ContextWrapper>
            <Typography
              noWrap
              variant="subtitle2"
              textOverflow="ellipsis"
              style={{ color: "rgba(0,0,0,0.54)" }}
            >
              {item.created_by}
            </Typography>

            <Typography noWrap variant="subtitle2" textOverflow="ellipsis">
              {item.text?.slice(0, 200) +
                (item.text?.length > 200 ? "..." : "")}
            </Typography>

            {!!item.attachment.url && (
              <Tooltip title={"Download" + item.attachment.name}>
                <IconButton
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  style={{
                    padding: "0",
                    color: "rgb(38,77,145)",
                  }}
                  onClick={() => downloadAttachment(item.attachment.url)}
                >
                  <TiAttachment />
                  <Typography
                    variant="caption"
                    style={{ color: "rgb(38,77,145)" }}
                  >
                    {item.attachment.name}
                  </Typography>
                </IconButton>
              </Tooltip>
            )}

            <Typography variant="caption">
              {convertDate(item.created_at)}
            </Typography>
          </ContextWrapper>
        </CommentWrapper>
      ))}

      <Button
        variant="outlined"
        onClick={() => {
          setCommentDialog({
            state: true,
            data: {
              text: "",
              attachment: {
                name: "",
                url: "",
              },
            },
          });
        }}
      >
        Add Comment
      </Button>

      <CommentsDialog
        userState={userState}
        commentsDialog={commentDialog}
        setCommentDialog={setCommentDialog}
      />
    </Wrapper>
  );
}
