import React from "react";

import DocumentInterface from "./DocumentInterface";

export default function Document({
  formData,
  pagePermissions,
  documents,
  setAddDocument,
  setDeleteDocument,
}) {
  return (
    <React.Fragment>
      <DocumentInterface
        documents={documents}
        setAddDocument={setAddDocument}
        setDeleteDocument={setDeleteDocument}
        formData={formData}
        pagePermissions={pagePermissions}
      />
    </React.Fragment>
  );
}
