import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";

import {
  Autocomplete,
  ComponentHeader,
  Paper,
  TextField,
} from "../../../components";
import TestTemplate from "../whatsapp interface/TestTemplate";

export default function FormDisplay({
  formikProps,
  agentID,
  language,
  category,
  addSample,
  botDetails,
}) {
  const [popUp, setPopUp] = useState(false);
  const handleChangeLanguage = (value, type) => {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateLanguage: setTemplateLanguage(prevVal.templateLanguage),
      }));

      function setTemplateLanguage(templateLanguage) {
        templateLanguage.code_id = value._id;
        return templateLanguage;
      }
    } else {
      value = {
        _id: "",
        language: "",
      };
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateLanguage: setTemplateLanguage(prevVal.templateLanguage),
      }));
      function setTemplateLanguage(templateLanguage) {
        templateLanguage.code_id = value._id;
        return templateLanguage;
      }
    }
  };
  return (
    <React.Fragment>
      <Paper className="topElement">
        <Grid item xs={12}>
          <ComponentHeader
            title="Template Details"
            subtitle="WhatsApp message templates are specific message formats that
            businesses use to send out notifications or customer care messages
            to people."
            buttons={[
              formikProps.values.ID && <ApprovedChip />,
              <PendingChip />,
              <RejectedChip />,
              <TestButton />,
            ]}
          />
        </Grid>

        <TestTemplate
          popUp={popUp}
          setPopUp={setPopUp}
          agentID={agentID}
          templateID={formikProps.values.ID}
        />

        <Grid style={{ paddingTop: "0px" }} item xs={12}>
          {botDetails?.authentication?.whatsapp_server === "normal" && (
            <Typography style={{ fontSize: "13px", color: "#0c5460" }}>
              <Typography variant="button" style={{ color: "#ff0000" }}>
                Important:
              </Typography>{" "}
              Please register only the{" "}
              <Typography variant="button" color="primary">
                Approved Templates
              </Typography>{" "}
              here or else they'll be not of use.
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="templateName"
            label="Template Name"
            required
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
            inputProps={{
              maxLength: 512,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formikProps.values?.templateName.length}/512
                  <IconButton>
                    <Tooltip title="When creating a message template, it is mandatory to give it a name. It is advised that the name reflect the template’s content. This name must only contain lowercase letters, numbers, and underscores.">
                      <Info />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            name="templateLanguage.code_id"
            label="Template Language"
            defaults={{
              primaryKey: "_id",
              displayLabel: "language",
            }}
            options={language}
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            onChange={(_, value, type) => handleChangeLanguage(value, type)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            required
            name="status"
            label="Status"
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            required
            name="category"
            label="Template Category"
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
          >
            {category.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.display_name}
                <br />
                {item.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Paper>
    </React.Fragment>
  );

  function TestButton() {
    if (!formikProps.values.ID) {
      return "";
    }
    return (
      formikProps.values.ID &&
      formikProps.values.verifiedStatus ===
        "8360b5eca314424a9d56c37004ccecc9" && (
        <Grid item textAlign={"end"} alignSelf="center">
          <Button
            onClick={() => setPopUp(true)}
            variant="contained"
            color="primary"
          >
            Test Template
          </Button>
        </Grid>
      )
    );
  }

  function ApprovedChip() {
    return (
      formikProps.values.ID &&
      formikProps.values.verifiedStatus ===
        "8360b5eca314424a9d56c37004ccecc9" && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              background: "green",
              margin: "4px",
            }}
          />
          <Typography variant="h6">Approved</Typography>
        </div>
      )
    );
  }

  function PendingChip() {
    return (
      formikProps.values.ID &&
      formikProps.values.verifiedStatus ===
        "531c1247d1ca4329b4eef4596a099b52" && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              background: "#FFF4E5",
              margin: "4px",
            }}
          />
          <Typography variant="h6">Pending</Typography>
        </div>
      )
    );
  }

  function RejectedChip() {
    return (
      formikProps.values.ID &&
      formikProps.values.verifiedStatus ===
        "c280844cac4643698021059955d6bbe5" && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              background: "#FFD2D2",
              margin: "4px",
            }}
          />
          <Typography variant="h6">Failed</Typography>
        </div>
      )
    );
  }
}
