import { useState, Fragment } from "react";

import pages from "../../constants/pages";
import { useComponent } from "../../hooks";
import { PageHeader } from "../../components";
import FaqTable from "./faq list components/FaqTable";

export default function FaqList({ pageID }) {
  const { pagePermissions } = useComponent({ pageID });
  const [faqList, setFaqList] = useState([
    { topic: "introduction", no_of_question: 8 },
  ]);

  return (
    <Fragment>
      <PageHeader
        pageName="Faq"
        addIcon={[
          "Add New Faq",
          pages.faqsDetail.route,
          pagePermissions.create,
        ]}
      />

      <FaqTable faqList={faqList} />
    </Fragment>
  );
}
