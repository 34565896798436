import { Formik } from "formik";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useComponent } from "../../../../hooks";
import WhatsappStyleBot from "../UI Components/WhatsappStyleBot";
import { validationSchema, objectFromFormData } from "./whatsappStyleBotObject";

export default function FormWhatsapp({
  formData,
  editState,
  setEditState,
  image,
  setImage,
  setFormData,
}) {
  const axios = useAxios();
  const { alert } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/bot/widget?agent_id=" + activeBot.ID,
          method: "PATCH",
          data: objectFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);

            setFormData(formData);

            setEditState(false);

            alert.success(response.message.displayMessage);
          }
        });
      }}
    >
      {(formikProps) => (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              setImage={setImage}
              image={image}
            />
          </Grid>

          <Grid item xs={5}>
            <WhatsappStyleBot formikProps={formikProps} />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
