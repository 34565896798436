import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import queryString from "query-string";

import { useAxios, useComponent } from "./../../hooks";
import { PageHeader, Loader } from "./../../components";
import { broadcastDetailsObject } from "./push notification details components/broadcastDetailsObject";
import EmailDisplayForm from "./push notification details components/push notification form component/Form";

export default function PushNotification({ pageID }) {
  const axios = useAxios();
  const params = useParams();
  const dispatch = useDispatch();
  const { loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const broadcastID = params.broadcastID || "";

  const [fields, setFields] = useState([]);
  const [template, setTemplate] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [scheduledTime, setScheduledTime] = useState([]);
  const [formData, setFormData] = useState(broadcastDetailsObject);

  useEffect(() => {
    loader.start(4);

    setFormData(broadcastDetailsObject);

    axios({
      url:
        "/broadcast_email/get_broadcast_list?broadcast_id=" +
        broadcastID +
        "&agent_id=" +
        activeBot.ID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setGroupList(response.data instanceof Array ? response.data : []);
      }
      loader.apiComplete();
    });

    axios({
      url: "/broadcast/get/group/" + activeBot.ID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setGroupList(response.data);
        loader.apiComplete();
      }
    });

    axios({
      url: "/custom_fields/get_fields_for_agent?agent_id=" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      setFields(response.data);
      loader.apiComplete();
    });

    axios({
      url: "/push_notification/template?agent_id=" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setTemplate(response.data.temp_obj);
        loader.apiComplete();
      }
    });
  }, [broadcastID, axios, activeBot, dispatch, loader]);

  return (
    <Loader>
      <PageHeader pageName="Create Broadcast" />

      <EmailDisplayForm
        formData={formData}
        groupList={groupList}
        setFormData={setFormData}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
        activeBot={activeBot}
        template={template}
        fields={fields}
      />
    </Loader>
  );
}
