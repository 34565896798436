import styled from "styled-components/macro";
import { Swiper as NormalSwiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Pagination } from "swiper";

import Slides from "./swiper components/Slides";

// added these coz of bug
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

const Swiper = styled(NormalSwiper)`
  height: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
`;

export default function Carousel() {
  return (
    <Swiper
      loop={true}
      modules={[Autoplay, Pagination]}
      pagination={{ dynamicBullets: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {Array(6)
        .fill()
        .map((_, index) => (
          <SwiperSlide key={index}>
            <Slides slideNo={index} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
