import styled from "styled-components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Circle } from "@mui/icons-material";
import {
  Paper as MuiPaper,
  Button,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import { useComponent } from "../../../hooks";
import { chatReducerConstants } from "./chatReducer";
import { changeActiveChat } from "../helperFunctions";

const Paper = styled(MuiPaper)`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

const TextFieldWithCSS = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: #4076d2;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
  .MuiOutlinedInput-input {
    display: flex;
    align-items: center;
    color: ${(props) => {
      switch (props.chatStatus) {
        case "offline":
          return "red";
        case "online":
          return "green";
        case "break":
          return "orange";
        default:
          return "inherit";
      }
    }};
  }
`;

export default function ActiveChatBar({
  setUserState,
  chatState,
  setChatState,
}) {
  const { axios } = useComponent();

  const { ID, activeBot } = useSelector((state) => state.memberDetails);

  const [memberStatus, setMemberStatus] = useState([]);

  useEffect(() => {
    axios({
      url: "/livechat/member-list",
      params: {
        bot_id: activeBot.ID,
        member_id: ID,
      },
    }).then((response) => {
      if (response.status) {
        const memberOnline = response.data?.livechat_config?.member_status
          ? (() => {
              switch (response.data?.livechat_config?.member_status) {
                case 0:
                  return "offline";
                case 1:
                  return "online";
                case 2:
                  return "break";
                default:
                  return "offline";
              }
            })()
          : !!response.data._id;

        setChatState({
          type: (() => {
            switch (memberOnline) {
              case "offline":
                return chatReducerConstants.SET_CHAT_STATUS_OFFLINE;
              case "online":
                return chatReducerConstants.SET_CHAT_STATUS_ONLINE;
              case "break":
                return chatReducerConstants.SET_CHAT_STATUS_BREAK;
              default:
                return chatReducerConstants.SET_CHAT_STATUS_OFFLINE;
            }
          })(),
        });
      }
    });

    axios({
      url: "livechat/member-status-list",
      params: {
        bot_id: activeBot.ID,
      },
    }).then((response) => {
      if (response.status) {
        setMemberStatus(response.data);
      }
    });
  }, [ID, activeBot.ID, axios, setChatState]);

  return (
    <Paper>
      <Button
        style={{
          marginRight: "8px",
          paddingRight: "18px",
          paddingLeft: "10px",
          alignContent: "center",
        }}
        color="primary"
        variant={chatState.activeChat !== "requests" ? "contained" : "text"}
        onClick={() => changeActiveChat(setUserState, "allChats", setChatState)}
      >
        All Users
      </Button>

      <Button
        color="primary"
        variant={chatState.activeChat === "requests" ? "contained" : "text"}
        onClick={() => changeActiveChat(setUserState, "requests", setChatState)}
      >
        Live Chat Queue
      </Button>

      <Grid item xs={4} style={{ marginLeft: "auto", width: "8%" }}>
        <TextFieldWithCSS
          select
          value={
            chatState.chatStatus.charAt(0).toUpperCase() +
            chatState.chatStatus.slice(1)
          }
          onChange={toggleChatStatus}
          chatStatus={chatState.chatStatus}
        >
          {memberStatus.map((items, index) => {
            return (
              <MenuItem
                value={items.description}
                sx={{
                  color:
                    items._id === 0
                      ? "Red"
                      : items._id === 1
                      ? "Green"
                      : items._id === 2
                      ? "orange"
                      : "inherit",
                }}
              >
                <Circle
                  style={{
                    fill:
                      items._id === 0
                        ? "Red"
                        : items._id === 1
                        ? "Green"
                        : items._id === 2
                        ? "orange"
                        : "inherit",
                  }}
                />
                {items.description.toUpperCase()}
              </MenuItem>
            );
          })}
        </TextFieldWithCSS>
      </Grid>
    </Paper>
  );

  function toggleChatStatus(e) {
    const { value } = e.target;
    const isOffline = value === "Offline";
    const isOnline = value === "Online";
    const isBreak = value === "Break";

    axios({
      url: `/livechat/member-availabilty/${ID}`,
      params: { bot_id: activeBot.ID },
      method: "POST",
      data: {
        is_online: isOffline ? false : true,
        member_status: (() => {
          switch (value) {
            case "Offline":
              return 0;
            case "Online":
              return 1;
            case "Break":
              return 2;
            default:
              return "";
          }
        })(),
      },
    }).then((response) => {
      if (response.status) {
        const statusType = isOffline
          ? chatReducerConstants.SET_CHAT_STATUS_OFFLINE
          : isOnline
          ? chatReducerConstants.SET_CHAT_STATUS_ONLINE
          : isBreak
          ? chatReducerConstants.SET_CHAT_STATUS_BREAK
          : "";

        setChatState({ type: statusType });
      }
    });
  }
}
