import { useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, Fragment } from "react";
import { DeleteOutlined } from "@mui/icons-material";

import pages from "../../../../../constants/pages";
import Paper from "./../../../../../components/Paper";
import { DeleteDialog } from "../../../../../components";
import { useAxios, useComponent } from "../../../../../hooks";

export default function CreateButton({ formikProps, editState, setEditState }) {
  const axios = useAxios();
  const { alert } = useComponent();
  const navigation = useNavigate();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [dialogState, setDialogState] = useState({
    state: false,
    id: formikProps.values.ID,
  });

  const handleDelete = () => {
    axios({
      url:
        "/broadcast_email/template/" +
        dialogState.id +
        "?agent_id=" +
        activeBot.ID,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        navigation(pages.emailTemplateList.route);
      }
    });
  };

  return (
    <Paper>
      {Boolean(formikProps.values.ID) && (
        <Fragment>
          <Grid item container justifyContent={"space-evenly"}>
            <Grid item container xs={9}>
              <Grid item xs={1.5}>
                <Button variant="outlined" onClick={() => navigation(-1)}>
                  Back
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditState(true);
                    editState && formikProps.handleSubmit();
                  }}
                >
                  {`${!editState ? "Edit Template" : "Submit Template"}`}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={3} display={"flex"} justifyContent={"end"}>
              <Button
                variant="contained"
                style={{ background: "#d32f2f", color: "#fff" }}
                onClick={() =>
                  setDialogState({
                    state: true,
                    id: formikProps.values.ID,
                  })
                }
              >
                <DeleteOutlined fontSize="small" /> &nbsp; Delete Template
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Grid item xs />

      <DeleteDialog
        dialogState={dialogState.state}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </Paper>
  );
}
