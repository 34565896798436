import { Fragment, useState } from "react";

import Form from "./business profile component/Form";
import { Loader, PageHeader } from "../../components";
import {
  businessDetailsObject,
  objectFromResponseData,
} from "./business profile component/businessDetailsObject";

export default function BusinessProfile() {
  const [edit, setEdit] = useState();
  const [formData, setFormData] = useState(businessDetailsObject);

  return (
    <Fragment>
      <PageHeader pageName="Business Profile" editIcon={[true]} />

      <Form
        edit={edit}
        setEdit={setEdit}
        formData={formData}
        setFormData={setFormData}
      />
    </Fragment>
  );
}
