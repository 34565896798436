import { Formik } from "formik";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../../constants/pages";
import { ButtonFooter } from "../../../../components";
import { useAxios, useComponent } from "../../../../hooks";
import { dataFromFormData } from "./slotDetailsObject";

export default function Form({
  formData,
  setEditState,
  editState,
  pagePermissions,
  intents,
  entities,
}) {
  const navigate = useNavigate();
  const { alert } = useComponent();
  const axios = useAxios({ baseURL: "botBuilder" });
  const activeBot = useSelector((state) => state.memberDetails.activeBot);

  function handleDelete() {
    axios({
      url: "/delete-slot/" + activeBot.ID + "/" + formData.slotName,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        navigate(pages.slotList.route);
      }
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/create-slot/" + activeBot.ID;
        if (!!formData.ID) {
          url = "/update-slot/" + activeBot.ID;
        }

        axios({
          url: url,
          method: "POST",
          data: dataFromFormData(formData),
        }).then((response) => {
          if (response.status) {
            setSubmitting(false);
            setEditState(false);

            navigate(pages.slotDetails.route + response.data.slot_name);
          } else {
            setSubmitting(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            formikProps={formikProps}
            setEditState={setEditState}
            editState={editState}
            intents={intents}
            entities={entities}
          />

          <ButtonFooter
            ID={formData.ID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
            deleteButton={[pagePermissions.delete, handleDelete, "Delete Slot"]}
          />
        </Fragment>
      )}
    </Formik>
  );
}
