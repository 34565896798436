import { Info } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

export default function Instructions() {
  return (
    <Grid
      style={{
        background: "#e8e8e8",
        display: "flex",
        padding: "12px 0 12px 12px",
        margin: "12px 1px 0 12px",
      }}
      item
      xs={12}
    >
      <div style={{ width: "30px" }}>
        <Info color="secondary" style={{ fontSize: "20px" }} />
      </div>
      <div>
        <Typography variant="body2" style={{ letterSpacing: "0.5px" }}>
          Here you can create Regex for your field which will validate your
          field from taking wrong input or data.
        </Typography>
        <Typography
          style={{
            color: "#0075F6",
            letterSpacing: "1px",
          }}
        >
          • Prefix must MATCH if present (case insensitive) and must be IGNORED
          if not present.
        </Typography>
        <Typography
          style={{
            color: "#0075F6",
            letterSpacing: "1px",
          }}
        >
          • Suffix must MATCH (spaces must be IGNORED!).
        </Typography>

        <Typography
          style={{
            letterSpacing: "1px",
          }}
        >
          Examples (prefix=ABCD, suffix=123456789):
        </Typography>

        <Typography
          style={{
            letterSpacing: "1px",
          }}
        >
          word: ABCD123456789 it is valid
        </Typography>
        <Typography
          style={{
            letterSpacing: "1px",
          }}
        >
          word: 123456789 it is valid
        </Typography>
        <Typography
          style={{
            letterSpacing: "1px",
          }}
        >
          word: ABC1234567 not valid
        </Typography>
      </div>
    </Grid>
  );
}
