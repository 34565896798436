import React, { useState } from "react";

import "./whatsapp.css";
import WhatsappWidgetInterface from "./WhatsappInterface";

export default function MobileViewBot({
  formikProps,
  phoneView,
  setPhoneView,
}) {
  const [showBubble, setShowBubble] = useState(true);
  return (
    <React.Fragment>
      {phoneView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="whatsappsmartphone">
            <WhatsappWidgetInterface
              formikProps={formikProps}
              showBubble={showBubble}
              setShowBubble={setShowBubble}
              phoneView={phoneView}
              setPhoneView={setPhoneView}
            />
          </div>
        </div>
      ) : (
        <WhatsappWidgetInterface
          phoneView={phoneView}
          setPhoneView={setPhoneView}
          formikProps={formikProps}
          showBubble={showBubble}
          setShowBubble={setShowBubble}
        />
      )}
    </React.Fragment>
  );
}
