import { useState } from "react";
import styled from "styled-components/macro";
import { Grid, Chip as MUIChip } from "@mui/material";

import { TextField } from "../../../../components";

const Chip = styled(MUIChip)`
  border-radius: 30px;
  margin: 2px;
`;

export default function To({ formikProps }) {
  const [inputValue, setInputValue] = useState("");

  function handleDelete(index) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.to?.splice(index, 1),
    }));
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleKeyDown(event) {
    if (event.key === "Enter" || event.keyCode === 32) {
      const newMails = [...formikProps.values.to];
      const duplicatedValues = newMails.indexOf(event.target.value.trim());

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }

      if (!event.target.value.replace(/\s/g, "").length) return;

      newMails.push(event.target.value.trim());
      for (let mail of newMails) {
        if (!validateEmail(mail)) {
          return;
        }
      }

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        to: newMails,
      }));
      setInputValue("");
    }
    if (
      formikProps.values.to.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        to: prevVal.to?.slice(0, prevVal.to.length - 1),
      }));
    }
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <TextField
          label="To"
          name="to"
          value={inputValue}
          formikProps={formikProps}
          onKeyDown={handleKeyDown}
          placeholder="Type email and press space or enter."
          InputProps={{
            startAdornment:
              formikProps.values.to.length > 0 &&
              formikProps.values.to.map((item, index) => (
                <Chip
                  key={item}
                  tabIndex={-1}
                  label={item}
                  onDelete={() => handleDelete(index)}
                />
              )),
            onChange: (event) => {
              handleInputChange(event);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
