import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  broadcastName: Yup.string().max(100).required("Enter broadcast name."),
  whenToBroadcast: Yup.string().required("Select when to broadcast."),
  broadcastDate: Yup.string().when("whenToBroadcast", {
    is: (whenToBroadcast) => whenToBroadcast === "later",
    then: Yup.string().required("Please select a date"),
  }),
  broadcastTime: Yup.string().when("whenToBroadcast", {
    is: (whenToBroadcast) => whenToBroadcast === "later",
    then: Yup.string().required("Please select valid time"),
  }),

  flowId: Yup.string().required("Field required"),
  broadcastFile: Yup.string().required("Please upload a file"),
});

export default validationSchema;
