import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import Table from "./group list components/Table";
import { useAxios, useComponent } from "../../hooks";
import { PageHeader, Loader } from "../../components";
import CreateGroupPopUp from "./group details components/CreateGroupPopup";
import pages from "../../constants/pages";

export default function GroupList({ pageID }) {
  const axios = useAxios();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const { pagePermissions, loader } = useComponent({ pageID });

  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState("");
  const [createGroupModal, setCreateGroupModal] = useState(false);

  useEffect(() => {
    loader.start();

    axios({
      url: "/broadcast/get/group/" + activeBot.ID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        loader.stop();
        setGroups(response.data);
      }
    });
  }, [axios, newGroup, loader, activeBot]);

  return (
    <Loader>
      <PageHeader
        pageName="Groups"
        buttons={[
          pagePermissions.create && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => setCreateGroupModal(true)}
            >
              Create Group
            </Button>
          ),
        ]}
      />

      <Table groups={groups} />

      <CreateGroupPopUp
        createGroupModal={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
        setNewGroup={setNewGroup}
        activeBot={activeBot}
      />
    </Loader>
  );
}
