import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Form from "./Form";
import { useAxios, useComponent } from "../../../../hooks";
import { entityObject, dataFromResponse } from "./entityObjectDetails";
import { Loader, PageHeader } from "../../../../components";

export default function EntityDetails({ pageID }) {
  const axios = useAxios({ baseURL: "botBuilder" });
  const params = useParams();

  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(entityObject);

  const { pagePermissions, loader } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const entityID = params.entityID || "";

  useEffect(() => {
    if (!!formData.ID) {
      setEditState(false);
    }
  }, [formData.ID]);

  useEffect(() => {
    loader.start(1);
    if (!!entityID) {
      axios({
        url: "/get-entity/" + activeBot.ID + "?entity_name=" + entityID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setFormData(dataFromResponse(response.data));
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [axios, loader, activeBot.ID, entityID]);

  return (
    <Loader>
      <PageHeader
        pageName="Entity Details"
        editIcon={[editState, setEditState, pagePermissions.update]}
      />
      <Form
        formData={formData}
        setFormData={setFormData}
        setEditState={setEditState}
        editState={editState}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
