import React, { useState } from "react";
import { useSelector } from "react-redux";

import Form from "./Form";
import { PageHeader } from "../../../../components";
import { broadcastDetailsObject } from "../../other components/broadcastDetailsObject";

export default function MessageBroadcastDetails({ pageID }) {
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [scheduledTime, setScheduledTime] = useState([]);
  const [formData, setFormData] = useState(broadcastDetailsObject);

  return (
    <React.Fragment>
      <PageHeader pageName="SMS Broadcast Details" />

      <Form
        formData={formData}
        activeBot={activeBot}
        setFormData={setFormData}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
      />
    </React.Fragment>
  );
}
