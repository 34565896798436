import { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import BotsTable from "./profile components/BotsTable";
import Form from "./profile components/Form";
import { useComponent } from "../../hooks";
import { Loader, PageHeader, Paper, ComponentHeader } from "../../components";
import {
  memberDetailsObject,
  objectFromResponseData,
} from "./profile components/memberDetailsObject";

export default function Profile() {
  const { axios, loader } = useComponent();

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(memberDetailsObject);

  useEffect(() => {
    loader.start();

    axios({ url: "/profile" })
      .then((res) => {
        loader.apiComplete();

        setFormData(objectFromResponseData(res.data));
      })
      .catch((err) => console.error(err));
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Profile"
        editIcon={[editState, setEditState, true]}
      />

      <Form
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
      />

      {!editState && (
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Bots"
              subtitle="List of all the bots you can use"
            />
          </Grid>

          <Grid item xs={12}>
            <BotsTable bots={formData.bots} />
          </Grid>
        </Paper>
      )}
    </Loader>
  );
}
