import React from "react";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../../components/DataTable";
import pages from "../../../../constants/pages";
import { convertDate } from "../../../../utils";

export default function Table({ entities }) {
  const navigate = useNavigate();

  function handleClick(_, cellMetaData) {
    navigate(
      pages.botEntityDetails.route +
        entities[cellMetaData.dataIndex].entity_name
    );
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
    onCellClick: handleClick,
  };

  const columns = [
    {
      name: "entity_name",
      label: "Entity name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "regex",
      label: "No. of Regex",
      options: {
        customBodyRender: (value) => {
          return value ? value.length : "Not Available";
        },
      },
    },

    {
      name: "lookups",
      label: "No. of Lookups",
      options: {
        customBodyRender: (value) => {
          return value ? value.length : "Not Available";
        },
      },
    },

    {
      name: "synonyms",
      label: "No. of Synonyms",
      options: {
        customBodyRender: (value) => {
          return value ? value.length : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "created_by",
      label: "Created By",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={entities} />;
}
