import { Fragment } from "react";
import { Formik } from "formik";

import { validationSchema, objectFromFormData } from "./memberDetailsObject";
import FormDisplay from "./FormDisplay";
import { ButtonFooter } from "../../../components";
import { useComponent } from "../../../hooks";

export default function Form({
  editState,
  setEditState,
  formData,
  setFormData,
}) {
  const { axios, alert } = useComponent();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/update/profile",
          method: "PUT",
          data: objectFromFormData(formData),
        })
          .then((response) => {
            if (response.status) {
              setSubmitting(false);

              setFormData(formData);
              setEditState(false);

              alert.success(response.message.displayMessage);
            } else {
              setSubmitting(false);
            }
          })
          .catch((err) => console.error(err));
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay editState={editState} formikProps={formikProps} />

          <ButtonFooter
            ID={formikProps.values.memberID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
