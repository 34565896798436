import { useState } from "react";
import { Info, CloudUpload } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Switch,
  MenuItem,
  Avatar,
  InputAdornment,
  Button,
} from "@mui/material";

import {
  TextFieldWithIcon,
  TextField,
  Paper,
  ComponentHeader,
} from "../../../../components";
import CropImage from "../crop image components/CropImage";

export default function Bubble({ formikProps, editState, image, setImage }) {
  const [openModal, setOpenModal] = useState(false);

  const handleChangePhoto = (photo) => {
    const file = photo.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setImage((prev) => ({
          ...prev,
          imageResult: reader.result,
          imageTarget: photo.target.files[0],
        }));
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }

    setOpenModal(true);
  };

  const handleChangeAvatar = (e) => {
    var src = e.target.currentSrc;
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      bubbleAvatar: src,
    }));
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"General Settings"}
          subtitle=" Manage basic settings from here, Add ripple effect, enable
                floating etc."
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Auto Open &nbsp;
          <Tooltip title="Enable this to automatically open the conversation window on page load.">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.windowAutoOpen}
          name="windowAutoOpen"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Ripple Effect &nbsp;
          <Tooltip title="Enable this to add a ripple effect to the widget bubble and get your users attention.">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.bubbleRipple}
          name="bubbleRipple"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography style={{ padding: "12px" }}>
          Float &nbsp;
          <Tooltip title="Enable this to give the user freedom to move the widget horizontally to avoid overlapping of website content.">
            <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid style={{ textAlign: "end" }} item xs={3}>
        <Switch
          checked={formikProps.values.bubbleFloat}
          name="bubbleFloat"
          onChange={formikProps.handleChange}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithIcon
          name="parentURL"
          label="Domain Name"
          case="none"
          required
          disabled={!editState}
          formikProps={formikProps}
          icon={
            <Tooltip title="Domain of your website where you want to add a widget.">
              <IconButton style={{ color: "#cbcbcb" }}>
                <Info />
              </IconButton>
            </Tooltip>
          }
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="popupText"
          label="PopUp Text"
          case="none"
          required
          disabled={!editState}
          formikProps={formikProps}
          style={{
            paddingRight: "0px",
          }}
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values.popupText.length}/100
                <Tooltip title="A welcome text for visitors to start the conversation.">
                  <IconButton style={{ color: "#cbcbcb" }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              popupText: e.target.value,
            }));
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          name="bubblePosition"
          label="Bubble Position"
          value={formikProps.values.bubblePosition}
          formikProps={formikProps}
          disabled={!editState}
        >
          <MenuItem value={"left"}>Left</MenuItem>
          <MenuItem value={"right"}>Right</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontSize: "0.9rem" }} variant="h5">
          Avatar
        </Typography>
        <Typography
          style={{
            color: "#808080",
          }}
          variant="body2"
        >
          Image that represents your business or chatbot use-case.
        </Typography>
        <div
          style={{
            display: "flex",
            padding: "20px",
            width: "500px",
          }}
        >
          <Avatar
            style={{
              height: "60px",
              width: "60px",
              margin: "0 10px 0 10px",
              cursor: "pointer",
            }}
            disabled={!editState}
            onClick={handleChangeAvatar}
            src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118620967/manAvatar.jpg"
          />

          <Avatar
            style={{
              height: "60px",
              width: "60px",
              margin: "0 10px 0 10px",
              cursor: "pointer",
            }}
            disabled={!editState}
            onClick={handleChangeAvatar}
            src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118671542/manAvatar2.jpg"
          />

          <Avatar
            style={{
              height: "60px",
              width: "60px",
              margin: "0 10px 0 10px",
              cursor: "pointer",
            }}
            disabled={!editState}
            onClick={handleChangeAvatar}
            src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118719512/womanAvatar.jpg"
          />

          <Avatar
            style={{
              height: "60px",
              width: "60px",
              margin: "0 10px 0 10px",
              cursor: "pointer",
            }}
            disabled={!editState}
            onClick={handleChangeAvatar}
            src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118749788/womanAvatar2.jpg"
          />

          <Typography style={{ margin: "15px 0px 15px 15px" }} variant="h5">
            OR
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                margin: "0 10px 0 10px",
                height: "60px",
                width: "60px",
                cursor: "pointer",
              }}
              src={image?.originalImage}
              onClick={handleChangeAvatar}
            />
            <input
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              disabled={!editState}
              onChange={(event) => handleChangePhoto(event)}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUpload />}
                style={{ margin: "10px" }}
                disabled={!editState}
              >
                Upload
              </Button>
            </label>
          </div>

          {image !== "" && (
            <CropImage
              openModal={openModal}
              setOpenModal={setOpenModal}
              formikProps={formikProps}
              image={image}
              setImage={setImage}
            />
          )}
        </div>
      </Grid>
    </Paper>
  );
}
