import convertDateToBackendFormat from "./convertDateToBackendFormat";

export const broadcastDetailsObject = {
  ID: "",
  channel: "",
  groupID: "",
  broadcastName: "",
  templateID: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
  Subject: "",
  mailText: "",
  variables: [],
};

export function objectFromFormData(formData, channel) {
  return {
    broadcast_name: formData.broadcastName,
    template_id: formData.templateID,
    schedule_type: formData.whenToBroadcast,
    ...(formData.broadcastDate && {
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
    }),
    ...(formData.broadcastTime && {
      schedule_time: formData.broadcastTime,
    }),
    file_url: formData.uploadCSVFile,
  };
}
